const allUnitReceipt = { data: [], loading: true };
const allUnitTableData = { data: [], loading: true };

export const GetAllUnitReceiptRed = (state = allUnitReceipt, action) => {

    switch (action.type) {
        case "get_allunitreceipt":
            return {
                
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetAllUnitReceiptTableRed = (state = allUnitTableData, action) => {
    switch (action.type) {

        case "get_allunittabledata":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
