import React, { useState, useEffect } from "react";
import "../../App.css";
import profilePic from "../../assets/profilelogo.jpg";

// import { BsBell } from "react-icons/bs";
// import { BsThreeDotsVertical } from "react-icons/bs";
import bellPic from "../../assets/bell.svg";
import dotpic from "../../assets/Group1.svg";
import { useHistory } from "react-router-dom";

import dashboardPic from "../../assets/dashboard.svg";
import employeePic from "../../assets/employee.svg";
import performancePic from "../../assets/performance.svg";
import payrollPic from "../../assets/payroll.svg";
import reportPic from "../../assets/report.svg";
import ledgerPic from "../../assets/ledger.svg";

import Dropdown from "react-bootstrap/Dropdown";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Link } from "react-router-dom";
// import AccPayable from "../Accounts/accpayablepages/journalentry";

import MainAddEmployeeComp from "./empolyees/addEmployee";
import HRDashboard from "./dashboard";
import MainDesignationCom from "./empolyees/designation";
import MainSalaryCom from "./empolyees/assignsalary";
import MainAdvanceLoanComp from "./payroll/advanceloan";
import MainPaySlipCom from "./payroll/payslip";
import MainAttendanceCom from "./attendence";
import MainAddRoleCom from "../Accounts/roles/roles";

import { useDispatch, useSelector } from "react-redux";
import { getallRoles } from "../actions/getroles";
import Changeform from "../Accounts/changeform/changeform";
import { useContext } from "react";
import { monthlyAlert } from "../ContextApi/MonthlyAlert";
import AltertModal from "../AlertModal/AltertModal";
import axios from "axios";
import moment from "moment";
import ChangeInReqMain from "../Accounts/ChangeInReqMain2/ChangeInReqMain";
import { Users } from "../Users/Users/Users";
import { getCompanyInfo } from "../actions/GetCompanyInfo";

function SideButton(props) {
  return (
    <>
      <button
        onClick={() => {
          props.onClick();
        }}
        className={
          props.active
            ? "grey side_button_hover row  sideText sideButton  mt-4"
            : "row side_button_hover sideText sideButton  mt-4"
        }
      >
        {props.icon}
        <p className=" ml-3 sideText green_text toggleButton">{props.text}</p>
      </button>
    </>
  );
}
function SideSubButton(props) {
  return (
    <>
      <button
        onClick={() => {
          props.onClick();
        }}
        className={
          props.active ? "sub_button_style_underline" : "sub_button_style "
        }
      >
        {props.icon}
        <span className=" sideText   ">{props.text}</span>
      </button>
      <br></br>
    </>
  );
}
const HrMainPage = () => {
  const history = useHistory();
  const companyInfo = useSelector((state) => state.getCompany);
  useEffect(() => {
    dispatch(getCompanyInfo());
  }, []);
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      localStorage.clear();
      sessionStorage.clear();
      history.push({ pathname: "/" });
    }
  }, []);
  const { changeState } = useContext(monthlyAlert);

  const [showAlert, setshowAlert] = useState(false);
  useEffect(() => {
    if (sessionStorage.getItem("isPaid") === "false") {
      changeState(true);
      setshowAlert(true);
    } else {
      changeState(false);
      setshowAlert(false);
    }
  }, []);
  // useEffect(() => {
  //   axios
  //     .get("https://sheetdb.io/api/v1/dskt971mukoor")
  //     .then((res) => {
  //       if (
  //         res?.data?.length > 0 &&
  //         res.data[0]?.IsPaid === "FALSE" &&
  //         Number(moment().format("DD")) > Number(res.data[0]?.Date)
  //       ) {
  //         sessionStorage.setItem("isPaid", false);
  //         changeState(true);
  //         setshowAlert(true);
  //       } else {
  //         sessionStorage.setItem("isPaid", true);

  //         changeState(false);
  //         setshowAlert(false);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  // toggle state start
  const rolesdata = useSelector((state) => state.GetAllRole);
  let dispatch = useDispatch();
  const [reload, setreload] = useState(true);
  const [toggle, setToggle] = useState(true);
  const toggleClose = () => {
    document.getElementsByClassName("toggleButton");
    let list = document.getElementsByClassName("toggleButton");
    if (toggle === true) {
      setToggle(false);
      for (let i = 0; i < list.length; i++) {
        list[i].style.display = "none";
      }
      document.getElementById("tog").removeAttribute("style");
    } else {
      setToggle(true);
      for (let i = 0; i < list.length; i++) {
        list[i].style.display = "block";
      }
      document.getElementById("tog").setAttribute("style", "width: 15em;");
    }
  };

  // toggle state end
  const Dashboard = [
    {
      component: <HRDashboard></HRDashboard>,
      rolename: "Hr Dashboard",
    },
  ];
  // account payable sub buttons
  const Attendence = [
    {
      text: "Attendence",
      component: <MainAttendanceCom></MainAttendanceCom>,
      rolename: "Attendence",
    },

    // {
    //     text: "Attendence List",
    //     component: <HRDashboard></HRDashboard>,

    // }
  ];
  // account payable sub buttons
  const Payroll = [
    {
      text: "Assign Salary",
      component: <MainSalaryCom></MainSalaryCom>,
      rolename: "Assign Salary",
    },
    {
      text: "Advance/Loan",
      component: <MainAdvanceLoanComp></MainAdvanceLoanComp>,
      rolename: "AdvanceLoan",
    },
    {
      text: "Pay Slip",
      component: <MainPaySlipCom></MainPaySlipCom>,
      rolename: "Pay Slip",
    },
    // {
    //     text: "Employee Incentive",
    //     component: <HRDashboard></HRDashboard>,

    // }
  ];
  const Employees = [
    {
      text: "Employee",
      component: <MainAddEmployeeComp></MainAddEmployeeComp>,
      rolename: "Add Employee",
    },

    {
      text: "Designation",
      component: <MainDesignationCom></MainDesignationCom>,
      rolename: "Designation",
    },
  ];
  // const Performance = [
  //     {

  //         component: <HRDashboard></HRDashboard>,

  //     },

  // ];
  // reports subbutton
  // const Reports = [
  //     {
  //         text: "Trail Balance",
  //         component: <HRDashboard></HRDashboard>,

  //     },
  //     {
  //         text: "Balance Sheet",
  //         component: <HRDashboard></HRDashboard>,

  //     },
  //     {
  //         text: "Profit & Loss",
  //         component: <HRDashboard></HRDashboard>,

  //     },
  //     {
  //         text: "Income Statment",
  //         component: <HRDashboard></HRDashboard>,

  //     },
  //     {
  //         text: "Cash Flow",
  //         component: <HRDashboard></HRDashboard>,

  //     },
  //     {
  //         text: "Retained Earning",
  //         component: <HRDashboard></HRDashboard>,

  //     },

  // ];

  // buttons state start
  const [statesubbutton, setsubbutton] = useState("");

  const [chosen1, setChosen1] = useState("Dashboard");
  const [viewcomponent, setview] = useState(null);

  // subbutton
  const [subchosen2, setsubchosen2] = useState({
    text: "Dashboard",
    icon: (
      <img src={dashboardPic} alt="Dashboard" className="ml-3 Icondashboard" />
    ),
    subbtn: Dashboard,
    rolename: "Hr Dashboard",
  });
  const [Menu] = useState([
    {
      text: "Dashboard",
      icon: (
        <img
          src={dashboardPic}
          alt="Dashboard"
          className="ml-3 Icondashboard"
        />
      ),
      // component: <DashboardBasic></DashboardBasic>,
      rolename: "Hr Dashboard",
      subbtn: Dashboard,
    },
    {
      text: "Attendence",
      icon: (
        <img src={ledgerPic} alt="Attendence" className="ml-3 Iconpayable" />
      ),
      // component: <DebtorProfile></DebtorProfile>,
      rolename: "Attendence",
      subbtn: Attendence,
    },
    {
      text: "Payroll",
      icon: <img src={payrollPic} alt="Payroll" className="ml-3 Iconpayable" />,
      // component: <DebtorProfile></DebtorProfile>,
      rolename: "Pay Roll",
      subbtn: Payroll,
    },
    {
      text: "Employees",
      icon: (
        <img src={employeePic} alt="Employees" className="ml-3 Iconpayable" />
      ),
      // component: <DebtorProfile></DebtorProfile>,
      rolename: "Employees",
      subbtn: Employees,
    },
    // {
    //     text: "Performance",
    //     icon: <img src={performancePic} alt="Performance" className="ml-3 Iconpayable" />,
    //     // component: <DebtorProfile></DebtorProfile>,
    //     subbtn: Performance
    // },
    // {
    //     text: "Reports",
    //     icon: <img src={reportPic} alt="Reports" className="ml-3 Iconpayable" />,
    //     // component: <DebtorProfile></DebtorProfile>,
    //     subbtn: Reports
    // },
  ]);
  // buttons state end
  useEffect(() => {
    dispatch(getallRoles());
  }, []);
  useEffect(() => {
    if (rolesdata?.data?.Roles) {
      if (
        rolesdata?.data?.Roles["Hr Dashboard"] ||
        rolesdata?.data?.Roles["Admin"]
      ) {
        setview(<HRDashboard />);
      }
    }
  }, [rolesdata]);
  console.log(rolesdata);
  return (
    <>
      {/* top name bar */}

      <div className="row">
        {/* toggle button */}

        <div
          className="col-auto p-0 sidebuttonsheight main_btn_style"
          id="tog"
          style={{ width: "13em " }}
        >
          <div
            onClick={() => {
              toggleClose();
            }}
          >
            {" "}
            <img src={dotpic} alt="dots" className="ml-3 Icongroup" />
          </div>
          <div className="line_styling">
            <hr></hr>
          </div>
          <div className=" m_top_custom pr-3 ">
            {/* buttons map function */}
            {Menu.map((btn, index) =>
              (rolesdata?.data?.Roles &&
                rolesdata?.data?.Roles[btn.rolename] !== undefined) ||
              localStorage.getItem("Designation") === "Admin" ? (
                <SideButton
                  key={index}
                  text={btn.text}
                  icon={btn.icon}
                  onClick={() => {
                    if (showAlert) {
                      changeState(true);
                    }

                    setChosen1(btn.text);

                    setsubchosen2(btn);
                    setview(btn.subbtn[0].component);
                    setsubbutton(btn.subbtn[0].text);
                    setreload(!reload);
                  }}
                  active={btn.text === chosen1}
                />
              ) : null
            )}
          </div>
        </div>

        <div className="col">
          <div className="row pt-1">
            <div className="col-auto pt-1">
              <span className="name_heading mt-1 green_text ">
                {localStorage.getItem("Designation")}
              </span>
              <br></br>
              <span className="designation_head green_text ">
                {localStorage.getItem("Designation")}
              </span>
            </div>
            <div className="col text-center pt-1">
              <div className="row pl-md-5 pl-lg-5 ">
                <div className="col-10 nav-space">
                  {rolesdata?.data?.Roles?.Admin &&
                  `${localStorage.getItem("Designation")}` === "Admin" ? (
                    <>
                      {" "}
                      <Link to="/projectSelection">
                        {" "}
                        <button className="top_menu_btn blue_text ">
                          Accounts
                        </button>
                      </Link>
                      <Link to="/inventory">
                        {" "}
                        <button className="top_menu_btn blue_text">
                          Inventory
                        </button>
                      </Link>
                      <Link to="/hr">
                        {" "}
                        <button className=" top_menu_btn-select ">
                          Human Resource
                        </button>
                      </Link>
                      {/* <Link to="/crm">
                        {" "}
                        <button className="top_menu_btn blue_text">CRM</button>
                      </Link>
                      <Link to="/assets">
                        {" "}
                        <button className="top_menu_btn blue_text">
                          Assets
                        </button>
                      </Link> */}
                    </>
                  ) : null}
                  {rolesdata?.data?.Roles &&
                  rolesdata?.data?.Roles["Accounts"] !== undefined ? (
                    <Link to="/projectSelection">
                      {" "}
                      <button className="top_menu_btn blue_text ">
                        Accounts
                      </button>
                    </Link>
                  ) : null}
                  {rolesdata?.data?.Roles &&
                  rolesdata?.data?.Roles["Inventory"] !== undefined ? (
                    <Link to="/inventory">
                      {" "}
                      <button className="top_menu_btn blue_text">
                        Inventory
                      </button>
                    </Link>
                  ) : null}
                  {rolesdata?.data?.Roles &&
                  rolesdata?.data?.Roles["HR"] !== undefined ? (
                    <Link to="/hr">
                      {" "}
                      <button className="top_menu_btn blue_text">
                        Human Resource
                      </button>
                    </Link>
                  ) : null}
                  {/* {rolesdata?.data?.Roles &&
                  rolesdata?.data?.Roles["CRM"] !== undefined ? (
                    <Link to="/crm">
                      {" "}
                      <button className="top_menu_btn-select">CRM</button>
                    </Link>
                  ) : null}
                  {rolesdata?.data?.Roles &&
                  rolesdata?.data?.Roles["Asset Module"] !== undefined ? (
                    <Link to="/assets">
                      {" "}
                      <button className="top_menu_btn blue_text">Assets</button>
                    </Link>
                  ) : null} */}

                  {/* <Link to="/account"> <button className="top_menu_btn blue_text ">Accounts</button></Link>
                                    <Link to="/inventory"> <button className="top_menu_btn blue_text ">Inventory</button></Link>
                                    <Link to="/hr"> <button className="top_menu_btn-select  ">Human Resource</button></Link>
                                    <Link to="/crm">  <button className="top_menu_btn blue_text ">CRM</button></Link>
                                    <Link to="/assets">  <button className="top_menu_btn blue_text ">Assets</button></Link> */}
                </div>
                <div className=" col-md-2  ">
                  <span className="right_nav">
                    <span className=" mr-1">
                      <img
                        src={bellPic}
                        alt="bell"
                        className="ml-3 Icontaxbell"
                      />
                    </span>
                    <span className=" ml-2">
                      <img
                        className="imageCircle"
                        src={companyInfo?.data?.Logo}
                        alt="profile"
                      />
                    </span>
                    <span>
                      <Dropdown className="accountBtn  drop_icon_style">
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="accountBtn p-0"
                        >
                          <RiArrowDropDownLine className=" drop_icon_style" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              localStorage.clear();
                              sessionStorage.clear();
                              history.push({ pathname: "/" });
                            }}
                          >
                            Logout
                          </Dropdown.Item>
                          {/* <Dropdown.Item
                            onClick={() => {
                              setview(<MainAddRoleCom />);
                              setChosen1("");
                            }}
                          >
                            {"  "}
                            Role
                          </Dropdown.Item> */}
                          {/* {rolesdata?.data?.Roles?.Admin && (
                            <Dropdown.Item
                              onClick={() => {
                                setview(<Users />);
                                setChosen1("");
                              }}
                            >
                              {"  "}
                              Users
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item
                            onClick={() => {
                              setview(<Changeform />);
                              setChosen1("");
                            }}
                          >
                            {"  "}
                            Change Request
                          </Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-auto pl-2 pr-2">
              {subchosen2.subbtn.map((btn, index) =>
                btn.text !== undefined ? (
                  (rolesdata?.data?.Roles &&
                    rolesdata?.data?.Roles[btn.rolename] !== undefined) ||
                  localStorage.getItem("Designation") === "Admin" ? (
                    <SideSubButton
                      text={btn.text}
                      key={index}
                      onClick={() => {
                        if (showAlert) {
                          changeState(true);
                        }

                        setsubbutton(btn.text);
                        setview(btn.component);
                        setreload(!reload);
                      }}
                      active={btn.text === statesubbutton}
                    />
                  ) : null
                ) : null
              )}
            </div>
            <div className="col viewcompmg">
              {/* component rendering */}
              {viewcomponent}
            </div>
          </div>
        </div>
      </div>
      <AltertModal />
    </>
  );
};
export default HrMainPage;
