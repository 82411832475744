import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { BsCloudUpload, BsFillInfoSquareFill } from "react-icons/bs";
import { RiArrowGoBackFill } from "react-icons/ri";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getrecoveryreport } from "../../../actions/AccountReports/recoveryreport";
import { getallTaxTable } from "../../../actions/accountsactions/addtax";
import { getallbanks, getallcash } from "../../../actions/getbanks";
import ScreenInfoModal from "../../../ScreenInfoModal/ScreenInfoModal";
import TaxModal from "../../TaxModal/TaxModal";
import RectifiyModal from "../../Vouchers/RectifyModal/rectifiyModal";
import { getallrefundpopulate } from "../../../actions/getrefundpopulate";

const RefundPaymentForm = (props) => {
  const resultsallbanks = useSelector((state) => state.getAllBanksReducer);
  const populate = useSelector((state) => state.getAllRefundPopulate);
  const resultsallcash = useSelector((state) => state.getAllCash);
  const resultalltaxes = useSelector((state) => state.getAllTaxTable);
  const [RevertModal, setRevertModal] = useState(false);
  const [stateamount, setamount] = useState("0");
  const [taxtotal, settaxtotal] = useState("0");
  const [allvalues, setallvalues] = useState([]);
  const [showTaxmodal, setshowTaxmodal] = useState(false);
  const [disableBtn, setdisableBtn] = useState(true);
  const dispatch = useDispatch();
  const [accountid, setaccountid] = useState(null);
  const [showDetailModal, setshowDetailModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [buyerId, setBuyerId] = useState(null);
  const [unitId, setUnitId] = useState(null);

  const [units, setUnits] = useState([]);
  const [unitAmount, setUnitAmount] = useState(0);

  const [rowstate, setrowstate] = useState([
    {
      generalVoucherSavedDetailId: null,
      accountId: 0,
      amount: 0,
    },
  ]);
  const [sumAmt, setSumAmt] = useState(0);

  let vouchernumber = `RV-${Math.floor(Date.now())}`;
  // let vouchernumber = Math.floor(Date.now());

  const DisableBtn = isLoading;
  useEffect(() => {
    dispatch(getallrefundpopulate());
    dispatch(getallbanks());
    dispatch(getallTaxTable());
    dispatch(getallcash());
  }, [dispatch]);

  useEffect(() => {
    if (props.vouchData?.VoucherId) {
      let sum = 0;
      let PreviousSelectedTax = props.vouchData.VoucherTaxSubmitedDetail.map(
        (data) => {
          sum = sum + data.TaxAmount;
          let obj = { ...data, Checked: true };
          return obj;
        }
      );
      settaxtotal(sum);

      PreviousSelectedTax = PreviousSelectedTax.map((value) => {
        let obj = value.Tax.TaxId;
        return { ...value, TaxId: obj };
      });
      setallvalues(PreviousSelectedTax);

      setdisableBtn(false);
      if (props.vouchData?.PaymentType === "CASH") {
        // document.getElementById("vouchertype").options[2].selected = true;
        document.getElementById("vouchertype").options[1].selected = true;
        document.getElementById("bankdiv").style.display = "none";
        document.getElementById("cashdiv").style.display = "block";
        setaccountid(props.vouchData?.DebitAccountId);
        // document.getElementById("Account").value =
        //   props.vouchData?.DebitAccount.AccountTitle;
        document.getElementById("cashs").value =
          props.vouchData?.CreditAccount.AccountId;
      } else {
        document.getElementById("vouchertype").options[2].selected = true;
        document.getElementById("bankdiv").style.display = "block";
        document.getElementById("cashdiv").style.display = "none";
        document.getElementById("chequeno").value =
          props.vouchData?.ChequeNumber;
        document.getElementById("banks").value =
          props.vouchData?.CreditAccount.AccountTitle;
        setaccountid(props.vouchData?.DebitAccountId);
        // document.getElementById("Account").value =
        //   props.vouchData?.DebitAccount.AccountTitle;
      }

      document.getElementById("voucherno").value = props.vouchData?.VoucherId;
      document.getElementById("date").value =
        props.vouchData?.AddedOnDate?.slice(0, 10);

      document.getElementById("Amount").value =
        props.vouchData?.GrossAmount.toLocaleString();
      document.getElementById("taxnet").value = props.vouchData?.NetAmount;
      document.getElementById("narration").value = props.vouchData?.Narration;
      settaxtotal(props.vouchData.TotaTaxAmount?.toString());
      setamount(props.vouchData?.GrossAmount?.toString());
      setUnitId(props.unitData?.UnitId);
      setBuyerId(props.unitData?.CustomerId);
      document.getElementById("buyer").value = props.unitData?.CustomerName;
      document.getElementById("unit").value = props.unitData?.UnitName;
      setUnitAmount(props.unitData?.Amount);
    }
  }, [
    props.vouchData,
    populate,
    resultsallcash,
    resultsallbanks,
    resultalltaxes,
  ]);
  const handleDisableBtn = () => {
    setdisableBtn(true);
  };
  const savedata = () => {
    if (document.getElementById("date").value === "") {
      toast.info("Date is Required");
    } else if (document.getElementById("Amount").value === "") {
      toast.info("Amount is Required");
    } else if (document.getElementById("narration").value === "") {
      toast.info("Narration is Required");
    }
    //  else if (document.getElementById("Account").value === "") {
    //   toast.info("Account is Required");
    // }
    else if (document.getElementById("vouchertype").selectedIndex === 0) {
      toast.info("Select Type");
    } else if (
      document.getElementById("vouchertype").options[
        document.getElementById("vouchertype").selectedIndex
      ].value === "Bank Voucher" &&
      (document.getElementById("banks").options[
        document.getElementById("banks").selectedIndex
      ].value === "Select" ||
        document.getElementById("chequeno").value === "")
    ) {
      toast.info("Cheque no and Bank name are Required");
    } else if (
      document.getElementById("vouchertype").options[
        document.getElementById("vouchertype").selectedIndex
      ].value === "Cash Voucher" &&
      document.getElementById("cashs").options[
        document.getElementById("cashs").selectedIndex
      ].value === "Select"
    ) {
      toast.info("Select Cash Account");
    } else if (buyerId === null) {
      toast.info("Buyer is Required");
    } else if (unitId === null) {
      toast.info("Unit is Required");
    } 
    // else if (
    //   parseFloat(document.getElementById("Amount").value.replace(/,/g, "")) >
    //   unitAmount
    // ) {
    //   toast.info(`Amount can't be greater than ${unitAmount.toLocaleString()}`);
    // }
     else {
      let paymentType =
        document.getElementById("vouchertype").options[
          document.getElementById("vouchertype").selectedIndex
        ].value;
      if (paymentType === "Bank Voucher") {
        paymentType = "Bank";
      } else {
        paymentType = "Cash";
      }
      let bankId =
        document.getElementById("banks").options[
          document.getElementById("banks").selectedIndex
        ].value;
      if (bankId === "Select") {
        bankId = 0;
      } else {
        bankId =
          document.getElementById("banks").options[
            document.getElementById("banks").selectedIndex
          ].dataset.id;
      }
      let cashId =
        document.getElementById("cashs").options[
          document.getElementById("cashs").selectedIndex
        ].value;
      if (cashId === "Select") {
        cashId = 0;
      } else {
        cashId =
          document.getElementById("cashs").options[
            document.getElementById("cashs").selectedIndex
          ].dataset.id;
      }
      let checkNumber;

      if (paymentType === "Cash") {
        checkNumber = null;
      } else {
        checkNumber = document.getElementById("chequeno").value;
      }
      let taxList = [];
      allvalues?.map((val, i) => {
        if (val.Checked === true) {
          taxList.push({
            TaxId: val.TaxId,
            TaxPer: Number(val.TaxPer),
            TaxAmount: Number(val.TaxAmount),
            Checked: true,
            TaxSubmitedDetailId: val.TaxSubmitedDetailId
              ? val.TaxSubmitedDetailId
              : 0,
            ProjectId: localStorage.getItem("ProjectId"),
            ReferenceId: "",
            ReferenceType: "",
          });
        }
        return true;
      });
      let data = {
        VoucherId: document.getElementById("voucherno").value,
        customerId: buyerId,
        unitId: unitId,
        CreditAccountId:
          paymentType === "Cash" ? parseInt(cashId) : parseInt(bankId),
        // Amount: parseFloat(document.getElementById("Amount").value),
        GrossAmount: parseFloat(
          document.getElementById("Amount").value.replace(/,/g, "")
        ),
        VoucherType: "refund payment",
        ChequeNumber: checkNumber,
        VoucherSaveDate: document.getElementById("date").value,
        PaymentType: paymentType,
        Narration: document.getElementById("narration").value,
        ProjectId: parseInt(`${localStorage.getItem("ProjectId")}`),
        TotaTaxAmount: taxtotal,
        NetAmount:
          parseFloat(
            document.getElementById("Amount").value.replace(/,/g, "")
          ) - taxtotal,
        // VoucherTaxSubmitedDetail: taxList,
      };

      document.getElementById("headingsave").disabled = true;

      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Vouchers/Payment/Refund/Add`,
        data: JSON.stringify({
          voucher: data,
          taxDetail: taxList,
          GeneralVoucherSavedDetail: rowstate,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("headingsave").disabled = false;
          console.log("result", result.data);

          if (result.data.IsSuccess === true) {
            console.log("result.data.IsSuccess", result.data.IsSuccess);
            document.getElementById("chequeno").value = "";
            document.getElementById("voucherno").value = "";
            document.getElementById("narration").value = "";

            document.getElementById("Amount").value = "";
            document.getElementById("vouchertype").selectedIndex = 0;
            document.getElementById("banks").selectedIndex = 0;
            document.getElementById("buyer").selectedIndex = "";
            document.getElementById("unit").selectedIndex = "";
            document.getElementById("taxnet").selectedIndex = 0;

            // document.getElementById("Account").value = "";
            setaccountid(null);
            setrowstate([]);
            setUnits([]);
            setUnitId(null);
            setBuyerId(null);
            props.setvoucherSaveDetails(null);

            toast.info(result.data.Message);
            props.cancelrefundtable();
            dispatch(getrecoveryreport());
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Someting went wrong");
          document.getElementById("headingsave").disabled = false;
        }
      );
    }
  };
  const submitdata = () => {
    setdisableBtn(true);
    axios({
      method: "post",
      url: `${
        process.env.REACT_APP_API_URL
      }Vouchers/Payment/Refund/Submit?voucherId=${
        document.getElementById("voucherno").value
      }`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          setdisableBtn(!disableBtn);
          document.getElementById("chequeno").value = "";
          document.getElementById("voucherno").value = "";
          document.getElementById("narration").value = "";

          document.getElementById("Amount").value = "";
          document.getElementById("vouchertype").selectedIndex = 0;
          document.getElementById("banks").selectedIndex = 0;
          document.getElementById("unit").value = "";
          document.getElementById("buyer").value = "";

          setaccountid(null);
          setrowstate([]);
          setUnitId(null);
          setBuyerId(null);
          setUnits([]);
          props.setvoucherSaveDetails(null);
          toast.info(result.data.Message);
          props.cancelrefundtable();
          dispatch(getrecoveryreport());
        } else {
          toast.info(result.data.Message);
          setdisableBtn(false);
        }
      },
      (error) => {
        toast.info("Someting went wrong");
        setdisableBtn(false);
      }
    );
  };

  const onCustomerChange = () => {
    var inputValue = document.getElementById("buyer").value;
    var options = document.getElementById("buyerlist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        populate.data.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });
        setBuyerId(val.CustomerId);
        setUnits(val.Units);
        break;
      }
    }
  };
  const onUnitChange = () => {
    var inputValue = document.getElementById("unit").value;
    var options = document.getElementById("unitlist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        units.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });
        setUnitId(val.UnitId);
        console.log("val", val);
        document.getElementById("Amount").value = val.Amount.toLocaleString();
        setamount(val.Amount.toLocaleString());
        setUnitAmount(val.Amount);
        break;
      }
    }
  };
  console.log("buyerId", buyerId);
  return (
    <>
      {/* <ToastContainer autoClose={5000} /> */}
      {populate.loading ? (
        <Loader type="ThreeDots" color="green" height={40} width={40} />
      ) : null}
      <div className="row">
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0 ">Date:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="date"
            onChange={handleDisableBtn}
            className="input_date_styling  "
            defaultValue={moment().format("YYYY-MM-DD")}
            id="date"
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-4   p-0"></div>
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0">Buyer:</label>
        </div>

        <div className="col-md-3 mt-4   p-0">
          {" "}
          <input
            disabled={props?.unitData?.CustomerName ? true : false}
            type="text"
            id="buyer"
            autoComplete="off"
            onChange={onCustomerChange}
            className="input_styling"
            list="buyerlist"
          ></input>
          <datalist id="buyerlist">
            {populate?.data?.map((x, idx) => (
              <option index={idx} value={`${x.CustomerName} (${idx + 1})`}>
                {x.CustomerCNIC}
              </option>
            ))}
          </datalist>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0">Voucher No:</label>
        </div>
        <div className="col-md-3 mt-4   p-0">
          <input
            type="text"
            disabled
            value={
              props.vouchData?.VoucherId
                ? props.vouchData?.VoucherId
                : vouchernumber
            }
            className="input_styling  "
            id="voucherno"
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-4   p-0"></div>
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0">Units:</label>
        </div>
        <div className="col-md-3 mt-4   p-0">
          <input
            disabled={
              props?.unitData?.UnitName
                ? true
                : units.length === 0
                ? true
                : false
            }
            type="text"
            id="unit"
            autoComplete="off"
            onChange={onUnitChange}
            className="input_styling"
            list="unitlist"
          ></input>
          <datalist id="unitlist">
            {units?.map((x, idx) => (
              <option index={idx} value={x?.UnitName}></option>
            ))}
          </datalist>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0 ">Type:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <select
            id="vouchertype"
            onChange={(e) => {
              handleDisableBtn();
              if (e.target.value === "Bank Voucher") {
                document.getElementById("bankdiv").style.display = "block";
                document.getElementById("cashdiv").style.display = "none";
              } else if (e.target.value === "Cash Voucher") {
                document.getElementById("bankdiv").style.display = "none";
                document.getElementById("cashdiv").style.display = "block";
              } else {
                document.getElementById("bankdiv").style.display = "none";
                document.getElementById("cashdiv").style.display = "none";
              }
            }}
            type="text"
            className="input_styling  "
          >
            <option>Select</option>

            <option selected>Cash Voucher</option>
            <option>Bank Voucher</option>
          </select>
          <span className="estaric">*</span>
        </div>

        <div className="col-md-2 mt-4   p-0"></div>
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0">Amount:</label>
        </div>
        <div className="col-md-3 mt-4   p-0">
          <input
            type="text"
            className="input_styling  "
            id="Amount"
            onChange={(e) => {
              handleDisableBtn();
              setamount(e.target.value);
              let taxAmt = 0;
              resultalltaxes.data?.forEach((item2) => {
                if (item2.Checked === true) {
                  item2.TaxAmount = parseFloat(
                    (item2.TaxPer / 100) *
                      parseFloat(e.target.value.replace(/,/g, ""))
                  );
                  taxAmt =
                    taxAmt +
                    parseFloat(
                      (item2.TaxPer / 100) *
                        parseFloat(e.target.value.replace(/,/g, ""))
                    );
                }
              });
              settaxtotal(taxAmt?.toString());
              if (e.target.value.length > 0) {
                document.getElementById("Amount").value = parseInt(
                  e.target.value.replace(/,/g, "")
                ).toLocaleString();
              }
            }}
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>
      <span id="cashdiv">
        <div className="row  ">
          <div className="col-md-2 mt-4 text-left p-0">
            <label className="input_label m-0">Cash:</label>
          </div>
          <div className="col-md-3 mt-4  p-0">
            <select
              onChange={handleDisableBtn}
              id="cashs"
              className="input_styling  "
            >
              <option>Select</option>
              {resultsallcash.data?.map((x) => (
                <option value={x.AccountId} data-id={x.AccountId}>
                  {x.AccountTitle}
                </option>
              ))}
            </select>
            <span className="estaric">*</span>
          </div>
          <div className="col-md-2 mt-4   p-0"></div>
        </div>
      </span>

      <span id="bankdiv" style={{ display: "none" }}>
        <div className="row ">
          <div className="col-md-2 mt-4  text-left p-0">
            <label className="input_label m-0">Bank:</label>
          </div>
          <div className="col-md-3 mt-4   p-0">
            <select
              onChange={handleDisableBtn}
              id="banks"
              className="input_styling  "
            >
              <option>Select</option>
              {resultsallbanks.data.map((x) => (
                <option data-id={x.AccountId}>{x.AccountTitle}</option>
              ))}
            </select>
            <span className="estaric">*</span>
          </div>

          <div className="col-md-2 mt-4   p-0"></div>

          <div className="col-md-2 mt-4  text-left p-0">
            <label className="input_label m-0">Cheque No:</label>
          </div>
          <div className="col-md-3 mt-4   p-0">
            <input
              id="chequeno"
              type="text"
              onChange={handleDisableBtn}
              placeholder="Cheque No"
              className="input_styling "
            ></input>
            <span className="estaric">*</span>
          </div>
        </div>
      </span>
      <div className="row ">
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Narration:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <textarea
            onChange={handleDisableBtn}
            className="input_address "
            id="narration"
          ></textarea>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-4   p-0"></div>

        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Net Amount:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text"
            className="input_styling  "
            disabled
            id="taxnet"
            value={parseFloat(
              parseFloat(stateamount.replace(/,/g, "")) - parseFloat(taxtotal)
            ).toLocaleString()}
          ></input>
        </div>
      </div>

      <div className=" float-right mt-md-4 mr-md-3">
        <span>
          <button
            className="save_btn ml-2  "
            onClick={() => {
              setshowDetailModal(true);
            }}
          >
            <BsFillInfoSquareFill size="1em" className="mr-1" />
            Detail
          </button>
        </span>
        {localStorage.getItem("Designation") === "Admin" && props.vouchData && (
          <span>
            <button
              className="save_btn ml-2 "
              onClick={() => setRevertModal(true)}
            >
              <RiArrowGoBackFill size="1em" />
              Revert
            </button>
          </span>
        )}
        <span>
          <button
            className="save_btn ml-2  "
            onClick={() => {
              if (document.getElementById("Amount").value !== "") {
                setamount(document.getElementById("Amount").value);
                setshowTaxmodal(true);
              }
            }}
          >
            Add Tax
          </button>
        </span>
        <span>
          <button
            className="cancel_btn ml-2  "
            onClick={props.cancelrefundtable}
          >
            Cancel
          </button>
        </span>
        <span>
          <button
            disabled={DisableBtn}
            className="save_btn ml-2  "
            id="headingsave"
            onClick={savedata}
          >
            {isLoading ? (
              "loading..."
            ) : (
              <>
                <BsCloudUpload size="1.3em" />
                Save
              </>
            )}
          </button>
        </span>{" "}
        {localStorage.getItem("Designation") === "Admin" && (
          <span>
            <button
              disabled={disableBtn}
              className="save_btn ml-2  "
              id="headingsubmit"
              onClick={submitdata}
            >
              {/* <RiSave3Fill size="1.3em" /> */}
              Submit
            </button>
          </span>
        )}
      </div>
      {/* <div id="mymodal" className="modal">
          <div className="modal-content-cat">
            <div className="row">
              <div className="col-12  page_heading">
                <h4 className="  text-center pt-2">Select Taxes</h4>
                <span class="close pr-2  " onClick={closemodal}>
                  &times;
                </span>
              </div>
            </div>
            <div className="row">
              <div className=" col-12  table_height pr-0 pl-0">
                <div className="m-2 mt-4">
                  <table className="table table-borderless m-0">
                    <thead>
                      <tr>
                        <th>Select</th>
                        <th>Name</th>
  
                        <th>Percentage</th>
                        <th>Tax Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {resultalltaxes.data.map((val, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              id={`advCheck${index}`}
                              type="checkbox"
                              name="check"
                              checked={val.Checked ? val.Checked : false}
                              onChange={(e) => {
                                handleDisableBtn()
                                resultalltaxes.data[index].Checked =
                                  e.target.checked;
  
                                if (e.target.checked == true) {
                                  resultalltaxes.data[index].taxAMt = parseFloat((parseFloat(val.TaxPer) / 100) * parseFloat(stateamount.replace(/,/g, '')))
  
                                  settaxtotal(
                                    parseFloat(
                                      parseFloat(taxtotal.replace(/,/g, "")) +
                                      parseFloat(
                                        document
                                          .getElementById(`taxtotal${index}`)
                                          .value.replace(/,/g, "")
                                      )
                                    ).toLocaleString()
                                  );
                                } else {
                                  settaxtotal(
                                    parseFloat(
                                      parseFloat(taxtotal.replace(/,/g, "")) -
                                      parseFloat(
                                        document
                                          .getElementById(`taxtotal${index}`)
                                          .value.replace(/,/g, "")
                                      )
                                    ).toLocaleString()
                                  );
                                }
                              }}
                            />
                          </td>
                          <td>{val.TaxName}</td>
  
                          <td>
                            <input
                              type="text"
                              id={`taxper${index}`}
                              defaultValue={val.TaxPer}
                              onChange={(e) => {
                                handleDisableBtn()
                                resultalltaxes.data[index].TaxPer =
                                  e.target.value;
                                resultalltaxes.data[index].taxAMt = parseFloat((parseFloat(e.target.value) / 100) * parseFloat(stateamount.replace(/,/g, '')))
                                document.getElementById(
                                  `taxtotal${index}`
                                ).value = parseFloat(
                                  (e.target.value / 100) *
                                  parseFloat(
                                    document
                                      .getElementById("Amount")
                                      .value.replace(/,/g, "")
                                  )
                                ).toLocaleString();
                                if (e.target.value === "") {
                                  settaxtotal("0")
                                } else {
                                  settaxtotal(parseFloat(parseFloat((e.target.value / 100) * parseFloat(document.getElementById("Amount").value.replace(/,/g, '')))).toString())
                                }
  
                              }}
                            ></input>
                          </td>
  
                          <td>
                            <input
                              type="text"
                              id={`taxtotal${index}`}
                              value={parseFloat(
                                (parseFloat(val.TaxPer) / 100) *
                                parseFloat(stateamount.replace(/,/g, ""))
                              ).toLocaleString()}
                              disabled
                            ></input>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className=" col-11 text-right pt-2 pr-0 pl-0">
                    <label className="input_label m-0"> Total Tax:</label>{" "}
                    <input
                      type="text"
                      value={taxtotal.toLocaleString()}
                      disabled
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

      {showDetailModal ? (
        <ScreenInfoModal
          setshowModal={setshowDetailModal}
          screenName="Refund Payment"
        />
      ) : null}
      {showTaxmodal === true ? (
        <TaxModal
          enableSaveBtn={handleDisableBtn}
          setshowTaxmodal={setshowTaxmodal}
          stateamount={stateamount}
          taxtotal={taxtotal}
          settaxtotal={settaxtotal}
          vouchData={props.vouchData}
          allvalues={allvalues}
          setallvalues={setallvalues}
        />
      ) : null}
      {RevertModal && (
        <RectifiyModal
          entryId={document.getElementById("voucherno").value}
          entryType={"General Payment"}
          addedById={props.vouchData.AddedById}
          setshowModal={setRevertModal}
        />
      )}
    </>
  );
};

export default RefundPaymentForm;
