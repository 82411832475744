const allmeasureunit = { data: [], loading: true };
const alldimensionunit = { data: [], loading: true };
const allunits = { data: [], loading: true };
const allunitCat = { data: [], loading: true };
const allunitTags = { data: [], loading: true };


export const GetAllMeasureUnit = (state = allmeasureunit, action) => {
  switch (action.type) {
    case "get_allmeasureunit":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};
export const GetAllDimensionUnit = (state = alldimensionunit, action) => {
  switch (action.type) {
    case "get_alldimensionunit":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export const GetAllUnits = (state = allunits, action) => {
  switch (action.type) {
    case "get_allunits":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export const GetAllUnitCategory = (state = allunitCat, action) => {
  switch (action.type) {
    case "get_allunits_category":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export const GetAllUnitTags = (state = allunitTags, action) => {
  switch (action.type) {
    case "get_allunits_tags":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};
