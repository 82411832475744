import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import {
  GetAllCreditors,
  getallcreditors,
  GetCreditorData,
} from "../../../actions/getcreditors";
import { getallProject } from "../../../actions/getprojects";
import LandSellerPrint from "../LandPrints/LandSellerPrint";
import { CSVLink } from "react-csv";

export const LandSellerTable = (props) => {
  const [creditordata, setcreditordata] = useState({});
  const resultallcreditors = useSelector((state) => state.getAllCreditors);
  const creditordatastate = useSelector((state) => state.getCreditordatastate);
  const rolesdata = useSelector((state) => state.GetAllRole);

  // print function
  const componentRef = useRef();

  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  //Table Data fiter

  var suggestionlist = [];

  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      dispatch(getallcreditors());
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");

        suggestions = resultallcreditors.data
          .sort()
          .filter((val) => regex.test(val.LandCreditorName));
      }
      suggestionlist = suggestions;
      dispatch(GetAllCreditors(suggestionlist));
    }
  };
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getallcreditors());
    dispatch(getallProject());
  }, [dispatch]);
  function showmodal(data) {
    document.getElementById("mymodal").style.display = "block";
    setcreditordata(data);
  }
  function closemodal() {
    document.getElementById("mymodal").style.display = "none";
  }
  const [CsvData, setCsvData] = useState([]);
  useEffect(() => {
    let arr = [];
    resultallcreditors.data?.forEach((val, index) => {
      arr.push({
        Sr: index + 1,
        Name: val.LandCreditorName,
        ID: val.LandCreditorCnic,
        "Contact No.": val.ContactNumber1,
        City: val.City,
      });
    });
    setCsvData(arr);
  }, [resultallcreditors.data]);
  return (
    <>
      <div hidden>
        <LandSellerPrint ref={componentRef} printData={resultallcreditors} />
      </div>
      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        <div className="col-md-3 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            onChange={onTextChange}
            placeholder="Land Seller "
          ></input>
        </div>
        {/* <div className="col-md-3 text-center  pr-0 pl-0">
                    <label className="input_label m-0">From:</label>
                    <input type="date" className="input_date ml-3" id="from date"></input>
                </div>
                <div className="col-md-3 text-center pr-0 pl-0">
                    <label className="input_label m-0">To:</label>
                    <input type="date" className="input_date ml-3" id="todate"></input>
                </div>
                <div className="col-md-1 pr-0 pl-0">
                    <button className="btn_Go">Go</button>
                </div> */}
        <div className="col-md-1 pr-0 pl-0">
          {resultallcreditors.loading ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </div>
      </div>

      <div className="row ">
        <div className=" col-12  table_height pr-0 pl-0">
          <div className="table-responsive mt-4">
            <table className="table table-borderless table-hover m-0">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Name</th>

                  <th>ID</th>

                  <th>Contact No.</th>
                  <th>City</th>

                  <th className="text-center">
                    {rolesdata?.data?.Access?.includes("C") ? (
                      <button
                        className="save_btn"
                        onClick={() => {
                          props.showcreditortable();
                        }}
                      >
                        New+
                      </button>
                    ) : null}
                  </th>
                </tr>
              </thead>
              <tbody>
                {resultallcreditors.data.map((val, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      showmodal(val);
                    }}
                  >
                    <td>{index + 1}</td>
                    <td>{val.LandCreditorName}</td>

                    <td>{val.LandCreditorCnic}</td>

                    <td>{val.ContactNumber1}</td>
                    <td>{val.City}</td>
                    <td className="text-center">
                      {rolesdata?.data?.Access?.includes("U") ? (
                        <FiEdit2 size="1em" />
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-6  text-right pr-0 pl-0">
          <button
            className="print_btn float-left"
            onClick={() => {
              printPaymentRec();
            }}
          >
            <TiPrinter size="1.3em" />
            Print
          </button>
          <button
            style={{ color: "black !important" }}
            className="print_btn ml-2 float-left"
          >
            <CSVLink
              style={{ color: "black" }}
              filename="Land_Seller_Csv"
              data={CsvData}
            >
              Download CSV
            </CSVLink>
          </button>
        </div>
      </div>

      <div id="mymodal" class="modal">
        <div class="modal-content-cat">
          <div className="row">
            <div className="col-12  page_heading">
              <h4 className="  text-center pt-2">Land Seller</h4>
              <span class="close pr-2  " onClick={closemodal}>
                &times;
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-6 mt-2">
              <ul>
                <li>
                  <b>Name:</b> {creditordata.LandCreditorName}
                </li>
                <li>
                  <b>Email:</b> {creditordata.Email}
                </li>
                <li>
                  <b>ID:</b> {creditordata.LandCreditorCnic}
                </li>

                <li>
                  <b>Contact No.1:</b> {creditordata.ContactNumber1}
                </li>
                <li>
                  <b>Contact No.2:</b> {creditordata.ContactNumber2}
                </li>
              </ul>
            </div>
            <div className="col-6 mt-2">
              <ul>
                <li>
                  <b>Country:</b> {creditordata.Country}
                </li>
                <li>
                  <b>State:</b> {creditordata.State}
                </li>
                <li>
                  <b>City:</b> {creditordata.City}
                </li>
                <li>
                  <b>Phone No:</b> {creditordata.PhoneNumber}
                </li>
                <li>
                  <b>Address:</b> {creditordata.LandCreditorAddress}
                </li>
              </ul>
            </div>
          </div>

          <div className="col-12 text-right">
            <button
              className=" save_btn   "
              onClick={() => {
                dispatch(GetCreditorData(creditordata));
                closemodal();
                props.showcreditortable();
              }}
            >
              <FiEdit2 size="0.8em" /> Edit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
