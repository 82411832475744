import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Country, State, City } from "country-state-city";
import Loader from "react-loader-spinner";

export const LandSellerForm = (props) => {
  const creditordatastate = useSelector((state) => state.getCreditordatastate);
  const resultsproject = useSelector((state) => state.getProject);
  const [individualcheck, setindividualcheck] = useState(true);
  const [allCountries, setallCountries] = useState([]);
  const [allStates, setallStates] = useState([]);
  const [allCity, setallCity] = useState([]);
  const [selectedCountries, setselectedCountries] = useState(null);
  const [selectedStates, setselectedStates] = useState(null);
  const [selectedCity, setselectedCity] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const data = Country?.getAllCountries()?.map((country) => ({
      value: country.name,
      displayValue: `${country.name}-${country.isoCode}`,
      isoCode: country.isoCode,
    }));
    setallCountries(data);
  }, [Country]);
  useEffect(() => {
    const data = State.getStatesOfCountry(
      selectedCountries?.split("-")[1]
    )?.map((state) => ({
      value: state.name,
      displayValue: `${state.name}-${state.isoCode}`,
    }));
    setallStates(data);
  }, [selectedCountries]);
  useEffect(() => {
    const data = City.getCitiesOfState(
      selectedCountries?.split("-")[1],
      selectedStates?.split("-")[1]
    ).map((city) => ({
      value: city.name,
      displayValue: city.name,
    }));
    console.log(data);
    setallCity(data);
  }, [selectedCountries, selectedStates]);
  let populatedata = {};
  if (
    creditordatastate.creditordata !== undefined ||
    creditordatastate.creditordata !== null
  ) {
    populatedata = creditordatastate.creditordata;
  } else {
    populatedata = creditordatastate;
  }
  function emptyfields() {
    setselectedCountries("");
    document.getElementById("LandSellerCountry").selectedIndex = 0;
    document.getElementById("LandSellerState").selectedIndex = 0;
    document.getElementById("LandSellerCity").selectedIndex = 0;
    document.getElementById("Phone").value = "";
    document.getElementById("Address").value = "";
    // document.getElementById("project").selectedIndex = 0;
    document.getElementById("creditorName").value = "";
    // document.getElementById("CNIC").value = "";
    document.getElementById("Cell1").value = "";
    document.getElementById("Cell2").value = "";
    document.getElementById("Email").value = "";
  }
  function addCreditor() {
    let regexp = new RegExp("^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$");
    let regexphone = new RegExp("^[0-9+]{4}-[0-9+]{7}$");
    let regexptcl = new RegExp("^[0-9+]{3}-[0-9+]{7}$");

    let regexcompany = new RegExp("^[0-9+]{7}$");

    let check =
      document.getElementById("CNIC") !== null
        ? document.getElementById("CNIC").value
        : null;
    let checkcell1 = document.getElementById("Cell1").value;
    let checkcell2 = document.getElementById("Cell2").value;
    let checkptcl = document.getElementById("Phone").value;

    let checkcompanyreg =
      document.getElementById("Companyregistration") !== null
        ? document.getElementById("Companyregistration").value
        : null;

    if (document.getElementById("creditorName").value === "") {
      toast.info("Name is required");
    }
    // else if (document.getElementById("project").selectedIndex === 0) { toast.info("Select any Project") }
    else if (
      document.getElementById("CNIC") !== null
        ? document.getElementById("CNIC").value === ""
        : null
    ) {
      toast.info("CNIC is required");
    } else if (
      document.getElementById("Companyregistration") !== null
        ? document.getElementById("Companyregistration").value === ""
        : null
    ) {
      toast.info("Company registration is required");
    } else if (
      !regexp.test(check) && document.getElementById("CNIC") !== null
        ? document.getElementById("CNIC").value !== ""
        : null
    ) {
      toast.info("Invalid CNIC. Format should be like xxxxx-xxxxxxx-x");
    } else if (
      !regexcompany.test(checkcompanyreg) &&
      document.getElementById("Companyregistration") !== null
        ? document.getElementById("Companyregistration").value !== ""
        : null
    ) {
      toast.info("Invalid Company Reg No. Should contain 7 digits");
    } else if (document.getElementById("LandSellerCity").selectedIndex === 0) {
      toast.info("Select any city");
    } else if (
      document.getElementById("LandSellerCountry").selectedIndex === 0
    ) {
      toast.info("Select any country");
    } else if (document.getElementById("LandSellerState").selectedIndex === 0) {
      toast.info("Select any State");
    } else if (
      !regexptcl.test(checkptcl) &&
      document.getElementById("Phone").value !== ""
    ) {
      toast.info("Invalid Phone. Format should be like xxx-xxxxxxx");
    } else if (document.getElementById("Cell1").value === "") {
      toast.info("cell no.1  is required");
    } else if (!regexphone.test(checkcell1)) {
      toast.info("Invalid Cell No. 1 Format should be like xxxx-xxxxxxx");
    } else if (
      !regexphone.test(checkcell2) &&
      document.getElementById("Cell2").value !== ""
    ) {
      toast.info("Invalid Cell No. 2 Format should be like xxxx-xxxxxxx");
    } else if (document.getElementById("Address").value === "") {
      toast.info("Address is required");
    } else {
      setIsLoading(true);

      var CreditorDetails = creditordatastate;

      CreditorDetails.LandCreditorId = populatedata.LandCreditorId;
      CreditorDetails.AccountId = populatedata.AccountId;
      CreditorDetails.LandCreditorName =
        document.getElementById("creditorName").value;
      CreditorDetails.LandCreditorAddress =
        document.getElementById("Address").value;
      CreditorDetails.LandCreditorCnic =
        individualcheck === true
          ? document.getElementById("CNIC").value
          : document.getElementById("Companyregistration").value;
      CreditorDetails.ContactNumber1 = document.getElementById("Cell1").value;
      CreditorDetails.ContactNumber2 = document.getElementById("Cell2").value;
      CreditorDetails.PhoneNumber = document.getElementById("Phone").value;
      CreditorDetails.Email = document.getElementById("Email").value;
      CreditorDetails.Type =
        individualcheck === true ? "INDIVIDUAL" : "COMPANY";

      CreditorDetails.City =
        document.getElementById("LandSellerCity").options[
          document.getElementById("LandSellerCity").selectedIndex
        ].value;
      CreditorDetails.Country =
        document.getElementById("LandSellerCountry").options[
          document.getElementById("LandSellerCountry").selectedIndex
        ].value;
      CreditorDetails.State =
        document.getElementById("LandSellerState").options[
          document.getElementById("LandSellerState").selectedIndex
        ].value;

      document.getElementById("creditorsave").disabled = true;
      axios({
        url: `${process.env.REACT_APP_API_URL}LandCreditors/Add`,
        method: "Post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
        data: JSON.stringify(CreditorDetails),
      }).then(
        (result) => {
          document.getElementById("creditorsave").disabled = false;

          if (result.data.IsSuccess === true) {
            setIsLoading(false);

            emptyfields();
            props.cancelcreditortable();

            toast.info(result.data.Message);
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          setIsLoading(false);
          toast.info("Something went wrong!");
          document.getElementById("creditorsave").disabled = false;
        }
      );
    }
  }
  useEffect(() => {
    {
      populatedata.Type === "INDIVIDUAL"
        ? setindividualcheck(true)
        : setindividualcheck(false);
    }
  }, [populatedata.Type]);
  useEffect(() => {
    if (populatedata.Country !== null && populatedata.Country !== undefined) {
      setselectedCountries(populatedata.Country);
    }
    if (populatedata.State !== null && populatedata.State !== undefined) {
      setselectedStates(populatedata.State);
    }
    if (populatedata.City !== null && populatedata.City !== undefined) {
      setselectedCity(populatedata.City);
    }
  }, [populatedata]);
  return (
    <>
      <div className="row ">
        <div className="col-md-12  text-left page_heading">
          <h4 className="m-0">Add Land Seller</h4>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-4 mt-2 text-right">
          <span className="txt input_label"> Individual</span>
        </div>
        <div className="col-md-1 mt-2 text-left">
          <span>
            {" "}
            <input
              type="radio"
              id="Investment"
              name="InvestmentType"
              checked={individualcheck}
              onClick={() => {
                // document.getElementById("showhide").style.display = "none";
                // document.getElementById("showhide2").style.display = "block";
                // document.getElementById("cityshowhide").style.display = "block";
                setindividualcheck(true);
              }}
            ></input>
          </span>
        </div>
        <div className="col-md-4 mt-2 text-right">
          <span className="txt input_label"> Company</span>
        </div>
        <div className="col-md-1 mt-2 text-left">
          <span>
            {" "}
            <input
              type="radio"
              name="existingType"
              id="existingproject"
              checked={!individualcheck}
              onClick={() => {
                setindividualcheck(false);
                // document.getElementById("showhide2").style.display = "none";
                // document.getElementById("showhide").style.display = "block";
              }}
            ></input>
          </span>
        </div>
      </div>
      {/* <div className="row ">
                <div className="col-md-4 mt-md-4  text-left p-0">
                 
                </div>
                <div className="col-md-3 text-center mt-md-4  ">
                    <select className="input_styling ml-md-3" id="project"  >
                        <option>Select Project</option>
                        {resultsproject.data.map((val, index) => (
                            // <option data-id={val.ProjectId}>{val.ProjectName}</option>
                            populatedata.ProjectLandCreditors !== undefined ? populatedata.ProjectLandCreditors.map((x) => (parseInt(x.ProjectId) === parseInt(val.ProjectId) ? <option selected data-id={val.ProjectId}>{val.ProjectName}</option> : null)) : <option data-id={val.ProjectId}>{val.ProjectName}</option>
                        ))}
                    </select>


                </div>
                <div className="col-md-4 mt-md-4  text-left p-0">

                </div>
            </div> */}
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Name :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3"
            id="creditorName"
            defaultValue={populatedata.LandCreditorName}
          />
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Email</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="email"
            className="input_styling ml-md-3 "
            id="Email"
            defaultValue={populatedata.Email}
          />
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          {populatedata.Type === "INDIVIDUAL" ||
          populatedata.Type === "COMPANY" ? (
            <label className="input_label m-0">
              {populatedata.Type === "INDIVIDUAL" ? "CNIC" : "Company Reg"}
            </label>
          ) : (
            <label className="input_label m-0">
              {individualcheck === true ? "CNIC" : "Company Reg"}
            </label>
          )}
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          {individualcheck === true ? (
            <>
              <input
                type="text"
                className="input_styling ml-md-3 "
                id="CNIC"
                maxLength={15}
                placeholder="xxxxx-xxxxxxx-x"
                defaultValue={populatedata.LandCreditorCnic}
                onKeyDown={(e) => {
                  if (e.key === "Backspace") {
                    document.getElementById("CNIC").value = "";
                  }
                }}
                onChange={(e) => {
                  if (
                    e.target.value?.length === 5 ||
                    e.target.value?.length === 13
                  ) {
                    document.getElementById("CNIC").value =
                      e.target.value + "-";
                  }
                }}
              />
              <span className="estaric">*</span>
            </>
          ) : (
            <>
              <input
                type="text"
                className="input_styling ml-md-3 "
                id="Companyregistration"
                placeholder="xxxxxxx"
                defaultValue={populatedata.LandCreditorCnic}
              />
              <span className="estaric">*</span>
            </>
          )}
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Phone No </label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="Phone"
            placeholder="xxx-xxxxxxx"
            defaultValue={populatedata.PhoneNumber}
            maxLength={12}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                document.getElementById("Phone").value = "";
              }
            }}
            onChange={(e) => {
              if (e.target.value?.length === 3) {
                document.getElementById("Phone").value = e.target.value + "-";
              }
            }}
          />
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Country</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <select
            id="LandSellerCountry"
            onChange={(e) => {
              setselectedCountries(e.target.value);
            }}
            className="input_styling ml-md-3"
            name="country"
            value={selectedCountries}
          >
            <option selected>Select</option>
            {allCountries?.map((item, i) => {
              return (
                <>
                  <option key={i} value={item?.displayValue?.toUpperCase()}>
                    {item?.displayValue?.toUpperCase()}
                  </option>
                </>
              );
            })}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">State</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <select
            className="input_styling ml-md-3"
            id="LandSellerState"
            onChange={(e) => {
              setselectedStates(e.target.value);
            }}
            value={selectedStates}
          >
            <option selected>Select</option>
            {allStates?.map((item, i) => {
              return (
                <>
                  <option key={i} value={item?.displayValue?.toUpperCase()}>
                    {item?.displayValue?.toUpperCase()}
                  </option>
                </>
              );
            })}
          </select>
          <span className="estaric">*</span>
        </div>
      </div>

      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">City</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <select
            className="input_styling ml-md-3"
            id="LandSellerCity"
            onChange={(e) => {
              setselectedCity(e.target.value);
            }}
            value={selectedCity}
          >
            <option selected>Select</option>
            {allCity?.map((item, i) => {
              return (
                <>
                  <option key={i} value={item?.displayValue?.toUpperCase()}>
                    {item?.displayValue?.toUpperCase()}
                  </option>
                </>
              );
            })}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Cell No 1:</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3"
            id="Cell1"
            placeholder="xxxx-xxxxxxx"
            defaultValue={populatedata.ContactNumber1}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                document.getElementById("Cell1").value = "";
              }
            }}
            maxLength={12}
            onChange={(e) => {
              if (e.target.value?.length === 4) {
                document.getElementById("Cell1").value = e.target.value + "-";
              }
            }}
          />
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Cell No 2: </label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="Cell2"
            placeholder="xxxx-xxxxxxx"
            defaultValue={populatedata.ContactNumber2}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                document.getElementById("Cell2").value = "";
              }
            }}
            maxLength={12}
            onChange={(e) => {
              if (e.target.value?.length === 4) {
                document.getElementById("Cell2").value = e.target.value + "-";
              }
            }}
          />
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>

        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Address:</label>
        </div>
        <div className="col-md-3 mt-4   p-0">
          <textarea
            className="input_address ml-md-3 "
            id="Address"
            defaultValue={populatedata.LandCreditorAddress}
          ></textarea>
          <span className="estaric">*</span>
        </div>
      </div>

      <div className="row pr-md-2 pt-md-4">
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
          className=" col-12 pr-0 pl-0  text-right "
        >
          <span>
            <button
              className="cancel_btn ml-2  "
              onClick={() => {
                props.cancelcreditortable();
                emptyfields();
              }}
            >
              Cancel
            </button>
          </span>
          <span className="ml-2">
            {isLoading ? (
              <>
                <Loader
                  type="Oval"
                  color="black"
                  height={30}
                  width={30}
                  radius={12}
                />
              </>
            ) : null}
          </span>
          <span>
            <button
              className="save_btn  ml-2"
              id="creditorsave"
              onClick={addCreditor}
            >
              <BsCloudUpload size="1.3em" />
              Save
            </button>
          </span>
        </div>
      </div>
    </>
  );
};
