import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import IndividualPrintHeader from "../../../Prints/PrintHeader/IndividualPrintHeader";
class ProjectsPrint extends Component {
    render() {

        return (
            <div style={{ backgroundColor: "white", height: "100%" }}>
                <div className="row m-0 p-0">
                <IndividualPrintHeader />
                </div>
                <div
                    className="row m-3 p-0"
                    style={{ border: "5px solid darkgrey", height: "85%" }}
                >
                    <div className="col-12 text-center mt-2">
                        <h4 style={{ color: "red" }}>
                            <u>Project </u>
                        </h4>
                        <h5>
                            {/* <b>Voucher No.:{" "}</b> */}
                            {/* {this.props.printData !== undefined ?

                                this.props.printData.GeneralVoucherId

                                : null} */}
                        </h5>
                        <h5 className="text-left">
                            <b>Project Name: {" "}</b>
                            {this.props.printData !== undefined ?

                                this.props.printData.ProjectCategory !== undefined && this.props.printData.ProjectCategory !== null ? this.props.printData.ProjectCategory.ProjectCategoryName : null

                                : null}
                        </h5>



                        <div className="mt-3">
                            <div class=" col-12">
                                <table class="table">
                                    <thead>
                                        <tr>




                                            <th> Category</th>
                                            <th> Owner</th>
                                            <th>Location</th>
                                            <th>City</th>
                                            <th>Adv %</th>
                                            <th>Installments</th>

                                        </tr>
                                    </thead>
                                    {this.props.printData !== undefined ?
                                        <tbody>
                                            <tr>
                                                <td>{this.props.printData.ProjectName}</td>

                                                <td>{this.props.printData.ProjectOwner}</td>
                                                <td>{this.props.printData.ProjectLocation}</td>
                                                <td>{this.props.printData.ProjectCity}</td>
                                                <td>{this.props.printData.AdvancePayment}</td>
                                                <td>{this.props.printData.Installments}</td>



                                            </tr>
                                        </tbody> : null}
                                </table>
                            </div>
                        </div>
                        {/* <h5 className="text-right pt-3">
                            <b>Narration:{" "}</b>
                            {this.props.printData !== undefined ?

                                this.props.printData.Narration

                                : null}
                        </h5> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default ProjectsPrint;