import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import { BsCloudUpload } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import {
  getallLoanPopulate,
  getallAdvanceTable,
} from "../../actions/HRactions/advanceloanaction";

import axios from "axios";
import { MdDeleteForever } from "react-icons/md";

import { FiEdit2 } from "react-icons/fi";
import { toast } from "react-toastify";

import Loader from "react-loader-spinner";
export const PayAdvance = (props) => {
  const loanpopulate = useSelector((state) => state.GetAllLoanPopulate);
  const [stateempid, setempid] = useState();
  let dispatch = useDispatch();
  var suggestionlist1 = [];
  const [state, setstate] = useState({
    EmployeeAdvanceId: 0,
    EmployeeId: 0,
    AdvanceAmount: null,
  });
  const designationSelecttion = (e) => {
    const value = e.target.value;
    if (value == "") {
      ReactDOM.render(null, document.getElementById("unitdemo"));
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = loanpopulate.data.Employees.sort().filter((v) =>
          regex.test(v.EmpName !== null ? v.EmpName : null)
        );
      }
      suggestionlist1 = suggestions;

      if (suggestionlist1.length === 0) {
        ReactDOM.render(null, document.getElementById("unitdemo"));
      } else {
        ReactDOM.render(
          <ul className="suggestbox">
            {suggestionlist1.map((item) => (
              <li className="listcss" onClick={() => suggestionselected1(item)}>
                {item === undefined ? item : item.EmpName}
              </li>
            ))}
          </ul>,
          document.getElementById("unitdemo")
        );
      }
    }
  };
  const onTextChangeEmployeeName = () => {
    var inputValue = document.getElementById("Employeename").value;
    var options = document.getElementById("employeenamelist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        loanpopulate.data.Employees.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });
        document.getElementById("Employeename").value = val.EmpName;

        setempid(val.EmployeeId);

        break;
      }
    }
  };
  const suggestionselected1 = (val) => {
    document.getElementById("Employeename").value = val.EmpName;

    setempid(val.EmployeeId);

    suggestionlist1 = [];
    ReactDOM.render(null, document.getElementById("unitdemo"));
  };
  const savedata = () => {
    if (document.getElementById("Employeename").value === "") {
      toast.info("Enter Employee Name");
    } else if (document.getElementById("loanamount").value === "") {
      toast.info("Enter Loan Amount");
    } else {
      let Loan = state;
      // let LoanAllowances = props.allallowances;

      Loan.EmployeeId =
        props.stateadvanceedit !== undefined &&
        props.stateadvanceedit.Employee !== undefined
          ? props.stateadvanceedit.Employee.EmployeeId
          : stateempid;
      Loan.EmployeeAdvanceId =
        props.stateadvanceedit !== undefined
          ? props.stateadvanceedit.EmployeeAdvanceId
          : 0;

      Loan.AdvanceAmount = parseInt(
        document.getElementById("loanamount").value.replace(/,/g, "")
      );

      document.getElementById("advancesave").disabled = true;
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Advances/Add`,
        data: JSON.stringify(Loan),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("advancesave").disabled = false;
          if (result.data.IsSuccess === true) {
            toast.info(result.data.Message);
            props.setadvanceedit([]);
            props.cancelpayadvancetable();
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Someting went wrong");
          document.getElementById("advancesave").disabled = false;
        }
      );
    }
  };
  useEffect(() => {
    dispatch(getallLoanPopulate());
  }, []);
  return (
    <>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Employee Name</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            list="employeenamelist"
            className="input_styling  ml-md-3"
            id="Employeename"
            defaultValue={
              props.stateadvanceedit !== undefined &&
              props.stateadvanceedit.Employee !== undefined
                ? props.stateadvanceedit.Employee.EmpName
                : null
            }
            onChange={onTextChangeEmployeeName}
          ></input>
          <span className="estaric">*</span>
          <datalist id="employeenamelist">
            {loanpopulate?.data?.Employees?.map((x, idx) => (
              <option index={idx} value={x.EmpName}>
                {x.EmployeeId}
              </option>
            ))}
          </datalist>
          <span id="unitdemo"></span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Amount</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling  ml-md-3"
            id="loanamount"
            defaultValue={
              props.stateadvanceedit !== undefined &&
              props.stateadvanceedit.AdvanceAmount !== undefined &&
              props.stateadvanceedit.AdvanceAmount !== null
                ? props.stateadvanceedit.AdvanceAmount.toLocaleString()
                : null
            }
            onChange={(e) => {
              if (e.target.value.length > 0)
                document.getElementById("loanamount").value = parseInt(
                  e.target.value.replace(/,/g, "")
                ).toLocaleString();
            }}
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>

      <div className=" float-right mt-md-4 ">
        <span>
          <button
            className="cancel_btn ml-2  "
            onClick={props.cancelpayadvancetable}
          >
            Cancel
          </button>
        </span>

        <span>
          <button
            className="save_btn ml-2  "
            id="advancesave"
            onClick={savedata}
          >
            <BsCloudUpload size="1.3em" />
            Save
          </button>
        </span>
      </div>
    </>
  );
};
export const PayAdvanceTable = (props) => {
  const rolesdata = useSelector((state) => state.GetAllRole);

  const advancedatastate = useSelector((state) => state.GetAllAdvanceTable);

  const [allLoanData, setallLoanData] = useState([]);
  const [filterloans, setfilterloans] = useState();

  let dispatch = useDispatch();

  var suggestionlist = [];

  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setfilterloans(advancedatastate.data.Advances);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");

        suggestions = filterloans
          .sort()
          .filter((val) =>
            regex.test(val.Employee !== null ? val.Employee.EmpName : null)
          );
      }
      suggestionlist = suggestions;
      setfilterloans(suggestionlist);
    }
  };

  const openmodalallowancetable = (x) => {
    setallLoanData(x);

    document.getElementById("modalofleadtable").style.display = "block";
  };

  useEffect(() => {
    dispatch(getallAdvanceTable());
  }, [dispatch]);
  useEffect(() => {
    setfilterloans(advancedatastate.data.Advances);
  }, [advancedatastate]);
  return (
    <>
      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label  m-0">Search:</label>
        </div>
        <div className="col-md-3   pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            placeholder="Employee"
            onChange={onTextChange}
          ></input>
        </div>

        <div className="col-md-1 pr-0 pl-0">
          {advancedatastate.loading ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </div>
      </div>

      <div className="table-responsive mt-4">
        <table className="table table-borderless  table-hover m-0">
          <thead>
            <tr>
              <th>Sr</th>

              <th>Employee</th>

              <th>Advance Amount</th>

              <th className="text-center">
                {rolesdata?.data?.Access?.includes("C") ? (
                  <button
                    className="save_btn"
                    onClick={() => {
                      props.showpayadvancetable();
                    }}
                  >
                    New+
                  </button>
                ) : null}
              </th>
            </tr>
          </thead>
          <tbody>
            {filterloans !== undefined
              ? filterloans.map((val, index) => (
                  <tr key={index + 1}>
                    <td className="cur_sor">{index + 1}</td>

                    <td className="cur_sor">
                      {val.Employee !== undefined ? val.Employee.EmpName : null}
                    </td>

                    <td className="cur_sor">
                      {val.AdvanceAmount !== undefined
                        ? val.AdvanceAmount.toLocaleString()
                        : null}
                    </td>

                    <td className="text-center cur_sor ">
                      {rolesdata?.data?.Access?.includes("U") ? (
                        <FiEdit2
                          title="Edit"
                          size="1.1em"
                          className="cur_sor"
                          onClick={() => {
                            props.setadvanceedit(val);
                            props.showpayadvancetable();
                          }}
                        />
                      ) : null}
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </div>
    </>
  );
};
