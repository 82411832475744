import axios from "axios";
import { toast } from "react-toastify";
export const InstallRescedule = (data) => {
  return {
    type: "get_installrescedule",
    payload: data,
    loader: false,
  };
};
export function installrescedule(data, link) {
  return (dispatch) => {
    let pgurl = `${
      link
        ? link + "&"
        : process.env.REACT_APP_API_URL +
          "Contracts/RescheduleInstallments/Populate?"
    }From=${data.From ? data.From : ""}&To=${
      data.To ? data.To : ""
    }&ProjectId=${data?.ProjectId ? data?.ProjectId : ""}&&UnitName=${
      data.UnitName ? data.UnitName : ""
    }&&AffliateCnic=${
      data.AffiliateCnic ? data.AffiliateCnic : ""
    }&&CustomerCnic=${data.CustomerCnic ? data.CustomerCnic : ""}`;
    axios({
      url: pgurl,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.Succeeded === true) {
          dispatch(InstallRescedule(result.data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
export const GetResceduleData = (data) => {
  return {
    type: "get_resceduledata",
    payload: data,
  };
};
