import React, { useEffect, useState } from "react";
import { CommissionPaymentForm } from "./CommissionPayment/CommissionPaymentForm";
import { CommissionPaymentTable } from "./CommissionPayment/CommissionPaymentTable";
import { DealPaymentForm } from "./DealPayment/DealPaymentForm";
import { DealPaymentTable } from "./DealPayment/DealPaymentTable";
import GeneralPaymentForm from "./GeneralPayment/GeneralPaymentForm";
import { GeneralPaymentTable } from "./GeneralPayment/GeneralPaymentTable";
import { InvestPaymentForm } from "./InvestPayment/InvestPaymentForm";
import { InvestPaymentTable } from "./InvestPayment/InvestPaymentTable";
import { LandPaymentForm } from "./LandPayment/LandPaymentForm";
import { LandPaymentTable } from "./LandPayment/LandPaymentTable";
import { SalaryPaymentForm } from "./SalaryPayment/SalaryPaymentForm";
import { SalaryPaymentTable } from "./SalaryPayment/SalaryPaymentTable";

const PaymentEntry = () => {
  const [tab, settab] = useState("General Payment");
  const [voucherData, setvoucherData] = useState(null);
  const [landVoucherData, setlandvoucherData] = useState(null);

  const [commPayData, setcommPayData] = useState(null);
  const [voucherSaveDetails, setvoucherSaveDetails] = useState(null);

  const showgeneraltable = () => {
    stateshowgeneral(
      <GeneralPaymentForm
        setvoucherData={setvoucherData}
        vouchData={voucherData}
        voucherSaveDetails={voucherSaveDetails}
        cancelgeneraltable={cancelgeneraltable}
        setvoucherSaveDetails={setvoucherSaveDetails}
      />
    );
  };
  const cancelgeneraltable = () => {
    setvoucherData(null);
    setvoucherSaveDetails(null);

    stateshowgeneral(
      <GeneralPaymentTable
        setvoucherData={setvoucherData}
        vouchData={voucherData}
        showgeneraltable={showgeneraltable}
        cancelgeneraltable={cancelgeneraltable}
        setvoucherSaveDetails={setvoucherSaveDetails}
        voucherSaveDetails={voucherSaveDetails}
      />
    );
  };
  const [showgeneral, stateshowgeneral] = useState(
    <GeneralPaymentTable
      showgeneraltable={showgeneraltable}
      cancelgeneraltable={cancelgeneraltable}
      setvoucherSaveDetails={setvoucherSaveDetails}
    />
  );
  useEffect(() => {
    if (voucherData === null) {
      stateshowgeneral(
        <GeneralPaymentTable
          setvoucherData={setvoucherData}
          vouchData={voucherData}
          showgeneraltable={showgeneraltable}
          cancelgeneraltable={cancelgeneraltable}
          setvoucherSaveDetails={setvoucherSaveDetails}
        />
      );
    } else {
      console.log("voucherData", voucherData);

      stateshowgeneral(
        <GeneralPaymentForm
          vouchData={voucherData}
          voucherSaveDetails={voucherSaveDetails}
          cancelgeneraltable={cancelgeneraltable}
          setvoucherSaveDetails={setvoucherSaveDetails}
        />
      );
    }
  }, [voucherData, voucherSaveDetails]);
  useEffect(() => {
    if (landVoucherData === null) {
      stateshowland(
        <LandPaymentTable
          showlandtable={showlandtable}
          cancellandtable={cancellandtable}
          setvoucherData={setlandvoucherData}
          vouchData={landVoucherData}
        />
      );
    } else {
      stateshowland(
        <LandPaymentForm
          setvoucherData={setlandvoucherData}
          vouchData={landVoucherData}
          cancellandtable={cancellandtable}
        />
      );
    }
  }, [landVoucherData]);
  // functions for land screen
  const showlandtable = () => {
    stateshowland(
      <LandPaymentForm
        setvoucherData={setlandvoucherData}
        vouchData={landVoucherData}
        cancellandtable={cancellandtable}
      />
    );
  };
  const cancellandtable = () => {
    stateshowland(
      <LandPaymentTable
        showlandtable={showlandtable}
        cancellandtable={cancellandtable}
        setvoucherData={setlandvoucherData}
        vouchData={landVoucherData}
      />
    );
  };
  const [showland, stateshowland] = useState(
    <LandPaymentTable
      showlandtable={showlandtable}
      cancellandtable={cancellandtable}
      setvoucherData={setvoucherData}
      vouchData={voucherData}
    />
  );
  // functions for deal reciept screen
  const showdealtable = () => {
    stateshowdeal(<DealPaymentForm canceldealtable={canceldealtable} />);
  };
  const canceldealtable = () => {
    stateshowdeal(
      <DealPaymentTable
        showdealtable={showdealtable}
        canceldealtable={canceldealtable}
      />
    );
  };
  const [showdeal, stateshowdeal] = useState(
    <DealPaymentTable
      showdealtable={showdealtable}
      canceldealtable={canceldealtable}
    />
  );
  // functions for recoverysalary screen
  const showsalarytable = () => {
    stateshowsalary(
      <SalaryPaymentForm cancelsalarytable={cancelsalarytable} />
    );
  };
  const cancelsalarytable = () => {
    stateshowsalary(
      <SalaryPaymentTable
        showsalarytable={showsalarytable}
        cancelsalarytable={cancelsalarytable}
      />
    );
  };
  const [showsalary, stateshowsalary] = useState(
    <SalaryPaymentTable
      showsalarytable={showsalarytable}
      cancelsalarytable={cancelsalarytable}
    />
  );
  // functions for loan screen
  const showloantable = () => {
    stateshowloan(<InvestPaymentForm cancelloantable={cancelloantable} />);
  };
  const cancelloantable = () => {
    stateshowloan(
      <InvestPaymentTable
        showloantable={showloantable}
        cancelloantable={cancelloantable}
      />
    );
  };
  const [showloan, stateshowloan] = useState(
    <InvestPaymentTable
      showloantable={showloantable}
      cancelloantable={cancelloantable}
    />
  );
  // functions for commission screen
  const showcommissiontable = () => {
    stateshowcommission(
      <CommissionPaymentForm
        setvoucherData={setcommPayData}
        vouchData={voucherData}
        cancelcommissiontable={cancelcommissiontable}
      />
    );
  };
  const cancelcommissiontable = () => {
    stateshowcommission(
      <CommissionPaymentTable
        setvoucherData={setcommPayData}
        vouchData={commPayData}
        showcommissiontable={showcommissiontable}
        cancelcommissiontable={cancelcommissiontable}
      />
    );
  };
  const [showcommission, stateshowcommission] = useState(
    <CommissionPaymentTable
      setvoucherData={setcommPayData}
      vouchData={commPayData}
      showcommissiontable={showcommissiontable}
      cancelcommissiontable={cancelcommissiontable}
    />
  );
  useEffect(() => {
    if (commPayData === null) {
      stateshowcommission(
        <CommissionPaymentTable
          setvoucherData={setcommPayData}
          vouchData={commPayData}
          showcommissiontable={showcommissiontable}
          cancelcommissiontable={cancelcommissiontable}
        />
      );
    } else {
      stateshowcommission(
        <CommissionPaymentForm
          setvoucherData={setcommPayData}
          vouchData={commPayData}
          cancelcommissiontable={cancelcommissiontable}
        />
      );
    }
  }, [commPayData]);

  return (
    <>
      <div className="row ">
        <div className="dash_background col-md-12 p-0">
          <div className="row  ">
            <div className="col-md-12 text-center p-0">
              <button
                className={
                  tab === "General Payment"
                    ? "comp_btns_selected"
                    : "comp_btns  "
                }
                onClick={() => {
                  settab("General Payment");
                }}
              >
                <span className="comp_btns_text">General Payment</span>
              </button>

              {`${localStorage.getItem("ProjectName")}` !== "HEAD OFFICE" ? (
                `${localStorage.getItem("ProjectOwner")}` != "OTHER" ? (
                  <button
                    className={
                      tab === "Land Payment"
                        ? "comp_btns_selected ml-1 "
                        : "comp_btns ml-1 "
                    }
                    onClick={() => {
                      settab("Land Payment");
                    }}
                  >
                    <span className="comp_btns_text"> Land Payment</span>
                  </button>
                ) : (
                  <button
                    className={
                      tab === "Land Payment"
                        ? "comp_btns_selected ml-1 "
                        : "comp_btns ml-1 "
                    }
                  >
                    <span
                      className="comp_btns_text"
                      style={{
                        textDecorationLine: "line-through",
                        textDecorationStyle: "solid",
                      }}
                    >
                      {" "}
                      Land Payment
                    </span>
                  </button>
                )
              ) : (
                <button
                  className={
                    tab === "Land Payment"
                      ? "comp_btns_selected ml-1 "
                      : "comp_btns ml-1 "
                  }
                >
                  <span
                    className="comp_btns_text"
                    style={{
                      textDecorationLine: "line-through",
                      textDecorationStyle: "solid",
                    }}
                  >
                    {" "}
                    Land Payment
                  </span>
                </button>
              )}

              {`${localStorage.getItem("ProjectName")}` === "HEAD OFFICE" ? (
                <button
                  className={
                    tab === "Salary Payment"
                      ? "comp_btns_selected ml-1 "
                      : "comp_btns ml-1 "
                  }
                  onClick={() => {
                    settab("Salary Payment");
                  }}
                >
                  <span className="comp_btns_text"> Salary Payment</span>
                </button>
              ) : (
                <button
                  className={
                    tab === "Salary Payment"
                      ? "comp_btns_selected ml-1 "
                      : "comp_btns ml-1 "
                  }
                >
                  <span
                    className="comp_btns_text"
                    style={{
                      textDecorationLine: "line-through",
                      textDecorationStyle: "solid",
                    }}
                  >
                    {" "}
                    Salary Payment
                  </span>
                </button>
              )}

              {`${localStorage.getItem("ProjectName")}` !== "HEAD OFFICE" ? (
                <button
                  className={
                    tab === "Loan Payment"
                      ? "comp_btns_selected ml-1 "
                      : "comp_btns ml-1 "
                  }
                  onClick={() => {
                    settab("Loan Payment");
                  }}
                >
                  <span className="comp_btns_text">Invest Payment</span>
                </button>
              ) : (
                <button
                  className={
                    tab === "Loan Payment"
                      ? "comp_btns_selected ml-1 "
                      : "comp_btns ml-1 "
                  }
                >
                  <span
                    className="comp_btns_text"
                    style={{
                      textDecorationLine: "line-through",
                      textDecorationStyle: "solid",
                    }}
                  >
                    Invest Payment
                  </span>
                </button>
              )}
              {`${localStorage.getItem("ProjectName")}` !== "HEAD OFFICE" ? (
                `${localStorage.getItem("ProjectOwner")}` != "PERSONAL" ? (
                  <button
                    className={
                      tab === "Deal Payment"
                        ? "comp_btns_selected ml-1 "
                        : "comp_btns ml-1 "
                    }
                    onClick={() => {
                      settab("Deal Payment");
                    }}
                  >
                    <span className="comp_btns_text">Deal Payment</span>
                  </button>
                ) : (
                  <button
                    className={
                      tab === "Deal Payment"
                        ? "comp_btns_selected ml-1 "
                        : "comp_btns ml-1 "
                    }
                  >
                    <span
                      className="comp_btns_text"
                      style={{
                        textDecorationLine: "line-through",
                        textDecorationStyle: "solid",
                      }}
                    >
                      Deal Payment
                    </span>
                  </button>
                )
              ) : (
                <button
                  className={
                    tab === "Deal Payment"
                      ? "comp_btns_selected ml-1 "
                      : "comp_btns ml-1 "
                  }
                >
                  <span
                    className="comp_btns_text"
                    style={{
                      textDecorationLine: "line-through",
                      textDecorationStyle: "solid",
                    }}
                  >
                    Deal Payment
                  </span>
                </button>
              )}
              {`${localStorage.getItem("ProjectName")}` !== "HEAD OFFICE" ? (
                <button
                  className={
                    tab === "Commission Payment"
                      ? "comp_btns_selected ml-1 "
                      : "comp_btns ml-1 "
                  }
                  onClick={() => {
                    settab("Commission Payment");
                  }}
                >
                  <span className="comp_btns_text" title="Commission Payment">
                    {" "}
                    Com Payment
                  </span>
                </button>
              ) : (
                <button
                  className={
                    tab === "Commission Payment"
                      ? "comp_btns_selected ml-1 "
                      : "comp_btns ml-1 "
                  }
                >
                  <span
                    className="comp_btns_text"
                    title="Commission Payment"
                    style={{
                      textDecorationLine: "line-through",
                      textDecorationStyle: "solid",
                    }}
                  >
                    {" "}
                    Com Payment
                  </span>
                </button>
              )}
            </div>
            <div className="col-md-12">
              {tab === "General Payment"
                ? showgeneral
                : tab === "Commission Payment"
                ? showcommission
                : tab === "Land Payment"
                ? showland
                : tab === "Salary Payment"
                ? showsalary
                : tab === "Loan Payment"
                ? showloan
                : tab === "Deal Payment"
                ? showdeal
                : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentEntry;
