import moment from "moment";
import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";

export class CustomerPrint extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "auto" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Customers - {localStorage.getItem("ProjectName")} </u>
            </h4>

            <div className="mt-5">
              <div class=" col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Name</th>
                      <th>GuardianName</th>
                      <th>CNIC</th>

                      <th>Contact No.</th>
                      <th>City</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.props?.printData?.data !== undefined
                      ? this.props?.printData?.data?.map((val, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{val.CustomerName}</td>
                            <td>{val.GuardianName}</td>
                            <td>{val.CustomerCnic}</td>

                            <td>{val.ContactNumber1}</td>
                            <td>{val.City}</td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
