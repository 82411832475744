import axios from "axios";
import { toast } from "react-toastify";
export const GetCancelPlotPayment = (data) => {
  return {
    type: "get_cancelplotpayment",
    payload: data,
    loader: false,
  };
};
export function getCancelPlotPayment() {
  let pgurl = `${
    process.env.REACT_APP_API_URL
  }Reports/CancelledPoltsPayment?ProjectId=${parseInt(
    `${localStorage.getItem("ProjectId")}`
  )}`;

  return (dispatch) => {
    axios({
      url: pgurl,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetCancelPlotPayment(result.data.Data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
