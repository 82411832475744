import React, { useContext, useState, useEffect } from "react";
import { monthlyAlert } from "../ContextApi/MonthlyAlert";
import icon from "../../assets/Invoice-Reminder.png";
import moment from "moment";

export default function AltertModal() {
  const { showAlertModal, changeState } = useContext(monthlyAlert);
  const [showIcon, setShowIcon] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowIcon(true);
    }, 60000); // Display icon after 1 minute (60000 milliseconds)

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {showAlertModal && (
        <div style={{ display: "block" }} className="modal">
          <div className="modal-content-Alert">
            <div className="row ">
              <div className="col-12  page_headingAlert">
                {showIcon && (
                  <span
                    className="close pr-2 closeicon"
                    id="hideMeAfter5Seconds"
                    onClick={() => changeState(false)}
                  >
                    &times;
                  </span>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{
                    marginTop: "10px",
                    margin: " 0 auto",
                    width: "100%",
                  }}
                  src={icon}
                  alt="icon"
                />
              </div>
              <marquee
                width="100%"
                direction="left"
                style={{ backgroundColor: "#21b6a8", color: "white" }}
              >
                {/* Please be informed that effective May 13, 2024, access to our
                software services will be discontinued unless payment is
                rendered. We kindly request your attention to this matter to
                ensure uninterrupted service. Thank you for your cooperation */}
                Please Clear Invoice
              </marquee>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
