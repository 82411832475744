import React, { useEffect, useState, useRef } from "react";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import { useReactToPrint } from "react-to-print";
import ProfitLossPrint from "../ReportPrints/ProfitLossPrint";
import { getprofitloss } from "../../../actions/AccountReports/profitandloss";
import moment from "moment/moment";
import { toast } from "react-toastify";

const IncomeStatement = () => {
  const [fromDate, setFromDate] = useState(
    moment().subtract(1, "year").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [dateLoading, setDateLoading] = useState(false);

  const statealldata = useSelector((state) => state.getProfitLoss);
  let stateolddata =
    statealldata.data.OldProfitAndLoss !== undefined
      ? statealldata.data.OldProfitAndLoss
      : "";
  let statenewdata =
    statealldata.data.NewProfitAndLoss !== undefined
      ? statealldata.data.NewProfitAndLoss
      : "";

  let dispatch = useDispatch();
  // print function
  const componentRef = useRef();

  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  //  revenue total start
  let Totaloldrevenue = 0;
  if (stateolddata.REVEUE !== undefined) {
    stateolddata.REVEUE.map(
      (val) => (Totaloldrevenue += parseFloat(val.Balance))
    );
  }
  let Totalnewrevenue = 0;
  if (statenewdata.REVEUE !== undefined) {
    statenewdata.REVEUE.map(
      (val) => (Totalnewrevenue += parseFloat(val.Balance))
    );
  }

  let Totaloldcostofrevnue = 0;
  if (stateolddata.CostofRevenue !== undefined) {
    stateolddata.CostofRevenue.map(
      (val) => (Totaloldcostofrevnue += parseFloat(val.Balance))
    );
  }
  let Totalnewcostofrevnue = 0;
  if (statenewdata.CostofRevenue !== undefined) {
    statenewdata.CostofRevenue.map(
      (val) => (Totalnewcostofrevnue += parseFloat(val.Balance))
    );
  }

  let grossprofitold =
    parseFloat(Totaloldrevenue) - parseFloat(Totaloldcostofrevnue);
  let grossprofitnew =
    parseFloat(Totalnewrevenue) - parseFloat(Totalnewcostofrevnue);
  console.log(Totalnewrevenue);
  console.log(Totalnewcostofrevnue);
  //  revenue total end
  let Totaloldadminexp = 0;
  if (stateolddata.AdminExpense !== undefined) {
    stateolddata.AdminExpense.map(
      (val) => (Totaloldadminexp += parseFloat(val.Balance.toLocaleString()))
    );
  }
  let Totalnewadminexp = 0;
  if (statenewdata.AdminExpense !== undefined) {
    statenewdata.AdminExpense.map(
      (val) => (Totalnewadminexp += parseFloat(val.Balance.toLocaleString()))
    );
  }

  let Totaloldoperatingexp = 0;
  if (stateolddata.OtherExpense !== undefined) {
    stateolddata.OtherExpense.map(
      (val) =>
        (Totaloldoperatingexp += parseFloat(val.Balance.toLocaleString()))
    );
  }
  let Totalnewoperatingexp = 0;
  if (statenewdata.OtherExpense !== undefined) {
    statenewdata.OtherExpense.map(
      (val) =>
        (Totalnewoperatingexp += parseFloat(val.Balance.toLocaleString()))
    );
  }

  let Totaloldotherincome = 0;
  if (stateolddata.OtherIncome !== undefined) {
    stateolddata.OtherIncome.map(
      (val) => (Totaloldotherincome += parseFloat(val.Balance.toLocaleString()))
    );
  }
  let Totalnewotherincome = 0;
  if (statenewdata.OtherIncome !== undefined) {
    statenewdata.OtherIncome.map(
      (val) => (Totalnewotherincome += parseFloat(val.Balance.toLocaleString()))
    );
  }

  let operatingprofitold =
    parseFloat(Totaloldotherincome) -
    (parseFloat(Totaloldoperatingexp) + parseFloat(Totaloldadminexp));
  let operatingprofitnew =
    parseFloat(Totalnewotherincome) -
    (parseFloat(Totalnewoperatingexp) + parseFloat(Totalnewadminexp));

  //    profit before taxation
  let profitbeforetaxold = stateolddata
    ? grossprofitold + operatingprofitold - stateolddata.FinanceCostSum
    : grossprofitold + operatingprofitold - 0;
  let profitbeforetaxnew =
    grossprofitnew + operatingprofitnew - statenewdata.FinanceCostSum;

  useEffect(() => {
    dispatch(
      getprofitloss(
        moment().subtract(1, "year").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      )
    );
  }, []);

  const handleGoClick = () => {
    const startDateMoment = moment(fromDate);
    const endDateMoment = moment(toDate);

    const differenceInDays = endDateMoment.diff(startDateMoment, "days");

    if (differenceInDays > 365) {
      toast.error(
        "Diffrence between Start Date and End Date should not be greater than 1 year"
      );
      return;
    }
    setDateLoading(true);
    dispatch(getprofitloss(fromDate, toDate, setDateLoading));
  };

  return (
    <>
      <div hidden>
        <ProfitLossPrint
          ref={componentRef}
          stateolddata={stateolddata}
          statenewdata={statenewdata}
          statealldata={statealldata}
          grossprofitold={grossprofitold}
          grossprofitnew={grossprofitnew}
          operatingprofitold={operatingprofitold}
          operatingprofitnew={operatingprofitnew}
          profitbeforetaxold={profitbeforetaxold}
          profitbeforetaxnew={profitbeforetaxnew}
        />
      </div>
      <div className="row   app_font ">
        <div className="dash_background col-12 pt-2 ">
          <div className="row ">
            <div className="col-md-3 text-center pr-0 pl-0">
              <label className="input_label m-0">From:</label>
              <input
                type="date"
                className="input_date ml-3"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </div>
            <div className="col-md-3 text-center pr-0 pl-0">
              <label className="input_label m-0">To:</label>
              <input
                type="date"
                className="input_date ml-3"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>
            <div className="col-md-1 pr-0 pl-0">
              <button
                className="btn_Go"
                disabled={!fromDate || !toDate || dateLoading}
                onClick={handleGoClick}
              >
                Go
              </button>
            </div>
            <div className="col-md-1 pr-0 pl-0">
              {dateLoading && (
                <Loader type="ThreeDots" color="green" height={40} width={40} />
              )}
            </div>
          </div>
          <div
            style={{ backgroundColor: "#CAF5F5", height: "1px" }}
            className="col-12 m-2"
          ></div>
          <div className="row ">
            <div className="col-md-6 pr-0 pl-0">
              <label className=" m-0">
                <b>
                  IGA DEVELOPERS STATEMENT OF FINANCIAL
                  POSITION:
                </b>
              </label>
            </div>
            <div className="col-md-6 pr-0 pl-0">
              {statealldata.loading ? (
                <Loader type="ThreeDots" color="green" height={40} width={40} />
              ) : null}
            </div>
          </div>
          {/* assets */}
          <div className="row ">
            <div className="col-md-6 pr-0 pl-0 pt-3"></div>
            <div className="col-md-3 pr-0 pl-0">
              <div>
                <b>
                  {/* {statealldata.data !== undefined
                    ? statealldata.data.PastYear
                    : null} */}
                  {statealldata.data?.LastOpeningDate &&
                    moment(statealldata.data?.LastOpeningDate)?.format(
                      "DD-MMM-YYYY"
                    )}{" "}
                  {statealldata.data?.LastClosingDate &&
                  statealldata.data?.LastClosingDate
                    ? "To"
                    : ""}{" "}
                  {statealldata.data?.LastClosingDate &&
                    moment(statealldata.data?.LastClosingDate)?.format(
                      "DD-MMM-YYYY"
                    )}
                </b>
              </div>
              <div>
                <b>Rupees</b>
              </div>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              <div>
                <b>
                  {/* {statealldata.data !== undefined
                    ? statealldata.data.CurrentYear
                    : null} */}
                  {statealldata.data?.CurrentOpeningDate &&
                    moment(statealldata.data?.CurrentOpeningDate)?.format(
                      "DD-MMM-YYYY"
                    )}{" "}
                  {statealldata.data?.CurrentOpeningDate &&
                  statealldata.data?.CurrentClosingDate
                    ? "To"
                    : ""}{" "}
                  {statealldata.data?.CurrentClosingDate &&
                    moment(statealldata.data?.CurrentClosingDate)?.format(
                      "DD-MMM-YYYY"
                    )}
                </b>
              </div>
              <div>
                <b>Rupees</b>
              </div>
            </div>
          </div>
          {/*Revenue */}
          <div className="row ">
            <div className="col-md-6 pr-0 pl-0">
              <label className="input_label m-0">
                <b>Revenue</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0"></div>
            <div className="col-md-3 pr-0 pl-0"></div>
          </div>
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              {statenewdata.REVEUE !== undefined
                ? statenewdata.REVEUE.map((val) => <div>{val.Title}</div>)
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata.REVEUE !== undefined
                ? stateolddata.REVEUE.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statenewdata.REVEUE !== undefined
                ? statenewdata.REVEUE.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              <label className="input_label m-0">
                <b>Cost of Revenue</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0"></div>
            <div className="col-md-3 pr-0 pl-0"></div>
          </div>
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              {statenewdata.CostofRevenue !== undefined
                ? statenewdata.CostofRevenue.map((val) => (
                    <div>{val.Title}</div>
                  ))
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata.CostofRevenue !== undefined
                ? stateolddata.CostofRevenue.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statenewdata.CostofRevenue !== undefined
                ? statenewdata.CostofRevenue.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
          </div>
          {/* Gross Profit */}
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0 text-center">
              <label className="input_label m-0">
                <b>Gross Profit</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata?.GrossProfit?.toLocaleString()}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statenewdata?.GrossProfit?.toLocaleString()}
            </div>
          </div>
          {/* Operating Expense */}
          <div className="row pt-3">
            <div className="col-md-12 pr-0 pl-0 text center">
              <h4 className="text-center"> Operating Expense</h4>
            </div>
            {/* <div className="col-md-3 pr-0 pl-0">

                  
                           
                        </div>
                        <div className="col-md-3 pr-0 pl-0">

       
                        </div> */}
          </div>
          {/* Administrative  Expense */}
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              <label className="input_label m-0">
                <b>Administrative Expense</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0"></div>
            <div className="col-md-3 pr-0 pl-0"></div>
          </div>
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              {statenewdata.AdminExpense !== undefined
                ? statenewdata.AdminExpense.map((val) => <div>{val.Title}</div>)
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata.AdminExpense !== undefined
                ? stateolddata.AdminExpense.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statenewdata.AdminExpense !== undefined
                ? statenewdata.AdminExpense.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
          </div>
          {/* Other Expense */}
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0 ">
              <label className="input_label m-0">
                <b>Other Operating Expense</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0"></div>
            <div className="col-md-3 pr-0 pl-0"></div>
          </div>
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              {statenewdata.OtherExpense !== undefined
                ? statenewdata.OtherExpense.map((val) => <div>{val.Title}</div>)
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata.OtherExpense !== undefined
                ? stateolddata.OtherExpense.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statenewdata.OtherExpense !== undefined
                ? statenewdata.OtherExpense.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
          </div>
          {/* Other Inceme */}
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0 ">
              <label className="input_label m-0">
                <b>Other Operating Income</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0"></div>
            <div className="col-md-3 pr-0 pl-0"></div>
          </div>
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              {statenewdata.OtherIncome !== undefined
                ? statenewdata.OtherIncome.map((val) => <div>{val.Title}</div>)
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata.OtherIncome !== undefined
                ? stateolddata.OtherIncome.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statenewdata.OtherIncome !== undefined
                ? statenewdata.OtherIncome.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0 text-center">
              <label className="input_label m-0 ">
                <b>Operating Profit</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata?.OperatingProfit?.toLocaleString()}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statenewdata?.OperatingProfit?.toLocaleString()}
            </div>
          </div>
          {/*Finance Cost*/}
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0 ">
              <label className="input_label m-0">
                <b>Finance Cost</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0"></div>
            <div className="col-md-3 pr-0 pl-0"></div>
          </div>
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              {statenewdata.FinanceCost !== undefined
                ? statenewdata.FinanceCost.map((val) => <div>{val.Title}</div>)
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata.FinanceCost !== undefined
                ? stateolddata.FinanceCost.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statenewdata.FinanceCost !== undefined
                ? statenewdata.FinanceCost.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0 text-center ">
              <label className="input_label m-0">
                <b>Profit Before Taxation</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata?.ProfitBeforeTaxation?.toLocaleString()}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statenewdata?.ProfitBeforeTaxation?.toLocaleString()}
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0 ">
              <label className="input_label m-0">
                <b>Taxation</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0"></div>
            <div className="col-md-3 pr-0 pl-0"></div>
          </div>
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0 text-center ">
              <label className="input_label m-0 ">
                <b>Profit For The Year</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata?.ProfitForThePeriod?.toLocaleString()}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statenewdata?.ProfitForThePeriod?.toLocaleString()}
            </div>
          </div>

          <div className="row pt-5">
            <div className="col-12  text-right pr-0 pl-0">
              <button
                style={{ marginBottom: "10px" }}
                className="print_btn float-left"
                onClick={() => {
                  printPaymentRec();
                }}
              >
                <TiPrinter size="1.3em" />
                Print
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IncomeStatement;
