import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { getallcustomers } from "../../../actions/getclients";
import Loader from "react-loader-spinner";
import {
  getalltransferunitpopulate,
  getAllTransferUnitTable,
} from "../../../actions/accountsactions/transferunit";
import axios from "axios";

export const TransferUnitPage = (props) => {
  const resultallPopulateData = useSelector(
    (state) => state.GetTransferUnitPopulate
  );
  console.log("resultallPopulateData", resultallPopulateData);
  let dispatch = useDispatch();

  const [clientAccid, setclientAccid] = useState("");
  const [currentUnits, setCurrentUnits] = useState([]);
  const [autoAmt, setautoAmt] = useState(false);
  const [autoInstallments, setAutoInstallments] = useState(false);

  const [currentUnitId, setCurrentUnitId] = useState("");
  const [newUnitId, setNewUnitId] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [remainingAmt, setRemainingAmt] = useState(0);

  const [advanceAmount, setAdvanceAmount] = useState(0);
  const [installmentAmt, setInstallmentAmt] = useState(0);
  const [netMarla, setNetMarla] = useState(0);
  const [ratePerMarla, setRatePerMarla] = useState(0);

  const [loader2, setloader2] = useState(false);

  var date = moment().format("YYYY-MM-DD");

  const onTextChangeContractName = () => {
    var inputValue = document.getElementById("contractName").value;
    var options = document.getElementById("contractnamelist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        resultallPopulateData?.data?.Customers.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });
        console.log("val", val);
        setCurrentUnits(val?.Units);
        setclientAccid(val.CustomerCnic);
        break;
      }
    }
  };

  const emptyfields = () => {
    setCurrentUnitId("");
    setNewUnitId("");
    setclientAccid("");
    document.getElementById("ratePerMarla").value = "";
    document.getElementById("installmentDueDate").value = "";
    document.getElementById("installmentAmount").value = "";
    document.getElementById("installmentDueDate").value = "";
    document.getElementById("newUnit").value = "";
    document.getElementById("currentunits").value = "";
    document.getElementById("contractName").value = "";
    setTotalAmount(0);
    setRemainingAmt(0);
    setNetMarla(0);
    setAdvanceAmount(0);
    setInstallmentAmt(0);
  };
  const handleSetNetMarla = (val) => {
    let netMarla = val;
    if (netMarla && netMarla.toString().includes(".")) {
      const [integerPart, fractionalPart] = netMarla.toString().split(".");

      const repeatedFractional = fractionalPart.repeat(
        4 / fractionalPart.length
      );

      setNetMarla(parseFloat(`${integerPart}.${repeatedFractional}`));
    } else {
      setNetMarla(netMarla);
    }
  };
  console.log("netMarla", netMarla);
  const onTextChangeNewUnit = () => {
    var inputValue = document.getElementById("newUnit").value;
    var options = document.getElementById("newUnitlist").childNodes;
    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        resultallPopulateData.data?.NewUnits?.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });

        setNewUnitId(val?.UnitId);
        handleSetNetMarla(val?.NetMarla);

        document.getElementById("ratePerMarla").value = parseInt(
          val?.RatePerMarla
        ).toLocaleString();
        setRatePerMarla(val?.RatePerMarla);

        break;
      }
    }
  };

  const onTextChangeCurrentUnit = () => {
    var inputValue = document.getElementById("currentunits").value;
    var options = document.getElementById("currentunitslist").childNodes;
    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        currentUnits?.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });

        setCurrentUnitId(val?.UnitId);
        setAdvanceAmount(val?.AdvanceAmount);
        break;
      }
    }
  };

  const savecontract = () => {
    if (
      currentUnitId === "" ||
      newUnitId === "" ||
      clientAccid === "" ||
      document.getElementById("ratePerMarla").value === "" ||
      document.getElementById("installmentDueDate").value === "" ||
      document.getElementById("installmentAmount").value === "" ||
      document.getElementById("installmentDueDate").value === ""
    ) {
      toast.info("All fields are mandatory");
    } else {
      setloader2(true);

      var data = {
        customerCNIC: clientAccid,
        oldUnitId: Number(currentUnitId),
        newUnitId: Number(newUnitId),
        saleRate: Number(
          document.getElementById("ratePerMarla").value.replace(/,/g, "")
        ),
        installmentAmount: Number(
          document.getElementById("installmentAmount").value.replace(/,/g, "")
        ),
        noOfInstallment: Number(
          document.getElementById("noOfInstallments").value
        ),
        installmentDueDate: document.getElementById("installmentDueDate").value,
      };
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Contracts/unitTransfer`,

        data: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result.data.IsSuccess === true) {
            toast.info(result.data.Message);
            emptyfields();
            props.formCancel();
            dispatch(getAllTransferUnitTable());
            setloader2(false);
          } else {
            toast.info(result.data.Message);
            setloader2(false);
          }
        },
        (error) => {
          toast.info(error);
          setloader2(false);
        }
      );
    }
  };

  useEffect(() => {
    dispatch(getalltransferunitpopulate());
  }, [dispatch]);
  useEffect(() => {
    if (autoInstallments) {
      console.log("first", Math.ceil(remainingAmt / installmentAmt));
      document.getElementById("noOfInstallments").value = Math.ceil(
        remainingAmt / installmentAmt
      );
    }
  }, [autoInstallments, remainingAmt, installmentAmt]);
  useEffect(() => {
    if (netMarla && ratePerMarla) {
      setTotalAmount(ratePerMarla * netMarla);
    }
  }, [ratePerMarla, netMarla]);
  useEffect(() => {
    console.log(
      "second",
      parseInt(
        remainingAmt / Number(document.getElementById("noOfInstallments").value)
      ).toLocaleString()
    );
    if (autoAmt) {
      setInstallmentAmt(
        parseInt(
          remainingAmt /
            Number(document.getElementById("noOfInstallments").value)
        )
      );
      document.getElementById("installmentAmount").value = parseInt(
        remainingAmt / Number(document.getElementById("noOfInstallments").value)
      ).toLocaleString();
    }
  }, [autoAmt, remainingAmt]);
  useEffect(() => {
    if (advanceAmount) {
      setRemainingAmt(totalAmount - advanceAmount);
    }
  }, [advanceAmount, totalAmount]);
  return (
    <>
      <div className="row ">
        <div className="col-md-12  text-left page_heading">
          <h4 className="m-0">Transfer Unit</h4>
          {resultallPopulateData?.loading ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-2 ">
          {" "}
          <span className="txt">Buyer Name</span>
        </div>

        <div style={{ display: "flex" }} className="col-4  text-left">
          {" "}
          <span style={{ width: "88%" }}>
            {" "}
            <input
              type="text"
              id="contractName"
              autoComplete="off"
              onChange={onTextChangeContractName}
              className="input_styling"
              style={{ width: "96%" }}
              list="contractnamelist"
            ></input>
            <datalist id="contractnamelist">
              {resultallPopulateData?.data?.Customers?.map((x, idx) => (
                <option index={idx} value={`${x.CustomerName} (${idx + 1})`}>
                  {x.CustomerCnic2}
                </option>
              ))}
            </datalist>
          </span>
          <span id="clientdemo"></span>
        </div>

        {/* <div className="col-3"></div> */}
        <div className="col-2">
          <span className="txt">{"Current Unit"}</span>
        </div>
        <div className="col-4  text-left">
          <span>
            {" "}
            <input
              type="text"
              id="currentunits"
              autoComplete="off"
              onChange={onTextChangeCurrentUnit}
              className="input_styling"
              list="currentunitslist"
            ></input>
            <datalist id="currentunitslist">
              {currentUnits?.map((x, idx) => (
                <option index={idx} value={x?.UnitName}>
                  {x?.UnitId}
                </option>
              ))}
            </datalist>
          </span>

          <span id="contractdetaildemo"></span>
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-2">
          <span className="txt">{"New Unit"}</span>
        </div>
        <div className="col-4  text-left">
          <span>
            {" "}
            <input
              type="text"
              id="newUnit"
              autoComplete="off"
              onChange={onTextChangeNewUnit}
              className="input_styling"
              list="newUnitlist"
            ></input>
            <datalist id="newUnitlist">
              {resultallPopulateData?.data?.NewUnits?.map((x, idx) => (
                <option index={idx} value={x?.UnitName}>
                  {x?.UnitId}
                </option>
              ))}
            </datalist>
          </span>

          <span id="contractdetaildemo"></span>
        </div>
        <div className="col-2  ">
          <span className="txt">Rate per Marla.</span>
        </div>
        <div className="col-4 text-left">
          <span>
            {" "}
            <input
              type="text"
              id="ratePerMarla"
              autoComplete="off"
              className="input_styling"
              onChange={(e) => {
                if (e.target.value.length > 0)
                  document.getElementById("ratePerMarla").value = parseInt(
                    e.target.value.replace(/,/g, "")
                  ).toLocaleString();
                setRatePerMarla(parseInt(e.target.value.replace(/,/g, "")));
              }}
            />
          </span>
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-2  ">
          <span className="txt">Total Amount</span>
        </div>
        <div className="col-4 text-left">
          <span>
            {" "}
            <input
              type="text"
              id="totalAmt"
              autoComplete="off"
              className="input_styling"
              disabled
              value={totalAmount?.toLocaleString()}
            />
          </span>
        </div>
        <div className="col-2">
          <span className="txt">Advance Amount</span>
        </div>
        <div className="col-4  text-left">
          <span>
            {" "}
            <input
              type="text"
              id="advanceAmount"
              autoComplete="off"
              disabled
              className="input_styling"
              value={advanceAmount?.toLocaleString()}
            ></input>
          </span>

          <span id="contractdetaildemo"></span>
        </div>
      </div>

      <div className="row pt-3">
        <div className="col-2  ">
          <span className="txt">Installment Amount</span>
        </div>
        <div className="col-4  text-left">
          <span>
            {" "}
            <input
              type="text"
              autoComplete="off"
              id="installmentAmount"
              onChange={(e) => {
                if (e.target.value.length > 0)
                  document.getElementById("installmentAmount").value = parseInt(
                    e.target.value.replace(/,/g, "")
                  ).toLocaleString();
                setInstallmentAmt(
                  Number(parseInt(e.target.value.replace(/,/g, "")))
                );
              }}
              className="input_styling"
            ></input>
          </span>
        </div>
        <div className="col-md-4  p-0 d-flex" style={{ gap: 2 }}>
          <input
            className="mb-3"
            type="checkbox"
            disabled={autoAmt}
            checked={autoInstallments}
            onChange={(e) => {
              setAutoInstallments(e.target.checked);
            }}
          />
          <p>Auto Installments</p>
        </div>
        <div className="col-2  "></div>

        <div className="col-2  ">
          <span className="txt">No. of Installments</span>
        </div>
        <div className="col-4 text-left">
          <span>
            {" "}
            <input
              type="number"
              id="noOfInstallments"
              className="input_styling"
            ></input>
          </span>
        </div>
        <div className="col-md-4  p-0 d-flex" style={{ gap: 2 }}>
          <input
            className="mb-3"
            type="checkbox"
            disabled={autoInstallments}
            checked={autoAmt}
            onChange={(e) => {
              setautoAmt(e.target.checked);
            }}
          />
          <p>Auto Installment Amount</p>
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-2  ">
          <span className="txt">1st Installment Due Date</span>
        </div>
        <div className="col-4  text-left">
          <span>
            {" "}
            <input
              type="date"
              id="installmentDueDate"
              className="input_styling"
              // defaultValue={date}
            ></input>
          </span>
        </div>
      </div>
      <div className="col-12  text-left">
        <br></br>
        <span className="ml-1 float-right">
          <button
            id="repursave"
            onClick={savecontract}
            className="save_btn  text-right  float-right mt-1"
          >
            Save
          </button>
        </span>
        <span className="ml-1 float-right">
          <button
            onClick={props.formCancel}
            className="cancel_btn  float-right mt-1 mr-2 text-right "
          >
            Cancel
          </button>
        </span>
        <span className=" ml-1 float-right">
          <div className="loaderclass ml-1">
            {" "}
            {loader2 && (
              <Loader type="ThreeDots" color="green" height={40} width={40} />
            )}
          </div>
        </span>
      </div>
    </>
  );
};
