import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { TiPrinter } from "react-icons/ti";
import { useReactToPrint } from "react-to-print";

import AdminSharePayableReportPrint from "../ReportPrints/AdminSharePayableReportPrint";
import { useDispatch, useSelector } from "react-redux";
import LandInstallTrackerPrint from "../ReportPrints/LandInstallTrackerPrint";
import { getCancelPlotPayment } from "../../../actions/AccountReports/cancelplotpayment";
import DetailModal from "./DetailModal";

const CancelPlotPayment = () => {
  const [filterdata, setfilterdata] = useState([]);

  const [csvData, setcsvData] = useState([]);
  const [ModalData, setModalData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const resultsgetcancelplotpayment = useSelector(
    (state) => state.GetCancelPlotPayment
  );
  // print function
  const AllcomponentRef = useRef();
  const AllprintRec = useReactToPrint({
    content: () => AllcomponentRef.current,
  });

  const IndvidualcomponentRef = useRef();

  const onTextChange = (e) => {
    if (
      document.getElementById("customerFilter").value === "" &&
      document.getElementById("unitFilter").value === ""
    ) {
      setfilterdata(resultsgetcancelplotpayment?.data);
    } else {
      let suggestions = [];
      {
        const regex = new RegExp(
          document.getElementById("customerFilter").value,
          "i"
        );
        const regex1 = new RegExp(
          document.getElementById("unitFilter").value,
          "i"
        );

        suggestions = resultsgetcancelplotpayment?.data
          ?.sort()
          ?.filter(
            (val) =>
              (document.getElementById("customerFilter").value === "" ||
                regex.test(val?.CustomerName)) &&
              (document.getElementById("unitFilter").value === "" ||
                regex1.test(val?.UnitName))
          );
      }
      setfilterdata(suggestions);
    }
  };
  const closemodal = () => {
    setShowModal(false);
    document.getElementById("myDetailmodal").style.display = "none";
  };
  const onRowClick = (data) => {
    setModalData(data);
    setShowModal(true);
    document.getElementById("myDetailmodal").style.display = "block";
  };
  useEffect(() => {
    if (resultsgetcancelplotpayment.data) {
      setfilterdata(resultsgetcancelplotpayment.data);
    }
  }, [resultsgetcancelplotpayment]);

  useEffect(() => {
    dispatch(getCancelPlotPayment());
  }, [dispatch]);
  console.log("filterdata", filterdata);
  return (
    <>
      <div hidden>
        <AdminSharePayableReportPrint
          ref={IndvidualcomponentRef}
          Data={ModalData}
        />
      </div>

      <div hidden>
        <LandInstallTrackerPrint ref={AllcomponentRef} Data={filterdata} />
      </div>

      <div className="row  app_font">
        <div className="dash_background col-12 pt-2">
          <div className="row mt-0">
            {/* <div
              style={{ backgroundColor: "#CAF5F5", height: "1px" }}
              className="col-12 m-2"
            ></div> */}
            <div className="col-12">
              <div className="row ">
                <div className="col-md-1 pr-0 pl-0">
                  <label className="input_label m-0">Search:</label>
                </div>

                <div className="col-md-3 pr-0 pl-0">
                  <input
                    type="text "
                    className="input_styling "
                    id="customerFilter"
                    onChange={onTextChange}
                    placeholder="Customer Name"
                  ></input>
                </div>

                <div className="col-md-3 pr-0 pl-0">
                  <input
                    type="text "
                    className="input_styling "
                    id="unitFilter"
                    onChange={onTextChange}
                    placeholder="Unit Name"
                  ></input>
                </div>
              </div>
            </div>
          </div>

          <div className=" col-12  table_height pr-0 pl-0">
            <div className="table-responsive mt-2">
              <table className="table table-borderless m-0">
                <thead>
                  <tr>
                    <th scope="col">Sr</th>
                    <th scope="col">Customer Name</th>
                    <th scope="col">Unit Name</th>
                    <th scope="col">Contract Payed Amount</th>
                    <th scope="col">Remaining Amount</th>
                    <th scope="col">Refund Amount</th>
                    <th scope="col">Narration</th>
                  </tr>
                </thead>
                <tbody>
                  {filterdata !== undefined
                    ? filterdata.map((x, id) => (
                        <>
                          <tr
                            key={id}
                            style={{ cursor: "pointer" }}
                            onClick={() => onRowClick(x?.Detail)}
                          >
                            <td>{id + 1}</td>
                            <td style={{ maxWidth: "350px" }}>
                              {x?.CustomerName}
                            </td>
                            <td>{x?.UnitName}</td>

                            <td>{x?.ContractPayedAmount?.toLocaleString()}</td>
                            <td>{x?.RemainingAmount?.toLocaleString()}</td>
                            <td>{x?.RefundAmount?.toLocaleString()}</td>
                            <td>{x?.Narration}</td>
                          </tr>
                        </>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
          <DetailModal closemodal={closemodal} TransactionData={ModalData} />

          {/* <div className="col-6  text-right pr-0 pl-0">
            <button
              disabled={filterdata?.length > 0 ? false : true}
              className="print_btn float-left"
              onClick={() => {
                AllprintRec();
              }}
            >
              <TiPrinter size="1.3em" />
              Print
            </button>
            {filterdata?.length > 0 && (
              <button
                style={{ color: "black !important" }}
                className="print_btn ml-2 float-left"
              >
                <CSVLink
                  style={{ color: "black" }}
                  filename="Land Installment Track"
                  data={csvData}
                >
                  Download CSV
                </CSVLink>
              </button>
            )}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default CancelPlotPayment;
