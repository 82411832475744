import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import {
  GetAffiliateData,
  getaffiliatepopulatetable,
  GetAffiliatesTable,
} from "../../../actions/inventoryactions/getaffiliates";
import AffiliatesPrint from "../DealsPrints/AffiliatesPrint";
import { CSVLink } from "react-csv";

export const AffiliateTable = (props) => {
  const [affiliatesdata, setcreditordata] = useState({});
  const resultallcreditors = useSelector((state) => state.getAffiliatesRed);
  const affiliatesdatastate = useSelector((state) => state.getAffliateEdit);
  const rolesdata = useSelector((state) => state.GetAllRole);

  let dispatch = useDispatch();
  // print function
  const componentRef = useRef();

  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  //Table Data fiter

  var suggestionlist = [];

  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      dispatch(getaffiliatepopulatetable());
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");

        suggestions = resultallcreditors.data
          .sort()
          .filter((val) => regex.test(val.AffiliateName));
      }
      suggestionlist = suggestions;
      dispatch(GetAffiliatesTable(suggestionlist));
    }
  };

  useEffect(() => {
    dispatch(getaffiliatepopulatetable());
  }, [dispatch]);
  function showmodal(data) {
    document.getElementById("mymodal").style.display = "block";
    setcreditordata(data);
  }
  function closemodal() {
    document.getElementById("mymodal").style.display = "none";
  }
  const [CsvData, setCsvData] = useState([]);
  useEffect(() => {
    let arr = [];
    resultallcreditors.data?.forEach((val, index) => {
      arr.push({
        Sr: index + 1,
        Name: val.AffiliateName,
        "CNIC/Reg": val.AffiliateCnic,
        "Contact No.": val.AffiliateContactNo1,
        City: val.AffiliateCity,
      });
    });
    setCsvData(arr);
  }, [resultallcreditors.data]);
  return (
    <>
      <div hidden>
        <AffiliatesPrint ref={componentRef} printData={resultallcreditors} />
      </div>
      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        <div className="col-md-3 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            onChange={onTextChange}
            placeholder="Affiliate Name "
          ></input>
        </div>

        <div className="col-md-1 pr-0 pl-0">
          {resultallcreditors.loading ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </div>
      </div>

      <div className="row ">
        <div className=" col-12  table_height pr-0 pl-0">
          <div className="table-responsive mt-4">
            <table className="table table-borderless table-hover m-0">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Name</th>

                  <th>CNIC/Reg</th>

                  <th>Contact No.</th>
                  <th>City</th>

                  <th className="text-center">
                    {rolesdata?.data?.Access?.includes("C") ? (
                      <button
                        className="save_btn"
                        onClick={() => {
                          props.showaffiliatestable();
                        }}
                      >
                        New+
                      </button>
                    ) : null}
                  </th>
                </tr>
              </thead>
              <tbody>
                {resultallcreditors.data.map((val, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      showmodal(val);
                    }}
                  >
                    <td>{index + 1}</td>
                    <td>{val.AffiliateName}</td>

                    <td>{val.AffiliateCnic}</td>

                    <td>{val.AffiliateContactNo1}</td>
                    <td>{val.AffiliateCity}</td>
                    <td className="text-center">
                      {rolesdata?.data?.Access?.includes("U") ? (
                        <FiEdit2 size="1em" />
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-6  text-right pr-0 pl-0">
          <button
            className="print_btn float-left"
            onClick={() => {
              printPaymentRec();
            }}
          >
            <TiPrinter size="1.3em" />
            Print
          </button>
          <button
            style={{ color: "black !important" }}
            className="print_btn ml-2 float-left"
          >
            <CSVLink
              style={{ color: "black" }}
              filename="Affiliate_Csv"
              data={CsvData}
            >
              Download CSV
            </CSVLink>
          </button>
        </div>
      </div>

      <div id="mymodal" class="modal">
        <div class="modal-content-cat">
          <div className="row">
            <div className="col-12  page_heading">
              <h4 className="  text-center pt-2">Affiliates Details</h4>
              <span class="close pr-2  " onClick={closemodal}>
                &times;
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-6 mt-2">
              <ul>
                <li>
                  <b>Name:</b> {affiliatesdata.AffiliateName}
                </li>
                <li>
                  <b>Email:</b> {affiliatesdata.AffiliateEmail}
                </li>
                <li>
                  <b>CNIC/Reg:</b> {affiliatesdata.AffiliateCnic}
                </li>

                <li>
                  <b>Contact No.1:</b> {affiliatesdata.AffiliateContactNo1}
                </li>
                <li>
                  <b>Contact No.2:</b> {affiliatesdata.AffiliateContactNo2}
                </li>
              </ul>
            </div>
            <div className="col-6 mt-2">
              <ul>
                <li>
                  <b>Country:</b> {affiliatesdata.AffiliateCountry}
                </li>
                <li>
                  <b>State:</b> {affiliatesdata.State}
                </li>
                <li>
                  <b>City:</b> {affiliatesdata.AffiliateCity}
                </li>
                <li>
                  <b>Phone No:</b> {affiliatesdata.AffiliatePhoneNumber}
                </li>
                <li>
                  <b>Affiliate Type:</b> {affiliatesdata.AffiliateType}
                </li>
                <li>
                  <b>Address:</b> {affiliatesdata.AffiliateAddress}
                </li>
              </ul>
            </div>
          </div>

          <div className="col-12 text-right">
            <button
              className=" save_btn   "
              onClick={() => {
                props.setedit("edit");
                dispatch(GetAffiliateData(affiliatesdata));
                closemodal();
                props.showaffiliatestable();
              }}
            >
              <FiEdit2 size="0.8em" /> Edit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
