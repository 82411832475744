const alltransferunitPopulate = { data: [], loading: true };
const transferunittable = { data: [], loading: true };
export const GetAllTransferUnitPopulateRed = (
  state = alltransferunitPopulate,
  action
) => {
  switch (action.type) {
    case "get_alltransferunitpopulate":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};
export const GetTransferUnitTable = (state = transferunittable, action) => {
  switch (action.type) {
    case "get_transferunittable":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};
