import React from "react";
import moment from "moment";

const DetailModal = ({ closemodal, TransactionData }) => {
  return (
    <>
      <div id="myDetailmodal" class="modal">
        <div class="modal-content-cat_voucher">
          <div className="row">
            <div className="col-12  page_heading">
              <h4 className="  text-center pt-2">Refund Detail</h4>
              <span class="close pr-2  " onClick={closemodal}>
                &times;
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-2">
              <div className="table-responsive_modal mt-4 table_height-voucher">
                <table className="table table-borderless m-0 ">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Entry Date</th>
                      <th>Narration</th>
                      <th>Payment Type</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {TransactionData !== undefined
                      ? TransactionData?.map((x, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                              {moment(x.AddedOnDate?.slice(0, 10)).format(
                                "DD-MMM-YYYY"
                              )}
                            </td>
                            <td>{x.Narration}</td>
                            <td>{x.PaymentType}</td>
                            <td>{x.Amount.toLocaleString()}</td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailModal;
