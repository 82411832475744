import axios from "axios";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { GrPrint } from "react-icons/gr";
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { DealBoughtPrint } from "../../accpayablepages/vouchersprints/paymentprint";
import DealPaymentTransactionDetailModal from "./DealPaymentTransactionDetailModal";
import { MdDeleteForever } from "react-icons/md";
import DealPaymentDeleteVoucherModal from "./DealPaymentDeleteVoucherModal";
// import getallRRpopulatetable from "../../../actions/"
import { CSVLink } from "react-csv";
import moment from "moment";

export const DealPaymentTable = (props) => {
  // const resultsrrtable = useSelector((state) => state.getAllRReceiptTable);
  const [printData, SetPrintData] = useState();
  const [loader, setloader] = useState(false);
  const [TransactionData, setTransactionData] = useState();
  const [printDataModal, SetPrintDataModal] = useState();
  const [statetoggle, settoggle] = useState(true);
  const [tablefilterstate, setTablefilter] = useState([]);

  const [DeleteVoucherData, setDeleteVoucherData] = useState({
    Email: "",
    Password: "",
    Reason: "",
  });
  const [showPassword, setshowPassword] = useState(false);
  const [SelecetdVoucher, setSelecetdVoucher] = useState(null);
  const [selectedVouchers, setSelectedVouchers] = useState([]);
  const [rendarComp, setrendarComp] = useState(false);
  const [loading, setloading] = useState(false);

  const rolesdata = useSelector((state) => state.GetAllRole);

  const [debitState, setdebitState] = useState(0);
  const [creditState, setcreditState] = useState(0);

  // print function

  const componentRef = useRef();

  const printRecoveryRec = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    if (printData !== undefined) {
      printRecoveryRec();
    }
  }, [printData, statetoggle]);
  //Table Data fiter
  // var suggestionlist = [];
  // const onTextChange = (e) => {
  //     const value = e.target.value;
  //     if (value === "") {

  //         setdata(tablefilterstate);
  //     } else {
  //         let suggestions = [];
  //         if (value.length > 0) {
  //             const regex = new RegExp(`${value}`, "i");

  //             suggestions = tablefilterstate
  //                 .sort()
  //                 .filter((val) =>
  //                     (regex.test(val.DealBoughtPaymentVoucherId)) || regex.test(val.Seller !== undefined && val.Seller !== null ? val.Seller.SellerName : null));

  //         }
  //         suggestionlist = suggestions;
  //         setdata(suggestionlist);
  //     }
  // };
  const onTextChange = (e) => {
    if (
      document.getElementById("customerfilter").value === "" &&
      document.getElementById("datefilter").value === "" &&
      document.getElementById("voucherfilter").value === "" &&
      document.getElementById("dealfilter").value === ""
    ) {
      setdata(datastate);
    } else {
      let suggestions = [];
      {
        const regex = new RegExp(
          document.getElementById("customerfilter").value,
          "i"
        );
        const regex1 = new RegExp(
          document.getElementById("voucherfilter").value,
          "i"
        );
        const regex2 = new RegExp(
          document.getElementById("datefilter").value,
          "i"
        );
        const regex3 = new RegExp(
          document.getElementById("dealfilter").value,
          "i"
        );

        suggestions = tablefilterstate
          .sort()
          .filter(
            (val) =>
              (document.getElementById("customerfilter").value === "" ||
                regex.test(val.Seller.SellerName)) &&
              (document.getElementById("voucherfilter").value === "" ||
                regex1.test(val.DealBoughtPaymentVoucherId)) &&
              (document.getElementById("datefilter").value === "" ||
                regex2.test(val.EntryDate)) &&
              (document.getElementById("dealfilter").value === "" ||
                regex3.test(val.DealName))
          );
      }
      setdata(suggestions);
    }
  };

  // useEffect(() => {

  //     dispatch(getallRRpopulatetable());
  // }, [dispatch]);
  const [datastate, setdata] = useState([]);
  const savedata = () => {
    // document.getElementById("dealapicall").disabled = true;
    setloader(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}Vouchers/Deal/Payment/Get`,
      data: JSON.stringify({
        ProjectId: parseInt(`${localStorage.getItem("ProjectId")}`),
        choice: "Bought",
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        // document.getElementById("dealapicall").disabled = false;
        if (result.data.IsSuccess === true) {
          setdata(result.data.DealBought);
          setTablefilter(result.data.DealBought);
          // document.getElementById("dealtype").selectedIndex = 0;

          setloader(false);

          // toast.info(result.data.Message);
        } else {
          toast.info(result.data.Message);
          setloader(false);
        }
      },
      (error) => {
        toast.info("Someting went wrong");
        // document.getElementById("dealapicall").disabled = false;
        setloader(false);
      }
    );
    // }
  };
  useEffect(() => {
    savedata();
  }, []);
  function showmodal(data) {
    document.getElementById("mymodal").style.display = "block";
    setTransactionData(data);
    var credit = 0;
    var debit = 0;
    data?.map((val) =>
      val.TransactionType === "CREDIT"
        ? (credit += parseFloat(val.Amount))
        : val.TransactionType === "DEBIT"
        ? (debit += parseFloat(val.Amount))
        : null
    );
    setdebitState(debit);
    setcreditState(credit);
  }
  function closemodal() {
    document.getElementById("mymodal").style.display = "none";
  }

  const showmodal2 = (data) => {
    setSelecetdVoucher(data);
    document.getElementById("DeleteVoucherModal").style.display = "block";
  };

  const closeDeleteVoucherModal = () => {
    document.getElementById("DeleteVoucherModal").style.display = "none";
    setSelecetdVoucher(null);
    setDeleteVoucherData({
      Email: "",
      Password: "",
      Reason: "",
    });
  };

  function handleCheckboxChange(i) {
    let arr = [];

    datastate?.forEach((itm) => {
      if (itm?.checked) {
        arr.push(itm?.DealBoughtPaymentVoucherId);
      }
    });
    setSelectedVouchers(arr);
  }

  const handelDeleteVoucherdataChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setDeleteVoucherData({ ...DeleteVoucherData, [name]: value });
  };

  function deleteVoucher() {
    if (DeleteVoucherData?.Email === "") {
      return toast.info("Email is Required");
    } else if (DeleteVoucherData?.Password === "") {
      return toast.info("Password is Required");
    } else if (DeleteVoucherData?.Reason === "") {
      return toast.info("Reason is Required");
    }
    setloading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}Scripts/DeleteAllUnsubmittedVouchers`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
      data: {
        Email: DeleteVoucherData?.Email,
        Password: DeleteVoucherData?.Password,
        Reason: DeleteVoucherData?.Reason,
        voucherId: selectedVouchers,
      },
    }).then(
      (result) => {
        console.log(result);
        setloading(false);
        if (result.data.IsSuccess === true) {
          setDeleteVoucherData({
            Email: "",
            Password: "",
            Reason: "",
          });
          toast.info(result.data.Message);
          setSelectedVouchers([]);
          savedata();
          closeDeleteVoucherModal();
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Someting went wrong");
      }
    );
  }

  const [CsvData, setCsvData] = useState([]);
  useEffect(() => {
    let arr = [];
    var i = 1;

    datastate?.forEach((val) => {
      let year = val.EntryDate.slice(0, 4);
      let day = val.EntryDate.slice(8, 10);
      let month = val.EntryDate.slice(5, 7);
      arr.push({
        Sr: i++,
        Date: day + "-" + month + "-" + year,
        "Voucher No": val.DealBoughtPaymentVoucherId,
        Seller:
          val.Seller !== undefined && val.Seller !== null
            ? val.Seller.SellerName
            : "",
        Deal: val.DealName,
        Amount: parseFloat(val.Amount).toLocaleString(),
        Narration: val.Narration,
      });
    });
    setCsvData(arr);
  }, [datastate]);

  return (
    <>
      <div hidden>
        <DealBoughtPrint ref={componentRef} printData={printData} />
      </div>

      {/* <div className="row pt-3 ">
                <div className="col-md-1 pr-0 pl-0">


                    <label className="input_label m-0">Select:</label>
                </div>
                <div className="col-md-3 pr-0 pl-0">

                    <select className="input_styling " id="dealtype">
                        <option>Select</option>

                        <option>Bought</option>
                        <option>Sold</option>
                    </select>
                </div>
                <div className="col-md-1 pr-0 pl-0">
                    <button className="btn_Go" onClick={savedata} id="dealapicall">Go</button>
                </div>

            </div> */}
      <div className="row pt-3">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            type="text"
            className="input_styling "
            id="datefilter"
            onChange={onTextChange}
            placeholder="Date"
            autoComplete="off"
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
          />
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            type="text"
            className="input_styling "
            id="voucherfilter"
            onChange={onTextChange}
            placeholder="Voucher"
            autoComplete="off"
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
          />
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            type="text"
            className="input_styling "
            id="customerfilter"
            onChange={onTextChange}
            placeholder="Seller"
            autoComplete="off"
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
          />
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            type="text"
            className="input_styling "
            id="dealfilter"
            onChange={onTextChange}
            placeholder="Deal Name"
            autoComplete="off"
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
          />
        </div>
        <div className="col-md-1 pr-0 pl-0">
          {loader ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
          {/* {resultsrrtable.loading ? <Loader type="ThreeDots"
                        color="green"
                        height={40}
                        width={40} /> : null} */}
        </div>
      </div>

      <div className="row pt-3 ">
        <div className="col-md-12 pr-0 pl-0">
          {selectedVouchers.length > 0 && (
            <div className="col-md-12 mt-2">
              <div className="float-right">
                {loading && (
                  <Loader
                    type="ThreeDots"
                    color="green"
                    height={40}
                    width={40}
                  />
                )}
                <span>
                  <button
                    onClick={() => {
                      showmodal2(selectedVouchers);
                    }}
                    className="cancel_btn ml-2"
                  >
                    <MdDeleteForever />
                    Delete All
                  </button>
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="row ">
        {/* <div className="col-md-1 pr-0 pl-0">


                    <label className="input_label m-0">Search:</label>
                </div>
                <div className="col-md-3 pr-0 pl-0">


                    <input type="text " className="input_styling " placeholder=" Voucher/Seller" onChange={onTextChange}></input>
                </div> */}
        {/* <div className="col-md-2 text-center  pr-0 pl-0">


                    <label className="input_label m-0">Deal Type:</label>
                </div>
                <div className="col-md-3 pr-0 pl-0">

                    <select className="input_styling " id="dealtype">
                        <option>Select</option>

                        <option>Bought</option>
                      <option>Sold</option> 
                    </select>
        </div> * /}
        < div className = "col-md-1 pr-0 pl-0" >
            <button className="btn_Go" onClick={savedata} id="dealapicall">Go</button>
                </div >
{/*
                <div className="col-md-3 text-center  pr-0 pl-0">
                    <label className="input_label m-0">From:</label>
                    <input type="date" className="input_date ml-3" id="from date"></input>
                </div>
                <div className="col-md-3 text-center pr-0 pl-0">
                    <label className="input_label m-0">To:</label>
                    <input type="date" className="input_date ml-3" id="todate"></input>
                </div>
                <div className="col-md-1 pr-0 pl-0">
                    <button className="btn_Go">Go</button>
                </div> */}
        <div className="col-md-1 pr-0 pl-0">
          {/* {resultsrrtable.loading ? <Loader type="ThreeDots"
                        color="green"
                        height={40}
                        width={40} /> : null} */}
          {/* {loader ?
                        <Loader
                            type="ThreeDots"
                            color="green"
                            height={40}
                            width={40}
                        /> : null} */}
        </div>
      </div>

      <div className="table-responsive vouchers_table_height mt-2">
        <table className="table table-borderless m-0">
          <thead>
            <tr>
              <th>Sr</th>
              <th>{""}</th>
              <th>Date</th>
              <th>Added On</th>
              <th>Voucher</th>

              <th>Seller</th>
              <th>Deal</th>

              <th>Amount</th>
              <th>Narration</th>

              <th className="text-center">
                {rolesdata?.data?.Access?.includes("C") ? (
                  <button
                    className="save_btn"
                    onClick={() => {
                      props.showdealtable();
                    }}
                  >
                    New+
                  </button>
                ) : null}
              </th>
            </tr>
          </thead>
          <tbody>
            {datastate.map((val, index) => {
              let year = val.EntryDate.slice(0, 4);
              let day = val.EntryDate.slice(8, 10);
              let month = val.EntryDate.slice(5, 7);
              switch (month) {
                case "01":
                  month = "Jan";

                  break;
                case "02":
                  month = "Feb";

                  break;
                case "03":
                  month = "Mar";

                  break;
                case "04":
                  month = "Apr";

                  break;
                case "05":
                  month = "May";

                  break;
                case "06":
                  month = "Jun";

                  break;
                case "07":
                  month = "Jul";

                  break;
                case "08":
                  month = "Aug";

                  break;
                case "09":
                  month = "Sep";

                  break;
                case "10":
                  month = "Oct";
                  break;
                case "11":
                  month = "Nov";
                  break;
                case "12":
                  month = "Dec";
                  break;
                default:
                  break;
              }
              return (
                <tr key={index}>
                  <td
                    className="table_data"
                    onClick={() => {
                      showmodal(val.Transaction);
                      SetPrintDataModal(val.Transaction);
                    }}
                  >
                    {index + 1}
                  </td>
                  <td>
                    {localStorage.getItem("Designation") === "Admin" ? (
                      <input
                        checked={val.checked ? val.checked : false}
                        key={index}
                        onChange={(e) => {
                          datastate[index].checked = e.target.checked;
                          setrendarComp(!rendarComp);
                          handleCheckboxChange(index);
                        }}
                        type="checkbox"
                        id={`checkedVoucher${index}`}
                      />
                    ) : null}
                  </td>
                  <td
                    className="table_data"
                    onClick={() => {
                      showmodal(val.Transaction);
                      SetPrintDataModal(val.Transaction);
                    }}
                  >
                    {day + "-" + month + "-" + year}
                  </td>
                  <td
                    className="table_data"
                    onClick={() => {
                      showmodal(val.Transaction);
                      SetPrintDataModal(val.Transaction);
                    }}
                  >
                    {moment(
                      val.Transaction[0]?.AddedOn?.slice(0, 10),
                      "YYYY:MM-DD"
                    )?.format("DD-MMM-YYYY")}
                  </td>
                  <td
                    className="table_data"
                    onClick={() => {
                      showmodal(val.Transaction);
                      SetPrintDataModal(val.Transaction);
                    }}
                  >
                    {val.DealBoughtPaymentVoucherId}
                  </td>
                  {val.Seller !== undefined && val.Seller !== null ? (
                    <td
                      className="table_data"
                      onClick={() => {
                        showmodal(val.Transaction);
                        SetPrintDataModal(val.Transaction);
                      }}
                    >
                      {val.Seller.SellerName}
                    </td>
                  ) : (
                    <td></td>
                  )}
                  <td
                    className="table_data"
                    onClick={() => {
                      showmodal(val.Transaction);
                      SetPrintDataModal(val.Transaction);
                    }}
                  >
                    {val.DealName}
                  </td>

                  <td
                    className="table_data"
                    onClick={() => {
                      showmodal(val.Transaction);
                      SetPrintDataModal(val.Transaction);
                    }}
                  >
                    {parseFloat(val.Amount).toLocaleString()}
                  </td>
                  <td
                    className="table_data"
                    onClick={() => {
                      showmodal(val.Transaction);
                      SetPrintDataModal(val.Transaction);
                    }}
                  >
                    {val.Narration}
                  </td>

                  <td
                    className="text-center cur_sor"
                    onClick={() => {
                      SetPrintData(val);
                      settoggle(!statetoggle);
                    }}
                  >
                    <GrPrint size="1.3em" />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <button
        style={{ color: "black !important" }}
        className="print_btn ml-2 float-left"
      >
        <CSVLink
          style={{ color: "black" }}
          filename="Deal_Payment_Csv"
          data={CsvData}
        >
          Download CSV
        </CSVLink>
      </button>

      <DealPaymentTransactionDetailModal
        debitState={debitState}
        creditState={creditState}
        printDataModal={printDataModal}
        closemodal={closemodal}
        TransactionData={TransactionData}
      />

      <DealPaymentDeleteVoucherModal
        setSelecetdVoucher={setSelecetdVoucher}
        DeleteVoucherData={DeleteVoucherData}
        handelDeleteVoucherdataChange={handelDeleteVoucherdataChange}
        closeDeleteVoucherModal={closeDeleteVoucherModal}
        showPassword={showPassword}
        setshowPassword={setshowPassword}
        loading={loading}
        deleteVoucher={deleteVoucher}
      />
    </>
  );
};
