import moment from "moment";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { GrPrint } from "react-icons/gr";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getallrepurchase } from "../../../actions/inventoryactions/repurchaseunit";
import { AllNewPurchasePrint } from "../ProjectsPrint/AllNewPurchasePrint";
import { NewPurchasePrint } from "../ProjectsPrint/NewPurchasePrint";
import { MdDeleteForever } from "react-icons/md";
import { toast } from "react-toastify";
import axios from "axios";
import { DetailPrint } from "../ProjectsPrint/DetailPrint";
import RePurchaseDeleteVoucherModal from "./RePurchaseDeleteVoucherModal";
import { CSVLink } from "react-csv";

export const RePurchaseTable = (props) => {
  const rolesdata = useSelector((state) => state.GetAllRole);

  const resultsallunits = useSelector((state) => state.getRePurchaseTable);
  const [filerunits, setfilterunits] = useState();
  const dispatch = useDispatch();
  const [creditordata, setcreditordata] = useState({});
  const [showAll, setShowAll] = useState(true);
  const [showPersonal, setShowPersonal] = useState(false);
  const [loading2, setloading2] = useState(false);
  const [repurchaseRow, setrepurchaseRow] = useState(null);
  const [select, setSelect] = useState("");
  const [rendarComp, setrendarComp] = useState(false);
  const [selectedVouchers, setSelectedVouchers] = useState([]);
  const [apiLoading, setapiLoading] = useState(false);
  const [loading, setloading] = useState(false);
  const [DeleteVoucherData, setDeleteVoucherData] = useState({
    Email: "",
    Password: "",
    Reason: "",
  });

  const [printData, SetPrintData] = useState();
  const [printDetails, SetPrintDetials] = useState();

  useEffect(() => {
    if (printDetails !== undefined) {
      Details();
    }
  }, [printDetails]);

  // print function
  const componentRef = useRef();
  const componentDetails = useRef();
  // console.log(resultsallunits);
  // const handleSelectChange = (e) => {
  //   const value = e.target.value;
  //   setShowAll(value === "all");
  //   setShowPersonal(value === "personal");
  //   setShowOther(value === "other");
  // };

  const printRecoveryBooking = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    if (printData !== undefined) {
      printRecoveryBooking();
    }
  }, [printData]);
  // all projects print

  const AllcomponentRef = useRef();

  const AllprintPaymentRec = useReactToPrint({
    content: () => AllcomponentRef.current,
  });

  const Details = useReactToPrint({
    content: () => componentDetails.current,
  });

  useEffect(() => {
    if (printDetails !== undefined) {
      Details();
    }
  }, [printDetails]);

  //Table Data fiter
  // const onTextChange = (e) => {
  //   if (
  //     document.getElementById("projectfilter").value === "" &&
  //     document.getElementById("unitfilter").value === "" &&
  //     document.getElementById("sectionfilter").value === "" &&
  //     document.getElementById("areafilter").value === "" &&
  //     document.getElementById("sellerfilter").value === ""
  //   ) {
  //     setfilterunits(resultsallunits.data);
  //   } else {
  //     let suggestions = [];
  //     {
  //       const regex = new RegExp(
  //         document.getElementById("projectfilter").value,
  //         "i"
  //       );
  //       const regex1 = new RegExp(
  //         document.getElementById("sectionfilter").value,
  //         "i"
  //       );
  //       const regex2 = new RegExp(
  //         document.getElementById("unitfilter").value,
  //         "i"
  //       );
  //       const regex3 = new RegExp(
  //         document.getElementById("areafilter").value,
  //         "i"
  //       );
  //       const regex4 = new RegExp(
  //         document.getElementById("sellerfilter").value,
  //         "i"
  //       );
  //       suggestions = resultsallunits.data
  //         .sort()
  //         .filter(
  //           (val) =>
  //             (document.getElementById("projectfilter").value === "" ||
  //               regex.test(val.Project)) &&
  //             (document.getElementById("sectionfilter").value === "" ||
  //               regex1.test(val.Unit.Section)) &&
  //             (document.getElementById("unitfilter").value === "" ||
  //               regex2.test(val.Unit.UnitName)) &&
  //             (document.getElementById("areafilter").value === "" ||
  //               regex3.test(val.Unit.Marla + "M" + val.Unit.Sarsai + "S")) &&
  //             (document.getElementById("sellerfilter").value === "" ||
  //             regex4.test(val.Seller))

  //         );
  //     }
  //     setfilterunits(suggestions);
  //   }
  // };

  // const onTextChange = (e) => {
  //   if (
  //     document.getElementById("projectfilter").value === "" &&
  //     document.getElementById("unitfilter").value === "" &&
  //     document.getElementById("sectionfilter").value === "" &&
  //     document.getElementById("areafilter").value === "" &&
  //     document.getElementById("sellerfilter").value === ""
  //   ) {
  //     setfilterunits(resultsallunits.data);
  //   } else {
  //     let suggestions = [];
  //     {
  //       const regex = new RegExp(
  //         document.getElementById("projectfilter").value,
  //         "i"
  //       );
  //       const regex1 = new RegExp(
  //         document.getElementById("sectionfilter").value,
  //         "i"
  //       );
  //       const regex2 = new RegExp(
  //         document.getElementById("unitfilter").value,
  //         "i"
  //       );
  //       const regex3 = new RegExp(
  //         document.getElementById("areafilter").value,
  //         "i"
  //       );
  //       const regex4 = new RegExp(
  //         document.getElementById("sellerfilter").value,
  //         "i"
  //       );
  //       suggestions = resultsallunits.data
  //         .sort()
  //         .filter(
  //           (val) =>
  //             (document.getElementById("projectfilter").value === "" ||
  //               regex.test(val.Project)) &&
  //             (document.getElementById("sectionfilter").value === "" ||
  //               regex1.test(val.Unit.Section)) &&
  //             (document.getElementById("unitfilter").value === "" ||
  //               regex2.test(val.Unit.UnitName)) &&
  //             (document.getElementById("areafilter").value === "" ||
  //               regex3.test(val.Unit.Marla + "M" + val.Unit.Sarsai + "S")) &&
  //             (document.getElementById("sellerfilter").value === "" ||
  //               regex4.test(val.Seller))
  //         );
  //     }
  //   }
  // };

  const onTextChange = (e) => {
    if (
      document.getElementById("projectfilter").value === "" &&
      document.getElementById("unitfilter").value === "" &&
      document.getElementById("sectionfilter").value === "" &&
      document.getElementById("areafilter").value === "" &&
      document.getElementById("sellerfilter").value === ""
    ) {
      setfilterunits(resultsallunits.data);
    } else {
      const regex = new RegExp(
        document.getElementById("projectfilter").value,
        "i"
      );
      const regex1 = new RegExp(
        document.getElementById("sectionfilter").value,
        "i"
      );
      const regex2 = new RegExp(
        document.getElementById("unitfilter").value,
        "i"
      );
      const regex3 = new RegExp(
        document.getElementById("areafilter").value,
        "i"
      );
      const regex4 = new RegExp(
        document.getElementById("sellerfilter").value,
        "i"
      );
      const suggestions = resultsallunits.data
        .sort()
        .filter(
          (val) =>
            (document.getElementById("projectfilter").value === "" ||
              regex.test(val.Project)) &&
            (document.getElementById("sectionfilter").value === "" ||
              regex1.test(val.Unit.Section)) &&
            (document.getElementById("unitfilter").value === "" ||
              regex2.test(val.Unit.UnitName)) &&
            (document.getElementById("areafilter").value === "" ||
              regex3.test(val.Unit.Marla + "M" + val.Unit.Sarsai + "S")) &&
            (document.getElementById("sellerfilter").value === "" ||
              regex4.test(val.Seller))
        );
      setfilterunits(suggestions);
    }
  };
  // console.log(filerunits);
  // var suggestionlist = [];

  // const onTextChange = (e) => {
  //     const value = e.target.value;
  //     if (value === "") {

  //         dispatch(getallunits());
  //     } else {
  //         let suggestions = [];
  //         if (value.length > 0) {
  //             const regex = new RegExp(`${value}`, "i");

  //             suggestions = resultsallunits.data
  //                 .sort()
  //                 .filter((val) =>
  //                     (regex.test(val.UnitName)));

  //         }
  //         suggestionlist = suggestions;
  //         dispatch(GetAllUnits(suggestionlist));
  //     }
  // };
  useEffect(() => {
    dispatch(getallrepurchase());
  }, [dispatch]);
  useEffect(() => {
    setfilterunits(resultsallunits.data);
  }, [resultsallunits]);
  function showmodal(data) {
    document.getElementById("RepurchaseDetailsMod").style.display = "block";
    setcreditordata(data);
  }
  function closemodal() {
    document.getElementById("RepurchaseDetailsMod").style.display = "none";
  }
  function showDeleteRepurchasemodal(data) {
    setrepurchaseRow(data);
    document.getElementById("deleteRepurchasemodal").style.display = "block";
  }
  function closeDeleteRepurchasemodal() {
    document.getElementById("deleteRepurchasemodal").style.display = "none";
    document.getElementById("deleteRepurchaseEmail").value = "";
    document.getElementById("deleteRepurchasePassword").value = "";
    document.getElementById("deleteRepurchaseReason").value = "";
  }
  function deleteRepurchase() {
    if (document.getElementById("deleteRepurchaseEmail").value === "") {
      return toast.info("Email is Required");
    } else if (
      document.getElementById("deleteRepurchasePassword").value === ""
    ) {
      return toast.info("Password is Required");
    } else if (document.getElementById("deleteRepurchaseReason").value === "") {
      return toast.info("Reason is Required");
    }
    setloading2(true);
    const Email = document.getElementById("deleteRepurchaseEmail").value;
    const Password = document.getElementById("deleteRepurchasePassword").value;
    const Reason = document.getElementById("deleteRepurchaseReason").value;
    // const data={
    //     Email:Email,
    //   Password:Password,

    // }
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}Scripts/DeleteRepurchaseUnit?Email=${Email}&Password=${Password}&Reason=${Reason}&unitId=${repurchaseRow?.Unit?.UnitId}&ProjectId=${repurchaseRow?.ProjectId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        // document.getElementById("headingsave").disabled = false;
        if (result.data.IsSuccess === true) {
          dispatch(getallrepurchase());
          document.getElementById("deleteRepurchaseEmail").value = "";
          document.getElementById("deleteRepurchasePassword").value = "";
          document.getElementById("deleteRepurchaseReason").value = "";
          // dispatch(getAllContractTable(data));
          toast.info(result.data.Message);
          // setdisableBtn(true);
          setloading2(false);
          setrepurchaseRow(null);
          closeDeleteRepurchasemodal();
        } else {
          toast.info(result.data.Message);
          setloading2(false);
        }
      },
      (error) => {
        setloading2(false);
        toast.info("Someting went wrong");
        // document.getElementById("headingsave").disabled = false;
      }
    );
  }

  const handelDeleteVoucherdataChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setDeleteVoucherData({ ...DeleteVoucherData, [name]: value });
  };

  function deleteVoucher() {
    if (DeleteVoucherData?.Email === "") {
      return toast.info("Email is Required");
    } else if (DeleteVoucherData?.Password === "") {
      return toast.info("Password is Required");
    } else if (DeleteVoucherData?.Reason === "") {
      return toast.info("Reason is Required");
    }
    setloading(true);

    const voucherIds1 = [];
    selectedVouchers.forEach((vocuher) => {
      voucherIds1.push(vocuher.id);
    });

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}Scripts/DeleteAllUnsubmittedVouchers`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
      data: {
        Email: DeleteVoucherData?.Email,
        Password: DeleteVoucherData?.Password,
        Reason: DeleteVoucherData?.Reason,
        voucherId: selectedVouchers,
      },
    }).then(
      (result) => {
        console.log(result);
        // document.getElementById("headingsave").disabled = false;
        if (result.data.IsSuccess === true) {
          setDeleteVoucherData({
            Email: "",
            Password: "",
            Reason: "",
          });
          setapiLoading(true);
          toast.info(result.data.Message);
          setloading(false);
          setSelectedVouchers([]);
          closemodal2();
        } else {
          toast.info(result.data.Message);
          setDeleteVoucherData({
            Email: "",
            Password: "",
            Reason: "",
          });
          setloading(false);
          // setvoucherRowdata(null)
        }
      },
      (error) => {
        toast.info("Someting went wrong");
        // document.getElementById("headingsave").disabled = false;
      }
    );
    setapiLoading(false);
  }

  function showmodal2() {
    document.getElementById("mymodal2").style.display = "block";
  }

  function closemodal2() {
    document.getElementById("mymodal2").style.display = "none";
    setDeleteVoucherData({
      Email: "",
      Password: "",
      Reason: "",
    });
  }

  function handleCheckboxChange() {
    let arr = filerunits
      ?.filter((itm) => itm?.checked)
      .map((itm) => itm?.UnitRepurchaseId);
    setSelectedVouchers(arr);
  }

  const [CsvData, setCsvData] = useState([]);
  useEffect(() => {
    let arr = [];
    filerunits?.forEach((val, index) => {
      arr.push({
        Sr: index + 1,
        Date: moment(val.Date.split("T")[0]).format("DD-MMM-YYYY"),
        Project:
          val.Project !== null && val.Project !== undefined ? val.Project : "",
        Section:
          val.Unit !== null && val.Unit !== undefined ? val.Unit.Section : "",
        Seller: val?.Seller ? val?.Seller : val?.Customer,
        Unit:
          val.Unit !== null && val.Unit !== undefined
            ? val.Unit.UnitName
            : null,
        Area:
          val.Unit !== null && val.Unit !== undefined
            ? val.Unit.Marla + "M" + val.Unit.Sarsai + "S"
            : null,
        Dimension:
          val.Unit !== null && val.Unit !== undefined
            ? val.Unit.DimensionLength + "x" + val.Unit.DimensionWidth
            : null,
        "Purchase Rate":
          val.Unit !== null && val.Unit !== undefined
            ? parseFloat(val.Unit.CostPerMarla).toLocaleString()
            : null,
        "Total Cost":
          val.Unit !== null && val.Unit !== undefined
            ? parseFloat(val.Unit.TotalCost).toLocaleString()
            : null,
      });
    });
    setCsvData(arr);
  }, [filerunits]);

  console.log("filerunits", filerunits);
  return (
    <>
      <div hidden>
        <NewPurchasePrint ref={componentRef} printData={printData} />
      </div>
      <div hidden>
        <AllNewPurchasePrint
          ref={AllcomponentRef}
          printData={filerunits}
          select={select}
        />
      </div>

      <div hidden>
        <DetailPrint creditorData={creditordata} ref={componentDetails} />
      </div>

      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        <div className="col-md-2 pr-0 pl-0" style={{ marginLeft: "-10px" }}>
          <input
            type="text "
            className="input_styling "
            id="projectfilter"
            onChange={onTextChange}
            placeholder="Project Name"
            autoComplete="off"
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
          ></input>
        </div>
        <div className="col-md-2 pr-0 pl-0" style={{ marginLeft: "-10px" }}>
          <input
            type="text "
            className="input_styling "
            id="sectionfilter"
            onChange={onTextChange}
            placeholder="Section"
            autoComplete="off"
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
          ></input>
        </div>
        <div className="col-md-2 pr-0 pl-0" style={{ marginLeft: "-10px" }}>
          <input
            type="text "
            className="input_styling "
            id="sellerfilter"
            onChange={onTextChange}
            placeholder="Seller"
            autoComplete="off"
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
          ></input>
        </div>
        <div className="col-md-2 pr-0 pl-0" style={{ marginLeft: "-10px" }}>
          <input
            type="text "
            className="input_styling "
            id="unitfilter"
            onChange={onTextChange}
            placeholder="Unit "
            autoComplete="off"
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
          ></input>
        </div>
        <div className="col-md-1 pr-0 pl-0" style={{ marginLeft: "-10px" }}>
          <input
            type="text "
            className="input_styling "
            id="areafilter"
            onChange={onTextChange}
            placeholder="Area "
            style={{ width: "130px" }}
            autoComplete="off"
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
          ></input>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <select
            className="input_styling "
            value={select}
            onChange={(e) => setSelect(e.target.value)}
            style={{ marginLeft: "65px" }}
          >
            <option value="" disabled>
              Project Owner...
            </option>
            <option value="all">ALL</option>
            <option value="personal">PERSONAL</option>
            <option value="other">OTHER</option>
          </select>
        </div>

        <div className="col-md-1 pr-0 pl-0">
          {resultsallunits.loading ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </div>
      </div>

      <div className="row ">
        <div className="col-12 pr-0 pl-0 mt-4">
          {selectedVouchers.length > 0 && (
            <div className="col-md-12">
              <div className="float-right d-flex align-items-center">
                {apiLoading && (
                  <Loader
                    type="ThreeDots"
                    color="green"
                    height={40}
                    width={40}
                  />
                )}
                <span>
                  <button
                    onClick={() => {
                      showmodal2(selectedVouchers);
                    }}
                    className="cancel_btn ml-2"
                  >
                    <MdDeleteForever />
                    Delete All
                  </button>
                </span>
              </div>
            </div>
          )}
        </div>
        <div className=" col-12  table_height pr-0 pl-0">
          <div className="table-responsive mt-4">
            <table className="table table-borderless m-0">
              <thead>
                <tr>
                  <th>Sr</th>
                  {/* <th>{""}</th> */}
                  <th>Date</th>
                  <th>Project</th>
                  <th>Section </th>
                  <th>Seller</th>
                  <th>Unit</th>
                  <th>Area</th>
                  <th>Dimension</th>
                  <th>Purchase Rate</th>
                  <th>Total Cost</th>

                  {select !== "personal" && <th>Purchase Cost</th>}

                  <th className="text-center">
                    {rolesdata?.data?.Access?.includes("C") ? (
                      <button
                        className="save_btn"
                        onClick={() => {
                          props.showprojecttable();
                        }}
                      >
                        New+
                      </button>
                    ) : null}
                  </th>
                </tr>
              </thead>
              <tbody>
                {filerunits !== undefined
                  ? filerunits.map(
                      (val, index) =>
                        select === "all" || select === "" ? (
                          <tr key={index}>
                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {index + 1}
                            </td>
                            {/* <td>
                              {localStorage.getItem("Designation") ===
                              "Admin" ? (
                                <input
                                  checked={val.checked ? val.checked : false}
                                  key={index}
                                  onChange={(e) => {
                                    filerunits[index].checked =
                                      e.target.checked;
                                    setrendarComp(!rendarComp);
                                    handleCheckboxChange(index);
                                  }}
                                  type="checkbox"
                                  id={`checkedVoucher${index}`}
                                />
                              ) : null}
                            </td> */}
                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {moment(val.Date.split("T")[0]).format(
                                "DD-MMM-YYYY"
                              )}
                            </td>

                            {val.Project !== null &&
                            val.Project !== undefined ? (
                              <td
                                className=" cur_sor "
                                onClick={() => {
                                  showmodal(val);
                                }}
                              >
                                {val.Project}
                              </td>
                            ) : (
                              <td></td>
                            )}
                            {val.Unit !== null && val.Unit !== undefined ? (
                              <td
                                className=" cur_sor "
                                onClick={() => {
                                  showmodal(val);
                                }}
                              >
                                {val.Unit.Section}
                              </td>
                            ) : (
                              <td></td>
                            )}
                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val?.Seller ? val?.Seller : val?.Customer}
                            </td>

                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val.Unit !== null && val.Unit !== undefined
                                ? val.Unit.UnitName
                                : null}
                            </td>

                            {/* <td>{val.NetMarla}</td> */}
                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val.Unit !== null && val.Unit !== undefined
                                ? val.Unit.Marla + "M" + val.Unit.Sarsai + "S"
                                : null}
                            </td>
                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val.Unit !== null && val.Unit !== undefined
                                ? val.Unit.DimensionLength +
                                  "x" +
                                  val.Unit.DimensionWidth
                                : null}
                            </td>
                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val.Unit !== null && val.Unit !== undefined
                                ? parseFloat(
                                    val.Unit.CostPerMarla
                                  ).toLocaleString()
                                : null}
                            </td>

                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val.Unit !== null && val.Unit !== undefined
                                ? parseFloat(
                                    val.Unit.TotalCost
                                  ).toLocaleString()
                                : null}
                            </td>

                            {select !== "personal" && (
                              <td className="cur_sor">
                                {val?.ProjectOwner === "PERSONAL"
                                  ? val?.PurchaseCost
                                  : null}
                              </td>
                            )}

                            {/* 
{select === "personal" ? null : (select === "other" ? (val?.PurchaseCost ? val.PurchaseCost : null) : val?.PurchaseCost)}
*/}

                            <td
                              className="text-center cur_sor "
                              onClick={() => SetPrintData(val)}
                            >
                              <GrPrint size="1.3em" />
                              &nbsp;
                              {localStorage.getItem("Designation") ===
                              "Admin" ? (
                                <MdDeleteForever
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    showDeleteRepurchasemodal(val);
                                  }}
                                  title="Delete"
                                  className="cur_sor"
                                  size="1.3em"
                                />
                              ) : null}
                            </td>
                          </tr>
                        ) : val.ProjectOwner === "OTHER" &&
                          select === "other" ? (
                          <tr key={index}>
                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {index + 1}
                            </td>
                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {moment(val.Date.split("T")[0]).format(
                                "DD-MMM-YYYY"
                              )}
                            </td>

                            {val.Project !== null &&
                            val.Project !== undefined ? (
                              <td
                                className=" cur_sor "
                                onClick={() => {
                                  showmodal(val);
                                }}
                              >
                                {val.Project}
                              </td>
                            ) : (
                              <td></td>
                            )}
                            {val.Unit !== null && val.Unit !== undefined ? (
                              <td
                                className=" cur_sor "
                                onClick={() => {
                                  showmodal(val);
                                }}
                              >
                                {val.Unit.Section}
                              </td>
                            ) : (
                              <td></td>
                            )}
                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val?.Seller ? val?.Seller : val?.Customer}
                            </td>

                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val.Unit !== null && val.Unit !== undefined
                                ? val.Unit.UnitName
                                : null}
                            </td>

                            {/* <td>{val.NetMarla}</td> */}
                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val.Unit !== null && val.Unit !== undefined
                                ? val.Unit.Marla + "M" + val.Unit.Sarsai + "S"
                                : null}
                            </td>
                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val.Unit !== null && val.Unit !== undefined
                                ? val.Unit.DimensionLength +
                                  "x" +
                                  val.Unit.DimensionWidth
                                : null}
                            </td>
                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val.Unit !== null && val.Unit !== undefined
                                ? parseFloat(
                                    val.Unit.CostPerMarla
                                  ).toLocaleString()
                                : null}
                            </td>

                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val.Unit !== null && val.Unit !== undefined
                                ? parseFloat(
                                    val.Unit.TotalCost
                                  ).toLocaleString()
                                : null}
                            </td>

                            {select !== "personal" && (
                              <td className="cur_sor">
                                {val?.ProjectOwner === "PERSONAL"
                                  ? val?.PurchaseCost
                                  : null}
                              </td>
                            )}

                            {/* 
{select === "personal" ? null : (select === "other" ? (val?.PurchaseCost ? val.PurchaseCost : null) : val?.PurchaseCost)}
*/}

                            <td
                              className="text-center cur_sor "
                              onClick={() => SetPrintData(val)}
                            >
                              <GrPrint size="1.3em" />
                              &nbsp;
                              {localStorage.getItem("Designation") ===
                              "Admin" ? (
                                <MdDeleteForever
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    showDeleteRepurchasemodal(val);
                                  }}
                                  title="Delete"
                                  className="cur_sor"
                                  size="1.3em"
                                />
                              ) : null}
                            </td>
                          </tr>
                        ) : val.ProjectOwner === "PERSONAL" &&
                          select === "personal" ? (
                          <tr key={index}>
                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {index + 1}
                            </td>
                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {moment(val.Date.split("T")[0]).format(
                                "DD-MMM-YYYY"
                              )}
                            </td>

                            {val.Project !== null &&
                            val.Project !== undefined ? (
                              <td
                                className=" cur_sor "
                                onClick={() => {
                                  showmodal(val);
                                }}
                              >
                                {val.Project}
                              </td>
                            ) : (
                              <td></td>
                            )}
                            {val.Unit !== null && val.Unit !== undefined ? (
                              <td
                                className=" cur_sor "
                                onClick={() => {
                                  showmodal(val);
                                }}
                              >
                                {val.Unit.Section}
                              </td>
                            ) : (
                              <td></td>
                            )}
                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val?.Seller ? val?.Seller : val?.Customer}
                            </td>

                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val.Unit !== null && val.Unit !== undefined
                                ? val.Unit.UnitName
                                : null}
                            </td>

                            {/* <td>{val.NetMarla}</td> */}
                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val.Unit !== null && val.Unit !== undefined
                                ? val.Unit.Marla + "M" + val.Unit.Sarsai + "S"
                                : null}
                            </td>
                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val.Unit !== null && val.Unit !== undefined
                                ? val.Unit.DimensionLength +
                                  "x" +
                                  val.Unit.DimensionWidth
                                : null}
                            </td>
                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val.Unit !== null && val.Unit !== undefined
                                ? parseFloat(
                                    val.Unit.CostPerMarla
                                  ).toLocaleString()
                                : null}
                            </td>

                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val.Unit !== null && val.Unit !== undefined
                                ? parseFloat(
                                    val.Unit.TotalCost
                                  ).toLocaleString()
                                : null}
                            </td>

                            {select !== "personal" && (
                              <td className="cur_sor">
                                {val?.ProjectOwner === "PERSONAL"
                                  ? val?.PurchaseCost
                                  : null}
                              </td>
                            )}

                            {/* 
                  {select === "personal" ? null : (select === "other" ? (val?.PurchaseCost ? val.PurchaseCost : null) : val?.PurchaseCost)}
                  */}

                            <td
                              className="text-center cur_sor "
                              onClick={() => SetPrintData(val)}
                            >
                              <GrPrint size="1.3em" />
                              &nbsp;
                              {/* {localStorage.getItem("Designation") ===
                              "Admin" ? (
                                <MdDeleteForever
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    showDeleteRepurchasemodal(val);
                                  }}
                                  title="Delete"
                                  className="cur_sor"
                                  size="1.3em"
                                />
                              ) : null} */}
                            </td>
                          </tr>
                        ) : null
                      //                   val.ProjectOwner === "OTHER" && select === "other" ?(
                      //                     val.ProjectOwner === "PERSONAL" && select === "personal" ? (
                      //                         <tr key={index}>
                      //                           <td
                      //                             className=" cur_sor "
                      //                             onClick={() => {
                      //                               showmodal(val);
                      //                             }}
                      //                           >
                      //                             {index + 1}
                      //                           </td>
                      //                           <td
                      //                             className=" cur_sor "
                      //                             onClick={() => {
                      //                               showmodal(val);
                      //                             }}
                      //                           >
                      //                             {moment(val.Date.split("T")[0]).format(
                      //                               "DD-MMM-YYYY"
                      //                             )}
                      //                           </td>

                      //                           {val.Project !== null && val.Project !== undefined ? (
                      //                             <td
                      //                               className=" cur_sor "
                      //                               onClick={() => {
                      //                                 showmodal(val);
                      //                               }}
                      //                             >
                      //                               {val.Project}
                      //                             </td>
                      //                           ) : (
                      //                             <td></td>
                      //                           )}
                      //                           {val.Unit !== null && val.Unit !== undefined ? (
                      //                             <td
                      //                               className=" cur_sor "
                      //                               onClick={() => {
                      //                                 showmodal(val);
                      //                               }}
                      //                             >
                      //                               {val.Unit.Section}
                      //                             </td>
                      //                           ) : (
                      //                             <td></td>
                      //                           )}
                      //                           <td
                      //                             className=" cur_sor "
                      //                             onClick={() => {
                      //                               showmodal(val);
                      //                             }}
                      //                           >
                      //                             {val?.Seller?val?.Seller:val?.Customer}
                      //                           </td>

                      //                           <td
                      //                             className=" cur_sor "
                      //                             onClick={() => {
                      //                               showmodal(val);
                      //                             }}
                      //                           >
                      //                             {val.Unit !== null && val.Unit !== undefined
                      //                               ? val.Unit.UnitName
                      //                               : null}
                      //                           </td>

                      //                           {/* <td>{val.NetMarla}</td> */}
                      //                           <td
                      //                             className=" cur_sor "
                      //                             onClick={() => {
                      //                               showmodal(val);
                      //                             }}
                      //                           >
                      //                             {val.Unit !== null && val.Unit !== undefined
                      //                               ? val.Unit.Marla + "M" + val.Unit.Sarsai + "S"
                      //                               : null}
                      //                           </td>
                      //                           <td
                      //                             className=" cur_sor "
                      //                             onClick={() => {
                      //                               showmodal(val);
                      //                             }}
                      //                           >
                      //                             {val.Unit !== null && val.Unit !== undefined
                      //                               ? val.Unit.DimensionLength +
                      //                                 "x" +
                      //                                 val.Unit.DimensionWidth
                      //                               : null}
                      //                           </td>
                      //                           <td
                      //                             className=" cur_sor "
                      //                             onClick={() => {
                      //                               showmodal(val);
                      //                             }}
                      //                           >
                      //                             {val.Unit !== null && val.Unit !== undefined
                      //                               ? parseFloat(
                      //                                   val.Unit.CostPerMarla
                      //                                 ).toLocaleString()
                      //                               : null}
                      //                           </td>

                      //                           <td
                      //                             className=" cur_sor "
                      //                             onClick={() => {
                      //                               showmodal(val);
                      //                             }}
                      //                           >
                      //                             {val.Unit !== null && val.Unit !== undefined
                      //                               ? parseFloat(val.Unit.TotalCost).toLocaleString()
                      //                               : null}
                      //                           </td>

                      //                           { select !== "personal" && (
                      //             <td className="cur_sor">
                      //               {val?.ProjectOwner === "PERSONAL" ? val?.PurchaseCost : null}
                      //             </td>
                      //                           )}

                      //                           {/*
                      //   {select === "personal" ? null : (select === "other" ? (val?.PurchaseCost ? val.PurchaseCost : null) : val?.PurchaseCost)}
                      //  */}

                      //                           <td
                      //                             className="text-center cur_sor "
                      //                             onClick={() => SetPrintData(val)}
                      //                           >
                      //                             <GrPrint size="1.3em" />
                      //                             &nbsp;
                      //                             {localStorage.getItem("Designation") === "Admin" ? (
                      //                               <MdDeleteForever
                      //                                 onClick={(e) => {
                      //                                   e.stopPropagation();
                      //                                   showDeleteRepurchasemodal(val);
                      //                                 }}
                      //                                 title="Delete"
                      //                                 className="cur_sor"
                      //                                 size="1.3em"
                      //                               />
                      //                             ) : null}
                      //                           </td>
                      //                         </tr>
                      //                      ):null ):null
                    )
                  : null}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-6  text-right pr-0 pl-0">
          <button
            className="print_btn float-left"
            onClick={() => {
              AllprintPaymentRec();
            }}
          >
            <TiPrinter size="1.3em" />
            Print
          </button>
          <button
            style={{ color: "black !important" }}
            className="print_btn ml-2 float-left"
          >
            <CSVLink
              style={{ color: "black" }}
              filename="Re_Purchase_Csv"
              data={CsvData}
            >
              Download CSV
            </CSVLink>
          </button>
        </div>
      </div>
      <div id="deleteRepurchasemodal" class="modal">
        <div class="modal-content-cat_voucher2">
          <div className="row ">
            <div className="col-12  page_heading">
              <h4 className="  text-center pt-2">Delete Repurchase</h4>
              <span class="close pr-2  " onClick={closeDeleteRepurchasemodal}>
                &times;
              </span>
            </div>
          </div>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-12 " style={{ paddingTop: "10px" }}>
              <label className="input_label m-0">Email:</label>
            </div>
            <div className="col-12 ">
              <input
                style={{ width: "100%" }}
                className="input_total_styling "
                placeholder="Email"
                id="deleteRepurchaseEmail"
                type="email"
                autoComplete="off"
                readonly="readonly"
                onMouseDown={(e) => {
                  e.target.removeAttribute("readonly");
                }}
              ></input>
            </div>
            <div className="col-12 " style={{ paddingTop: "10px" }}>
              <label className="input_label m-0">Password:</label>
            </div>
            <div className="col-12 ">
              <input
                style={{ width: "100%" }}
                className="input_total_styling "
                placeholder="Password"
                id="deleteRepurchasePassword"
                autoComplete="off"
                readonly="readonly"
                onMouseDown={(e) => {
                  e.target.removeAttribute("readonly");
                }}
              ></input>
            </div>
            <div className="col-12  " style={{ paddingTop: "10px" }}>
              <label className="input_label m-0">Reason:</label>
            </div>
            <div className="col-12  " style={{ width: "100%" }}>
              <textarea
                style={{ width: "100%", height: "100px" }}
                className="input_total_styling "
                placeholder="Reason"
                id="deleteRepurchaseReason"
                rows="4"
              ></textarea>
            </div>
            <div
              style={{
                display: "flex",
                float: "right",
                width: "100%",
                justifyContent: "end",
                marginRight: "3%",
                marginTop: "10px",
                alignItems: "center",
              }}
            >
              {loading2 && (
                <Loader type="ThreeDots" color="green" height={40} width={40} />
              )}
              <div>
                <span>
                  <button
                    className="cancel_btn ml-2  "
                    onClick={() => {
                      closeDeleteRepurchasemodal();
                      setrepurchaseRow(null);
                    }}
                  >
                    Cancel
                  </button>
                </span>
              </div>
              <div>
                <span>
                  <button
                    className="save_btn ml-2  "
                    disabled={loading2}
                    onClick={deleteRepurchase}
                  >
                    Save
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="RepurchaseDetailsMod"
        class="modalscreen "
        style={{
          height: "100vh",

          overflow: "hidden",
        }}
      >
        <div
          class="modal-content-screen1 pt-1"
          style={{
            height: "90vh",
            overflowY: "scroll",
          }}
        >
          <div className="row">
            <div className="col-12  page_heading">
              <h4 className="  text-center">Details</h4>
              <span class="close pr-2  " onClick={closemodal}>
                &times;
              </span>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12 mt-2">
                <div className="table-responsive_modal table_height-voucher mt-4 ">
                  <div className="text-center">
                    <b>Transaction Details</b>
                  </div>
                  <table className="table table-borderless m-0 ">
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th>Account</th>
                        <th>Debit</th>
                        <th>Credit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {creditordata !== undefined
                        ? creditordata?.Transaction?.map((x, i) => {
                            return (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{x?.Account?.AccountTitle}</td>
                                <td>
                                  {x.TransactionType === "DEBIT"
                                    ? x?.Amount?.toLocaleString()
                                    : null}
                                </td>
                                <td>
                                  {x?.TransactionType === "CREDIT"
                                    ? x?.Amount?.toLocaleString()
                                    : null}
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-5">
              <div className="table-responsive_modal  mt-0">
                <div className="text-center">
                  <b>Unit Details</b>
                </div>
              </div>
            </div>
            <div
              className="col-6 mt-2"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ul>
                <li>
                  <b>Project:</b>{" "}
                  {creditordata.Project !== undefined
                    ? creditordata.Project
                    : null}
                </li>
                <li>
                  <b>Section:</b>{" "}
                  {creditordata.Unit !== undefined
                    ? creditordata.Unit.Section
                    : null}
                </li>
                <li>
                  <b>Unit:</b>{" "}
                  {creditordata.Unit !== undefined
                    ? creditordata.Unit.UnitName
                    : null}
                </li>

                <li>
                  <b>Marla:</b>{" "}
                  {creditordata.Unit !== undefined
                    ? creditordata.Unit.Marla
                    : null}
                </li>
                <li>
                  <b>Sarsai:</b>{" "}
                  {creditordata.Unit !== undefined
                    ? creditordata.Unit.Sarsai
                    : null}
                </li>
                <li>
                  <b>LxW:</b>{" "}
                  {creditordata.Unit !== undefined
                    ? creditordata.Unit.DimensionLength +
                      "x" +
                      creditordata.Unit.DimensionWidth
                    : null}
                </li>
                <li>
                  <b>Measurment Unit:</b>{" "}
                  {creditordata.Unit !== undefined
                    ? creditordata.Unit.RatePerMarlaOrsqft
                    : null}
                </li>
              </ul>
            </div>
            <div
              className="col-6 mt-0"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ul>
                <li>
                  <b>Net Marla:</b>{" "}
                  {creditordata.Unit !== undefined
                    ? parseFloat(creditordata.Unit.NetMarla).toLocaleString()
                    : null}
                </li>
                <li>
                  <b>Net Sqft:</b>{" "}
                  {creditordata.Unit !== undefined
                    ? parseFloat(creditordata.Unit.NetSqft).toLocaleString()
                    : null}
                </li>
                <li>
                  <b>Sale Rate:</b>{" "}
                  {creditordata.Unit !== undefined
                    ? parseFloat(
                        creditordata.Unit.RatePerMarla
                      ).toLocaleString()
                    : null}
                </li>
                <li>
                  <b>Total Value:</b>{" "}
                  {creditordata.Unit !== undefined
                    ? parseFloat(creditordata.Unit.Price).toLocaleString()
                    : null}
                </li>
                <li>
                  <b>Cost Rate:</b>{" "}
                  {creditordata.Unit !== undefined
                    ? parseFloat(
                        creditordata.Unit.CostPerMarla
                      ).toLocaleString()
                    : null}
                </li>
                <li>
                  <b>Total Cost:</b>{" "}
                  {creditordata.Unit !== undefined
                    ? parseFloat(creditordata.Unit.TotalCost).toLocaleString()
                    : null}
                </li>
              </ul>
            </div>
            <div className="col-12 mt-4 text-right pr-0 pl-0">
              <button
                className="print_btn float-right"
                onClick={() => {
                  SetPrintDetials(creditordata);
                }}
              >
                <TiPrinter size="1.3em" />
                Print
              </button>
            </div>
          </div>

          <div className="col-12 text-right"></div>
        </div>
      </div>

      {/* <div id="RepurchaseDetailsMod" class="modal">
        <div class="modal-content-cat">
          <div className="row">
            <div className="col-12  page_heading">
              <h4 className="  text-center pt-2">Unit Detail</h4>
              <span class="close pr-2  " onClick={closemodal}>
                &times;
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-6 mt-2">
              <ul>
                <li>
                  <b>Project:</b>{" "}
                  {creditordata.Project !== undefined
                    ? creditordata.Project
                    : null}
                </li>
                <li>
                  <b>Section:</b>{" "}
                  {creditordata.Unit !== undefined
                    ? creditordata.Unit.Section
                    : null}
                </li>
                <li>
                  <b>Unit:</b>{" "}
                  {creditordata.Unit !== undefined
                    ? creditordata.Unit.UnitName
                    : null}
                </li>

                <li>
                  <b>Marla:</b>{" "}
                  {creditordata.Unit !== undefined
                    ? creditordata.Unit.Marla
                    : null}
                </li>
                <li>
                  <b>Sarsai:</b>{" "}
                  {creditordata.Unit !== undefined
                    ? creditordata.Unit.Sarsai
                    : null}
                </li>
                <li>
                  <b>LxW:</b>{" "}
                  {creditordata.Unit !== undefined
                    ? creditordata.Unit.DimensionLength +
                      "x" +
                      creditordata.Unit.DimensionWidth
                    : null}
                </li>
                <li>
                  <b>Measurment Unit:</b>{" "}
                  {creditordata.Unit !== undefined
                    ? creditordata.Unit.RatePerMarlaOrsqft
                    : null}
                </li>
              </ul>
            </div>
            <div className="col-6 mt-2">
              <ul>
                <li>
                  <b>Net Marla:</b>{" "}
                  {creditordata.Unit !== undefined
                    ? parseFloat(creditordata.Unit.NetMarla).toLocaleString()
                    : null}
                </li>
                <li>
                  <b>Net Sqft:</b>{" "}
                  {creditordata.Unit !== undefined
                    ? parseFloat(creditordata.Unit.NetSqft).toLocaleString()
                    : null}
                </li>
                <li>
                  <b>Sale Rate:</b>{" "}
                  {creditordata.Unit !== undefined
                    ? parseFloat(
                        creditordata.Unit.RatePerMarla
                      ).toLocaleString()
                    : null}
                </li>
                <li>
                  <b>Total Value:</b>{" "}
                  {creditordata.Unit !== undefined
                    ? parseFloat(creditordata.Unit.Price).toLocaleString()
                    : null}
                </li>
                <li>
                  <b>Cost Rate:</b>{" "}
                  {creditordata.Unit !== undefined
                    ? parseFloat(
                        creditordata.Unit.CostPerMarla
                      ).toLocaleString()
                    : null}
                </li>
                <li>
                  <b>Total Cost:</b>{" "}
                  {creditordata.Unit !== undefined
                    ? parseFloat(creditordata.Unit.TotalCost).toLocaleString()
                    : null}
                </li>
              </ul>
            </div>
          </div>

          <div className="col-12 text-right"></div>
        </div>
      </div> */}

      <RePurchaseDeleteVoucherModal
        closemodal2={closemodal2}
        DeleteVoucherData={DeleteVoucherData}
        handelDeleteVoucherdataChange={handelDeleteVoucherdataChange}
        loading={loading}
        deleteVoucher={deleteVoucher}
      />
    </>
  );
};
