import { useState } from "react";
import { TransferUnitPage } from "./TransferUnitForm";
import { TransferUnitTable } from "./TransferUnitTable";

const MainTransferUnit = () => {
  const [statecontractdata, setcontractdata] = useState({});
  const [stateCustomersNomineeRecord, setCustomersNomineeRecord] = useState([]);
  const changecomponent = () => {
    setComponentState("TransferPage");
  };

  const formCancel = () => {
    setComponentState("TransferTable");
  };
  const showaddnominee = () => {
    setComponentState("addnominee");
  };
  const [componentState, setComponentState] = useState("TransferTable");

  return (
    <>
      <>
        <div className="row ">
          <div className="dash_background col-12 p-0">
            <div className="row ">
              <div className="col-md-12">
                {/* {componentState} */}
                {componentState == "TransferTable" ? (
                  <TransferUnitTable
                    changecomponent={changecomponent}
                    formCancel={formCancel}
                  />
                ) : componentState == "TransferPage" ? (
                  <TransferUnitPage
                    statecontractdata={statecontractdata}
                    stateCustomersNomineeRecord={stateCustomersNomineeRecord}
                    //  stateCustomersNomineeRecord={stateCustomersNomineeRecord}
                    showaddnominee={showaddnominee}
                    formCancel={formCancel}
                    setcontractdata={setcontractdata}
                    setCustomersNomineeRecord={setCustomersNomineeRecord}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};
export default MainTransferUnit;
