import { useState } from "react";
import AddNominee from "./AddNominee";
import { ContractForm } from "./ContractForm";
import { ContractTable } from "./ContractTable";

const ContractWithUnit = () => {
  const [statecontractdata, setcontractdata] = useState({});
  const [stateCustomersNomineeRecord, setCustomersNomineeRecord] = useState([]);
  const [updateRow, setupdateRow] = useState(null);
  const showcontracttable = () => {
    stateshowcontract("ContractAdd");
  };
  const cancelcontracttable = () => {
    stateshowcontract("ContractTable");
  };
  const showaddnominee = () => {
    stateshowcontract("addnominee");
  };
  const [showcontract, stateshowcontract] = useState("ContractTable");

  return (
    <>
      <div className="row">
        <div className="dash_background col-12 p-0">
          <div className="row ">
            <div className="col-md-12">
              {/* {showcontract} */}
              {showcontract == "ContractTable" ? (
                <ContractTable
                  showcontracttable={showcontracttable}
                  setupdateRow={setupdateRow}
                  cancelcontracttable={cancelcontracttable}
                  isFile={false}
                />
              ) : showcontract == "addnominee" ? (
                <AddNominee
                  cancelcontracttable={cancelcontracttable}
                  statecontractdata={statecontractdata}
                  stateCustomersNomineeRecord={stateCustomersNomineeRecord}
                />
              ) : showcontract == "ContractAdd" ? (
                <ContractForm
                  updateRow={updateRow}
                  statecontractdata={statecontractdata}
                  stateCustomersNomineeRecord={stateCustomersNomineeRecord}
                  // stateCustomersNomineeRecord={stateCustomersNomineeRecord}
                  showaddnominee={showaddnominee}
                  cancelcontracttable={cancelcontracttable}
                  setcontractdata={setcontractdata}
                  setCustomersNomineeRecord={setCustomersNomineeRecord}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractWithUnit;
