import { useEffect } from "react";
import { useState } from "react";

import RefundPaymentForm from "./RefundPaymentForm";
import { RefundPaymentTable } from "./RefundPaymentTable";

const RefundPaymentMain = () => {
  const [voucherData, setvoucherData] = useState(null);
  const [unitData, setunitData] = useState(null);

  const [voucherSaveDetails, setvoucherSaveDetails] = useState(null);

  const showrefundtable = () => {
    stateshowrefund(
      <RefundPaymentForm
        cancelrefundtable={cancelrefundtable}
        setvoucherSaveDetails={setvoucherSaveDetails}
        voucherSaveDetails={voucherSaveDetails}
      />
    );
  };
  const cancelrefundtable = () => {
    stateshowrefund(
      <RefundPaymentTable
        setvoucherData={setvoucherData}
        setunitData={setunitData}
        vouchData={voucherData}
        unitData={unitData}
        showrefundtable={showrefundtable}
        cancelrefundtable={cancelrefundtable}
        setvoucherSaveDetails={setvoucherSaveDetails}
        voucherSaveDetails={voucherSaveDetails}
      />
    );
  };
  const [showrefund, stateshowrefund] = useState(
    <RefundPaymentTable
      setvoucherData={setvoucherData}
      setunitData={setunitData}
      vouchData={voucherData}
      unitData={unitData}
      showrefundtable={showrefundtable}
      cancelrefundtable={cancelrefundtable}
      setvoucherSaveDetails={setvoucherSaveDetails}
      voucherSaveDetails={voucherSaveDetails}
    />
  );
  useEffect(() => {
    if (voucherData === null) {
      stateshowrefund(
        <RefundPaymentTable
          setvoucherData={setvoucherData}
          setunitData={setunitData}
          vouchData={voucherData}
          showrefundtable={showrefundtable}
          cancelrefundtable={cancelrefundtable}
          setvoucherSaveDetails={setvoucherSaveDetails}
          voucherSaveDetails={voucherSaveDetails}
          unitData={unitData}
        />
      );
    } else {
      stateshowrefund(
        <RefundPaymentForm
          setvoucherData={setvoucherData}
          setunitData={setunitData}
          vouchData={voucherData}
          unitData={unitData}
          showrefundtable={showrefundtable}
          cancelrefundtable={cancelrefundtable}
          setvoucherSaveDetails={setvoucherSaveDetails}
          voucherSaveDetails={voucherSaveDetails}
        />
      );
    }
  }, [voucherData]);
  return (
    <>
      <div className="row">
        <div className="dash_background col-12 p-0">
          <div className="row ">
            <div className="col-md-12">{showrefund}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefundPaymentMain;
