import axios from "axios";
import { toast } from "react-toastify";
export const GetAllTransferUnitPopulate = (data) => {
  return {
    type: "get_alltransferunitpopulate",
    payload: data,
    loader: false,
  };
};
export function getalltransferunitpopulate() {
  return (dispatch) => {
    let pgurl = `${
      process.env.REACT_APP_API_URL
    }Contracts/populateUnitTransfer?ProjectId=${localStorage.getItem(
      "ProjectId"
    )}`;

    axios({
      url: pgurl,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllTransferUnitPopulate(result.data.Data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}

export const GetAllTransferUnitTableData = (data) => {
  return {
    type: "get_transferunittable",
    payload: data,
    loader: false,
  };
};
export function getAllTransferUnitTable(data) {
  return (dispatch) => {
    let pgurl = `${
      process.env.REACT_APP_API_URL
    }Contracts/getUnitTransfer?ProjectId=${localStorage.getItem("ProjectId")}`;
    // if (localStorage.getItem("ProjectId") === null) {
    //   pgurl = `${process.env.REACT_APP_API_URL}Contracts/Transfer/GetAll`;
    // }
    axios({
      url: pgurl,
      method: "Get",
      data: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllTransferUnitTableData(result.data?.Data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
