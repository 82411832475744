import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { BsCloudUpload, BsFillInfoSquareFill } from "react-icons/bs";
import { RiArrowGoBackFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getallacounts } from "../../../actions/getheading";
import ScreenInfoModal from "../../../ScreenInfoModal/ScreenInfoModal";
import RectifiyModal from "../RectifyModal/rectifiyModal";
import { JornalEntryTab } from "./JournalEntryTab";

export const JournalEntryForm = (props) => {
  const resultsallaccount = useSelector((state) => state.getAllAccountsReducer);
  const dispatch = useDispatch();
  const [rowstate, setrowstate] = useState([
    {
      JournalVoucherSavedId: 0,
      JournalVoucherSavedDetailId: 0,
      AccountId: "",
      AccTitle: "",
      Description: "",
      CreditAmount: "",
      DebitAmount: "",
    },
  ]);
  const [showDetailModal, setshowDetailModal] = useState(false);

  const [RevertModal, setRevertModal] = useState(false);
  const [disableBtn, setdisableBtn] = useState(false);
  let vouchernumber = `JV-${Math.floor(Date.now())}`;
  const [voucherID, setvoucherID] = useState(vouchernumber);
  useEffect(() => {
    dispatch(getallacounts());
  }, [dispatch]);
  useEffect(() => {
    if (props.vouchData != null) {
      document.getElementById("date").value = props.vouchData?.SavedDate?.slice(
        0,
        10
      );
      document.getElementById("narration").value = props.vouchData?.Narration;
      setvoucherID(props.vouchData?.VoucherId);
      // setrowstate(props.vouchData?.JournalVoucherSavedDetails)
    } else {
      setvoucherID(vouchernumber);
    }
  }, [props.vouchData]);
  const savedata = () => {
    let newcountrow = [];
    if (document.getElementById("date").value === "") {
      toast.info("Date Is Required ");
    } else if (document.getElementById("narration").value === "") {
      toast.info("Narration Is Required ");
    } else if (document.getElementById("vouchernumber").value === "") {
      toast.info("Voucher Number Is Required ");
    } else if (rowstate.length === 0) {
      toast.info("Please add transactions detail in table ");
    } else {
      rowstate.forEach((x) => {
        newcountrow.push({
          JournalVoucherSavedDetailId: x.JournalVoucherSavedDetailId,
          JournalVoucherSavedId: x.JournalVoucherSavedId,
          AccountId: parseInt(x.AccountId),
          Description: x.Description,
          Amount: x.DebitAmount === "" ? x.CreditAmount : x.DebitAmount,
          // ? parseFloat(x.CreditAmount?.replace(/,/g, ""))
          // : parseFloat(x.DebitAmount?.replace(/,/g, "")),
          TransactionType: x.CreditAmount === "" ? "DEBIT" : "CREDIT",
        });
      });
      let DebitAmount = parseInt(
        document.getElementById("totaldebitamount1").value.replace(/,/g, "")
      );
      let CreditAmount = parseInt(
        document.getElementById("totalcreditamount1").value.replace(/,/g, "")
      );
      let Entrydate = document.getElementById("date").value;
      let vouchernumber = document.getElementById("vouchernumber").value;
      let narration = document.getElementById("narration").value;

      var data = {
        VoucherId: voucherID,
        SavedDate: Entrydate,
        JournalVoucherSavedDetails: newcountrow,
        TotalDebit: parseFloat(DebitAmount),
        TotalCredit: parseFloat(CreditAmount),
        Narration: narration,
        ProjectId: parseInt(`${localStorage.getItem("ProjectId")}`),
      };

      if (DebitAmount === CreditAmount) {
        document.getElementById("savebuttonjournal").disabled = true;
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}Vouchers/Journal/Add`,
          data: JSON.stringify(data),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "content-type": "application/json",
          },
        }).then(
          (result) => {
            document.getElementById("savebuttonjournal").disabled = false;

            if (result.data.IsSuccess === true) {
              toast.info(result.data.Message);

              document.getElementById("totaldebitamount1").value = "";
              document.getElementById("totalcreditamount1").value = "";
              document.getElementById("narration").value = "";
              rowstate.forEach((x, idx) => {
                document.getElementById(`accountid${idx}`).value = "";
                document.getElementById(`AccountTitle${idx}`).value = "";
                document.getElementById(`description${idx}`).value = "";
                document.getElementById(`debitamount${idx}`).value = "";
                document.getElementById(`creditamount${idx}`).value = "";
              });
              setrowstate([]);
              setvoucherID(vouchernumber);
            } else {
              toast.info(result.data.Message);
            }
          },
          (error) => {
            document.getElementById("savebuttonjournal").disabled = false;

            toast.info("something went wrong ");
          }
        );
      } else {
        toast.info("Transactions are not equal!");
      }
    }
  };
  const submitdata = () => {
    setdisableBtn(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}Vouchers/Journal/Submit?voucherId=${
        document.getElementById("vouchernumber").value
      }`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        document.getElementById("savebuttonjournal").disabled = false;

        if (result.data.IsSuccess === true) {
          toast.info(result.data.Message);

          document.getElementById("totaldebitamount1").value = "";
          document.getElementById("totalcreditamount1").value = "";
          document.getElementById("narration").value = "";

          rowstate.forEach((x, idx) => {
            document.getElementById(`accountid${idx}`).value = "";
            document.getElementById(`AccountTitle${idx}`).value = "";
            document.getElementById(`description${idx}`).value = "";
            document.getElementById(`debitamount${idx}`).value = "";
            document.getElementById(`creditamount${idx}`).value = "";
          });
          setrowstate([]);
          setvoucherID(vouchernumber);
        } else {
          toast.info(result.data.Message);
          setdisableBtn(false);
        }
      },
      (error) => {
        setdisableBtn(false);
        toast.info("something went wrong ");
      }
    );
  };

  return (
    <>
      <div className="row ">
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0 ">Date:</label>
        </div>
        <div className="col-md-3 mt-4   p-0">
          <input
            type="date"
            className="input_date_styling "
            id="date"
            onChange={() => setdisableBtn(true)}
            defaultValue={moment().format("YYYY-MM-DD")}
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-4  p-0"></div>

        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Voucher No:</label>
        </div>
        <div className="col-md-3 mt-4    p-0">
          <input
            type="text "
            className="input_styling "
            disabled
            id="vouchernumber"
            value={props.vouchData ? props.vouchData?.VoucherId : vouchernumber}
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>

      <div className="row ">
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Narration:</label>
        </div>
        <div className="col-md-3 mt-4    p-0">
          <textarea
            onChange={() => setdisableBtn(true)}
            className="input_address "
            id="narration"
          ></textarea>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-4  p-0"></div>
      </div>
      <div className="mt-4">
        <JornalEntryTab
          resultsallaccount={resultsallaccount}
          rowstate={rowstate}
          setrowstate={setrowstate}
          setdisableBtn={setdisableBtn}
          vouchId={voucherID}
          // vouchId={document.getElementById("vouchernumber").value ? document.getElementById("vouchernumber").value : ""}
          vouchData={props.vouchData?.JournalVoucherSavedDetails}
        />
      </div>
      <div className="row pl-3 pr-3 pb-2">
        <div className="col-md-3 mt-4   mt-2 text-md-Left "></div>
        <div className="col-md-3 mt-4   mt-2 text-md-Left "></div>
        <div className="col-md-1 mt-4 text-md-center ">Debit:</div>
        <div className="col-md-2 mt-4 mt-2 text-md-right ">
          <input
            type="text"
            readOnly
            id="totaldebitamount1"
            className="input_styling"
          />
        </div>
        <div className="col-md-1 mt-4 text-md-center ">Credit:</div>

        <div className="col-md-2 mt-4 mt-2 text-md-right ">
          <input
            type="text"
            readOnly
            id="totalcreditamount1"
            className="input_styling"
          />
        </div>
      </div>
      <div className=" float-right mt-md-4">
        <span>
          <button
            className="save_btn ml-2  "
            onClick={() => {
              setshowDetailModal(true);
            }}
          >
            <BsFillInfoSquareFill size="1em" className="mr-1" />
            Detail
          </button>
        </span>
        {localStorage.getItem("Designation") === "Admin" && props.vouchData && (
          <span>
            <button
              className="save_btn ml-2  "
              id="headingsave"
              onClick={() => setRevertModal(true)}
            >
              <RiArrowGoBackFill size="1em" />
              Revert
            </button>
          </span>
        )}
        <span>
          <button
            className="cancel_btn ml-2  "
            onClick={props.canceljournaltable}
          >
            Cancel
          </button>
        </span>
        <span>
          <button
            className="save_btn ml-2  "
            id="savebuttonjournal"
            onClick={savedata}
            disabled={!disableBtn}
          >
            <BsCloudUpload size="1.3em" />
            Save
          </button>
        </span>
        {localStorage.getItem("Designation") === "Admin" && (
          <span>
            <button
              disabled={disableBtn}
              className="save_btn ml-2  "
              id="headingsave"
              onClick={submitdata}
            >
              {/* <RiSave3Fill size="1.3em" /> */}
              Submit
            </button>
          </span>
        )}
      </div>
      {RevertModal && (
        <RectifiyModal
          entryId={document.getElementById("vouchernumber").value}
          entryType={"Journal Entry"}
          addedById={props.vouchData.AddedById}
          setshowModal={setRevertModal}
        />
      )}
      {showDetailModal ? (
        <ScreenInfoModal
          setshowModal={setshowDetailModal}
          screenName="Journal Entry"
        />
      ) : null}
    </>
  );
};
