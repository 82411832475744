import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { GrPrint } from "react-icons/gr";
import { MdDeleteForever } from "react-icons/md";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { getallCRpopulatetable } from "../../../actions/vouchersactions/getcomreceipt";
import {
  CommissionReceiptPrint,
  CommissionRecModal,
} from "../../accpayablepages/vouchersprints/receiptprints";
import CommossionReceiptDeleteVoucherModal from "./CommissionReceiptDeleteVoucherModal";
import { CSVLink } from "react-csv";
import CommissionReceiptModal from "./CommissionReceiptModal";
import useDownloadCsv from "../../../actions/downloadCsvHook";
import { getAllContractTablePopulate } from "../../../actions/accountsactions/addcontract";
import { getallsellers } from "../../../actions/inventoryactions/addseller";
import moment from "moment";

export const CommissionReceiptTable = (props) => {
  const resultsrrtable = useSelector((state) => state.getAllComReceiptTable);
  const [TransactionData, setTransactionData] = useState();
  const [filterdata, setfilterdata] = useState();
  const [statetoggle, settoggle] = useState(true);
  const [printData, SetPrintData] = useState();
  const [debitState, setdebitState] = useState(0);
  const [creditState, setcreditState] = useState(0);
  const [SelecetdVoucher, setSelecetdVoucher] = useState(null);
  const rolesdata = useSelector((state) => state.GetAllRole);
  const [allLinks, setallLinks] = useState(null);
  const [data, downloadCsv] = useDownloadCsv();
  const [allUnits, setallUnits] = useState([]);
  const [unitId, setunitId] = useState("");
  const [sellerId, setSellerId] = useState("");

  const [contractId, setContractId] = useState("");
  const [allCustomers, setallCustomers] = useState([]);
  const resultallsellers = useSelector((state) => state.getAllSellers);
  const [filterObject, setfilterObject] = useState({
    date: "",
    voucherNumber: "",
    seller: "",
    unit: "",
  });
  const resultsallcontractspopulate = useSelector(
    (state) => state.getContractTablePopulate
  );
  useEffect(() => {
    if (resultsallcontractspopulate.data) {
      setallCustomers(resultsallcontractspopulate.data?.Buyers);
      setallUnits(resultsallcontractspopulate.data?.Units);
    }
  }, [resultsallcontractspopulate]);

  const onTextChangeUnit = () => {
    var inputValue = document.getElementById("unitfilterId").value;
    var options = document.getElementById("unitfilterIdOpt").childNodes;
    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        allUnits.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });

        // document.getElementById("City").value = val.Nominee.NomineeCity;
        // document.getElementById("Country").value = val.Nominee.NomineeCountry;
        setunitId(val.UnitId);
        setContractId(
          val.Contract?.length > 0 ? val.Contract[0]?.ContractId : ""
        );
        break;
      }
      setunitId("");
      setContractId("");
    }
  };
  const onTextChangeSeller = () => {
    var inputValue = document.getElementById("sellerfilter").value;
    var options = document.getElementById("sellerId").childNodes;
    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        resultallsellers?.data?.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });

        // document.getElementById("City").value = val.Nominee.NomineeCity;
        // document.getElementById("Country").value = val.Nominee.NomineeCountry;
        setSellerId(val.SellerId);

        break;
      }
      setSellerId("");
    }
  };
  /////////// Delete Voucher Data and functions Start ///////////////
  const [DeleteVoucherData, setDeleteVoucherData] = useState({
    Email: "",
    Password: "",
    Reason: "",
  });
  const [showPassword, setshowPassword] = useState(false);
  const [loading, setloading] = useState(false);

  const closeDeleteVoucherModal = () => {
    document.getElementById("DeleteVoucherModal").style.display = "none";
    setSelecetdVoucher(null);
    setDeleteVoucherData({
      Email: "",
      Password: "",
      Reason: "",
    });
  };
  const handelDeleteVoucherdataChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setDeleteVoucherData({ ...DeleteVoucherData, [name]: value });
  };

  function deleteVoucher() {
    if (DeleteVoucherData?.Email === "") {
      return toast.info("Email is Required");
    } else if (DeleteVoucherData?.Password === "") {
      return toast.info("Password is Required");
    } else if (DeleteVoucherData?.Reason === "") {
      return toast.info("Reason is Required");
    }
    setloading(true);
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}Scripts/DeleteCR?Email=${DeleteVoucherData?.Email}&Password=${DeleteVoucherData?.Password}&Reason=${DeleteVoucherData?.Reason}&voucherId=${SelecetdVoucher?.CommissionReceiptVoucherId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        setloading(false);
        if (result.data.IsSuccess === true) {
          setDeleteVoucherData({
            Email: "",
            Password: "",
            Reason: "",
          });
          toast.info(result.data.Message);
          dispatch(getallCRpopulatetable({}));
          closeDeleteVoucherModal();
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Someting went wrong");
      }
    );
  }

  /////////// Delete Voucher Data and functions End ///////////////

  // print function
  const componentRef = useRef();

  const printRecoveryRec = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    if (printData !== undefined) {
      printRecoveryRec();
    }
  }, [printData, statetoggle]);
  const [printDataModal, SetPrintDataModal] = useState();
  // print function
  const componentRefModal = useRef();

  const printGenrealRecModal = useReactToPrint({
    content: () => componentRefModal.current,
  });

  const onTextChange = (e) => {
    let name = e?.target?.name;
    let value = e?.target?.value;

    setfilterObject({ ...filterObject, [name]: value });
    if (
      document.getElementById("sellerfilter")?.value === "" &&
      document.getElementById("datefilter")?.value === "" &&
      document.getElementById("voucherfilter")?.value === "" &&
      document.getElementById("unitfilter")?.value === ""
    ) {
      setfilterdata(resultsrrtable?.data.data);
    }
    // else {
    //   let suggestions = [];
    //   {
    //     const regex = new RegExp(
    //       document.getElementById("sellerfilter").value,
    //       "i"
    //     );
    //     const regex1 = new RegExp(
    //       document.getElementById("voucherfilter").value,
    //       "i"
    //     );
    //     const regex2 = new RegExp(
    //       document.getElementById("datefilter").value,
    //       "i"
    //     );
    //     const regex3 = new RegExp(
    //       document.getElementById("unitfilter").value,
    //       "i"
    //     );

    //     suggestions = resultsrrtable.data.data
    //       .sort()
    //       .filter(
    //         (val) =>
    //           (document.getElementById("sellerfilter").value === "" ||
    //             regex.test(val.Seller.SellerName)) &&
    //           (document.getElementById("voucherfilter").value === "" ||
    //             regex1.test(val.CommissionReceiptVoucherId)) &&
    //           (document.getElementById("datefilter").value === "" ||
    //             regex2.test(val.EntryDate)) &&
    //           (document.getElementById("unitfilter").value === "" ||
    //             regex3.test(val.Unit.UnitName))
    //       );
    //   }
    //   setfilterdata(suggestions);
    // }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    let data = {};
    dispatch(getallCRpopulatetable(data));
    dispatch(getAllContractTablePopulate());
    dispatch(getallsellers());
  }, [dispatch]);
  function showmodal(data) {
    document.getElementById("mymodal").style.display = "block";
    setTransactionData(data);
    var credit = 0;
    var debit = 0;
    data?.map((val) =>
      val.TransactionType === "CREDIT"
        ? (credit += parseFloat(val.Amount))
        : val.TransactionType === "DEBIT"
        ? (debit += parseFloat(val.Amount))
        : null
    );
    setdebitState(debit);
    setcreditState(credit);
  }
  function closemodal() {
    document.getElementById("mymodal").style.display = "none";
  }
  function ongo() {
    setloading(true);
    let data = {
      ProjectId: `${localStorage.getItem("ProjectId")}`,
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
      VoucherId: document.getElementById("voucherfilter").value,
      FilterDate: document.getElementById("datefilter").value,
      UnitId: unitId,
      SellerId: sellerId,
    };

    dispatch(getallCRpopulatetable(data));
    onTextChange();
  }
  useEffect(() => {
    console.log(resultsrrtable);
    if (resultsrrtable.data) {
      setfilterdata(resultsrrtable.data);
    }
  }, [resultsrrtable]);

  const getPaginationData = (link) => {
    let data = {
      ProjectId: Number(`${localStorage.getItem("ProjectId")}`),
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
    };
    setloading(true);
    console.log(link);
    dispatch(getallCRpopulatetable(data, link));
  };
  useEffect(() => {
    if (resultsrrtable.data) {
      setallLinks(resultsrrtable.data);
      setfilterdata(resultsrrtable.data.data);

      setloading(false);
    }
  }, [resultsrrtable]);

  const [CsvData, setCsvData] = useState([]);
  const [newData, setNewData] = useState([]);
  const csvLinkRef = useRef();

  const handleDownload = (vt) => {
    downloadCsv(vt)
      .then((csvData) => {
        setNewData(csvData);
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  useEffect(() => {
    if (newData.length > 0) {
      let arr = [];
      var i = 1;
      newData?.forEach((val) => {
        let year = val.EntryDate.slice(0, 4);
        let day = val.EntryDate.slice(8, 10);
        let month = val.EntryDate.slice(5, 7);
        arr.push({
          Sr: i++,
          Date: day + "-" + month + "-" + year,
          "Voucher No": val.CommissionReceiptVoucherId,
          Seller: val.Seller.SellerName,
          Unit: val.Unit !== undefined ? val.Unit.UnitName : "",
          Amount: parseFloat(val.Amount).toLocaleString(),
        });
      });
      console.log("arr", arr);
      setCsvData(arr);
    }
  }, [newData]);
  useEffect(() => {
    if (csvLinkRef.current && CsvData.length > 0) {
      csvLinkRef.current.link.click();
      setCsvData([]);
    }
  }, [CsvData]);
  return (
    <>
      <div hidden>
        <CommissionReceiptPrint ref={componentRef} printData={printData} />
      </div>
      <div hidden>
        <CommissionRecModal
          ref={componentRefModal}
          total={debitState}
          debitTotal={debitState}
          creditTotal={creditState}
          printData={printDataModal}
        />
      </div>
      <div className="row pt-3">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            autoComplete="off"
            name="date"
            value={filterObject?.date}
            type="date"
            className="input_styling "
            id="datefilter"
            onChange={onTextChange}
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
          />
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            autoComplete="off"
            name="voucherNumber"
            value={filterObject?.voucherNumber}
            type="text"
            className="input_styling "
            id="voucherfilter"
            onChange={onTextChange}
            placeholder="Voucher"
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
          />
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            autoComplete="off"
            name="seller"
            readOnly
            list="sellerId"
            className="input_styling "
            id="sellerfilter"
            onChange={onTextChangeSeller}
            placeholder="Seller"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
          />
          <datalist id="sellerId">
            {resultallsellers?.data?.map((x, idx) => {
              return (
                <option index={idx} value={x.SellerName}>
                  {x.SellerId}
                </option>
              );
            })}
          </datalist>
        </div>
        {/* <div className="col-md-2 pr-0 pl-0">
          <input
            name="unit"
            value={filterObject?.unit}
            type="text"
            autoComplete={"false"}
            autoCorrect="off"
            className="input_styling "
            id="unitfilter"
            onChange={onTextChange}
            placeholder="Unit"
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
          />
        </div> */}
        <div className="col-md-2 pr-0 pl-0">
          <input
            className="input_styling ml-md-3"
            id="unitfilterId"
            autoComplete="off"
            defaultValue={""}
            readOnly
            onMouseDown={(e) => {
              e.target.removeAttribute("readOnly");
            }}
            placeholder="Unit"
            list="unitfilterIdOpt"
            onChange={onTextChangeUnit}
          />
          <datalist id="unitfilterIdOpt">
            {allUnits?.map((x, idx) => {
              return (
                <option index={idx} value={x.UnitName}>
                  {x.UnitId}
                </option>
              );
            })}
          </datalist>
        </div>
        <div className="col-md-1 pr-0 pl-0">
          <CommissionReceiptModal />
        </div>
        <div className="col-md-1 pr-0 pl-0">
          {resultsrrtable.loading || loading ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </div>
      </div>

      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0"></div>
        <div className="col-md-4   pr-0 pl-0">
          <label className="input_label m-0">From:</label>
          <input type="date" className="input_date ml-3" id="fromdate"></input>
        </div>
        <div className="col-md-4  pr-0 pl-0">
          <label className="input_label m-0">To:</label>
          <input type="date" className="input_date ml-3" id="todate"></input>
        </div>
        <div className="col-md-1 pr-0 pl-0">
          <button className="btn_Go" onClick={ongo}>
            Go
          </button>
        </div>
      </div>

      <div className="table-responsive vouchers_table_height mt-4">
        <table className="table table-borderless m-0">
          <thead>
            <tr>
              <th>Sr</th>
              <th>Date</th>
              <th>Added On</th>
              <th>Voucher No.</th>
              <th>Seller</th>
              <th>Unit</th>
              <th>Amount</th>
              <th className="text-center">
                {rolesdata?.data?.Access?.includes("C") ? (
                  <button
                    className="save_btn"
                    onClick={() => {
                      props.showcommissiontable();
                    }}
                  >
                    New+
                  </button>
                ) : null}
              </th>
            </tr>
          </thead>
          <tbody>
            {filterdata !== undefined
              ? filterdata.map((val, index) => {
                  let year = val.EntryDate.slice(0, 4);
                  let day = val.EntryDate.slice(8, 10);
                  let month = val.EntryDate.slice(5, 7);
                  switch (month) {
                    case "01":
                      month = "Jan";

                      break;
                    case "02":
                      month = "Feb";

                      break;
                    case "03":
                      month = "Mar";

                      break;
                    case "04":
                      month = "Apr";

                      break;
                    case "05":
                      month = "May";

                      break;
                    case "06":
                      month = "Jun";

                      break;
                    case "07":
                      month = "Jul";

                      break;
                    case "08":
                      month = "Aug";

                      break;
                    case "09":
                      month = "Sep";

                      break;
                    case "10":
                      month = "Oct";
                      break;
                    case "11":
                      month = "Nov";
                      break;
                    case "12":
                      month = "Dec";
                      break;
                    default:
                      break;
                  }
                  return (
                    <tr key={index}>
                      <td
                        className="table_data"
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {index +
                          1 +
                          (allLinks?.PageNumber - 1) * allLinks?.PageSize}
                      </td>
                      <td
                        className="table_data"
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {day + "-" + month + "-" + year}
                      </td>
                      <td
                        className="table_data"
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {moment(
                          val.Transaction[0]?.AddedOn?.slice(0, 10),
                          "YYYY:MM-DD"
                        )?.format("DD-MMM-YYYY")}
                      </td>

                      <td
                        className="table_data"
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {val.CommissionReceiptVoucherId}
                      </td>
                      {val.Seller !== undefined ? (
                        <td
                          className="table_data"
                          onClick={() => {
                            showmodal(val.Transaction);
                            SetPrintDataModal(val.Transaction);
                          }}
                        >
                          {val.Seller.SellerName}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      {/* {val.Project !== undefined && val.Project !== null ?
                                      <td>{val.Project.ProjectName}</td> : <td></td>} */}
                      {val.Unit !== undefined ? (
                        <td
                          className="table_data"
                          onClick={() => {
                            showmodal(val.Transaction);
                            SetPrintDataModal(val.Transaction);
                          }}
                        >
                          {val.Unit.UnitName}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td
                        className="table_data"
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {parseFloat(val.Amount).toLocaleString()}
                      </td>

                      <td className="text-center cur_sor">
                        <GrPrint
                          size="1em"
                          onClick={() => {
                            SetPrintData(val);
                            settoggle(!statetoggle);
                          }}
                        />
                        &nbsp;
                        {localStorage.getItem("Designation") === "Admin" ? (
                          <MdDeleteForever
                            onClick={() => {
                              setSelecetdVoucher(val);
                              document.getElementById(
                                "DeleteVoucherModal"
                              ).style.display = "block";
                            }}
                            title="Delete"
                            className="cur_sor"
                            size="1em"
                          />
                        ) : null}
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
      <div className="col-12  text-right pr-0 pl-0">
        <button
          disabled={allLinks?.LastPage ? false : true}
          className="print_btn float-right mx-1"
          onClick={() => getPaginationData(allLinks?.LastPage)}
        >
          Last
        </button>
        <button
          disabled={allLinks?.PreviousPage ? false : true}
          className="print_btn float-right mx-1"
          onClick={() => getPaginationData(allLinks?.PreviousPage)}
        >
          Previous
        </button>
        <button
          disabled={allLinks?.NextPage ? false : true}
          className="print_btn float-right mx-1"
          onClick={() => getPaginationData(allLinks?.NextPage)}
        >
          Next
        </button>
        <button
          disabled={allLinks?.FirstPage ? false : true}
          className="print_btn float-right pl-1 mx-1"
          onClick={() => getPaginationData(allLinks?.FirstPage)}
        >
          First
        </button>
      </div>
      <button
        style={{ color: "black !important" }}
        className="print_btn ml-2 float-left"
        onClick={() => handleDownload("CRV")}
      >
        Download CSV
      </button>
      <CSVLink
        ref={csvLinkRef}
        style={{ display: "none" }} // Hide the link element
        filename="Commission_Receipt_Csv"
        data={CsvData}
      >
        Download CSV
      </CSVLink>
      <div id="mymodal" class="modal">
        <div class="modal-content-cat_voucher">
          <div className="row">
            <div className="col-12  page_heading">
              <h4 className="  text-center pt-2">Transaction Detail</h4>
              <span class="close pr-2  " onClick={closemodal}>
                &times;
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-2">
              <div className="table-responsive_modal mt-4 table_height-voucher">
                <table className="table table-borderless m-0 ">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Submit On</th>
                      <th>Account</th>
                      <th>Narration</th>
                      <th>Debit</th>

                      <th>Credit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {TransactionData !== undefined
                      ? TransactionData.map((x, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                              {moment(x.EntryDate?.slice(0, 10)).format(
                                "DD-MMM-YYYY"
                              )}
                            </td>
                            <td>{x.Account.AccountTitle}</td>
                            <td>{x.Narration}</td>
                            <td>
                              {x.TransactionType === "DEBIT"
                                ? x.Amount.toLocaleString()
                                : null}
                            </td>

                            <td>
                              {x.TransactionType === "CREDIT"
                                ? x.Amount.toLocaleString()
                                : null}
                            </td>
                          </tr>
                        ))
                      : null}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>

                      <td>
                        {" "}
                        <label className="input_label m-0">Total:</label>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="input_total_styling "
                          placeholder="Debit"
                          disabled
                          defaultValue={parseFloat(debitState).toLocaleString()}
                          key={debitState}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="input_total_styling "
                          placeholder="Credit"
                          disabled
                          defaultValue={parseFloat(
                            creditState
                          ).toLocaleString()}
                          key={creditState}
                        ></input>
                      </td>
                      {/* <td><input type="text" className="input_total_styling " placeholder="Balance" disabled value={stateledgerdata[stateledgerdata.length - 1]?.AccountBalance?.toLocaleString()}></input></td> */}
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 mt-2">
              <button
                className="print_btn float-left"
                onClick={() => {
                  printGenrealRecModal();
                }}
              >
                <TiPrinter size="1.3em" />
                Print
              </button>
            </div>
          </div>
        </div>
      </div>

      <CommossionReceiptDeleteVoucherModal
        setSelecetdVoucher={setSelecetdVoucher}
        DeleteVoucherData={DeleteVoucherData}
        handelDeleteVoucherdataChange={handelDeleteVoucherdataChange}
        closeDeleteVoucherModal={closeDeleteVoucherModal}
        showPassword={showPassword}
        setshowPassword={setshowPassword}
        loading={loading}
        deleteVoucher={deleteVoucher}
      />
    </>
  );
};
