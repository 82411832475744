import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getallProject } from "../../../actions/getprojects";
import { Country, State, City } from "country-state-city";
import Loader from "react-loader-spinner";

export const AffiliateForm = (props) => {
  const affiliatesdatastate = useSelector((state) => state.getAffliateEdit);
  const resultsproject = useSelector((state) => state.getProject);
  const [toggle, settoggle] = useState(false);
  const [allprojectname, setallprojectname] = useState([]);
  const [allCountries, setallCountries] = useState([]);
  const [allStates, setallStates] = useState([]);
  const [allCity, setallCity] = useState([]);
  const [selectedCountries, setselectedCountries] = useState(null);
  const [selectedStates, setselectedStates] = useState(null);
  const [selectedCity, setselectedCity] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const data = Country?.getAllCountries()?.map((country) => ({
      value: country.name,
      displayValue: `${country.name}-${country.isoCode}`,
      isoCode: country.isoCode,
    }));
    setallCountries(data);
  }, [Country]);
  useEffect(() => {
    const data = State.getStatesOfCountry(
      selectedCountries?.split("-")[1]
    )?.map((state) => ({
      value: state.name,
      displayValue: `${state.name}-${state.isoCode}`,
    }));
    setallStates(data);
  }, [selectedCountries]);
  useEffect(() => {
    const data = City.getCitiesOfState(
      selectedCountries?.split("-")[1],
      selectedStates?.split("-")[1]
    ).map((city) => ({
      value: city.name,
      displayValue: city.name,
    }));
    console.log(data);
    setallCity(data);
  }, [selectedCountries, selectedStates]);
  let dispatch = useDispatch();

  let populatedata = {};
  const [individualcheck, setindividualcheck] = useState(true);

  if (
    affiliatesdatastate.affiliatesdata !== undefined ||
    affiliatesdatastate.affiliatesdata !== null
  ) {
    populatedata = affiliatesdatastate.affiliatesdata;
  } else {
    populatedata = affiliatesdatastate;
  }

  // function emptyfields() {
  //     document.getElementById("Country").selectedIndex = 0;
  //     document.getElementById("City").selectedIndex = 0;
  //     // document.getElementById("affiliatetype").selectedIndex = 0;
  //     document.getElementById("Phone").value = "";
  //     document.getElementById("Address").value = "";

  //     document.getElementById("creditorName").value = "";
  //     document.getElementById("CNIC").value = "";
  //     document.getElementById("Cell1").value = "";
  //     document.getElementById("Cell2").value = "";
  //     document.getElementById("Email").value = "";

  // }
  function addAffiliates() {
    let regexp = new RegExp("^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$");
    let regexphone = new RegExp("^[0-9+]{4}-[0-9+]{7}$");
    let regexptcl = new RegExp("^[0-9+]{3}-[0-9+]{7}$");

    let regexcompany = new RegExp("^[0-9+]{7}$");
    let check =
      document.getElementById("CNIC") !== null
        ? document.getElementById("CNIC").value
        : null;
    let checkcell1 = document.getElementById("Cell1").value;
    let checkcell2 = document.getElementById("Cell2").value;
    let checkptcl = document.getElementById("Phone").value;
    let checkcompanyreg =
      document.getElementById("Companyregistration") !== null
        ? document.getElementById("Companyregistration").value
        : null;

    if (document.getElementById("creditorName").value === "") {
      toast.info("Name is required");
    } else if (
      document.getElementById("CNIC") !== null
        ? document.getElementById("CNIC").value === ""
        : null
    ) {
      toast.info("CNIC is required");
    } else if (
      document.getElementById("Companyregistration") !== null
        ? document.getElementById("Companyregistration").value === ""
        : null
    ) {
      toast.info("Company registration is required");
    } else if (
      !regexp.test(check) && document.getElementById("CNIC") !== null
        ? document.getElementById("CNIC").value !== ""
        : null
    ) {
      toast.info("Invalid CNIC. Format should be like xxxxx-xxxxxxx-x");
    } else if (
      !regexcompany.test(checkcompanyreg) &&
      document.getElementById("Companyregistration") !== null
        ? document.getElementById("Companyregistration").value !== ""
        : null
    ) {
      toast.info("Invalid Company Reg No. Should contain 7 digits");
    }

    // else if (document.getElementById("CNIC").value === "") { toast.info("CNIC is required") }
    // else if (!regexp.test(check)) {
    //     toast.info('Invalid CNIC. Format should be like xxxxx-xxxxxxx-x');

    // }
    else if (document.getElementById("Address").value === "") {
      toast.info("Address is required");
    } else if (
      !regexptcl.test(checkptcl) &&
      document.getElementById("Phone").value !== ""
    ) {
      toast.info("Invalid Phone. Format should be like xxx-xxxxxxx");
    } else if (document.getElementById("Cell1").value === "") {
      toast.info("cell no.1  is required");
    } else if (!regexphone.test(checkcell1)) {
      toast.info("Invalid Cell No. 1 Format should be like xxxx-xxxxxxx");
    } else if (
      !regexphone.test(checkcell2) &&
      document.getElementById("Cell2").value !== ""
    ) {
      toast.info("Invalid Cell No. 2 Format should be like xxxx-xxxxxxx");
    } else if (selectedCountries === null || selectedCountries === "Select") {
      toast.info("Select any country");
    } else if (selectedStates === null || selectedStates === "Select") {
      toast.info("Select any state");
    } else if (selectedCity === null || selectedCity === "Select") {
      toast.info("Select any city");
    }
    // else if (document.getElementById("affiliatetype").selectedIndex === 0) { toast.info("Select Affiliate Type") }
    else {
      setIsLoading(true);
      let Projects = [];
      allprojectname.forEach((x, index) => {
        Projects.push({
          ProjectId: x.ProjectId,
          ProjectName: x.ProjectName,
        });
      });

      var Affiliate = affiliatesdatastate;

      // Affiliate.LandCreditorId = populatedata.LandCreditorId;
      // Affiliate.AccountId = populatedata.AccountId;
      Affiliate.AffiliateName = document.getElementById("creditorName").value;
      Affiliate.AffiliateAddress = document.getElementById("Address").value;
      // Affiliate.AffiliateCnic = document.getElementById("CNIC").value;
      Affiliate.AffiliateCnic =
        individualcheck === true
          ? document.getElementById("CNIC").value
          : document.getElementById("Companyregistration").value;

      Affiliate.AffiliateContactNo1 = document.getElementById("Cell1").value;
      Affiliate.AffiliateContactNo2 = document.getElementById("Cell2").value;
      Affiliate.AffiliatePhoneNumber = document.getElementById("Phone").value;
      Affiliate.AffiliateEmail = document.getElementById("Email").value;
      Affiliate.AffiliateType =
        individualcheck === true ? "INDIVIDUAL" : "COMPANY";

      // Affiliate.AffiliateType = document.getElementById(
      //     "affiliatetype"
      // ).options[
      //     document.getElementById("affiliatetype").selectedIndex
      // ].value
      Affiliate.AffiliateCity = selectedCity;
      Affiliate.AffiliateCountry = selectedCountries;
      Affiliate.State = selectedStates;

      document.getElementById("creditorsave").disabled = true;
      axios({
        url: `${process.env.REACT_APP_API_URL}Affiliates/Add`,
        method: "Post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
        data: JSON.stringify({
          Affiliate,
          Projects,
        }),
      }).then(
        (result) => {
          document.getElementById("creditorsave").disabled = false;

          if (result.data.IsSuccess === true) {
            setIsLoading(false);
            // emptyfields();

            toast.info(result.data.Message);

            props.cancelaffiliatestable();
            props.setedit("noedit");
          } else {
            setIsLoading(false);
            toast.info(result.data.Message);
            props.setedit("noedit");
          }
        },
        (error) => {
          toast.info("Something went wrong!");
          document.getElementById("creditorsave").disabled = false;
          props.setedit("noedit");
        }
      );
    }
  }
  function addtoarray() {
    let check = 0;

    allprojectname.forEach((x) => {
      if (
        parseInt(x.ProjectId) ===
        parseInt(
          document.getElementById("projectname").options[
            document.getElementById("projectname").selectedIndex
          ].dataset.id
        )
      ) {
        toast.info("Multiple Allowances cannot be added!");
        check = 1;
      } else {
        check = 0;
      }
    });
    if (document.getElementById("projectname").selectedIndex === 0) {
      toast.info("Select Allowance");
    } else if (check === 1) {
      toast.info("Select Other Project");
    } else {
      let listofallprojectname = {
        ProjectId: parseInt(
          document.getElementById("projectname").options[
            document.getElementById("projectname").selectedIndex
          ].dataset.id
        ),
        ProjectName:
          document.getElementById("projectname").options[
            document.getElementById("projectname").selectedIndex
          ].value,
      };

      setallprojectname([...allprojectname, listofallprojectname]);
      document.getElementById("projectname").selectedIndex = 0;
    }
  }
  const deleteinstallment = (idx) => {
    const rows = [...allprojectname];

    rows.splice(idx, 1);

    setallprojectname(rows);
  };
  useEffect(() => {
    {
      populatedata.AffiliateType === "INDIVIDUAL"
        ? setindividualcheck(true)
        : setindividualcheck(false);
    }
  }, [populatedata.AffiliateType]);
  useEffect(() => {
    dispatch(getallProject());
  }, [dispatch]);
  console.log(populatedata);
  useEffect(() => {
    if (
      populatedata.AffiliateCountry !== null &&
      populatedata.AffiliateCountry !== undefined
    ) {
      setselectedCountries(populatedata.AffiliateCountry);
    }
    if (populatedata.State !== null && populatedata.State !== undefined) {
      setselectedStates(populatedata.State);
    }
    if (
      populatedata.AffiliateCity !== null &&
      populatedata.AffiliateCity !== undefined
    ) {
      setselectedCity(populatedata.AffiliateCity);
    }
  }, [populatedata]);
  return (
    <>
      <div className="row ">
        <div className="col-md-12  text-left page_heading">
          <h4 className="m-0">Add Affiliates</h4>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-4 mt-2 text-right">
          <span className="txt input_label"> Individual</span>
        </div>
        <div className="col-md-1 mt-2 text-left">
          <span>
            {" "}
            <input
              type="radio"
              id="Investment"
              name="InvestmentType"
              checked={individualcheck}
              onClick={() => {
                // document.getElementById("showhide").style.display = "none";
                // document.getElementById("showhide2").style.display = "block";
                // document.getElementById("cityshowhide").style.display = "block";
                setindividualcheck(true);
              }}
            ></input>
          </span>
        </div>
        <div className="col-md-4 mt-2 text-right">
          <span className="txt input_label"> Company</span>
        </div>
        <div className="col-md-1 mt-2 text-left">
          <span>
            {" "}
            <input
              type="radio"
              name="existingType"
              id="existingproject"
              checked={!individualcheck}
              onClick={() => {
                setindividualcheck(false);
                // document.getElementById("showhide2").style.display = "none";
                // document.getElementById("showhide").style.display = "block";
              }}
            ></input>
          </span>
        </div>
      </div>

      {props.isedit == "noedit" ? (
        <div className="row ">
          <div className="col-md-2 mt-md-4  text-left p-0">
            <label className="input_label m-0 ">Project Name :</label>
          </div>
          <div className="col-md-3 mt-md-4  p-0">
            <select
              id="projectname"
              className="input_styling  ml-md-3"
              onChange={(e) => {
                if (e.target.value !== "Select") {
                  // setprojectid(document.getElementById("projectname").options[document.getElementById("projectname").selectedIndex].dataset.id);
                }
              }}
            >
              <option>Select</option>
              {resultsproject.data.map((x, index) => (
                <option data-id={x.ProjectId} data-idx={index} key={index}>
                  {x.ProjectName}
                </option>
              ))}
            </select>
            <span className="estaric">*</span>
          </div>
          <div className="col-md-2 mt-md-4   p-0">
            <button className="save_btn" onClick={addtoarray}>
              Add
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Name :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3"
            id="creditorName"
            defaultValue={populatedata.AffiliateName}
          />
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Email</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="email"
            className="input_styling ml-md-3 "
            id="Email"
            defaultValue={populatedata.AffiliateEmail}
          />
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          {populatedata.AffiliateType === "INDIVIDUAL" ||
          populatedata.AffiliateType === "COMPANY" ? (
            <label className="input_label m-0">
              {populatedata.AffiliateType === "INDIVIDUAL"
                ? "CNIC"
                : "Company Reg"}
            </label>
          ) : (
            <label className="input_label m-0">
              {individualcheck === true ? "CNIC" : "Company Reg"}
            </label>
          )}
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          {/* <input type="text" className="input_styling ml-md-3 " id="CNIC" placeholder="xxxxx-xxxxxxx-x" defaultValue={populatedata.AffiliateCnic} /><span className="estaric">*</span> */}
          {individualcheck === true ? (
            <>
              <input
                type="text"
                className="input_styling ml-md-3 "
                id="CNIC"
                placeholder="xxxxx-xxxxxxx-x"
                defaultValue={populatedata.AffiliateCnic}
                maxLength={15}
                onKeyDown={(e) => {
                  if (e.key === "Backspace") {
                    document.getElementById("CNIC").value = "";
                  }
                }}
                onChange={(e) => {
                  if (
                    e.target.value?.length === 5 ||
                    e.target.value?.length === 13
                  ) {
                    document.getElementById("CNIC").value =
                      e.target.value + "-";
                  }
                }}
              />
              <span className="estaric">*</span>
            </>
          ) : (
            <>
              <input
                type="text"
                className="input_styling ml-md-3 "
                id="Companyregistration"
                placeholder="xxxxxxx"
                defaultValue={populatedata.AffiliateCnic}
              />
              <span className="estaric">*</span>
            </>
          )}
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Phone No </label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="Phone"
            placeholder="xxx-xxxxxxx"
            defaultValue={populatedata.AffiliatePhoneNumber}
            maxLength={12}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                document.getElementById("Phone").value = "";
              }
            }}
            onChange={(e) => {
              if (e.target.value?.length === 3) {
                document.getElementById("Phone").value = e.target.value + "-";
              }
            }}
          />
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Country</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <select
            id="AffiliateCountry"
            onChange={(e) => {
              setselectedCountries(e.target.value);
            }}
            className="input_styling ml-md-3"
            name="country"
            value={selectedCountries}
          >
            <option selected>Select</option>
            {allCountries?.map((item, i) => {
              return (
                <>
                  <option key={i} value={item?.displayValue?.toUpperCase()}>
                    {item?.displayValue?.toUpperCase()}
                  </option>
                </>
              );
            })}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">States</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <select
            className="input_styling ml-md-3"
            id="AffiliateState"
            onChange={(e) => {
              setselectedStates(e.target.value);
            }}
            value={selectedStates}
          >
            <option selected>Select</option>
            {allStates?.map((item, i) => {
              return (
                <>
                  <option key={i} value={item?.displayValue?.toUpperCase()}>
                    {item?.displayValue?.toUpperCase()}
                  </option>
                </>
              );
            })}
          </select>
          <span className="estaric">*</span>
        </div>
      </div>

      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">City</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <select
            className="input_styling ml-md-3"
            id="AffiliateCity"
            onChange={(e) => {
              setselectedCity(e.target.value);
            }}
            value={selectedCity}
          >
            <option selected>Select</option>
            {allCity?.map((item, i) => {
              return (
                <>
                  <option key={i} value={item?.displayValue?.toUpperCase()}>
                    {item?.displayValue?.toUpperCase()}
                  </option>
                </>
              );
            })}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Cell No 1:</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3"
            id="Cell1"
            placeholder="xxxx-xxxxxxx"
            defaultValue={populatedata.AffiliateContactNo1}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                document.getElementById("Cell1").value = "";
              }
            }}
            maxLength={12}
            onChange={(e) => {
              if (e.target.value?.length === 4) {
                document.getElementById("Cell1").value = e.target.value + "-";
              }
            }}
          />
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Cell No 2: </label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="Cell2"
            placeholder="xxxx-xxxxxxx"
            defaultValue={populatedata.AffiliateContactNo2}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                document.getElementById("Cell2").value = "";
              }
            }}
            maxLength={12}
            onChange={(e) => {
              if (e.target.value?.length === 4) {
                document.getElementById("Cell2").value = e.target.value + "-";
              }
            }}
          />
        </div>

        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Address:</label>
        </div>
        <div className="col-md-3 mt-4   p-0">
          <textarea
            className="input_address ml-md-3 "
            id="Address"
            defaultValue={populatedata.AffiliateAddress}
          ></textarea>
          <span className="estaric">*</span>
        </div>
      </div>
      {props.isedit == "noedit" ? (
        <div className="mt-4">
          <div className="table-responsive mt-4">
            <table className="table table-borderless m-0">
              <thead>
                <tr>
                  <th>Project Id </th>

                  <th>Project Name</th>
                  <th>Dell</th>
                </tr>
              </thead>
              <tbody>
                {allprojectname.map((val, index) => (
                  <tr key={index}>
                    <td>{val.ProjectId}</td>
                    <td>{val.ProjectName}</td>

                    <td
                      onClick={() => {
                        deleteinstallment(index);
                        settoggle(!toggle);
                      }}
                    >
                      <MdDeleteForever size="1.6em" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="row pr-md-2 pt-md-4">
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
          className=" col-12 pr-0 pl-0  text-right "
        >
          <span>
            <button
              className="cancel_btn ml-2  "
              onClick={() => {
                props.cancelaffiliatestable();
                props.setedit("noedit");
              }}
            >
              Cancel
            </button>
          </span>
          <span className="ml-2">
            {isLoading ? (
              <>
                <Loader
                  type="Oval"
                  color="black"
                  height={30}
                  width={30}
                  radius={12}
                />
              </>
            ) : null}
          </span>
          <span>
            <button
              className="save_btn  ml-2"
              id="creditorsave"
              onClick={addAffiliates}
            >
              <BsCloudUpload size="1.3em" />
              Save
            </button>
          </span>
        </div>
      </div>
    </>
  );
};
