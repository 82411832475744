import axios from "axios";
import { toast } from "react-toastify";

export function updateVoucherPrintDate(voucherId, openPrint) {
  let url = `${process.env.REACT_APP_API_URL}Vouchers/General/UpdateVoucherPrintDate?voucherId=${voucherId}`;

  return () => {
    axios({
      url: url,
      method: "Post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log("updateVoucherPrintDate", result);
        if (result.data.IsSuccess === true) {
          openPrint();
        } else {
          toast.error(result.data.Message);
        }
      },
      (error) => {
        console.log("updateVoucherPrintDate", error);
        toast.error("Something went wrong!");
      }
    );
  };
}
