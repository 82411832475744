import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { GrPrint } from "react-icons/gr";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getallrecoverypopulatetable } from "../../../actions/vouchersactions/getloanpayment";
import { LoanPaymentPrint } from "../../accpayablepages/vouchersprints/paymentprint";
import InvestPaymentTransactionDetailModal from "./InvestPaymentTransactionDetailModal";
import { MdDeleteForever } from "react-icons/md";
import axios from "axios";
import { toast } from "react-toastify";
import InvestPaymentDeleteVoucherModal from "./InvestPaymentDeleteVoucherModal";
import { CSVLink } from "react-csv";
import InvestPaymentModal from "./InvestPaymentModal";
import moment from "moment";

export const InvestPaymentTable = (props) => {
  const resultsloanpaytable = useSelector((state) => state.getLoanPaymentTable);
  const [TransactionData, setTransactionData] = useState();
  const dispatch = useDispatch();
  const [filterdata, setfilterdata] = useState();
  const [statetoggle, settoggle] = useState(true);
  const [printData, SetPrintData] = useState();
  const rolesdata = useSelector((state) => state.GetAllRole);

  const [debitState, setdebitState] = useState(0);
  const [creditState, setcreditState] = useState(0);
  const [rendarComp, setrendarComp] = useState(false);
  const [selectedVouchers, setSelectedVouchers] = useState([]);
  const [loading, setloading] = useState(false);
  const [DeleteVoucherData, setDeleteVoucherData] = useState({
    Email: "",
    Password: "",
    Reason: "",
  });
  // print function
  const componentRef = useRef();

  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  const [printDataModal, SetPrintDataModal] = useState();
  // print function

  useEffect(() => {
    if (printData !== undefined) {
      printPaymentRec();
    }
  }, [printData, statetoggle]);

  const onTextChange = (e) => {
    if (
      document.getElementById("loanfilter").value === "" &&
      document.getElementById("datefilter").value === "" &&
      document.getElementById("voucherfilter").value === "" &&
      document.getElementById("lenderfilter").value === ""
    ) {
      setfilterdata(resultsloanpaytable.data);
    } else {
      let suggestions = [];
      {
        const regex = new RegExp(
          document.getElementById("loanfilter").value,
          "i"
        );
        const regex1 = new RegExp(
          document.getElementById("voucherfilter").value,
          "i"
        );
        const regex2 = new RegExp(
          document.getElementById("datefilter").value,
          "i"
        );
        const regex3 = new RegExp(
          document.getElementById("lenderfilter").value,
          "i"
        );

        suggestions = resultsloanpaytable.data
          .sort()
          .filter(
            (val) =>
              (document.getElementById("loanfilter").value === "" ||
                regex.test(val.Loan.LoanName)) &&
              (document.getElementById("voucherfilter").value === "" ||
                regex1.test(val.LoanPaymentVoucherId)) &&
              (document.getElementById("datefilter").value === "" ||
                regex2.test(val.EntryDate)) &&
              (document.getElementById("lenderfilter").value === "" ||
                regex3.test(val.Lender.LenderName))
          );
      }
      setfilterdata(suggestions);
    }
  };
  function showmodal(data) {
    document.getElementById("mymodal").style.display = "block";
    setTransactionData(data);
    var credit = 0;
    var debit = 0;
    data?.map((val) =>
      val.TransactionType === "CREDIT"
        ? (credit += parseFloat(val.Amount))
        : val.TransactionType === "DEBIT"
        ? (debit += parseFloat(val.Amount))
        : null
    );
    setdebitState(debit);
    setcreditState(credit);
  }
  function closemodal() {
    document.getElementById("mymodal").style.display = "none";
  }
  useEffect(() => {
    dispatch(getallrecoverypopulatetable({}));
  }, [dispatch]);
  function ongo() {
    let data = {
      ProjectId: `${localStorage.getItem("ProjectId")}`,
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
    };

    dispatch(getallrecoverypopulatetable(data));
  }
  useEffect(() => {
    setfilterdata(resultsloanpaytable.data);
  }, [resultsloanpaytable]);

  function handleCheckboxChange() {
    let arr = filterdata
      ?.filter((itm) => itm.checked)
      ?.map((itm) => itm.LoanPaymentVoucherId);
    setSelectedVouchers(arr);
  }

  function showmodal2() {
    document.getElementById("mymodal2").style.display = "block";
  }

  function closemodal2() {
    document.getElementById("mymodal2").style.display = "none";
    setDeleteVoucherData({
      Email: "",
      Password: "",
      Reason: "",
    });
  }

  const handelDeleteVoucherDataChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setDeleteVoucherData({ ...DeleteVoucherData, [name]: value });
  };

  function deletegeneralPaymentVoucher() {
    if (DeleteVoucherData?.Email === "") {
      return toast.info("Email is Required");
    } else if (DeleteVoucherData?.Password === "") {
      return toast.info("Password is Required");
    } else if (DeleteVoucherData?.Reason === "") {
      return toast.info("Reason is Required");
    }
    setloading(true);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}Scripts/DeleteAllUnsubmittedVouchers`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
      data: {
        Email: DeleteVoucherData?.Email,
        Password: DeleteVoucherData?.Password,
        Reason: DeleteVoucherData?.Reason,
        voucherId: selectedVouchers,
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          setDeleteVoucherData({
            Email: "",
            Password: "",
            Reason: "",
          });
          toast.info(result.data.Message);
          setDeleteVoucherData({
            Email: "",
            Password: "",
            Reason: "",
          });
          setSelectedVouchers([]);
          dispatch(getallrecoverypopulatetable({}));
          setloading(false);
          closemodal2();
        } else {
          toast.info(result.data.Message);
          setloading(false);
        }
      },
      (error) => {
        toast.info("Someting went wrong");
      }
    );
  }

  const [CsvData, setCsvData] = useState([]);
  useEffect(() => {
    let arr = [];
    var i = 1;

    filterdata?.forEach((val) => {
      let year = val.EntryDate.slice(0, 4);
      let day = val.EntryDate.slice(8, 10);
      let month = val.EntryDate.slice(5, 7);
      arr.push({
        Sr: i++,
        Date: day + "-" + month + "-" + year,
        "Voucher No": val.LoanPaymentVoucherId,
        "Invest Name":
          val.Loan !== undefined && val.Loan !== null ? val.Loan.LoanName : "",
        Investor:
          val.Lender !== undefined && val.Lender !== null
            ? val.Lender.LenderName
            : "",
        "Paid Amount": parseFloat(val.PaidAmount).toLocaleString(),
      });
    });
    setCsvData(arr);
  }, [filterdata]);

  return (
    <>
      <div hidden>
        <LoanPaymentPrint ref={componentRef} printData={printData} />
      </div>

      <div className="row pt-3">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="datefilter"
            onChange={onTextChange}
            placeholder="Date"
            autoComplete="off"
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
          ></input>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="voucherfilter"
            onChange={onTextChange}
            placeholder="Voucher"
            autoComplete="off"
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
          ></input>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="loanfilter"
            onChange={onTextChange}
            placeholder="Investment Name"
            autoComplete="off"
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
          ></input>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="lenderfilter"
            onChange={onTextChange}
            placeholder="Investor"
            autoComplete="off"
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
          ></input>
        </div>
        <div className="col-md-1 pr-0 pl-0">
          <InvestPaymentModal />
        </div>
        <div className="col-md-1 pr-0 pl-0">
          {resultsloanpaytable.loading ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </div>
      </div>

      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0"></div>
        <div className="col-md-4   pr-0 pl-0">
          <label className="input_label m-0">From:</label>
          <input type="date" className="input_date ml-3" id="fromdate"></input>
        </div>
        <div className="col-md-4  pr-0 pl-0">
          <label className="input_label m-0">To:</label>
          <input type="date" className="input_date ml-3" id="todate"></input>
        </div>
        <div className="col-md-1 pr-0 pl-0">
          <button className="btn_Go" onClick={ongo}>
            Go
          </button>
        </div>

        <div className="col-md-12 pr-0 pl-0">
          {selectedVouchers.length > 0 && (
            <div className="col-md-12 mt-2">
              <div className="d-flex justify-content-end align-items-center">
                <span>
                  <button
                    onClick={() => {
                      showmodal2();
                    }}
                    className="cancel_btn"
                  >
                    <MdDeleteForever />
                    Delete All
                  </button>
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="table-responsive vouchers_table_height mt-2">
        <table className="table table-borderless m-0">
          <thead>
            <tr>
              <th>Sr</th>
              <th>{""}</th>
              <th>Date</th>
              <th>Added On</th>
              <th>Voucher</th>
              <th>Invest Name</th>
              <th>Investor</th>
              <th>Paid Amount</th>
              <th className="text-center">
                {rolesdata?.data?.Access?.includes("C") ? (
                  <button
                    className="save_btn"
                    onClick={() => {
                      props.showloantable();
                    }}
                  >
                    New+
                  </button>
                ) : null}
              </th>
            </tr>
          </thead>
          <tbody>
            {filterdata !== undefined
              ? filterdata.map((val, index) => {
                  let year = val.EntryDate.slice(0, 4);
                  let day = val.EntryDate.slice(8, 10);
                  let month = val.EntryDate.slice(5, 7);
                  switch (month) {
                    case "01":
                      month = "Jan";

                      break;
                    case "02":
                      month = "Feb";

                      break;
                    case "03":
                      month = "Mar";

                      break;
                    case "04":
                      month = "Apr";

                      break;
                    case "05":
                      month = "May";

                      break;
                    case "06":
                      month = "Jun";

                      break;
                    case "07":
                      month = "Jul";

                      break;
                    case "08":
                      month = "Aug";

                      break;
                    case "09":
                      month = "Sep";

                      break;
                    case "10":
                      month = "Oct";
                      break;
                    case "11":
                      month = "Nov";
                      break;
                    case "12":
                      month = "Dec";
                      break;
                    default:
                      break;
                  }
                  return (
                    <tr key={index}>
                      <td
                        className="table_data"
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {index + 1}
                      </td>
                      <td>
                        {localStorage.getItem("Designation") === "Admin" ? (
                          <input
                            checked={val.checked ? val.checked : false}
                            key={index}
                            onChange={(e) => {
                              filterdata[index].checked = e.target.checked;
                              setrendarComp(!rendarComp);
                              handleCheckboxChange();
                            }}
                            type="checkbox"
                            id={`checkedVoucher${index}`}
                          />
                        ) : null}
                      </td>
                      <td
                        className="table_data"
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {day + "-" + month + "-" + year}
                      </td>
                      <td
                        className="table_data"
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {moment(
                          val.Transaction[0]?.AddedOn?.slice(0, 10),
                          "YYYY:MM-DD"
                        )?.format("DD-MMM-YYYY")}
                      </td>
                      <td
                        className="table_data"
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {val.LoanPaymentVoucherId}
                      </td>
                      {val.Loan !== undefined && val.Loan !== null ? (
                        <td
                          className="table_data"
                          onClick={() => {
                            showmodal(val.Transaction);
                            SetPrintDataModal(val.Transaction);
                          }}
                        >
                          {val.Loan.LoanName}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      {val.Lender !== undefined && val.Lender !== null ? (
                        <td
                          className="table_data"
                          onClick={() => {
                            showmodal(val.Transaction);
                            SetPrintDataModal(val.Transaction);
                          }}
                        >
                          {val.Lender.LenderName}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td
                        className="table_data"
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {parseFloat(val.PaidAmount).toLocaleString()}
                      </td>
                      <td
                        className="text-center cur_sor"
                        onClick={() => {
                          SetPrintData(val);
                          settoggle(!statetoggle);
                        }}
                      >
                        <GrPrint size="1.3em" />
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
      <button
        style={{ color: "black !important" }}
        className="print_btn ml-2 float-left"
      >
        <CSVLink
          style={{ color: "black" }}
          filename="Invest_Payment_Csv"
          data={CsvData}
        >
          Download CSV
        </CSVLink>
      </button>
      <InvestPaymentTransactionDetailModal
        debitState={debitState}
        creditState={creditState}
        printDataModal={printDataModal}
        closemodal={closemodal}
        TransactionData={TransactionData}
      />

      <InvestPaymentDeleteVoucherModal
        DeleteVoucherData={DeleteVoucherData}
        handelDeleteVoucherDataChange={handelDeleteVoucherDataChange}
        deletegeneralPaymentVoucher={deletegeneralPaymentVoucher}
        loading={loading}
        closemodal2={closemodal2}
      />
    </>
  );
};
