import moment from "moment";
import React, { Component } from "react";
// import gravitywatermark from "../../../../assets/Gravitywater.jpg";

export class ContractOfficalPrint extends Component {
  render() {
    const divStyle = {
      backgroundImage: `url(${this.props.companyInfo?.data?.Logo})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      position: "relative",
      zIndex: 0,
    };

    return (
      <div style={{ backgroundColor: "white" }}>
        <div
          style={{ minHeight: "1550px" }}
          className="row pt-0 m-0"
        >
          <div>.</div>
          <div
            className="col-12 pr-5 pl-5 pt-5  "
            style={{
              marginTop: "250px !important",
            }}
          >
            <div className="row ">
              <div className="col-12 text-center">
                {" "}
                <h2>IQRAR NAMA</h2>
              </div>
            </div>

            <div className="row pt-1">
              <div className="col-9 pt-1">
                {" "}
                <label className=" label_print">
                  <b>Contract No. </b>
                </label>
                <input
                  type="text"
                  style={{ width: "50%" }}
                  className="input_print_styling  ml-2"
                  value={
                    this.props.printData !== undefined &&
                    this.props.printData?.ContractId !== undefined
                      ? this.props.printData.ContractId
                      : null
                  }
                ></input>{" "}
              </div>
            </div>
            <header style={divStyle}>
              <div className="overlay-div"></div>
              <div className="row p-0 pt-1">
                <div
                  className="col-12 p-0 "
                  style={{ border: "1px solid black" }}
                >
                  <div className="row p-0 ">
                    <div className="col-12 pt-1">
                      {" "}
                      <label className=" label_print">
                        <b>Owner Inforamtion:</b>{" "}
                      </label>
                      {/* <input type="text" style={{ width: "87%" }} className="input_print_styling ml-2 " value={this.props.printData !== undefined && this.props.printData.CustomerCnicNavigation !== undefined ? this.props.printData.CustomerCnicNavigation.CustomerName : null}></input> */}
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-5 pt-1">
                      {" "}
                      <label className=" label_print">
                        Name:{" "}
                        {this.props.printData !== undefined &&
                        this.props.printData.CustomerCnicNavigation !==
                          undefined
                          ? this.props.printData.CustomerCnicNavigation
                              .CustomerName
                          : null}
                      </label>
                      {/* <input type="text" style={{ width: "87%" }} className="input_print_styling ml-2 " value={this.props.printData !== undefined && this.props.printData.CustomerCnicNavigation !== undefined ? this.props.printData.CustomerCnicNavigation.CustomerName : null}></input> */}
                    </div>

                    <div className="col-7 pt-1">
                      {" "}
                      <label className=" label_print">
                        Father/Husb:
                        {this.props.printData !== undefined &&
                        this.props.printData.CustomerCnicNavigation !==
                          undefined
                          ? this.props.printData.CustomerCnicNavigation
                              .GuardianName
                          : null}{" "}
                      </label>
                      {/* <input type="text" style={{ width: "83%" }} className="input_print_styling ml-2 " value={this.props.printData !== undefined && this.props.printData.EntryDate !== undefined ? moment(this.props.printData.EntryDate.split("T")[0]).format("DD-MMM-YYYY") : null}></input> */}
                    </div>
                    <div className="col-5 pt-1">
                      {" "}
                      <label className=" label_print">
                        CNIC:{" "}
                        {this.props.printData !== undefined &&
                        this.props.printData.CustomerCnicNavigation !==
                          undefined
                          ? this.props.printData.CustomerCnicNavigation
                              .CustomerCnic2
                          : null}{" "}
                      </label>
                    </div>
                    <div className="col-7 pt-1">
                      {" "}
                      <label className=" label_print">
                        Ph:{" "}
                        {this.props.printData !== undefined &&
                        this.props.printData.CustomerCnicNavigation !==
                          undefined
                          ? this.props.printData.CustomerCnicNavigation
                              .ContactNumber1
                          : null}{" "}
                      </label>
                    </div>
                    <div className="col-12 pt-1">
                      {" "}
                      <label className="">
                        {" "}
                        <span className=" label_print ">Address:</span>{" "}
                        <span style={{ fontSize: "1.2em" }}>
                          {this.props.printData !== undefined &&
                          this.props.printData.CustomerCnicNavigation !==
                            undefined
                            ? this.props.printData.CustomerCnicNavigation
                                .CustomerAddress
                            : null}
                        </span>{" "}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-0 pt-2">
                <div
                  className="col-12 p-0 "
                  style={{ border: "1px solid black" }}
                >
                  <div className="row p-0 ">
                    <div className="col-12 pt-1">
                      {" "}
                      <label className=" label_print">
                        <b>Nominee Inforamtion:</b>{" "}
                      </label>
                      {/* <input type="text" style={{ width: "87%" }} className="input_print_styling ml-2 " value={this.props.printData !== undefined && this.props.printData.CustomerCnicNavigation !== undefined ? this.props.printData.CustomerCnicNavigation.CustomerName : null}></input> */}
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-5 pt-1">
                      {" "}
                      <label className=" label_print">
                        Name:{" "}
                        {this.props.printData !== undefined &&
                        this.props.printData.Nominee !== undefined
                          ? this.props.printData.Nominee.NomineeName
                          : null}
                      </label>
                      {/* <input type="text" style={{ width: "87%" }} className="input_print_styling ml-2 " value={this.props.printData !== undefined && this.props.printData.Nominee !== undefined ? this.props.printData.Nominee.CustomerName : null}></input> */}
                    </div>

                    <div className="col-7 pt-1">
                      {" "}
                      <label className=" label_print">
                        Father/Husb:
                        {this.props.printData !== undefined &&
                        this.props.printData.Nominee !== undefined
                          ? this.props.printData.Nominee.NomineeGuardianName
                          : null}{" "}
                      </label>
                      {/* <input type="text" style={{ width: "83%" }} className="input_print_styling ml-2 " value={this.props.printData !== undefined && this.props.printData.EntryDate !== undefined ? moment(this.props.printData.EntryDate.split("T")[0]).format("DD-MMM-YYYY") : null}></input> */}
                    </div>
                    <div className="col-5 pt-1">
                      {" "}
                      <label className=" label_print">
                        CNIC:{" "}
                        {this.props.printData !== undefined &&
                        this.props.printData.Nominee !== undefined
                          ? this.props.printData.Nominee.NomineeCnic
                          : null}{" "}
                      </label>
                    </div>
                    <div className="col-7 pt-1">
                      {" "}
                      <label className=" label_print">
                        Ph:{" "}
                        {this.props.printData !== undefined &&
                        this.props.printData.Nominee !== undefined
                          ? this.props.printData.Nominee.NomineeCellNoOne
                          : null}{" "}
                      </label>
                    </div>
                    <div className="col- pt-1">
                      {" "}
                      <label>
                        {" "}
                        <span className=" label_print pl-3">Address:</span>{" "}
                        <span style={{ fontSize: "1.2em" }}>
                          {this.props.printData !== undefined &&
                          this.props.printData.Nominee !== undefined
                            ? this.props.printData.Nominee.NomineeAddress
                            : null}
                        </span>{" "}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-0 pt-2">
                <div className="col-12 p-0 ">
                  <div className="row p-0 ">
                    <div
                      className="col-5 p-0 "
                      style={{ border: "1px solid black" }}
                    >
                      <div className="row p-0 ">
                        <div className="col-12 pt-1">
                          {" "}
                          <label className=" label_print">
                            <b>Property Inforamtion:</b>{" "}
                          </label>
                          {/* <input type="text" style={{ width: "87%" }} className="input_print_styling ml-2 " value={this.props.printData !== undefined && this.props.printData.CustomerCnicNavigation !== undefined ? this.props.printData.CustomerCnicNavigation.CustomerName : null}></input> */}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 pt-1">
                          {" "}
                          <label className=" label_print">
                            Name:{" "}
                            { localStorage.getItem("ProjectName")}
                          </label>
                          {/* <input type="text" style={{ width: "87%" }} className="input_print_styling ml-2 " value={this.props.printData !== undefined && this.props.printData.CustomerCnicNavigation !== undefined ? this.props.printData.CustomerCnicNavigation.CustomerName : null}></input> */}
                        </div>
                        <div className="col-12 pt-1">
                          {" "}
                          <label className=" label_print">
                            <span className="label_print p-0">Address: </span>
                            <span style={{ fontSize: "0.8em" }}>
                              {this.props.printData !== undefined &&
                              this.props.printData.Project !== undefined
                                ? this.props.printData.Project.ProjectLocation
                                : null}
                            </span>
                          </label>
                        </div>
                        <div className="col-12 pt-1">
                          {" "}
                          <label className=" label_print">
                            Kh No:
                            {this.props.printData !== undefined &&
                            this.props.printData.Unit !== undefined &&
                            this.props.printData.Unit.LandPurchase !== null
                              ? this.props.printData.Unit.LandPurchase
                                  .KhewatNumber
                              : null}
                          </label>
                        </div>
                        <div className="col-12 pt-1">
                          {" "}
                          <label className=" label_print">
                            Sq No:
                            {this.props.printData !== undefined &&
                            this.props.printData.Unit !== undefined &&
                            this.props.printData.Unit.LandPurchase !== null
                              ? this.props.printData.Unit.LandPurchase.SqaNumber
                              : null}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-7 p-0 "
                      style={{ border: "1px solid black" }}
                    >
                      <div className="row p-0 ">
                        <div className="col-12 pt-1">
                          {" "}
                          <label className=" label_print">
                            <b>Unit Inforamtion:</b>{" "}
                          </label>
                          {/* <input type="text" style={{ width: "87%" }} className="input_print_styling ml-2 " value={this.props.printData !== undefined && this.props.printData.CustomerCnicNavigation !== undefined ? this.props.printData.CustomerCnicNavigation.CustomerName : null}></input> */}
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-6 pt-1">
                          {" "}
                          <label className=" label_print">
                            S/Date:{" "}
                            {this.props.printData !== undefined &&
                            this.props.printData.EntryDate !== undefined
                              ? moment(
                                  this.props.printData.EntryDate.split("T")[0]
                                ).format("DD-MMM-YYYY")
                              : null}
                          </label>
                          {/* <input type="text" style={{ width: "87%" }} className="input_print_styling ml-2 " value={this.props.printData !== undefined && this.props.printData.CustomerCnicNavigation !== undefined ? this.props.printData.CustomerCnicNavigation.CustomerName : null}></input> */}
                        </div>

                        <div className="col-3  pt-1 ">
                          {" "}
                          <label className=" label_print">Res.</label>
                          {this.props.printData !== undefined &&
                          this.props.printData.Unit?.Section !== undefined &&
                          this.props.printData.Unit?.Section?.Category
                            .CategoryName !== "COMMERCIAL" ? (
                            <input
                              type="checkbox"
                              checked
                              style={{ width: "20px", height: "20px" }}
                              className=" ml-2"
                            ></input>
                          ) : null}
                        </div>
                        <div className="col-3  pt-1 ">
                          {" "}
                          <label className=" label_print">Com.</label>{" "}
                          {this.props.printData !== undefined &&
                          this.props.printData.Unit?.Section !== undefined &&
                          this.props.printData.Unit?.Section?.Category
                            .CategoryName === "COMMERCIAL" ? (
                            <input
                              type="checkbox"
                              checked
                              style={{ width: "20px", height: "20px" }}
                              className=" ml-2"
                            ></input>
                          ) : null}
                        </div>

                        <div className="col-6 pt-1">
                          {" "}
                          <label className=" label_print">
                            Unit No:{" "}
                            {this.props.printData !== undefined &&
                            this.props.printData.Unit !== undefined
                              ? this.props.printData.Unit?.UnitName
                              : null}
                          </label>
                        </div>
                        <div className="col-6 pt-1">
                          {" "}
                          <label className=" label_print">
                            Size:{" "}
                            {this.props.printData !== undefined &&
                            this.props.printData.Unit !== undefined
                              ? this.props.printData.Unit.RatePerMarlaOrsqft ==
                                "MARLA"
                                ? this.props.printData.Unit.Marla +
                                  "M " +
                                  this.props.printData.Unit.Sarsai +
                                  " S"
                                : this.props.printData.Unit.NetSqft.toFixed(
                                    2
                                  ).toLocaleString() + "SQFT"
                              : null}
                          </label>
                        </div>
                        <div className="col-12 pt-1">
                          {" "}
                          <label className=" label_print">
                            {" "}
                            {this.props.printData !== undefined &&
                            this.props.printData.Unit !== undefined &&
                            this.props.printData.Unit.RatePerMarlaOrsqft ==
                              "MARLA"
                              ? "Per Marla:"
                              : "Per Sqft:"}
                            {this.props.printData !== undefined
                              ? // this.props.printData.Unit !== undefined &&
                                // this.props.printData.Unit.RatePerMarla !== null
                                this.props.printData?.SaleRate?.toLocaleString()
                              : null}{" "}
                          </label>
                        </div>

                        <div className="col-12 pt-1">
                          {" "}
                          <label className=" label_print">
                            Total Value:
                            {this.props.printData !== undefined &&
                            this.props.printData.TotalPrice !== null
                              ? this.props.printData?.TotalPrice?.toLocaleString()
                              : null}{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-12 ">
                  {" "}
                  <label className=" label_print">
                    <b>Declaration</b>{" "}
                  </label>
                </div>
              </div>
              <div className="row ">
                <div className="col-12 ">
                  {" "}
                  <label className=" label_print">
                    I/We do hereby confirm that I/We;
                  </label>
                </div>
              </div>
              <div className="row ">
                <div className="col-12 ">
                  {" "}
                  <label className=" label_print">
                    a) have read and understood the terms and conditions and do
                    hereby agree to abide by the same
                  </label>
                </div>
              </div>
              <div className="row ">
                <div className="col-12 ">
                  {" "}
                  <label className=" label_print">
                    b) have read and understood the terms and conditions and do
                    hereby agree to abide by the same.I/We do hereby further
                    confirm that I/We shall abide by the existing and any future
                    terms and conditions laid down by the Company,{" "}
                    <b>"{this.props.companyInfo?.data?.CompanyName}"</b>.
                  </label>
                </div>
              </div>
              <div className="row ">
                <div className="col-12 ">
                  {" "}
                  <label className=" label_print">
                    c) I/We do hereby further confirm that I/We shall pay the
                    charges / fee as may be decided by the Company for Common
                    and General services of the Society{" "}
                    <b>"{localStorage.getItem("ProjectName")}"</b>.
                  </label>
                </div>
              </div>
            </header>

            <div className="row ">
              <div className="col-5 pt-3 text-center">
                {" "}
                <label className=" label_print">
                  <b>Buyer</b>{" "}
                </label>
              </div>
              <div className="col-2"></div>
              <div className="col-5 pt-3 text-center">
                {" "}
                <label className=" label_print">
                  <b>Project Director</b>{" "}
                </label>
              </div>
            </div>
            <div className="row ">
              <div className="col-5 pt-2">
                {" "}
                <label className=" label_print">Sign: </label>
                <input
                  type="text"
                  style={{ width: "80%" }}
                  className="input_print_styling  ml-2"
                ></input>{" "}
              </div>
              <div className="col-2"></div>
              <div className="col-5 pt-2">
                {" "}
                <label className=" label_print">Sign: </label>
                <input
                  type="text"
                  style={{ width: "80%" }}
                  className="input_print_styling ml-2"
                ></input>{" "}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-5 pt-2">
                {" "}
                <label className=" label_print">Thumb Impression. </label>
                <input
                  type="text"
                  style={{ width: "35%" }}
                  className="input_print_styling  ml-2"
                ></input>{" "}
              </div>
              <div className="col-2"></div>
              <div className="col-5 pt-2">
                {" "}
                <label className=" label_print">Name:</label>
                <input
                  type="text"
                  style={{ width: "76%" }}
                  className="input_print_styling ml-2"
                ></input>{" "}
              </div>
            </div>

            {/* <div className="row ">
                            <div className="col-5 pt-4"> <label className=" label_print">Thumb Impression. </label>
                                <input type="text" style={{ width: "40%" }} className="input_print_styling  ml-2" ></input> </div>
                            <div className="col-2"></div>
                        </div> */}
          </div>
        </div>
        <div
          style={{ minHeight: "1550px" }}
          className="row pt-5 m-0 "
        >
          <div className="col-12">
            <div className="row ">
              <div className="col-12  "></div> <div className="col-12 "></div>{" "}
              <div className="col-12  "></div>
              <div className="col-12  ">
                {" "}
                <label className=" label_print">
                  <b>Terms And Conditions</b>{" "}
                </label>
              </div>
            </div>
            <header style={divStyle}>
              <div className="overlay-div"></div>
              {/* <div className="row  "  >

                                <div className="col-12 label_print  mt-5">
                                    <ul style={{ listStyleType: "disc" }}>
                                        <li >You are requested to clear the outstanding dues as per attached schedule</li>
                                        <li className="mt-2">Please note that the developerreserves the right to cancel the allotment of the plot at any time in case of violation of byelaws / Rules / regulations and default in payment of outstanding dues / maintenance charges. Your allotment is particularly regulated as per following major conditions:</li>

                                    </ul>

                                </div>


                            </div> */}
              <div className="col-12  pt-2">
                <label className=" label_print">
                  {" "}
                  The developer/Company reserves the right to cancel the
                  allotment of the plot at any time in case of violation of
                  byelaws / Rules / regulations and default in payment of
                  outstanding dues / maintenance charges. Your allotment is
                  particularly regulated as per following major conditions:{" "}
                </label>
              </div>

              <div className="row ">
                <div className="col-12 label_print pt-1">
                  <ol type="a">
                    <li className="mt-2">
                      The developer will be responsible for development (i.e.
                      Boundary wall, Carpet Roads and sewerage).
                    </li>
                    <li className="mt-2">
                      Price of the property and the related taxes and duties to
                      be paid by the customer.
                    </li>
                    <li className="mt-2">
                      Legal formalities (transfer / registry) will be completed
                      only on satisfactory receipt of the entire price.A final
                      measurement (Tateema) will be done at the time of
                      possession and applicant agrees to accept the changes
                      inactual size of the plot and allow the developer to
                      adjust the resulting charges accordinglyas per booking
                      price.
                    </li>
                    {localStorage.getItem("ProjectName") === "ProjectName" && (
                      <li className="mt-2">
                        An amount of Rs. 15,000/-per Marla will be paid by the
                        applicant for sui gas connections. However, this amount
                        may vary according to the actual cost of the sui gas
                        connection(residential only).
                      </li>
                    )}
                    <li className="mt-2">
                      Should there be any changes in the project’s plan, layouts
                      due to Govt. compliance, then they would be adjusted
                      accordingly. The applicant agrees to accept these changes.
                    </li>
                    <li className="mt-2">
                      The construction of the unit will be subject to
                      theapplicableGovt. rules/laws and subject to the approval
                      of the developer.
                    </li>
                    <li className="mt-2">
                      Documentation and transfer charges will be borne by the
                      buyer as suggested by the legal advisor of the developer.
                      Transfer of a unit from one applicant tonew buyer will be
                      conducted only on satisfactory receipt of the dues as per
                      payment plan.
                    </li>
                    <li className="mt-2">
                      The transfer charges of file will be Rs. 5,000/-.
                    </li>
                    <li className="mt-2">
                      The maintenance and security servicesof the project willbe
                      executed by the developer. Maintenance and security
                      charges will be charged on Marla basis to the unit
                      owners/tenants..
                    </li>
                    <li className="mt-2">
                      No cattle/animals are allowed within the vicinity of the
                      project.
                    </li>
                    <li className="mt-2">One Marla will be of 270 sq. ft.</li>
                    <li className="mt-2">The amount of any cancelled unit will be returned after deduction of 15% of the amount.</li>
                  </ol>
                </div>
              </div>
            </header>
            <div className="row ">
              <div className="col-4 pt-0 text-center">
                {" "}
                <label className=" label_print">
                  <b>Buyer</b>{" "}
                </label>
              </div>
              <div className="col-4 pt-0 text-center">
                {" "}
                <label className=" label_print">
                  <b>Witness 1</b>{" "}
                </label>
              </div>
              <div className="col-4 pt-0 text-center ">
                <label className=" label_print">
                  <b>Witness 2</b>{" "}
                </label>
              </div>
            </div>
            <div className="row ">
              <div className="col-4 pt-0">
                {" "}
                <label className=" label_print">Sign. </label>
                <input
                  type="text"
                  style={{ width: "78%" }}
                  className="input_print_styling  ml-2"
                ></input>{" "}
              </div>
              <div className="col-4 pt-0">
                {" "}
                <label className=" label_print">Sign. </label>
                <input
                  type="text"
                  style={{ width: "78%" }}
                  className="input_print_styling  ml-2"
                ></input>{" "}
              </div>
              <div className="col-4 pt-0">
                {" "}
                <label className=" label_print">Sign. </label>
                <input
                  type="text"
                  style={{ width: "78%" }}
                  className="input_print_styling  ml-2"
                ></input>{" "}
              </div>
            </div>

            <div className="row ">
              <div className="col-4 pt-5">
                {" "}
                <label className=" label_print">Thumb Impression. </label>
                <input
                  type="text"
                  style={{ width: "30%" }}
                  className="input_print_styling  ml-2"
                ></input>{" "}
              </div>
              <div className="col-4 pt-5">
                {" "}
                <label className=" label_print">Thumb Impression. </label>
                <input
                  type="text"
                  style={{ width: "30%" }}
                  className="input_print_styling  ml-2"
                ></input>{" "}
              </div>
              <div className="col-4 pt-5">
                {" "}
                <label className=" label_print">Thumb Impression. </label>
                <input
                  type="text"
                  style={{ width: "30%" }}
                  className="input_print_styling  ml-2"
                ></input>{" "}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ minHeight: "1550px" }}
          className="row pt-5 m-0"
        >
          <div>.</div>
          <div
            className="col-12"
            style={{
              marginTop: "250px !important",
            }}
          >
            <div className="row ">
              <div className="col-12 text-center "> </div>
            </div>
            <header style={divStyle}>
              <div className="overlay-div"></div>
              <div className="row ">
                <div className="col-12 text-center "> </div>
              </div>
              <div className="row ">
                <div className="col-12 text-center "> </div>
              </div>
              <div className="row">
                <div className="col-8">
                  {" "}
                  <label className=" label_print">
                    <b>Contract No.</b>{" "}
                    {this.props.printData !== undefined &&
                    this.props.printData.ContractId !== undefined
                      ? this.props.printData.ContractId
                      : null}
                  </label>
                </div>

                <div className="col-4">
                  {" "}
                  <label className=" label_print">
                    <b>Date:</b>{" "}
                    {this.props.printData !== undefined &&
                    this.props.printData.EntryDate !== undefined
                      ? moment(
                          this.props.printData.EntryDate.split("T")[0]
                        ).format("DD-MMM-YYYY")
                      : null}
                  </label>
                </div>
              </div>
              <div className="row ">
                <div className="col-12 pt-4">
                  {" "}
                  <label className=" label_print">
                    <b>Name:</b>
                    {this.props.printData !== undefined &&
                    this.props.printData.CustomerCnicNavigation !== undefined
                      ? this.props.printData.CustomerCnicNavigation.CustomerName
                      : null}{" "}
                  </label>
                </div>
              </div>
              <div className="row ">
                <div className="col-12 pt-4">
                  {" "}
                  <label className=" label_print">
                    <b>CNIC:</b>{" "}
                    {this.props.printData !== undefined &&
                    this.props.printData.CustomerCnicNavigation !== undefined
                      ? this.props.printData.CustomerCnicNavigation
                          .CustomerCnic2
                      : null}
                  </label>
                </div>
              </div>
              <div className="row ">
                <div className="col-3 pt-4">
                  <label className=" label_print">
                    <b>Shop No.</b>
                  </label>
                  <label className=" label_print2">
                    {" "}
                    {this.props.printData !== undefined &&
                    this.props.printData.Unit !== undefined
                      ? this.props.printData.Unit.UnitName
                      : null}
                  </label>
                </div>
                <div className="col-3 pt-4">
                  <label className=" label_print">
                    <b>Size.</b>{" "}
                  </label>
                  <label className=" label_print2">
                    {" "}
                    {this.props.printData !== undefined &&
                    this.props.printData.Unit !== undefined
                      ? this.props.printData.Unit.RatePerMarlaOrsqft == "MARLA"
                        ? this.props.printData.Unit.Marla +
                          "M " +
                          this.props.printData.Unit.Sarsai +
                          " S"
                        : this.props.printData.Unit.NetSqft.toFixed(
                            2
                          ).toLocaleString() + "SQFT"
                      : null}
                  </label>
                </div>
                <div className="col-3 pt-4">
                  <label className=" label_print">
                    {this.props.printData !== undefined &&
                    this.props.printData.Unit !== undefined &&
                    this.props.printData.Unit.RatePerMarlaOrsqft == "MARLA" ? (
                      <b>Rate/Marla.</b>
                    ) : (
                      <b>Rate/Sqft.</b>
                    )}{" "}
                  </label>
                  <label className=" label_print2">
                    {" "}
                    {this.props.printData?.SaleRate !== undefined
                      ? this.props.printData?.SaleRate?.toLocaleString()
                      : null}
                  </label>
                </div>
                <div className="col-3 pt-4">
                  <label className=" label_print">
                    <b>Total Value.</b>
                  </label>
                  <label className=" label_print2">
                    {" "}
                    {this.props.printData !== undefined &&
                    this.props.printData?.TotalPrice !== null
                      ? this.props.printData?.TotalPrice?.toLocaleString()
                      : null}
                  </label>
                </div>
              </div>

              <div className="row ">
                <div className="col-12 pt-4">
                  {" "}
                  <label className=" label_print">
                    <b>Installment Plan</b>{" "}
                  </label>
                </div>
              </div>

              <div className="row ">
                <table
                  className="table table-bordered "
                  style={{
                    backgroundColor: "transparent !important",
                  }}
                >
                  <thead className=" ">
                    <tr>
                      <th
                        className="label_print_td"
                        scope="col"
                      >
                        Sr
                      </th>
                      <th
                        className="label_print_td"
                        scope="col"
                      >
                        Description
                      </th>
                      {/* <th className="label_print_td" scope="col">Install No.</th> */}
                      <th
                        className="label_print_td"
                        scope="col"
                      >
                        Due Date
                      </th>
                      <th
                        className="label_print_td"
                        scope="col"
                      >
                        Due Amount
                      </th>
                      <th
                        className="label_print_td"
                        scope="col"
                      >
                        Paid Date
                      </th>
                      <th
                        className="label_print_td"
                        scope="col"
                      >
                        Paid Amount
                      </th>
                      <th
                        className="label_print_td"
                        scope="col"
                      >
                        Balance{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody style={divStyle}>
                    {this.props.printData !== undefined ? (
                      <tr>
                        <td className="label_print_td">1</td>

                        <td className="label_print_td">Booking</td>
                        <td className="label_print_td">
                          {this.props.printData !== undefined &&
                          this.props.printData.EntryDate !== undefined
                            ? moment(
                                this.props.printData.EntryDate.split("T")[0]
                              ).format("DD-MMM-YYYY")
                            : null}
                        </td>

                        <td className="label_print_td">
                          {this.props.printData.AdvanceAmount !== undefined
                            ? this.props.printData.AdvanceAmount?.toLocaleString()
                            : 0}
                        </td>
                        <td className="label_print_td">
                          {this.props.printData !== undefined &&
                          this.props.printData.UnitVoucher?.length > 0
                            ? moment(
                                this.props.printData.UnitVoucher[0]?.EntryDate.split(
                                  "T"
                                )[0]
                              ).format("DD-MMM-YYYY")
                            : null}
                        </td>
                        <td className="label_print_td">
                          {this.props.printData.AdvanceReceived?.toLocaleString()}
                        </td>
                        <td className="label_print_td">
                          {(
                            this.props.printData.AdvanceAmount -
                            this.props.printData.AdvanceReceived
                          ).toLocaleString()}
                        </td>

                        {/* <td className="label_print_td" >

                                                    {this.props.printData.AdvanceAmount - this.props.printData.AdvanceReceived !== 0 ? "Pending" : "Paid"}
                                                </td> */}
                      </tr>
                    ) : null}
                    {this.props.printData === undefined ||
                    this.props.printData.ContractInstallments === undefined
                      ? // contractinstallment.length == 0
                        null
                      : this.props.printData.ContractInstallments.map(
                          (x, id) => (
                            <tr
                              key={id}
                              style={{
                                backgroundColor: "transparent",
                              }}
                            >
                              <td className="label_print_td">{id + 2}</td>
                              <td className="label_print_td">Installment</td>
                              {/* <td className="label_print_td">{x.InstallmentNo}</td> */}
                              <td className="label_print_td">
                                {moment(x.DueDate.split("T")[0]).format(
                                  "DD-MMM-YYYY"
                                )}
                              </td>
                              <td className="label_print_td">
                                {x.DueAmount.toLocaleString()}
                              </td>
                              <td className="label_print_td">
                                {x.UnitReceiptDetail?.length !== 0 &&
                                x.UnitReceiptDetail[0]?.UnitVoucher
                                  ?.EntryDate !== null
                                  ? moment(
                                      x.UnitReceiptDetail[
                                        x.UnitReceiptDetail?.length - 1
                                      ]?.UnitVoucher?.EntryDate?.split("T")[0]
                                    ).format("DD-MMM-YYYY")
                                  : x.UnitReceiptDetail?.length !== 0 &&
                                    x.UnitReceiptDetail[0]?.AdjustmentVoucher
                                      ?.EntryDate !== null
                                  ? x.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate?.split(
                                      "T"
                                    )[0]
                                  : null}
                              </td>

                              <td className="label_print_td">
                                {x.ReceivedAmount.toLocaleString()}
                              </td>
                              <td className="label_print_td">
                                {(
                                  x.DueAmount - x.ReceivedAmount
                                ).toLocaleString()}
                              </td>
                            </tr>
                          )
                        )}
                  </tbody>
                  <tbody style={divStyle}>
                    <tr>
                      <th
                        className="label_print_td"
                        scope="col"
                      ></th>
                      <th
                        className="label_print_td"
                        scope="col"
                      ></th>
                      {/* <th className="label_print_td" scope="col">Install No.</th> */}
                      <th
                        className="label_print_td"
                        scope="col"
                      ></th>
                      <th
                        className="label_print_td"
                        scope="col"
                      >
                        {this.props.printData !== undefined &&
                        this.props.printData.TotalPrice !== undefined
                          ? this.props.printData.TotalPrice.toLocaleString()
                          : 0}
                      </th>
                      <th
                        className="label_print_td"
                        scope="col"
                      ></th>
                      <th
                        className="label_print_td"
                        scope="col"
                      >
                        {this.props.printData !== undefined &&
                        this.props.printData.CustomerReceivable !== undefined &&
                        this.props.printData.TotalPrice !== undefined
                          ? parseInt(
                              this.props.printData.TotalPrice -
                                this.props.printData.CustomerReceivable
                            ).toLocaleString()
                          : 0}{" "}
                      </th>
                      <th
                        className="label_print_td"
                        scope="col"
                      >
                        {this.props.printData !== undefined &&
                        this.props.printData.CustomerReceivable !== undefined
                          ? this.props.printData.CustomerReceivable.toLocaleString()
                          : 0}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="row ">
                <div className="col-5 pt-4 text-center">
                  {" "}
                  <label className=" label_print">
                    <b>Buyer</b>{" "}
                  </label>
                </div>
                <div className="col-2"></div>
                <div className="col-5 pt-4 text-center">
                  {" "}
                  <label className=" label_print">
                    <b>Project Director</b>{" "}
                  </label>
                </div>
              </div>
              <div className="row ">
                <div className="col-5 pt-5">
                  {" "}
                  <label className=" label_print">Sign. </label>
                  <input
                    type="text"
                    style={{ width: "83%" }}
                    className="input_print_styling  ml-2"
                  ></input>{" "}
                </div>
                <div className="col-2"></div>
                <div className="col-5 pt-5">
                  {" "}
                  <label className=" label_print">Name: </label>
                  <input
                    type="text"
                    style={{ width: "76%" }}
                    className="input_print_styling ml-2"
                  ></input>{" "}
                </div>
              </div>
              <div className="row ">
                <div className="col-5 pt-5">
                  {" "}
                  <label className=" label_print"> </label>
                  {/* <input type="text" style={{ width: "83%" }} className="input_print_styling  ml-2" ></input> */}
                </div>
                <div className="col-2"></div>
                <div className="col-5 pt-5">
                  {" "}
                  <label className=" label_print">Sign: </label>
                  <input
                    type="text"
                    style={{ width: "80%" }}
                    className="input_print_styling ml-2"
                  ></input>{" "}
                </div>
              </div>

              <div className="row ">
                <div className="col-5 pt-5">
                  {" "}
                  <label className=" label_print">Thumb Impression. </label>
                  <input
                    type="text"
                    style={{ width: "40%" }}
                    className="input_print_styling  ml-2"
                  ></input>{" "}
                </div>
                <div className="col-2"></div>
                <div className="col-5 pt-5">
                  {" "}
                  <label className=" label_print">CNIC: </label>
                  <input
                    type="text"
                    style={{ width: "76%" }}
                    className="input_print_styling ml-2"
                  ></input>{" "}
                </div>
              </div>
            </header>
          </div>
        </div>
      </div>
    );
  }
}
