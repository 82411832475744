const pendingAmount = { data: [], loading: true };
export const GetLandPendingAmountRed = (state = pendingAmount, action) => {
  switch (action.type) {
    case "get_alllandpendingamount":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};
