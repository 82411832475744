import moment from "moment";
import { useEffect, useState } from "react";
import { useRef } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import {
  GetResceduleData,
  InstallRescedule,
  installrescedule,
} from "../../../actions/accountsactions/installmentsrescedule";
import { CSVLink } from "react-csv";
import { getAllContractTablePopulate } from "../../../actions/accountsactions/addcontract";

export const InstallResceduleTable = (props) => {
  const resultsallcontracts = useSelector(
    (state) => state.getAllInstallRescedulePopulate
  );
  const resultsallcontractspopulate = useSelector(
    (state) => state.getContractTablePopulate
  );
  const [loading, setloading] = useState(false);

  const [allCustomers, setallCustomers] = useState([]);
  const [allAffiliates, setallAffiliates] = useState([]);
  const [allUnits, setallUnits] = useState([]);
  const [customerCnic, setcustomerCnic] = useState("");
  const [unitId, setunitId] = useState("");
  const [affiliateCnic, setaffiliateCnic] = useState("");
  const [paginationData, setpaginationData] = useState(null);

  const dispatch = useDispatch();
  const onTextChangeBuyer = () => {
    var inputValue = document.getElementById("buyerfilterId").value;
    var options = document.getElementById("buyerfilterIdOpt").childNodes;
    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        allCustomers.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });

        // document.getElementById("City").value = val.Nominee.NomineeCity;
        // document.getElementById("Country").value = val.Nominee.NomineeCountry;
        console.log(val);
        setcustomerCnic(val.CustomerCnicNavigation?.CustomerCnic);
        break;
      }
      setcustomerCnic("");
    }
  };
  const onTextChangeAffiliate = () => {
    var inputValue = document.getElementById("affiliatefilterId").value;
    var options = document.getElementById("affiliatefilterIdOpt").childNodes;
    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        allAffiliates?.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });

        // document.getElementById("City").value = val.Nominee.NomineeCity;
        // document.getElementById("Country").value = val.Nominee.NomineeCountry;
        setaffiliateCnic(val.AffiliateCnicNavigation?.AffiliateCnic);
        break;
      }
      setaffiliateCnic("");
    }
  };
  const onTextChangeUnit = () => {
    var inputValue = document.getElementById("unitfilterId").value;
    var options = document.getElementById("unitfilterIdOpt").childNodes;
    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        allUnits.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });

        // document.getElementById("City").value = val.Nominee.NomineeCity;
        // document.getElementById("Country").value = val.Nominee.NomineeCountry;
        setunitId(val.UnitName);
        break;
      }
      setunitId("");
    }
  };

  // const [printData, SetPrintData] = useState();
  // // print function
  // const componentRef = useRef();

  // const printRecoveryBooking = useReactToPrint({
  //     content: () => componentRef.current,
  // });
  // useEffect(() => {
  //     if (printData !== undefined) {
  //         printRecoveryBooking();
  //     }
  // }, [printData]);
  // // all projects print

  const AllcomponentRef = useRef();

  const AllprintPaymentRec = useReactToPrint({
    content: () => AllcomponentRef.current,
  });

  //Table Data fiter

  let suggestionlist = [];

  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      dispatch(installrescedule());
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");

        suggestions = resultsallcontracts.data?.data
          .sort()
          .filter(
            (val) =>
              regex.test(val.Unit.UnitName) ||
              regex.test(val.CustomerCnicNavigation.CustomerName)
          );
      }
      suggestionlist = suggestions;
      dispatch(InstallRescedule(suggestionlist));
    }
  };

  const [CsvData, setCsvData] = useState([]);
  useEffect(() => {
    let arr = [];
    var i = 1;

    resultsallcontracts.data?.data?.forEach((val) => {
      arr.push({
        Sr: i++,
        Date: moment(val?.EntryDate).format("DD-MMM-YYYY"),
        Buyer: val.CustomerCnicNavigation.CustomerName,
        Affiliate: val.AffiliateCnicNavigation.AffiliateName,
        Manager: val.Manager.Employee.EmpName,
        Unit: val.Unit.UnitName,
        Total: val.TotalPrice?.toLocaleString(),
        Receiveable: val.CustomerReceivable?.toLocaleString(),
      });
    });
    setCsvData(arr);
  }, [resultsallcontracts.data?.data]);
  useEffect(() => {
    if (resultsallcontractspopulate.data) {
      setallAffiliates(resultsallcontractspopulate.data?.Affiliates);
      setallCustomers(resultsallcontractspopulate.data?.Buyers);
      setallUnits(resultsallcontractspopulate.data?.Units);
    }
  }, [resultsallcontractspopulate]);
  useEffect(() => {
    if (resultsallcontracts.data) {
      setpaginationData(resultsallcontracts.data);
      setloading(false);
    }
  }, [resultsallcontracts]);
  useEffect(() => {
    let data = {
      ProjectId: Number(`${localStorage.getItem("ProjectId")}`),
      From: document.getElementById("fromdate")?.value,
      To: document.getElementById("todate")?.value,
      UnitName: unitId,
      CustomerCnic: customerCnic,
      AffiliateCnic: affiliateCnic,
    };
    setloading(true);
    dispatch(installrescedule(data));
    dispatch(getAllContractTablePopulate());
  }, [dispatch]);
  function ongo() {
    let data = {
      ProjectId: Number(`${localStorage.getItem("ProjectId")}`),
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
      UnitName: unitId,
      CustomerCnic: customerCnic,
      AffiliateCnic: affiliateCnic,
    };
    setloading(true);
    dispatch(installrescedule(data));
  }
  const getPaginationData = (link) => {
    let data = {
      ProjectId: Number(`${localStorage.getItem("ProjectId")}`),
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
      UnitName: unitId,
      CustomerCnic: customerCnic,
      AffiliateCnic: affiliateCnic,
    };

    dispatch(installrescedule(data, link));
    setloading(true);
  };
  return (
    <>
      {/* <div hidden>
                <ProjectsPrint
                    ref={componentRef}

                    printData={printData}

                />
            </div> */}
      {/* <div hidden>
                <ContractPrint
                    ref={AllcomponentRef}

                    printData={resultsallcontracts}

                />
            </div> */}
      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        {/* <div className="col-md-3 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            placeholder="Unit/Buyer"
            onChange={onTextChange}
          ></input>
        </div> */}
        <div className="col-md-2 pr-0 pl-0">
          <input
            className="input_styling ml-md-3"
            id="unitfilterId"
            autoComplete="off"
            placeholder="Unit"
            list="unitfilterIdOpt"
            onChange={onTextChangeUnit}
          />
          <datalist id="unitfilterIdOpt">
            {allUnits?.map((x, idx) => (
              <option index={idx} value={x.UnitName}>
                {x.UnitId}
              </option>
            ))}
          </datalist>
          {/* <input
            type="text "
            className="input_styling "
            id="sectionfilter"
            onChange={onTextChange}
            placeholder="Buyer"
          ></input> */}
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            className="input_styling ml-md-3"
            id="buyerfilterId"
            autoComplete="off"
            placeholder="Buyer"
            list="buyerfilterIdOpt"
            onChange={onTextChangeBuyer}
          />
          <datalist id="buyerfilterIdOpt">
            {allCustomers?.map((x, idx) => (
              <option
                index={idx}
                value={`${x.CustomerCnicNavigation?.CustomerName} (${idx + 1})`}
              >
                {x.CustomerCnicNavigation?.CustomerCnic2}
              </option>
            ))}
          </datalist>
          {/* <input
            type="text "
            className="input_styling "
            id="sectionfilter"
            onChange={onTextChange}
            placeholder="Buyer"
          ></input> */}
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            className="input_styling ml-md-3"
            id="affiliatefilterId"
            autoComplete="off"
            placeholder="Affiliate"
            list="affiliatefilterIdOpt"
            onChange={onTextChangeAffiliate}
          />
          <datalist id="affiliatefilterIdOpt">
            {allAffiliates?.map((x, idx) => (
              <option
                index={idx}
                value={x.AffiliateCnicNavigation?.AffiliateName}
              >
                {x.AffiliateCnicNavigation?.AffiliateCnic}
              </option>
            ))}
          </datalist>
        </div>
        {/* <div className="col-md-3 text-center  pr-0 pl-0">
                    <label className="input_label m-0">From:</label>
                    <input type="date" className="input_date ml-3" id="from date"></input>
                </div>
                <div className="col-md-3 text-center pr-0 pl-0">
                    <label className="input_label m-0">To:</label>
                    <input type="date" className="input_date ml-3" id="todate"></input>
                </div>
                <div className="col-md-1 pr-0 pl-0">
                    <button className="btn_Go">Go</button>
                </div> */}
        <div className="col-md-1 pr-0 pl-0">
          {resultsallcontracts.loading || loading ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </div>
      </div>
      <div className="row pt-2 ">
        <div className="col-md-3  pr-0 pl-0">
          <label className="input_label m-0">From:</label>
          <input type="date" className="input_date ml-3" id="fromdate"></input>
        </div>
        <div className="col-md-3 text-center pr-0 pl-0">
          <label className="input_label m-0">To:</label>
          <input type="date" className="input_date ml-3" id="todate"></input>
        </div>
        <div className="col-md-1 pr-0 pl-0">
          <button className="btn_Go" onClick={ongo}>
            Go
          </button>
        </div>
        <div className="col-md-1 pr-0 pl-0"></div>
      </div>
      <div className="row ">
        <div className=" col-12  table_height pr-0 pl-0">
          <div className="table-responsive mt-4">
            <table className="table table-borderless m-0 table-hover cur_sor">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Date</th>
                  <th> Buyer</th>
                  <th> Affiliate</th>
                  <th>Manager</th>
                  <th> Unit</th>

                  <th> Total</th>
                  <th> Receiveable</th>

                  <th className="text-center">
                    {/* <button
                                            className="save_btn"
                                            onClick={() => {
                                                props.showcontracttable();
                                            }}
                                        >
                                            New+
                </button> */}
                  </th>
                </tr>
              </thead>
              <tbody>
                {resultsallcontracts.data?.data !== undefined
                  ? resultsallcontracts.data?.data?.map((val, index) => {
                      // let year = val.EntryDate.slice(0, 4);
                      // let day = val.EntryDate.slice(8, 10);
                      // let month = val.EntryDate.slice(5, 7);
                      // switch (month) {
                      //     case "01":
                      //         month = "Jan";

                      //         break;
                      //     case "02":
                      //         month = "Feb";

                      //         break;
                      //     case "03":
                      //         month = "Mar";

                      //         break;
                      //     case "04":
                      //         month = "Apr";

                      //         break;
                      //     case "05":
                      //         month = "May";

                      //         break;
                      //     case "06":
                      //         month = "Jun";

                      //         break;
                      //     case "07":
                      //         month = "Jul";

                      //         break;
                      //     case "08":
                      //         month = "Aug";

                      //         break;
                      //     case "09":
                      //         month = "Sep";

                      //         break;
                      //     case "10":
                      //         month = "Oct";
                      //         break;
                      //     case "11":
                      //         month = "Nov";
                      //         break;
                      //     case "12":
                      //         month = "Dec";
                      //         break;
                      //     default:
                      //         break;
                      // }
                      return (
                        <tr key={index}>
                          <td className="table_data">
                            {index +
                              1 +
                              (paginationData?.PageNumber - 1) *
                                paginationData?.PageSize}
                          </td>
                          <td className="table_data">
                            {moment(val?.EntryDate).format("DD-MMM-YYYY")}
                          </td>

                          <td className="table_data">
                            {val.CustomerCnicNavigation.CustomerName}
                          </td>
                          <td className="table_data">
                            {val.AffiliateCnicNavigation.AffiliateName}
                          </td>
                          <td className="table_data">
                            {val.Manager.Employee.EmpName}
                          </td>
                          <td className="table_data">{val.Unit.UnitName}</td>

                          <td className="table_data">
                            {val.TotalPrice?.toLocaleString()}
                          </td>
                          <td className="table_data">
                            {val.CustomerReceivable?.toLocaleString()}
                          </td>
                          {/* <td>{val.InstallmentStatus}</td> */}

                          <td
                            className="text-center cur_sor"
                            onClick={() => {
                              console.log("val.ContractId", val.ContractId);
                              dispatch(GetResceduleData(val));
                              props.showcontracttable(val.ContractId);
                            }}
                          >
                            <button className="save_btn">reschedule</button>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-6  text-right pr-0 pl-0">
          <button
            style={{ color: "black !important" }}
            className="print_btn ml-2 float-left"
          >
            <CSVLink
              style={{ color: "black" }}
              filename="Installment_Reschedule_Csv"
              data={CsvData}
            >
              Download CSV
            </CSVLink>
          </button>
        </div>

        <div className="col-6  text-right pr-0 pl-0">
          <button
            disabled={paginationData?.LastPage ? false : true}
            className="print_btn float-right mx-1"
            onClick={() => {
              getPaginationData(paginationData?.LastPage);
            }}
          >
            Last
          </button>
          <button
            disabled={paginationData?.PreviousPage ? false : true}
            className="print_btn float-right mx-1"
            onClick={() => {
              getPaginationData(paginationData?.PreviousPage);
            }}
          >
            Previous
          </button>
          <button
            disabled={paginationData?.NextPage ? false : true}
            className="print_btn float-right mx-1"
            onClick={() => {
              getPaginationData(paginationData?.NextPage);
            }}
          >
            Next
          </button>
          <button
            disabled={paginationData?.FirstPage ? false : true}
            className="print_btn float-right pl-1 mx-1"
            onClick={() => {
              getPaginationData(paginationData?.FirstPage);
            }}
          >
            First
          </button>
        </div>
      </div>
    </>
  );
};
