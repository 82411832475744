import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactDOM from "react-dom";
import { BsCloudUpload } from "react-icons/bs";
import { Country, State, City } from "country-state-city";
import noImage from "../../../../assets/noImage.png";

const AddCustomerForm = (props) => {
  const clientdatastate = useSelector((state) => state.getCustomersdata);
  const customerPopulate = useSelector((state) => state.getAllClients);
  const [statemanagers, setmanagersdata] = useState();
  const [allCountries, setallCountries] = useState([]);
  const [allStates, setallStates] = useState([]);
  const [allCity, setallCity] = useState([]);
  const [selectedCountries, setselectedCountries] = useState(null);
  const [selectedStates, setselectedStates] = useState(null);
  const [selectedCity, setselectedCity] = useState(null);
  useEffect(() => {
    const data = Country?.getAllCountries()?.map((country) => ({
      value: country.name,
      displayValue: `${country.name}-${country.isoCode}`,
      isoCode: country.isoCode,
    }));
    setallCountries(data);
  }, [Country]);
  useEffect(() => {
    const data = State.getStatesOfCountry(
      selectedCountries?.split("-")[1]
    )?.map((state) => ({
      value: state.name,
      displayValue: `${state.name}-${state.isoCode}`,
    }));
    setallStates(data);
  }, [selectedCountries]);
  useEffect(() => {
    const data = City.getCitiesOfState(
      selectedCountries?.split("-")[1],
      selectedStates?.split("-")[1]
    ).map((city) => ({
      value: city.name,
      displayValue: city.name,
    }));
    console.log(data);
    setallCity(data);
  }, [selectedCountries, selectedStates]);

  function numberWithCommas(x) {
    let num = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  let dispatch = useDispatch();
  let populatedata = {};
  if (
    clientdatastate.customerdata !== undefined ||
    clientdatastate.customerdata !== null
  ) {
    populatedata = clientdatastate.customerdata;
  } else {
    populatedata = clientdatastate;
  }
  function emptyfields() {
    document.getElementById("City").selectedIndex = 0;
    document.getElementById("Country").selectedIndex = 0;
    document.getElementById("Rating").value = "";
    document.getElementById("Phone").value = "";
    document.getElementById("Address").value = "";
    document.getElementById("Occupation").value = "";
    document.getElementById("clientName").value = "";
    document.getElementById("CNIC").value = "";
    document.getElementById("Cell1").value = "";
    document.getElementById("Cell2").value = "";
    document.getElementById("Email").value = "";
  }
  function addCustomer() {
    let regexp = new RegExp("^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$");
    let regexphone = new RegExp("^[0-9+]{4}-[0-9+]{7}$");
    let regexptcl = new RegExp("^[0-9+]{3}-[0-9+]{7}$");
    let check = document.getElementById("CNIC").value;
    let checkcell1 = document.getElementById("Cell1").value;
    let checkcell2 = document.getElementById("Cell2").value;
    let checkptcl = document.getElementById("Phone").value;
    if (document.getElementById("clientName").value === "") {
      toast.info("Name is required");
    } else if (document.getElementById("CNIC").value === "") {
      toast.info("CNIC is required");
    } else if (!regexp.test(check)) {
      toast.info("Invalid CNIC. Format should be like xxxxx-xxxxxxx-x");
    } else if (document.getElementById("Address").value === "") {
      toast.info("Address is required");
    } else if (document.getElementById("Guardian").value === "") {
      toast.info("Guardian is required");
    }

    // else if (document.getElementById("Occupation").value === "") { toast.info("Occupation  is required") }
    // else if (document.getElementById("Rating").value === "") { toast.info("Rating  is required") }
    else if (
      !regexptcl.test(checkptcl) &&
      document.getElementById("Phone").value !== ""
    ) {
      toast.info("Invalid Phone. Format should be like xxx-xxxxxxx");
    } else if (document.getElementById("Cell1").value === "") {
      toast.info("cell no.1  is required");
    } else if (!regexphone.test(checkcell1)) {
      toast.info("Invalid Cell No. 1 Format should be like xxxx-xxxxxxx");
    } else if (
      !regexphone.test(checkcell2) &&
      document.getElementById("Cell2").value !== ""
    ) {
      toast.info("Invalid Cell No. 2 Format should be like xxxx-xxxxxxx");
    } else if (
      document.getElementById("City").options[
        document.getElementById("City").selectedIndex
      ].value === "Select"
    ) {
      toast.info("Select any city");
    } else if (
      document.getElementById("State").options[
        document.getElementById("State").selectedIndex
      ].value === "Select"
    ) {
      toast.info("Select any State");
    } else if (
      document.getElementById("Country").options[
        document.getElementById("Country").selectedIndex
      ].value === "Select"
    ) {
      toast.info("Select  country");
    } else {
      let formData = new FormData();
      formData.append(
        "CustomerName",
        document.getElementById("clientName").value
      );
      formData.append(
        "CustomerAddress",
        document.getElementById("Address").value
      );
      formData.append("CustomerCnic2", document.getElementById("CNIC").value);
      formData.append("ContactNumber1", document.getElementById("Cell1").value);
      formData.append("ContactNumber2", document.getElementById("Cell2").value);
      formData.append("PhoneNumber", document.getElementById("Phone").value);
      formData.append("Email", document.getElementById("Email").value);
      formData.append(
        "Occupation",
        document.getElementById("Occupation").value
      );
      formData.append("ClientRating", document.getElementById("Rating").value);
      formData.append(
        "City",
        document.getElementById("City").options[
          document.getElementById("City").selectedIndex
        ].value
      );
      formData.append(
        "Country",
        document.getElementById("Country").options[
          document.getElementById("Country").selectedIndex
        ].value
      );

      if (populatedata?.CustomerCnic) {
        formData.append("CustomerCnic", populatedata?.CustomerCnic);
      }

      formData.append(
        "GuardianName",
        document.getElementById("Guardian").value
      );
      formData.append(
        "State",
        document.getElementById("State").options[
          document.getElementById("State").selectedIndex
        ].value
      );
      if (empimage?.file) {
        formData.append("Image", empimage?.file);
      }
      document.getElementById("clientsave").disabled = true;
      let pgurl = `${
        process.env.REACT_APP_API_URL
      }Customers/Add?ProjectId=${localStorage.getItem("ProjectId")}`;
      if (props.stateedit === "yes") {
        pgurl = `${
          process.env.REACT_APP_API_URL
        }Customers/Edit?ProjectId=${localStorage.getItem("ProjectId")}`;
      }
      axios({
        url: pgurl,
        method: "Post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "multipart/form-data",
        },
        data: formData,
      }).then(
        (result) => {
          document.getElementById("clientsave").disabled = false;

          if (result.data.IsSuccess === true) {
            emptyfields();

            toast.info(result.data.Message);
            props.cancelclienttable();
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Something went wrong!");
          document.getElementById("clientsave").disabled = false;
        }
      );
    }
  }
  //    auto suggestion
  var Clientsuggestionlist = [];
  const bclientonTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      ReactDOM.render(null, document.getElementById("bclientdemo"));
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = customerPopulate.data
          .sort()
          .filter((v) => regex.test(v.ManagerClientCnic));
      }

      Clientsuggestionlist = suggestions;
      // suggestionlist.push("Add New");
      if (Clientsuggestionlist.length === 0) {
        ReactDOM.render(null, document.getElementById("bclientdemo"));
      } else {
        ReactDOM.render(
          <ul className="suggestbox">
            {Clientsuggestionlist.map((item) => (
              <li
                className="listcss"
                onClick={() => bClientsuggestionselected(item)}
              >
                {item === undefined ? item : item.ManagerClientCnic}
              </li>
            ))}
          </ul>,
          document.getElementById("bclientdemo")
        );
      }
    }
  };
  const bClientsuggestionselected = (val) => {
    document.getElementById("jBuyer").value = val.ManagerClientCnic;
    document.getElementById("clientName").value = val.ManagerClientName;
    document.getElementById("Email").value = val.Email;
    document.getElementById("Occupation").value = val.Occupation;
    document.getElementById("CNIC").value = val.ManagerClientCnic;
    document.getElementById("City").value = val.City;
    document.getElementById("Country").value = val.Country;
    document.getElementById("Rating").value = val.ManagerClientRating;
    document.getElementById("Phone").value = val.PhoneNumber;
    document.getElementById("Cell1").value = val.ContactNumber1;
    document.getElementById("Cell2").value = val.ContactNumber2;
    document.getElementById("Address").value = val.Address;

    // setmanagersdata(val.CustomerCnic);
    Clientsuggestionlist = [];
    ReactDOM.render(null, document.getElementById("bclientdemo"));
  };

  const onTextChangeJBuyer = () => {
    var inputValue = document.getElementById("jBuyer").value;
    var options = document.getElementById("jbuyerlist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        customerPopulate.data.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });
        document.getElementById("jBuyer").value = val.ManagerClientCnic;
        document.getElementById("clientName").value = val.ManagerClientName;
        document.getElementById("Email").value = val.Email;
        document.getElementById("Occupation").value = val.Occupation;
        document.getElementById("CNIC").value = val.ManagerClientCnic;
        document.getElementById("City").value = val.City;
        document.getElementById("Country").value = val.Country;
        document.getElementById("Rating").value = val.ManagerClientRating;
        document.getElementById("Phone").value = val.PhoneNumber;
        document.getElementById("Cell1").value = val.ContactNumber1;
        document.getElementById("Cell2").value = val.ContactNumber2;
        document.getElementById("Address").value = val.Address;
        break;
      }
    }
  };
  const inputRef = useRef();
  useEffect(() => {
    if (populatedata.Country !== null && populatedata.Country !== undefined) {
      setselectedCountries(populatedata.Country);
    }
    if (populatedata.State !== null && populatedata.State !== undefined) {
      setselectedStates(populatedata.State);
    }
    if (populatedata.City !== null && populatedata.City !== undefined) {
      setselectedCity(populatedata.City);
    }
    if (populatedata?.Image) {
      setempimage({
        url: populatedata?.Image,
        file: null,
      });
    }
  }, [populatedata]);
  const [empimage, setempimage] = useState(null);
  const handleImageChange = (e) => {
    const obj = {
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    };
    setempimage(obj);
  };
  // const handleRemoveImage = () => {
  //   inputRef.current.value = "";
  //   setempimage(null);
  // };
  return (
    <>
      <div className="row ">
        <div className="col-md-12   text-left page_heading">
          <h4 className="m-0">Add Customer</h4>
        </div>
      </div>
      <div className="row ">
        <div
          className="col-md-12 text-center"
          style={{
            width: "150px",
            height: "170px",
            borderRadius: "50%",
            padding: "10px",
          }}
        >
          {!empimage ? (
            <img
              src={noImage}
              classNameF="rounded-circle"
              style={{ width: "150px" }}
              alt="Avatar"
            />
          ) : (
            <img
              src={`${empimage?.url}`}
              alt={
                empimage
                  ? empimage?.url
                      .split("?")[0]
                      ?.split(".com/")[1]
                      ?.split("-")[1]
                  : "profile"
              }
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "50%",
              }}
            />
          )}

          <div
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <button
              className="save_btn  ml-2 mt-3"
              onClick={() => {
                inputRef.current.click();
              }}
            >
              Upload Photo
            </button>
            {/* <button
              variant="outlined"
              color="error"
              sx={{
                display: "none",
                alignItems: "center",

                border: "1px solid red ",
              }}
              onClick={handleRemoveImage}
            >
              Remove
            </button> */}
          </div>
        </div>

        <div className="col-md-2 mt-md-4  text-center p-0">
          <label className="input_label m-0">Transfer from CRM clients :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3"
            id="jBuyer"
            placeholder="Search By CNIC "
            onChange={onTextChangeJBuyer}
            list="jbuyerlist"
          />
          <datalist id="jbuyerlist">
            {customerPopulate?.data?.map((x, idx) => (
              <option index={idx} value={x.ManagerClientCnic}>
                {x.ManagerClientName}
              </option>
            ))}
          </datalist>
          <span id="bclientdemo"></span>
        </div>
        <div className="col-md-3 mt-md-4   p-0"></div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Name :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3"
            id="clientName"
            disabled={props.stateedit == "yes" ? true : false}
            defaultValue={populatedata.CustomerName || ""}
          />
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Guardian :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3"
            id="Guardian"
            defaultValue={populatedata.GuardianName || ""}
          />
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">CNIC</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="CNIC"
            placeholder="xxxxx-xxxxxxx-x"
            defaultValue={populatedata.CustomerCnic2 || ""}
            maxLength={15}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                document.getElementById("CNIC").value = "";
              }
            }}
            onChange={(e) => {
              if (
                e.target.value?.length === 5 ||
                e.target.value?.length === 13
              ) {
                document.getElementById("CNIC").value = e.target.value + "-";
              }
            }}
          />
          <span className="estaric">*</span>
        </div>

        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Email</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="email"
            className="input_styling ml-md-3 "
            id="Email"
            defaultValue={populatedata.Email || ""}
          />
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Country</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <select
            id="Country"
            onChange={(e) => {
              setselectedCountries(e.target.value);
            }}
            className="input_styling ml-md-3"
            name="country"
            value={selectedCountries}
          >
            <option selected>Select</option>
            {allCountries?.map((item, i) => {
              return (
                <>
                  <option key={i} value={item?.displayValue?.toUpperCase()}>
                    {item?.displayValue?.toUpperCase()}
                  </option>
                </>
              );
            })}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>

        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">States</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <select
            className="input_styling ml-md-3"
            id="State"
            onChange={(e) => {
              setselectedStates(e.target.value);
            }}
            value={selectedStates}
          >
            <option selected>Select</option>
            {allStates?.map((item, i) => {
              return (
                <>
                  <option key={i} value={item?.displayValue?.toUpperCase()}>
                    {item?.displayValue?.toUpperCase()}
                  </option>
                </>
              );
            })}
          </select>
          <span className="estaric">*</span>
        </div>
        {/* <div className="col-md-3 mt-md-4  p-0">
          <select className="input_styling ml-md-3" id="City">
            <option selected>Select</option>
            {populatedata.City !== null && populatedata.City !== undefined ? (
              <option selected> {populatedata.City}</option>
            ) : (
              <option selected>Select</option>
            )}
            <option value="Islamabad">Islamabad</option>
            <option value="" disabled>
              Punjab Cities
            </option>
            <option value="Ahmed Nager Chatha">Ahmed Nager Chatha</option>
            <option value="Ahmadpur East">Ahmadpur East</option>
            <option value="Ali Khan Abad">Ali Khan Abad</option>
            <option value="Alipur">Alipur</option>
            <option value="Arifwala">Arifwala</option>
            <option value="Attock">Attock</option>
            <option value="Bhera">Bhera</option>
            <option value="Bhalwal">Bhalwal</option>
            <option value="Bahawalnagar">Bahawalnagar</option>
            <option value="Bahawalpur">Bahawalpur</option>
            <option value="Bhakkar">Bhakkar</option>
            <option value="Burewala">Burewala</option>
            <option value="Chillianwala">Chillianwala</option>
            <option value="Chakwal">Chakwal</option>
            <option value="Chichawatni">Chichawatni</option>
            <option value="Chiniot">Chiniot</option>
            <option value="Chishtian">Chishtian</option>
            <option value="Daska">Daska</option>
            <option value="Darya Khan">Darya Khan</option>
            <option value="Dera Ghazi Khan">Dera Ghazi Khan</option>
            <option value="Dhaular">Dhaular</option>
            <option value="Dina">Dina</option>
            <option value="Dinga">Dinga</option>
            <option value="Dipalpur">Dipalpur</option>
            <option value="Faisalabad">Faisalabad</option>
            <option value="Ferozewala">Ferozewala</option>
            <option value="Fateh Jhang">Fateh Jang</option>
            <option value="Ghakhar Mandi">Ghakhar Mandi</option>
            <option value="Gojra">Gojra</option>
            <option value="Gujranwala">Gujranwala</option>
            <option value="Gujrat">Gujrat</option>
            <option value="Gujar Khan">Gujar Khan</option>
            <option value="Hafizabad">Hafizabad</option>
            <option value="Haroonabad">Haroonabad</option>
            <option value="Hasilpur">Hasilpur</option>
            <option value="Haveli Lakha">Haveli Lakha</option>
            <option value="Jatoi">Jatoi</option>
            <option value="Jalalpur">Jalalpur</option>
            <option value="Jattan">Jattan</option>
            <option value="Jampur">Jampur</option>
            <option value="Jaranwala">Jaranwala</option>
            <option value="Jhang">Jhang</option>
            <option value="Jhelum">Jhelum</option>
            <option value="Kalabagh">Kalabagh</option>
            <option value="Karor Lal Esan">Karor Lal Esan</option>
            <option value="Kasur">Kasur</option>
            <option value="Kamalia">Kamalia</option>
            <option value="Kamoke">Kamoke</option>
            <option value="Khanewal">Khanewal</option>
            <option value="Khanpur">Khanpur</option>
            <option value="Kharian">Kharian</option>
            <option value="Khushab">Khushab</option>
            <option value="Kot Addu">Kot Addu</option>
            <option value="Jauharabad">Jauharabad</option>
            <option value="Lahore">Lahore</option>
            <option value="Lalamusa">Lalamusa</option>
            <option value="Layyah">Layyah</option>
            <option value="Liaquat Pur">Liaquat Pur</option>
            <option value="Lodhran">Lodhran</option>
            <option value="Malakwal">Malakwal</option>
            <option value="Mamoori">Mamoori</option>
            <option value="Mailsi">Mailsi</option>
            <option value="Mandi Bahauddin">Mandi Bahauddin</option>
            <option value="Mian Channu">Mian Channu</option>
            <option value="Mianwali">Mianwali</option>
            <option value="Multan">Multan</option>
            <option value="Murree">Murree</option>
            <option value="Muridke">Muridke</option>
            <option value="Mianwali Bangla">Mianwali Bangla</option>
            <option value="Muzaffargarh">Muzaffargarh</option>
            <option value="Narowal">Narowal</option>
            <option value="Nankana Sahib">Nankana Sahib</option>
            <option value="Okara">Okara</option>
            <option value="Renala Khurd">Renala Khurd</option>
            <option value="Pakpattan">Pakpattan</option>
            <option value="Pattoki">Pattoki</option>
            <option value="Pir Mahal">Pir Mahal</option>
            <option value="Qaimpur">Qaimpur</option>
            <option value="Qila Didar Singh">Qila Didar Singh</option>
            <option value="Rabwah">Rabwah</option>
            <option value="Raiwind">Raiwind</option>
            <option value="Rajanpur">Rajanpur</option>
            <option value="Rahim Yar Khan">Rahim Yar Khan</option>
            <option value="Rawalpindi">Rawalpindi</option>
            <option value="Sadiqabad">Sadiqabad</option>
            <option value="Safdarabad">Safdarabad</option>
            <option value="Sahiwal">Sahiwal</option>
            <option value="Sangla Hill">Sangla Hill</option>
            <option value="Sarai Alamgir">Sarai Alamgir</option>
            <option value="Sargodha">Sargodha</option>
            <option value="Shakargarh">Shakargarh</option>
            <option value="Sheikhupura">Sheikhupura</option>
            <option value="Sialkot">Sialkot</option>
            <option value="Sohawa">Sohawa</option>
            <option value="Soianwala">Soianwala</option>
            <option value="Siranwali">Siranwali</option>
            <option value="Talagang">Talagang</option>
            <option value="Taxila">Taxila</option>
            <option value="Toba Tek Singh">Toba Tek Singh</option>
            <option value="Vehari">Vehari</option>
            <option value="Wah Cantonment">Wah Cantonment</option>
            <option value="Wazirabad">Wazirabad</option>
            <option value="" disabled>
              Sindh Cities
            </option>
            <option value="Badin">Badin</option>
            <option value="Bhirkan">Bhirkan</option>
            <option value="Rajo Khanani">Rajo Khanani</option>
            <option value="Chak">Chak</option>
            <option value="Dadu">Dadu</option>
            <option value="Digri">Digri</option>
            <option value="Diplo">Diplo</option>
            <option value="Dokri">Dokri</option>
            <option value="Ghotki">Ghotki</option>
            <option value="Haala">Haala</option>
            <option value="Hyderabad">Hyderabad</option>
            <option value="Islamkot">Islamkot</option>
            <option value="Jacobabad">Jacobabad</option>
            <option value="Jamshoro">Jamshoro</option>
            <option value="Jungshahi">Jungshahi</option>
            <option value="Kandhkot">Kandhkot</option>
            <option value="Kandiaro">Kandiaro</option>
            <option value="Karachi">Karachi</option>
            <option value="Kashmore">Kashmore</option>
            <option value="Keti Bandar">Keti Bandar</option>
            <option value="Khairpur">Khairpur</option>
            <option value="Kotri">Kotri</option>
            <option value="Larkana">Larkana</option>
            <option value="Matiari">Matiari</option>
            <option value="Mehar">Mehar</option>
            <option value="Mirpur Khas">Mirpur Khas</option>
            <option value="Mithani">Mithani</option>
            <option value="Mithi">Mithi</option>
            <option value="Mehrabpur">Mehrabpur</option>
            <option value="Moro">Moro</option>
            <option value="Nagarparkar">Nagarparkar</option>
            <option value="Naudero">Naudero</option>
            <option value="Naushahro Feroze">Naushahro Feroze</option>
            <option value="Naushara">Naushara</option>
            <option value="Nawabshah">Nawabshah</option>
            <option value="Nazimabad">Nazimabad</option>
            <option value="Qambar">Qambar</option>
            <option value="Qasimabad">Qasimabad</option>
            <option value="Ranipur">Ranipur</option>
            <option value="Ratodero">Ratodero</option>
            <option value="Rohri">Rohri</option>
            <option value="Sakrand">Sakrand</option>
            <option value="Sanghar">Sanghar</option>
            <option value="Shahbandar">Shahbandar</option>
            <option value="Shahdadkot">Shahdadkot</option>
            <option value="Shahdadpur">Shahdadpur</option>
            <option value="Shahpur Chakar">Shahpur Chakar</option>
            <option value="Shikarpaur">Shikarpaur</option>
            <option value="Sukkur">Sukkur</option>
            <option value="Tangwani">Tangwani</option>
            <option value="Tando Adam Khan">Tando Adam Khan</option>
            <option value="Tando Allahyar">Tando Allahyar</option>
            <option value="Tando Muhammad Khan">Tando Muhammad Khan</option>
            <option value="Thatta">Thatta</option>
            <option value="Umerkot">Umerkot</option>
            <option value="Warah">Warah</option>
            <option value="" disabled>
              Khyber Cities
            </option>
            <option value="Abbottabad">Abbottabad</option>
            <option value="Adezai">Adezai</option>
            <option value="Alpuri">Alpuri</option>
            <option value="Akora Khattak">Akora Khattak</option>
            <option value="Ayubia">Ayubia</option>
            <option value="Banda Daud Shah">Banda Daud Shah</option>
            <option value="Bannu">Bannu</option>
            <option value="Batkhela">Batkhela</option>
            <option value="Battagram">Battagram</option>
            <option value="Birote">Birote</option>
            <option value="Chakdara">Chakdara</option>
            <option value="Charsadda">Charsadda</option>
            <option value="Chitral">Chitral</option>
            <option value="Daggar">Daggar</option>
            <option value="Dargai">Dargai</option>
            <option value="Darya Khan">Darya Khan</option>
            <option value="Dera Ismail Khan">Dera Ismail Khan</option>
            <option value="Doaba">Doaba</option>
            <option value="Dir">Dir</option>
            <option value="Drosh">Drosh</option>
            <option value="Hangu">Hangu</option>
            <option value="Haripur">Haripur</option>
            <option value="Karak">Karak</option>
            <option value="Kohat">Kohat</option>
            <option value="Kulachi">Kulachi</option>
            <option value="Lakki Marwat">Lakki Marwat</option>
            <option value="Latamber">Latamber</option>
            <option value="Madyan">Madyan</option>
            <option value="Mansehra">Mansehra</option>
            <option value="Mardan">Mardan</option>
            <option value="Mastuj">Mastuj</option>
            <option value="Mingora">Mingora</option>
            <option value="Nowshera">Nowshera</option>
            <option value="Paharpur">Paharpur</option>
            <option value="Pabbi">Pabbi</option>
            <option value="Peshawar">Peshawar</option>
            <option value="Saidu Sharif">Saidu Sharif</option>
            <option value="Shorkot">Shorkot</option>
            <option value="Shewa Adda">Shewa Adda</option>
            <option value="Swabi">Swabi</option>
            <option value="Swat">Swat</option>
            <option value="Tangi">Tangi</option>
            <option value="Tank">Tank</option>
            <option value="Thall">Thall</option>
            <option value="Timergara">Timergara</option>
            <option value="Tordher">Tordher</option>
            <option value="" disabled>
              Balochistan Cities
            </option>
            <option value="Awaran">Awaran</option>
            <option value="Barkhan">Barkhan</option>
            <option value="Chagai">Chagai</option>
            <option value="Dera Bugti">Dera Bugti</option>
            <option value="Gwadar">Gwadar</option>
            <option value="Harnai">Harnai</option>
            <option value="Jafarabad">Jafarabad</option>
            <option value="Jhal Magsi">Jhal Magsi</option>
            <option value="Kacchi">Kacchi</option>
            <option value="Kalat">Kalat</option>
            <option value="Kech">Kech</option>
            <option value="Kharan">Kharan</option>
            <option value="Khuzdar">Khuzdar</option>
            <option value="Killa Abdullah">Killa Abdullah</option>
            <option value="Killa Saifullah">Killa Saifullah</option>
            <option value="Kohlu">Kohlu</option>
            <option value="Lasbela">Lasbela</option>
            <option value="Lehri">Lehri</option>
            <option value="Loralai">Loralai</option>
            <option value="Mastung">Mastung</option>
            <option value="Musakhel">Musakhel</option>
            <option value="Nasirabad">Nasirabad</option>
            <option value="Nushki">Nushki</option>
            <option value="Panjgur">Panjgur</option>
            <option value="Pishin Valley">Pishin Valley</option>
            <option value="Quetta">Quetta</option>
            <option value="Sherani">Sherani</option>
            <option value="Sibi">Sibi</option>
            <option value="Sohbatpur">Sohbatpur</option>
            <option value="Washuk">Washuk</option>
            <option value="Zhob">Zhob</option>
            <option value="Ziarat">Ziarat</option>
          </select>
          <span className="estaric">*</span>
        </div> */}
        {/* <div className="col-md-3 mt-md-4   p-0">
            <select id="Country" className="input_styling ml-md-3" name="country">
              <option selected>Select</option>
              {populatedata.Country !== null &&
              populatedata.Country !== undefined ? (
                <option selected> {populatedata.Country}</option>
              ) : (
                <option value="Pakistan">Pakistan</option>
              )}
            </select>
            <span className="estaric">*</span>
          </div> */}
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">City</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <select
            className="input_styling ml-md-3"
            id="City"
            onChange={(e) => {
              setselectedCity(e.target.value);
            }}
            value={selectedCity}
          >
            <option selected>Select</option>
            {allCity?.map((item, i) => {
              return (
                <>
                  <option key={i} value={item?.displayValue?.toUpperCase()}>
                    {item?.displayValue?.toUpperCase()}
                  </option>
                </>
              );
            })}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Occupation:</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3"
            id="Occupation"
            defaultValue={populatedata.Occupation}
          />
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Cell No 1:</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3"
            id="Cell1"
            placeholder="xxxx-xxxxxxx"
            defaultValue={populatedata.ContactNumber1 || ""}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                document.getElementById("Cell1").value = "";
              }
            }}
            maxLength={12}
            onChange={(e) => {
              if (e.target.value?.length === 4) {
                document.getElementById("Cell1").value = e.target.value + "-";
              }
            }}
          />
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Cell No 2: </label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="Cell2"
            placeholder="xxxx-xxxxxxx"
            defaultValue={populatedata.ContactNumber2 || ""}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                document.getElementById("Cell2").value = "";
              }
            }}
            maxLength={12}
            onChange={(e) => {
              if (e.target.value?.length === 4) {
                document.getElementById("Cell2").value = e.target.value + "-";
              }
            }}
          />
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Address:</label>
        </div>
        <div className="col-md-3 mt-4   p-0">
          <textarea
            className="input_address ml-md-3 "
            id="Address"
            defaultValue={populatedata.CustomerAddress || ""}
          ></textarea>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Phone No </label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="Phone"
            placeholder="xxx-xxxxxxx"
            defaultValue={populatedata.PhoneNumber || ""}
            maxLength={12}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                document.getElementById("Phone").value = "";
              }
            }}
            onChange={(e) => {
              if (e.target.value?.length === 3) {
                document.getElementById("Phone").value = e.target.value + "-";
              }
            }}
          />
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Customer Rating:</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3"
            id="Rating"
            defaultValue={populatedata.ClientRating || ""}
            onChange={(e) => {
              if (e.target.value.length > 0)
                document.getElementById("Rating").value = parseInt(
                  e.target.value.replace(/,/g, "")
                ).toLocaleString();
            }}
          />
        </div>
      </div>

      <div className="row pr-md-2 pt-md-4">
        <div className=" col-12 pr-0 pl-0  text-right ">
          <span>
            <button
              className="cancel_btn ml-2  "
              onClick={() => {
                props.cancelclienttable();
                emptyfields();
              }}
            >
              Cancel
            </button>
          </span>
          <span>
            <button
              className="save_btn  ml-2"
              id="clientsave"
              onClick={addCustomer}
            >
              <BsCloudUpload size="1.3em" />
              Save
            </button>
          </span>
        </div>
      </div>
      <input
        type={"file"}
        onChange={handleImageChange}
        ref={inputRef}
        accept="image/*"
        style={{ display: "none" }}
      />
    </>
  );
};
export default AddCustomerForm;
