import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactDOM from "react-dom";
import { getallcontractpopulate } from "../../../actions/accountsactions/addcontract";
import moment from "moment";
import { getaffiliatepopulatetable } from "../../../actions/inventoryactions/getaffiliates";
import { getallcustomers } from "../../../actions/getclients";
import { BsCloudUpload } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import AddNominee from "./AddNominee";
import AddBuyerModal from "./AddBuyerModal";
import AddAffiliateModal from "./AddAffiliateModal";
import Loader from "react-loader-spinner";

export const ContractForm = (props) => {
  const AffiliateData = useSelector((state) => state.getAffiliatesRed);
  const resultallclients = useSelector((state) => state.getAllCustomersRed);
  const contractprojectpopulatedata = useSelector(
    (state) => state.getContractPopulate
  );
  const [render, setrender] = useState(false);

  // const resultallemployees = useSelector(state => state.getAllEmployees);
  let dispatch = useDispatch();
  const [InstallmentDurationList, setInstallmentDurationList] = useState([]);
  const [idval, setidval] = useState(0);
  const [unitlist, setunitlist] = useState([]);
  const [UnitId, setUnitId] = useState(0);
  const [valmarlas, setMarlas] = useState();
  const [NetAmount, setNetAmount] = useState();
  const [installmentdifference, setinstallmentdifference] = useState(0);
  const [NetTotalAmount, setNetTotalAmount] = useState(0);
  const [installmenttotal, setinstallmenttotal] = useState();
  const [jointbuyers, setjointbuyers] = useState([]);
  const [buyercnicval, setbuyercnicval] = useState("");
  const [buyercnicval2, setbuyercnicval2] = useState("");
  const [individualcheck, setindividualcheck] = useState(true);
  const [isfirstscnerio, setisfirstscnerio] = useState(false);
  const [marlafieldchange, setmarlafieldchange] = useState();
  const [resaleCheck, setresaleCheck] = useState(false);
  // for deal sold
  const [isdeal, setisdeal] = useState(false);
  const [isdealsol, setisdealsold] = useState(false);
  const [valdealrate, setdealrate] = useState("");
  const [dealval, setdealval] = useState("");
  const [dealvalformarg, setdealvalformrg] = useState("0");

  const [dealadvanceval, setdealadvanceval] = useState(0);
  const [dealadvanceper, setdealadvanceper] = useState(0);

  const [additonalmarginper, setadditonalmarginper] = useState(0);
  const [statemanagercom, setmangercomm] = useState("0");
  const [additonalmarginsold, setadditonalmarginsold] = useState("0");

  //   for deal bought
  const [dealboughtcommissionamt, setdealboughtcommissionamt] = useState(0);
  const [dealboughtcommissionper, setdealboughtcommissionper] = useState(0);
  const [additonalmarginbought, setadditonalmarginbought] = useState("");
  const [addmrgonchange, setaddmrgonchange] = useState("0");

  const [boughtadditonalmarginper, setboughtadditonalmarginper] = useState(0);
  const [isdealbought, setisdealbought] = useState(false);
  const [boughtratepermarla, setboughtratepermarla] = useState(0);
  const [boughttotalprice, setboughttotalprice] = useState(0);
  const [boughttotalpriceforadv, setboughttotalpriceforadv] = useState(0);

  const [dealboughtadvanceval, setdealboughtadvanceval] = useState(0);
  const [dealboughtadvanceper, setdealboughtadvanceper] = useState(0);
  const [projectadvanceper, setprojectadvanceper] = useState(0);
  // firstscnerio
  const [valsalerate, setsalerate] = useState();
  const [valadvancerate, setvaladvancerate] = useState();
  const [valtotalrate, setvaltotalrate] = useState(0);

  const [managercommissonexp, setmanagercommissonexp] = useState(false);
  const [advancePer, setadvancePer] = useState(0);
  const [showBuyerModal, setshowBuyerModal] = useState(false);
  const [showAffiliateModal, setshowAffiliateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  console.log(jointbuyers);
  useEffect(() => {
    if (isdealbought) {
      let margin = parseInt(
        document.getElementById("AdditionalMrgPer") !== null
          ? document.getElementById("AdditionalMrgPer").value
          : 0
      );
      setadditonalmarginbought(
        parseFloat(
          Math.round(
            (parseFloat(boughttotalprice.replace(/,/g, "")) -
              parseFloat(boughttotalpriceforadv.replace(/,/g, ""))) *
              (margin / 100)
          )
        ).toLocaleString()
      );
      setaddmrgonchange(
        parseFloat(
          Math.round(
            parseFloat(boughttotalprice.replace(/,/g, "")) -
              parseFloat(boughttotalpriceforadv.replace(/,/g, ""))
          )
        ).toLocaleString()
      );
    }
  }, [boughttotalpriceforadv]);
  useEffect(() => {
    if (isdealsol) {
      if (
        parseFloat(
          Math.round(
            document.getElementById("newcontractPrice").value.replace(/,/g, "")
          )
        ) !== parseFloat(Math.round(dealval.replace(/,/g, "")))
      ) {
        let margin = parseInt(
          document.getElementById("AdditionalMrgPer") !== null
            ? document.getElementById("AdditionalMrgPer").value
            : 0
        );
        // setadditonalmarginsold((dealval - dealvalformarg) * (margin / 100));
        // console.log((dealval - dealvalformarg) * (margin / 100));
        // setaddmrgonchange((dealval - dealvalformarg))
        setadditonalmarginsold(
          parseFloat(
            Math.round(
              parseFloat(
                document
                  .getElementById("newcontractPrice")
                  .value.replace(/,/g, "")
              ) -
                parseFloat(dealval.replace(/,/g, "")) * (margin / 100)
            )
          ).toLocaleString()
        );
        console.log(
          (parseFloat(
            document.getElementById("newcontractPrice").value.replace(/,/g, "")
          ) -
            parseFloat(dealval.replace(/,/g, ""))) *
            (margin / 100)
        );

        setaddmrgonchange(
          parseFloat(
            Math.round(
              parseFloat(
                document
                  .getElementById("newcontractPrice")
                  .value.replace(/,/g, "")
              ) - parseFloat(dealval.replace(/,/g, ""))
            )
          ).toLocaleString()
        );
      }
    }
  }, [dealval]);

  // const [clientAccid, setclientAccid] = useState();
  // const [affiliateAccid, setaffiliateAccid] = useState("");
  const savecontract = () => {
    if (
      document.getElementById("salerateval").value < 0 &&
      document.getElementById("salerateval").value == ""
    ) {
      toast.info("Sale Rate should be greater than 0");
    } else if (document.getElementById("contractproject").selectedIndex === 0) {
      toast.info("Select Project from dropdown");
    } else if (document.getElementById("contractShop").value === "") {
      toast.info("Enter Plot ");
    } else if (
      document.getElementById("Affiliatecheck") !== null
        ? document.getElementById("Affiliatecheck").checked === true &&
          affiliatecnic === null
        : null
    ) {
      toast.info("Enter Affiliate");
    } else if (
      document.getElementById("Managercheck") !== null
        ? document.getElementById("Managercheck").checked === true &&
          document.getElementById("employees").selectedIndex === 0
        : null
    ) {
      toast.info("Enter Manager");
    } else if (clientcnic === null) {
      toast.info("Select Buyer");
    } else if (document.getElementById("salerateval").value === "") {
      toast.info("Enter sale rate ");
    } else {
      // console.log(installmentList);
      let advancevalue;
      if (document.getElementById("advanceval").value == "") {
        advancevalue = 0;
      } else {
        advancevalue = document.getElementById("advanceval").value;
      }
      setIsLoading(true);
      var data = {
        Contract: {
          EntryDate: document.getElementById("contractDate").value,
          UnitId: parseInt(UnitId),
          UpdatedAdvancePercent: Number(advancePer),
          // CustomerCnic: clientAccid,
          // AffiliateCnic: affiliateAccid,
          // AffiliateAccount: brokerdetail !== null && brokerdetail.accid !== null ? brokerdetail.accid : affiliateAccid,
          CustomerCnic: clientcnic,
          AffiliateCnic:
            brokerdetail !== null && brokerdetail.accid !== 0
              ? brokerdetail.accid
              : affiliatecnic,
          AdvanceAmount: parseFloat(advancevalue.replace(/,/g, "")),
          SaleRate: parseFloat(
            document.getElementById("salerateval").value.replace(/,/g, "")
          ),

          TotalPrice: parseFloat(
            document.getElementById("newcontractPrice").value.replace(/,/g, "")
          ),
          ContractStatus: "Pending",
          CustomerReceivable: parseFloat(
            document.getElementById("newcontractPrice").value.replace(/,/g, "")
          ),
          ProjectId: parseInt(
            document.getElementById("contractproject").options[
              document.getElementById("contractproject").selectedIndex
            ].dataset.id
          ),

          SalesTeamEmployeeId: parseInt(
            document.getElementById("employees").options[
              document.getElementById("employees").selectedIndex
            ].dataset.id
          ),
          AdvancePaidToGravity:
            document.getElementById("advancepaidtogravity").checked === true
              ? true
              : false,
          ReSaleContract: resaleCheck,
        },

        JointBuyers: jointbuyers,
      };
      document.getElementById("instasavebtn").disabled = true;
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Contracts/Add`,
        data: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result.data.IsSuccess === true) {
            setIsLoading(false);
            toast.info(result.data.Message);
            document.getElementById("instasavebtn").disabled = false;
            // dispatch(getAllContractTablePopulate());
            props.cancelcontracttable();
          } else {
            setIsLoading(false);

            toast.info(result.data.Message);
            document.getElementById("instasavebtn").disabled = false;
          }
        },
        (error) => {
          setIsLoading(false);

          toast.info(error);
          document.getElementById("instasavebtn").disabled = false;
        }
      );
    }
  };
  // project on change
  const setunits = (e) => {
    if (e.target.value === "Select") {
      document.getElementById("contractShop").readOnly = true;
      document.getElementById("contractShop").placeholder =
        "Select Project First";
    } else {
      document.getElementById("contractShop").readOnly = false;
      document.getElementById("contractShop").placeholder = "";
      // setprojectadvanceper(document.getElementById("contractproject").options[document.getElementById("contractproject").selectedIndex].dataset.advpay)
      let value = e.target.value;
      contractprojectpopulatedata.data.Projects.map((x) => {
        if (x.ProjectName.trim() === value.trim()) {
          // setDuration(x.Installementduration);
          if (x.Units != null) {
            setunitlist(x.Units);
          } else setunitlist([]);
        }
      });
    }
  };
  useEffect(() => {
    // let value=document.getElementById("contractproject").value
    // console.log(document.getElementById("contractproject").value)
    contractprojectpopulatedata?.data?.Projects?.map((x) => {
      if (x.ProjectId == localStorage.getItem("ProjectId")) {
        // setDuration(x.Installementduration);
        if (x.Units != null) {
          setunitlist(x.Units);
        } else setunitlist([]);
      }
    });
  }, [contractprojectpopulatedata]);
  // unit on change function
  var suggestionlist1 = [];

  const onTextChangeUnit = () => {
    var inputValue = document.getElementById("contractShop").value;
    var options = document.getElementById("mylist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        unitlist.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });
        console.log(val);
        setUnitId(val.UnitId);
        setadvancePer(val.Section?.AdvanceAmountPer);
        setNetAmount(parseFloat(Math.round(val.netAmount)).toLocaleString());
        setNetTotalAmount(
          parseFloat(Math.round(val.netAmount)).toLocaleString()
        );

        if (val.ReservedUnits.length > 0) {
          // setleademployeeid(val.ReservedUnits[0].Lead.InitiateByNavigation.EmployeeId);
          // setleadbuyerid(val.ReservedUnits[0].Lead.ManagerClient.ManagerClientCnic);
          setleadbuyerid(val.ReservedUnits[0].ManagerClient.ManagerClientCnic);

          setindividualcheck(false);
          document.getElementById("showhide2").style.display = "none";
          document.getElementById("showhide").style.display = "block";
          // setmanagercommissonexp(true);
        } else {
          setindividualcheck(true);
          // setleademployeeid(0)
          document.getElementById("showhide2").style.display = "block";
          document.getElementById("showhide").style.display = "none";
          // setmanagercommissonexp(false);
        }
        let valM;
        if (val.RatePerMarlaOrsqft == "MARLA") {
          let marla = val.Marla.toString();
          let sarsai = val.Sarsai.toString();
          let marlasarsai = marla.concat(".", sarsai, sarsai, sarsai, sarsai);
          valM = parseFloat(marlasarsai);
          setMarlas(parseFloat(marlasarsai));
          setmarlafieldchange("MARLA");
        }
        if (val.RatePerMarlaOrsqft == "SQFT") {
          valM = parseFloat(val.NetSqft);
          setMarlas(parseFloat(val.NetSqft));
          setmarlafieldchange("SQFT");
        }
        // setMarlas(parseFloat(val.valmarlas));
        if (val.DealBoughtId === null && val.DealSoldUnits.length === 0) {
          setisfirstscnerio(true);
          setisdeal(false);
          setisdealsold(false);

          setisdealbought(false);
          setsalerate(
            parseFloat(Math.round(val.RatePerMarla)).toLocaleString()
          );
          // setvaladvancerate(projectadvanceper / 100 * parseFloat(val.Price))
          setprojectadvanceper(val.Section.AdvanceAmountPer);
          setvaladvancerate(
            parseFloat(
              Math.round(
                (parseFloat(val.Section.AdvanceAmountPer) / 100) *
                  parseFloat(val.Price)
              )
            ).toLocaleString()
          );

          // setvaltotalrate(parseFloat(val.Price))
          setNetAmount(parseFloat(Math.round(val.Price)).toLocaleString());
          // setisdealbought(true);
          // setisdealsold(true);
        } else {
          if (val.DealSoldUnits.length > 0 && val.Status === "DEAL RESERVED") {
            setisfirstscnerio(false);
            setisdeal(true);
            setisdealbought(false);
            setisdealsold(true);
            setdealrate(
              parseFloat(
                Math.round(val.DealSoldUnits[0].NewSaleRate)
              ).toLocaleString()
            );
            setdealval(
              parseFloat(
                Math.round(val.DealSoldUnits[0].TotalSaleValue)
              ).toLocaleString()
            );
            // setdealvalformrg(
            //   parseFloat(
            //     Math.round(val.DealSoldUnits[0].TotalSaleValue)
            //   ).toLocaleString()
            // );
            // setadditonalmarginsold((val.DealSoldUnits[0].TotalSaleValue - val.DealSoldUnits[0].OldSaleRate) * val.DealSoldUnits[0].Deal.ProfitMarginPer / 100)
            setadditonalmarginsold("0");

            setdealadvanceval(
              Math.round(
                parseFloat(
                  (val.DealSoldUnits[0].AdvancePer / 100) *
                    val.DealSoldUnits[0].TotalSaleValue
                )
              ).toLocaleString()
            );
            setdealadvanceper(val.DealSoldUnits[0].AdvancePer);
            setadditonalmarginper(val.DealSoldUnits[0].Deal.ProfitMarginPer);

            setbrokerdetail({
              accid:
                val.DealSoldUnits[0] !== undefined
                  ? val.DealSoldUnits[0].Deal.AffiliateCnicNavigation
                      .AffiliateCnic
                  : null,
              acctitle:
                val.DealSoldUnits[0] !== undefined
                  ? val.DealSoldUnits[0].Deal.AffiliateCnicNavigation
                      .AffiliateName
                  : null,
            });
            if (val.DealSoldUnits[0].Deal.CommissionPer !== null) {
              document.getElementById("contractcomamount").value = parseFloat(
                Math.round(
                  (val.DealSoldUnits[0].Deal.CommissionPer / 100) *
                    val.DealSoldUnits[0].TotalSaleValue
                )
              ).toLocaleString();
              document.getElementById("contractcom").value = Math.round(
                val.DealSoldUnits[0].Deal.CommissionPer
              );
            }
            // if (val.DealSoldUnits[0].Deal.ProfitMarginPer !== null) {
            //     document.getElementById("AdditionalMrgAmt").value = Math.round(val.DealSoldUnits[0].Deal.ProfitMarginPer / 100 * val.DealSoldUnits[0].TotalSaleValue)
            //     document.getElementById("AdditionalMrgPer").value = val.DealSoldUnits[0].Deal.ProfitMarginPer;
            //   }
          } else if (
            val.DealBought !== undefined &&
            val.DealSoldUnits.length === 0
          ) {
            setisdeal(true);
            setisdealsold(false);
            setisfirstscnerio(false);

            setisdealbought(true);
            setdealrate("");
            setboughtratepermarla(
              parseFloat(Math.round(val.RatePerMarla)).toLocaleString()
            );
            setboughttotalprice(
              parseFloat(Math.round(val.RatePerMarla * valM)).toLocaleString()
            );
            setboughttotalpriceforadv(
              parseFloat(Math.round(val.CostPerMarla * valM)).toLocaleString()
            );

            // dealboughtcommissionamt(val.DealBought[0].AdvancePer/100 *val.DealSoldUnits[0].TotalSaleValue);
            // setdealboughtcommissionper(val.DealBought.CommissionPer);
            setboughtadditonalmarginper(val.DealBought.ProfitMarginPer);
            // setdealboughtadvanceval(projectadvanceper / 100 * (val.Price));
            // setdealboughtadvanceper(projectadvanceper);
            setprojectadvanceper(val.Section.AdvanceAmountPer);

            setdealboughtadvanceval(
              parseFloat(
                Math.round((val.Section.AdvanceAmountPer / 100) * val.Price)
              ).toLocaleString()
            );
            setdealboughtadvanceper(val.Section.AdvanceAmountPer);

            // if (val.DealBought.CommissionPer !== null) {
            //     if (document.getElementById("DealCommAmt") !== null && document.getElementById("DealCommPer") !== null) {
            //         document.getElementById("DealCommAmt").value = Math.round((val.Price - (val.valmarlas * val.CostPerMarla)) * val.DealBought.CommissionPer / 100)
            //         document.getElementById("DealCommPer").value = Math.round(val.DealBought.CommissionPer);
            //     }
            // }
            // if (val.DealBought.CommissionPer !== null) {

            let amt =
              (valM * val.RatePerMarla * val.DealBought.CommissionPer) / 100;

            // document.getElementById("DealCommAmt").value = parseFloat(
            //   Math.round(amt)
            // ).toLocaleString();
            setdealboughtcommissionamt(
              parseFloat(Math.round(valM * val.CostPerMarla)).toLocaleString()
            );
            // document.getElementById("DealCommPer").value =
            //   val.DealBought.CommissionPer;

            // }
            if (val.DealBought.ProfitMarginPer !== null) {
              //   document.getElementById("AdditionalMrgAmt").value =val.DealBought.CommissionPer / 100 * val.DealSoldUnits[0].TotalSaleValue
              // document.getElementById("AdditionalMrgPer").value =
              //   val.DealBought.ProfitMarginPer;
            }
          }
        }
        break;
      }
    }
  };

  const [brokerdetail, setbrokerdetail] = useState({
    accid: 0,
    acctitle: null,
  });
  const [stateleademployeeid, setleademployeeid] = useState();
  const [stateleadbuyerid, setleadbuyerid] = useState();

  const suggestionselected1 = (val) => {
    document.getElementById("contractShop").value = val.UnitName;
    setUnitId(val.UnitId);
    setNetAmount(parseFloat(Math.round(val.netAmount)).toLocaleString());
    setNetTotalAmount(parseFloat(Math.round(val.netAmount)).toLocaleString());
    if (val.ReservedUnits.length > 0) {
      // setleademployeeid(val.ReservedUnits[0].Lead.InitiateByNavigation.EmployeeId);
      // setleadbuyerid(val.ReservedUnits[0].Lead.ManagerClient.ManagerClientCnic);
      setleadbuyerid(val.ReservedUnits[0].ManagerClient.ManagerClientCnic);

      setindividualcheck(false);
      document.getElementById("showhide2").style.display = "none";
      document.getElementById("showhide").style.display = "block";
      // setmanagercommissonexp(true);
    } else {
      setindividualcheck(true);
      // setleademployeeid(0)
      document.getElementById("showhide2").style.display = "block";
      document.getElementById("showhide").style.display = "none";
      // setmanagercommissonexp(false);
    }
    let valM;
    if (val.RatePerMarlaOrsqft == "MARLA") {
      let marla = val.Marla.toString();
      let sarsai = val.Sarsai.toString();
      let marlasarsai = marla.concat(".", sarsai, sarsai, sarsai, sarsai);
      valM = parseFloat(marlasarsai);
      setMarlas(parseFloat(marlasarsai));
      setmarlafieldchange("MARLA");
    }
    if (val.RatePerMarlaOrsqft == "SQFT") {
      valM = parseFloat(val.NetSqft);
      setMarlas(parseFloat(val.NetSqft));
      setmarlafieldchange("SQFT");
    }
    // setMarlas(parseFloat(val.valmarlas));
    if (val.DealBoughtId === null && val.DealSoldUnits.length === 0) {
      setisfirstscnerio(true);
      setisdeal(false);
      setisdealsold(false);

      setisdealbought(false);
      setsalerate(parseFloat(Math.round(val.RatePerMarla)).toLocaleString());
      // setvaladvancerate(projectadvanceper / 100 * parseFloat(val.Price))
      setprojectadvanceper(val.Section.AdvanceAmountPer);
      setvaladvancerate(
        parseFloat(
          Math.round(
            (parseFloat(val.Section.AdvanceAmountPer) / 100) *
              parseFloat(val.Price)
          )
        ).toLocaleString()
      );

      // setvaltotalrate(parseFloat(val.Price))
      setNetAmount(parseFloat(Math.round(val.Price)).toLocaleString());
      // setisdealbought(true);
      // setisdealsold(true);
    } else {
      if (val.DealSoldUnits.length > 0 && val.Status === "DEAL RESERVED") {
        setisfirstscnerio(false);
        setisdeal(true);
        setisdealbought(false);
        setisdealsold(true);
        setdealrate(
          parseFloat(
            Math.round(val.DealSoldUnits[0].NewSaleRate)
          ).toLocaleString()
        );
        setdealval(
          parseFloat(
            Math.round(val.DealSoldUnits[0].TotalSaleValue)
          ).toLocaleString()
        );
        setdealvalformrg(
          parseFloat(
            Math.round(val.DealSoldUnits[0].TotalSaleValue)
          ).toLocaleString()
        );
        // setadditonalmarginsold((val.DealSoldUnits[0].TotalSaleValue - val.DealSoldUnits[0].OldSaleRate) * val.DealSoldUnits[0].Deal.ProfitMarginPer / 100)
        setadditonalmarginsold("0");

        setdealadvanceval(
          Math.round(
            parseFloat(
              (val.DealSoldUnits[0].AdvancePer / 100) *
                val.DealSoldUnits[0].TotalSaleValue
            )
          ).toLocaleString()
        );
        setdealadvanceper(val.DealSoldUnits[0].AdvancePer);
        setadditonalmarginper(val.DealSoldUnits[0].Deal.ProfitMarginPer);

        setbrokerdetail({
          accid:
            val.DealSoldUnits[0] !== undefined
              ? val.DealSoldUnits[0].Deal.AffiliateCnicNavigation.AffiliateCnic
              : null,
          acctitle:
            val.DealSoldUnits[0] !== undefined
              ? val.DealSoldUnits[0].Deal.AffiliateCnicNavigation.AffiliateName
              : null,
        });
        if (val.DealSoldUnits[0].Deal.CommissionPer !== null) {
          document.getElementById("contractcomamount").value = parseFloat(
            Math.round(
              (val.DealSoldUnits[0].Deal.CommissionPer / 100) *
                val.DealSoldUnits[0].TotalSaleValue
            )
          ).toLocaleString();
          document.getElementById("contractcom").value = Math.round(
            val.DealSoldUnits[0].Deal.CommissionPer
          );
        }
        // if (val.DealSoldUnits[0].Deal.ProfitMarginPer !== null) {
        //     document.getElementById("AdditionalMrgAmt").value = Math.round(val.DealSoldUnits[0].Deal.ProfitMarginPer / 100 * val.DealSoldUnits[0].TotalSaleValue)
        //     document.getElementById("AdditionalMrgPer").value = val.DealSoldUnits[0].Deal.ProfitMarginPer;
        //   }
      } else if (
        val.DealBought !== undefined &&
        val.DealSoldUnits.length === 0
      ) {
        setisdeal(true);
        setisdealsold(false);
        setisfirstscnerio(false);

        setisdealbought(true);
        setdealrate("");
        setboughtratepermarla(
          parseFloat(Math.round(val.RatePerMarla)).toLocaleString()
        );
        setboughttotalprice(
          parseFloat(Math.round(val.RatePerMarla * valM)).toLocaleString()
        );
        setboughttotalpriceforadv(
          parseFloat(Math.round(val.CostPerMarla * valM)).toLocaleString()
        );

        // dealboughtcommissionamt(val.DealBought[0].AdvancePer/100 *val.DealSoldUnits[0].TotalSaleValue);
        // setdealboughtcommissionper(val.DealBought.CommissionPer);
        setboughtadditonalmarginper(val.DealBought.ProfitMarginPer);
        // setdealboughtadvanceval(projectadvanceper / 100 * (val.Price));
        // setdealboughtadvanceper(projectadvanceper);
        setprojectadvanceper(val.Section.AdvanceAmountPer);

        setdealboughtadvanceval(
          parseFloat(
            Math.round((val.Section.AdvanceAmountPer / 100) * val.Price)
          ).toLocaleString()
        );
        setdealboughtadvanceper(val.Section.AdvanceAmountPer);

        // if (val.DealBought.CommissionPer !== null) {
        //     if (document.getElementById("DealCommAmt") !== null && document.getElementById("DealCommPer") !== null) {
        //         document.getElementById("DealCommAmt").value = Math.round((val.Price - (val.valmarlas * val.CostPerMarla)) * val.DealBought.CommissionPer / 100)
        //         document.getElementById("DealCommPer").value = Math.round(val.DealBought.CommissionPer);
        //     }
        // }
        // if (val.DealBought.CommissionPer !== null) {

        let amt =
          (valM * val.CostPerMarla * val.DealBought.CommissionPer) / 100;

        document.getElementById("DealCommAmt").value = parseFloat(
          Math.round(amt)
        ).toLocaleString();
        setdealboughtcommissionamt(
          parseFloat(Math.round(valM * val.CostPerMarla)).toLocaleString()
        );
        document.getElementById("DealCommPer").value =
          val.DealBought.CommissionPer;

        // }
        if (val.DealBought.ProfitMarginPer !== null) {
          //   document.getElementById("AdditionalMrgAmt").value =val.DealBought.CommissionPer / 100 * val.DealSoldUnits[0].TotalSaleValue
          document.getElementById("AdditionalMrgPer").value =
            val.DealBought.ProfitMarginPer;
        }
      }
    }

    // setcostprice(parseFloat(val.NetAmount));

    suggestionlist1 = [];
    ReactDOM.render(null, document.getElementById("unitdemo"));
  };
  function calculaterate(e) {
    if (e.target.value !== "") {
      e.target.value = parseInt(
        document.getElementById("salerateval").value.replace(/,/g, "")
      ).toLocaleString();
    } else {
      e.target.value = "0";
    }
    var marlas = parseFloat(document.getElementById("marlas").value);
    var plotrate = parseFloat(
      document.getElementById("salerateval").value.replace(/,/g, "")
    );
    if (marlas == "") {
      marlas = "0";
    } else if (plotrate == "") {
      plotrate = "0";
    }

    var result = Math.ceil(parseFloat(marlas) * parseFloat(plotrate));

    setNetTotalAmount(parseFloat(Math.round(result)).toLocaleString());
    setNetAmount(parseFloat(Math.round(result)).toLocaleString());
    if (isdealsol) {
      setdealval(parseFloat(Math.round(result)).toLocaleString());
      setdealadvanceval(
        parseFloat(
          Math.round((dealadvanceper / 100) * parseFloat(result))
        ).toLocaleString()
      );

      let margin = parseInt(document.getElementById("AdditionalMrgPer").value);

      setadditonalmarginsold(
        parseFloat(
          Math.round(
            (parseFloat(result) -
              parseFloat(dealvalformarg.replace(/,/g, ""))) *
              (parseFloat(margin) / 100)
          )
        ).toLocaleString()
      );

      setaddmrgonchange(
        parseFloat(
          Math.round(
            parseFloat(result) - parseFloat(dealvalformarg.replace(/,/g, ""))
          )
        ).toLocaleString()
      );
    }
    if (isdealbought) {
      setboughttotalprice(parseFloat(Math.round(result)).toLocaleString());
      setdealboughtadvanceval(
        parseFloat(
          Math.round((dealboughtadvanceper / 100) * result)
        ).toLocaleString()
      );
      // let com=document.getElementById("contractcom").value;
      // document.getElementById("contractcomamount").value=com/100*result;

      // let margin = parseInt(document.getElementById("AdditionalMrgPer").value);
      // document.getElementById("AdditionalMrgAmt").value=((result-boughttotalpriceforadv)*(margin/100));
      // setadditonalmarginbought(
      //   parseFloat(
      //     Math.round(
      //       (parseFloat(result) -
      //         parseFloat(boughttotalpriceforadv.replace(/,/g, ""))) *
      //       (margin / 100)
      //     )
      //   ).toLocaleString()
      // );
      // setaddmrgonchange(
      //   parseFloat(
      //     Math.round(
      //       parseFloat(result) -
      //       parseFloat(boughttotalpriceforadv.replace(/,/g, ""))
      //     )
      //   ).toLocaleString()
      // );

      // console.log(dealval);
      // console.log(result);
      // console.log(margin);
      // console.log(((result - dealval) * margin) / 100);
    }

    setvaladvancerate(
      parseFloat(
        Math.round((projectadvanceper / 100) * result)
      ).toLocaleString()
    );

    document.getElementById("advanceval").disabled = false;
  }

  const [clientcnic, setclientcnic] = useState(null);
  const onTextChangeBuyerList = () => {
    var inputValue = document.getElementById("clients").value;
    var options = document.getElementById("buyerlist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        resultallclients.data.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });
        console.log("val", val);
        props.setCustomersNomineeRecord(val.CustomersNomineeRecord);
        setclientcnic(val.CustomerCnic);
        break;
      }
    }
  };

  const [affiliatecnic, setaffiliatecnic] = useState(null);
  const onTextChangeAffiliateList = () => {
    var inputValue = document.getElementById("affiliates").value;
    var options = document.getElementById("affiliatelist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        AffiliateData.data.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });

        setaffiliatecnic(val.AffiliateCnic);
        break;
      }
    }
  };

  useEffect(() => {
    // if (!isdealbought && !isdeal)
    {
      let value = parseFloat(
        document.getElementById("advanceval").value.replace(/,/g, "")
      );
      let total = parseFloat(
        document.getElementById("newcontractPrice").value.replace(/,/g, "")
      );
      if (value === "") {
        // document.getElementById("contractPrice").readOnly = false;
        setNetTotalAmount(parseFloat(Math.round(total)).toLocaleString());
      } else {
        // document.getElementById("contractPrice").readOnly = true;
        var result = parseFloat(total) - parseFloat(value);
        if (!Number.isNaN(result)) {
          setNetTotalAmount(parseFloat(Math.round(result)).toLocaleString());
        } else {
          setNetTotalAmount("");
        }
      }
    }
  });
  // useEffect(() => {
  //   if (!isdeal) {
  //     let value =
  //       document.getElementById("contractcom").value == ""
  //         ? ""
  //         : parseFloat(
  //           document.getElementById("contractcom").value.replace(/,/g, "")
  //         );
  //     let total =
  //       document.getElementById("newcontractPrice").value == ""
  //         ? ""
  //         : parseFloat(
  //           document
  //             .getElementById("newcontractPrice")
  //             .value.replace(/,/g, "")
  //         );
  //     console.log(total);
  //     if (value !== "") {

  //       let result = (parseFloat(value) / 100) * parseFloat(total);
  //       console.log(result);
  //       document.getElementById("contractcomamount").value = parseInt(
  //         Math.round(result)
  //       ).toLocaleString();

  //     }
  //   }
  // });
  // advance onchange
  const changetotal = (e) => {
    var value = parseFloat(e.target.value.replace(/,/g, ""));
    var total = parseFloat(
      document.getElementById("newcontractPrice").value.replace(/,/g, "")
    );
    if (value <= total) {
      let advance = (value / total) * 100;
      setadvancePer(Math.round(advance).toFixed(2));
    } else {
      toast.info("You cant add advance amount greater than total price");
      e.target.value = "";
      setadvancePer("");
    }

    if (value === "") {
      // document.getElementById("contractPrice").readOnly = false;
      setNetTotalAmount(parseFloat(Math.round(total)).toLocaleString());
    } else {
      // document.getElementById("contractPrice").readOnly = true;
      var result = parseFloat(total) - parseFloat(value);
      if (!Number.isNaN(result)) {
        setNetTotalAmount(parseFloat(Math.round(result)).toLocaleString());
      } else {
        setNetTotalAmount("");
      }
    }
  };

  // custom installments
  const custominstallments = () => {
    if (parseInt(installmentdifference) === 0) {
      toast.info("Remaining Amount is 0 , you can't create installments");
    } else if (document.getElementById("customDate").value === "") {
      toast.info("Select Date ");
    } else if (document.getElementById("customAmount").value === "") {
      toast.info("Enter custome installment amount");
    } else {
      if (parseFloat(document.getElementById("customAmount").value) > "0") {
        let sum = 0;
        InstallmentDurationList.map((x) => {
          if (x.amount != "" || x.amount != null || x.amount != undefined)
            sum = sum + parseFloat(x.amount);
        });

        let idv = idval + 1;
        let amount = parseFloat(
          document.getElementById("customAmount").value.replace(/,/g, "")
        );
        let installmentdate = document.getElementById("customDate").value;
        if (amount === "") {
          amount = "0";
        }
        sum = sum + parseFloat(amount);
        if (
          sum >
          parseFloat(
            document.getElementById("newcontractPrice").value.replace(/,/g, "")
          )
        ) {
          toast.info(
            "Installments value is greater than total receivable amount"
          );
        } else if (sum === 0) {
          toast.info("Please enter value greater than 0!");
        } else {
          // document.getElementById("advanceval").disabled = true;
          let item = {
            id: idv,
            amount: amount,

            date: installmentdate,
          };
          setidval(idv + 1);
          setInstallmentDurationList([...InstallmentDurationList, item]);
          // document.getElementById("NoOfInstallements").value = (
          //     InstallmentDurationList.length + 1
          // ).toString();
          document.getElementById("customAmount").value = "";
        }
      } else {
        toast.info("Please enter value greater than 0!");
      }
    }
  };
  const deleteinstallment = (idx) => {
    const rows = [...InstallmentDurationList];
    rows.splice(idx, 1);

    setInstallmentDurationList(rows);
  };
  // custom installments end
  // auto installments
  function monthDiff(dateFrom, dateTo) {
    return (
      1 +
      dateTo.getMonth() -
      dateFrom.getMonth() +
      12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    );
  }
  const autoinstallments = () => {
    if (parseInt(installmentdifference) === 0) {
      toast.info("Remaining Amount is 0 , you can't create installments");
    } else if (document.getElementById("noofinstall").value === "") {
      toast.info("Add no of installment");
    } else if (document.getElementById("startDate").value === "") {
      toast.info("Enter starting date ");
    } else if (document.getElementById("Enddate").value === "") {
      toast.info("Enter end date ");
    } else {
      let startmonth = document.getElementById("startDate").value;
      let endmonth = document.getElementById("Enddate").value;
      let noofinstall = parseInt(document.getElementById("noofinstall").value);
      let paydate = document.getElementById("paydate").value;

      if (startmonth !== "" && endmonth !== "" && noofinstall !== "") {
        document.getElementById("autoinstallbutton").disabled = "true";

        let totalmonths = monthDiff(new Date(startmonth), new Date(endmonth));

        let totalintallmentsmonths = totalmonths / noofinstall;

        let singleintallvalue = Math.round(
          parseFloat(installmentdifference.replace(/,/g, "")) / noofinstall
        );

        let tabledata = [];
        let dt = new Date(
          parseInt(startmonth.split("-")[0]),
          parseInt(startmonth.split("-")[1]) - 1,
          paydate
        );
        let tIV = 0;
        let i = 1;
        for (; i <= noofinstall - 1; i++) {
          if (Number(singleintallvalue.toString().slice(-2)) >= 50) {
            let amt = 100 - Number(singleintallvalue.toString().slice(-2));
            singleintallvalue = singleintallvalue + amt;
          } else if (Number(singleintallvalue.toString().slice(-2)) < 50) {
            singleintallvalue =
              singleintallvalue -
              Number(singleintallvalue.toString().slice(-2));
          }
          tIV += singleintallvalue;
          let item = {
            id: i,
            amount: parseFloat(singleintallvalue),
            date: moment(dt).format("MM/DD/YYYY"),
          };
          dt.setMonth(dt.getMonth() + parseInt(totalintallmentsmonths));
          setidval(i);
          tabledata.push(item);
          // setidval(idv + 1);
        }
        let item = {
          id: i,
          amount: parseFloat(installmentdifference.replace(/,/g, "") - tIV),
          date: moment(dt).format("MM/DD/YYYY"),
        };

        tabledata.push(item);
        setInstallmentDurationList(tabledata);
        // setInstallmentDurationList([...InstallmentDurationList, item]);
      } else {
        toast.info("select start, end month and number of installments");
      }
    }
  };
  // auto installments end
  //JointBuyer Suggestion start
  var bClientsuggestionlist = [];
  const bclientonTextChange = (e) => {
    const value = e.target.value;
    console.log(value);
    var inputValue = document.getElementById("jBuyer").value;
    var options = document.getElementById("jointbuyerlist").childNodes;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value === inputValue) {
        console.log(options[i].attributes?.CustomerCnic.value);
        setbuyercnicval(options[i].attributes?.CustomerCnic.value);
        setbuyercnicval2(options[i].attributes?.CustomerCnic2.value);
        // console.log(cashAccounts[options[i].attributes.idxx.value]?.SaleInvoices);
        break;
      }
    }
    // bClientsuggestionselected(item)
    // if (value === "") {
    //   ReactDOM.render(null, document.getElementById("bclientdemo"));
    // } else {
    //   let suggestions = [];
    //   if (value.length > 0) {
    //     const regex = new RegExp(`${value}`, "i");
    //     suggestions = resultallclients.data
    //       .sort()
    //       .filter((v) => regex.test(v.CustomerName));
    //   }

    //   bClientsuggestionlist = suggestions;
    //   if (bClientsuggestionlist.length === 0) {
    //     ReactDOM.render(null, document.getElementById("bclientdemo"));
    //   } else {
    //     ReactDOM.render(
    //       <ul className="suggestbox">
    //         {bClientsuggestionlist.map((item) => (
    //           <li
    //             className="listcss"
    //             onClick={() => bClientsuggestionselected(item)}
    //           >
    //             {item === undefined ? item : item.CustomerName}
    //           </li>
    //         ))}
    //       </ul>,
    //       document.getElementById("bclientdemo")
    //     );
    //   }
    // }
  };
  const bClientsuggestionselected = (val) => {
    document.getElementById("jBuyer").value = val.CustomerName;
    setbuyercnicval(val.CustomerCnic);

    bClientsuggestionlist = [];
    ReactDOM.render(null, document.getElementById("bclientdemo"));
  };
  const bcustominstallmentsdel = () => {
    document.getElementById("advanceval").disabled = false;
    setjointbuyers([]);
    setbuyercnicval("");
  };

  const bcustominstallments = () => {
    var name = document.getElementById("jBuyer").value;
    if (buyercnicval === "") {
      toast.info("select buyer first");
    } else {
      var item = {
        Name: name,
        CustomerCnic: buyercnicval,
        CustomerCnic2: buyercnicval2,
      };
      setjointbuyers([...jointbuyers, item]);
      document.getElementById("jBuyer").value = "";
    }
  };
  //joint buyersuggestion end
  useEffect(() => {
    dispatch(getaffiliatepopulatetable());
    dispatch(getallcustomers());
    dispatch(getallcontractpopulate());
  }, [dispatch]);

  useEffect(() => {
    if (
      isdealbought === true &&
      document.getElementById("Managercheck").checked === true
    ) {
      setmanagercommissonexp(true);
      // document.getElementById("hiddendiv").style.display = "hidden";
    } else if (
      isdealbought === true &&
      document.getElementById("Affiliatecheck").checked === true
    ) {
      // document.getElementById("hiddendiv").style.display = "block";
      setmanagercommissonexp(false);
    }
  }, []);
  const openmodalrecovery = (x) => {
    document.getElementById("modalofaddnominee").style.display = "block";
  };
  console.log(contractprojectpopulatedata?.data?.Projects);
  const removeItemFromArray = (array, itemToRemove) => {
    const index = array?.indexOf(itemToRemove);
    console.log(index);

    array.splice(itemToRemove, 1);
    setjointbuyers(array);
    setrender(!render);
  };
  return (
    <>
      {/* <div className="row ">
        <div className="col-md-12  text-left page_heading">
          <h4 className="m-0">Add Contract</h4>
        </div>
      </div> */}
      <div className="col-md-12 text-right">
        <span>
          {contractprojectpopulatedata?.data?.Projects === undefined ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </span>
      </div>
      <div className="row">
        <div className="col-md-2 mt-3 text-left p-0">
          <label className="input_label m-0 ">Project:</label>
        </div>

        <div className="col-md-3  mt-3  p-0">
          <select
            id="contractproject"
            className="input_styling  ml-md-3"
            onChange={setunits}
            disabled
          >
            <option>Select Project</option>
            {contractprojectpopulatedata.data.Projects !== undefined
              ? contractprojectpopulatedata.data.Projects.map((x) =>
                  parseInt(`${localStorage.getItem("ProjectId")}`) ===
                  parseInt(x.ProjectId) ? (
                    <option
                      selected
                      data-advpay={x.AdvancePayment}
                      data-id={x.ProjectId}
                    >
                      {x.ProjectName}
                    </option>
                  ) : null
                )
              : null}
          </select>
        </div>
        <div className="col-md-2 mt-3  p-0"></div>
        <div className="col-md-2 mt-3 text-left p-0">
          <label className="input_label m-0 ">Date:</label>
        </div>
        <div className="col-md-3 mt-3  p-0">
          <input
            type="date"
            className="input_date_styling ml-md-3 "
            id="contractDate"
            defaultValue={moment().format("YYYY-MM-DD")}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 mt-3 text-left p-0">
          <label className="input_label m-0">Unit:</label>
        </div>
        <div className="col-md-3 mt-3   p-0">
          <span>
            <input
              type="text"
              id="contractShop"
              placeholder="Select Units"
              className="input_styling ml-md-3 "
              onChange={onTextChangeUnit}
              autoComplete="off"
              list="mylist"
            ></input>
            <datalist id="mylist">
              {unitlist?.map((x, idx) => (
                <option index={idx} value={x.UnitName}>
                  {x.Status}
                </option>
              ))}
            </datalist>
          </span>
          <span id="unitdemo"></span>
        </div>
        <div className="col-md-2 mt-3  p-0"></div>
        <div className="col-md-2 mt-3  p-0">
          <span className="txt">Joint-Buyers:</span>{" "}
          <input
            type="checkbox"
            id="myCheck"
            checked={jointbuyers.length > 0}
            onClick={() => {
              // var checkBox = document.getElementById("myCheck");
              // if (checkBox.checked == true) {
              document.getElementById("jBuyer").value = "";
              document.getElementById("jointbuyermodel").style.display =
                "block";
              // }
            }}
          />
        </div>
        {/* <div className="col-md-1 mt-3  p-0"></div> */}

        <div style={{ marginLeft: "14px" }} className="col-md-2 mt-3  p-0">
          <span className="txt">Re-Sale Contract:</span>{" "}
          <input
            type="checkbox"
            id="ResaleCheck"
            value={resaleCheck}
            onClick={() => {
              setresaleCheck(!resaleCheck);
            }}
          />
        </div>
        {/* <div className="col-md-5 mt-3 text-left p-0">
          <div className="row " hidden={isdealsol}>
            <div className="col-md-4 text-right">
              <span className="txt input_label"> Affiliate</span>
            </div>
            <div className="col-md-1  text-left">
              <span>
                {" "}
                <input
                  type="radio"
                  id="Affiliatecheck"
                  name="InvestmentType"
                  checked={individualcheck}
                  onClick={() => {
                    document.getElementById("showhide").style.display = "none";
                    document.getElementById("showhide2").style.display =
                      "block";

                    setindividualcheck(true);
                  }}
                ></input>
              </span>
            </div>
            <div className="col-md-4 text-right">
              <span className="txt input_label" hidden={isdealsol}>
                {" "}
                Manager
              </span>
            </div>
            <div className="col-md-1  text-left">
              <span hidden={isdealsol}>
                {" "}
                <input
                  type="radio"
                  name="managercheck"
                  id="Managercheck"
                  checked={!individualcheck}
                  onClick={() => {
                    setindividualcheck(false);
                    document.getElementById("showhide2").style.display = "none";
                    document.getElementById("showhide").style.display = "block";
                    // if (isdealsol) { setmanagercommissonexp(true); }

                    if (isdealbought) {
                      // document
                      //   .getElementById("contractcom")
                      //   .removeAttribute("disabled");
                      // document
                      //   .getElementById("contractcomamount")
                      //   .removeAttribute("disabled");
                    }
                  }}
                ></input>
              </span>
            </div>
          </div>
        </div> */}
      </div>
      <div className="row">
        <div className="col-md-2 mt-3 text-left p-0">
          <label className="input_label m-0 ">Buyer:</label>
        </div>
        <div className="col-md-3 mt-3  p-0">
          <input
            id="clients"
            type="text"
            list="buyerlist"
            className="input_styling  ml-md-3"
            onChange={onTextChangeBuyerList}
            autoComplete="off"
          ></input>
          <datalist id="buyerlist">
            {resultallclients?.data?.map((x, idx) => (
              <option index={idx} value={`${x.CustomerName} (${idx + 1})`}>
                {x.CustomerCnic2}
              </option>
            ))}
          </datalist>
        </div>
        <div className="col-md-1 mt-3   p-0">
          <button
            className="save_btn ml-2  "
            onClick={() => {
              setshowBuyerModal(true);
            }}
          >
            Add
          </button>
        </div>
        {/* <div className="col-md-1 mt-3 text-left p-0"></div> */}
        <div className="col-md-1 mt-3  p-0"></div>

        <div className="col-md-5 mt-3 text-left p-0">
          <div className="row text-left " hidden={isdealsol}>
            {/* <div style={{backgroundColor:"red",textAlign:"start",alignSelf:"start"}} className="col-md-2"> */}
            <span className="txt input_label "> Affiliate</span>
            {/* </div> */}
            <div className="col-md-1 text-left ">
              <span>
                {" "}
                <input
                  type="radio"
                  id="Affiliatecheck"
                  name="InvestmentType"
                  checked={individualcheck}
                  onClick={() => {
                    document.getElementById("showhide").style.display = "none";
                    document.getElementById("showhide2").style.display =
                      "block";

                    setindividualcheck(true);
                  }}
                ></input>
              </span>
            </div>
            <div className="col-md-3 mt-3  p-0"></div>

            {/* <div  className="col-md-2 text-left"> */}
            <span className="txt input_label" hidden={isdealsol}>
              {" "}
              Manager
            </span>
            {/* </div> */}
            <div className="col-md-1  text-left">
              <span hidden={isdealsol}>
                {" "}
                <input
                  type="radio"
                  name="managercheck"
                  id="Managercheck"
                  checked={!individualcheck}
                  onClick={() => {
                    setindividualcheck(false);
                    document.getElementById("showhide2").style.display = "none";
                    document.getElementById("showhide").style.display = "block";
                    // if (isdealsol) { setmanagercommissonexp(true); }

                    if (isdealbought) {
                      // document
                      //   .getElementById("contractcom")
                      //   .removeAttribute("disabled");
                      // document
                      //   .getElementById("contractcomamount")
                      //   .removeAttribute("disabled");
                    }
                  }}
                ></input>
              </span>
            </div>
          </div>
        </div>
        {/* <div style={{ display: "none" }} id="showhide" className="col-md-5 p-0">
          <div className="row ">
            <div className="col-md-4 mt-4 text-left p-0">
              <label className="input_label m-0">Manager:</label>
            </div>
            <div className="col-md-1 mt-4 text-left p-0"></div>
            <div className="col-md-7 mt-4   p-0">
              <select
                id="employees"
                className="input_styling  ml-md-2"
                onChange={() => {
                  document.getElementById("contractcom").value = "0";
                  document.getElementById("contractcomamount").value = "0";
                }}
              >
                <option>Select</option>

                {contractprojectpopulatedata.data.Managers !== undefined
                  ? contractprojectpopulatedata.data.Managers.map((x) =>
                    parseInt(x.EmployeeId) ===
                      parseInt(stateleademployeeid) ? (
                      <option
                        selected
                        data-com={x.CommissionPercentage}
                        data-id={x.EmployeeId}
                      >
                        {x.EmpName}
                      </option>
                    ) : (
                      <option
                        data-com={x.CommissionPercentage}
                        data-id={x.EmployeeId}
                      >
                        {x.EmpName}
                      </option>
                    )
                  )
                  : null}
              </select>
            </div>
          </div>
        </div> */}
        {/* <div id="showhide2" className="col-md-5 p-0">
          <div className="row">
            <div className="col-md-4 mt-3 text-left p-0">
              <label className="input_label m-0">Affiliate :</label>
            </div>
            <div className="col-md-1 mt-3 text-left p-0"></div>
            <div className="col-md-7 mt-3   p-0">
              <input
                id="affiliates"
                className="input_styling  ml-md-2"
                list="affiliatelist"
                autoComplete="off"
                onChange={onTextChangeAffiliateList}
              ></input>
              <datalist id="affiliatelist">
                {AffiliateData?.data?.map((x, idx) =>
                  isdealsol ? (
                    <option selected>{brokerdetail.acctitle}</option>
                  ) : (
                    <option index={idx} value={x.AffiliateName}>
                      {x.AffiliateCnic}
                    </option>
                  )
                )}
              </datalist>
            </div>
          </div>
        </div> */}
      </div>
      <div className="row">
        <div className="col-md-2 mt-3 text-left p-0">
          <label className="input_label m-0 ">
            {marlafieldchange === "MARLA" ? "Marlas" : "SQFT"}
          </label>
        </div>
        <div className="col-md-3 mt-3  p-0">
          <input
            type="number"
            className="input_styling ml-md-3 "
            id="marlas"
            readOnly
            defaultValue={valmarlas}
          />
        </div>
        <div className="col-md-2 mt-3  p-0"></div>

        <div style={{ display: "none" }} id="showhide" className="col-md-5 p-0">
          <div className="row ">
            <div className="col-md-4 mt-3 text-left p-0">
              <label className="input_label m-0">Manager:</label>
            </div>
            <div className="col-md-1 mt-4 text-left p-0"></div>
            <div className="col-md-7 mt-3   p-0">
              <select
                id="employees"
                className="input_styling  ml-md-2"
                onChange={() => {
                  document.getElementById("contractcom").value = "0";
                  document.getElementById("contractcomamount").value = "0";
                }}
              >
                <option>Select</option>

                {contractprojectpopulatedata.data.Managers !== undefined
                  ? contractprojectpopulatedata.data.Managers.map((x) =>
                      parseInt(x.EmployeeId) ===
                      parseInt(stateleademployeeid) ? (
                        <option
                          selected
                          data-com={x.CommissionPercentage}
                          data-id={x.EmployeeId}
                        >
                          {x.EmpName}
                        </option>
                      ) : (
                        <option
                          data-com={x.CommissionPercentage}
                          data-id={x.EmployeeId}
                        >
                          {x.EmpName}
                        </option>
                      )
                    )
                  : null}
              </select>
            </div>
          </div>
        </div>
        <div id="showhide2" className="col-md-5 p-0">
          <div className="row">
            <div className="col-md-4 mt-3 text-left p-0">
              <label className="input_label m-0">Affiliate :</label>
            </div>
            <div className="col-md-1 mt-3 text-left p-0"></div>
            <div className="col-md-5 mt-3   p-0">
              <input
                id="affiliates"
                className="input_styling  ml-md-2"
                list="affiliatelist"
                autoComplete="off"
                onChange={onTextChangeAffiliateList}
              ></input>
              <datalist id="affiliatelist">
                {AffiliateData?.data?.map((x, idx) =>
                  isdealsol ? (
                    <option selected>{brokerdetail.acctitle}</option>
                  ) : (
                    <option index={idx} value={x.AffiliateName}>
                      {x.AffiliateCnic}
                    </option>
                  )
                )}
              </datalist>
            </div>
            <div className="col-md-1 mt-3   p-0">
              <button
                className="save_btn ml-2  "
                onClick={() => {
                  setshowAffiliateModal(true);
                }}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 mt-3 text-left p-0">
          <label className="input_label m-0">Advance %:</label>
        </div>
        <div className="col-md-3 mt-3   p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="adv%"
            value={advancePer}
            onChange={(e) => {
              if (Number(e.target.value) <= 100) {
                setadvancePer(e.target.value);
                let advance =
                  (parseFloat(
                    document
                      .getElementById("newcontractPrice")
                      .value?.replace(/,/g, "")
                  ) /
                    100) *
                  Number(e.target.value);
                document.getElementById("advanceval").value =
                  Math.round(advance).toLocaleString();
              }
            }}
          />
        </div>
        <div className="col-md-2 mt-3  p-0"></div>

        <div className="col-md-2 mt-3 text-left p-0">
          <label className="input_label m-0 ">Sale Rate:</label>
        </div>
        <div className="col-md-3 mt-3  p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            onChange={calculaterate}
            defaultValue={
              isdealsol
                ? valdealrate
                : isdealbought
                ? boughtratepermarla
                : valsalerate
            }
            id="salerateval"
          />
        </div>

        <div className="col-md-2 mt-3 text-left p-0">
          <label className="input_label m-0">Advance :</label>
        </div>
        <div className="col-md-3 mt-3   p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            defaultValue={
              isdealsol
                ? dealadvanceval
                : isdealbought
                ? dealboughtadvanceval
                : valadvancerate
            }
            key={
              isdealsol
                ? dealadvanceval
                : isdealbought
                ? dealboughtadvanceval
                : valadvancerate
            }
            id="advanceval"
            onChange={changetotal}
          />
        </div>
        <div className="col-md-2 mt-3  p-0">
          <span className="txt" hidden={!isdealsol}>
            Paid to gravity:
          </span>{" "}
          <input
            type="checkbox"
            id="advancepaidtogravity"
            hidden={!isdealsol}
          />
        </div>
        <div className="col-md-2 mt-3 text-left p-0">
          <label className="input_label m-0">Total :</label>
        </div>
        <div className="col-md-3 mt-3   p-0">
          {/* <input type="number" className="input_styling ml-md-3 " id="newcontractPrice"
                            value={dealval!==null&& dealval!==undefined?dealval:NetAmount} disabled /> */}
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="newcontractPrice"
            value={
              isdealsol ? dealval : isdealbought ? boughttotalprice : NetAmount
            }
            disabled
          />
        </div>
        {/* <div className="col-md-2 mt-3  p-0"></div> */}
      </div>

      <div className="row pr-md-2 pt-md-4">
        <div className=" col-6 pr-0 pl-0  "></div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
          className=" col-6 pr-0 pl-0  text-right "
        >
          <span>
            <button
              className="cancel_btn ml-2  "
              onClick={props.cancelcontracttable}
            >
              Cancel
            </button>
          </span>
          <span className="ml-2">
            {isLoading ? (
              <>
                <Loader
                  type="Oval"
                  color="black"
                  height={30}
                  width={30}
                  radius={12}
                />
              </>
            ) : null}
          </span>
          <span>
            <button
              className="save_btn ml-2  "
              id="instasavebtn"
              onClick={() => {
                savecontract();
              }}
            >
              <BsCloudUpload size="1.3em" />
              Save
            </button>
          </span>
        </div>
      </div>

      {/* <div className="  mt-3 overflow-auto" style={{ height: "145px" }}>
            <table class="table tableCash table-hover ">
              <thead class="purple whiteText">
                <tr>
                  <th scope="col">Installment No</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Date</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {InstallmentDurationList.map((x, idx) => (
                  <tr>
                    <th scope="row">{x.id}</th>
                    <td>{x.amount.toLocaleString()}</td>
                    <td>{x.date}</td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteinstallment(idx)}
                    >
                      <MdDeleteForever size="1.6em" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> */}
      {/* <div className="row mt-1">
            <div className="col-6 text-center">
              <b>Total:</b> {installmenttotal}
            </div>
  
          </div> */}

      <div id="jointbuyermodel" class="modalscreen ">
        <div class="modal-content-screen1">
          <span
            class="close"
            onClick={() => {
              var checkBox = document.getElementById("myCheck");
              if (buyercnicval === "") {
                checkBox.checked = false;
              }
              document.getElementById("jointbuyermodel").style.display = "none";
            }}
          >
            &times;
          </span>
          <div className="container">
            <div className="col-12 pl-0">
              <span className="txt">
                <b>Buyer Name</b>
              </span>
              <div className="row">
                <div className="col-4 loaderclass mt-1 pl-0 text-left">
                  <span>
                    {" "}
                    <input
                      type="text"
                      placeholder="Select Buyer"
                      onChange={bclientonTextChange}
                      id="jBuyer"
                      className="input_styling pl-0"
                      list="jointbuyerlist"
                      autoComplete="off"
                    />
                  </span>
                  <datalist id="jointbuyerlist">
                    {resultallclients.data?.map((x, idx) => (
                      <option
                        index={idx}
                        CustomerCnic={x?.CustomerCnic}
                        CustomerCnic2={x?.CustomerCnic2}
                        value={`${x.CustomerName} (${idx + 1})`}
                      >
                        {x.CustomerCnic2}
                      </option>
                    ))}
                  </datalist>
                  <span id="bclientdemo"></span>
                  <span> </span>
                </div>

                <div className="col-2 loaderclass mt-1 pl-0 text-left">
                  <button
                    className="save_btn ml-2  "
                    onClick={bcustominstallments}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-2 overflow-auto" style={{ height: "300px" }}>
              <table class="table tableCash table-hover">
                <thead class="purple whiteText">
                  <tr>
                    <th scope="col">Sr.No</th>
                    <th scope="col">Name</th>
                    <th scope="col">Cnic</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {jointbuyers?.map((x, id) => (
                    <tr>
                      <th scope="row">{id + 1}</th>
                      <td>{x?.Name}</td>
                      <td>{x?.CustomerCnic2}</td>
                      <td>
                        <MdDeleteForever
                          size="1.4em"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            removeItemFromArray(jointbuyers, id);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div id="modalofaddnominee" class="modalscreen ">
        <div class="modal-content-dashboard pt-1">
          <div className="row ">
            <div className="col-12  page_heading">
              <h4 className="  text-center "> Add Nominee</h4>
              <span
                class="close pr-2  "
                onClick={() => {
                  document.getElementById("modalofaddnominee").style.display =
                    "none";
                }}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="row ">
            <div className="col-12  mt-3">
              <AddNominee
                cancelcontracttable={props.cancelcontracttable}
                statecontractdata={props.statecontractdata}
                stateCustomersNomineeRecord={props.stateCustomersNomineeRecord}
              />
            </div>
          </div>
        </div>
      </div>
      {showBuyerModal && (
        <AddBuyerModal setshowBuyerModal={setshowBuyerModal} />
      )}
      {showAffiliateModal && (
        <AddAffiliateModal setshowAffiliateModal={setshowAffiliateModal} />
      )}
    </>
  );
};
