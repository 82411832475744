import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import tgic from "../../../../assets/tgic.png";
import moment from "moment";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
import IndividualPrintHeader from "../../../Prints/PrintHeader/IndividualPrintHeader";
var converter = require("number-to-words");
class GeneralPayPrint extends Component {
  render() {
    let credit = this.props?.printData?.Transaction?.length
      ? 0
      : this.props?.printData?.GeneralVoucherSaved?.NetAmount;
    let debit = 0;

    console.log("this.props.printData", this.props.printData);
    return (
      <div className="row m-0 p-0">
        {/* <IndividualPrintHeader /> */}
        <div className="col-12 d-flex justify-content-between align-items-center">
          <div>Print Date: {moment()?.format("DD-MMM-YY, hh:mm a")}</div>

          <div>
            <img
              src={tgic}
              style={{
                height: "120px",
                width: "120px",
                mixBlendMode: "multiply",
              }}
              className="pt-2"
              alt="Company logo"
            />
          </div>
        </div>

        <div className="col-12 mt-5">
          <h4 className="text-center">
            <u>General Payment</u>
          </h4>
          <h4 className="text-center">{localStorage.getItem("ProjectName")}</h4>
        </div>

        <div className="col-6 mt-2">
          Voucher No:{" "}
          {this.props.printData !== undefined
            ? this.props.printData.GeneralVoucherId
            : null}
        </div>
        <div className="col-6 mt-2 text-right">
          Voucher Date:{" "}
          {this.props.printData !== undefined
            ? moment(this.props.printData.EntryDate.split("T")[0]).format(
                "DD-MMM-YYYY"
              )
            : null}
        </div>

        <div className="col-6 mt-2">
          Payment Type:{" "}
          {this.props.printData !== undefined
            ? this.props.printData.PaymentType
            : null}
        </div>
        {/* <div className="col-6 mt-2 text-right">
                    Print Date:{" "}
                    {moment().format("DD-MMM-YYYY")}
                </div> */}

        {/* <div className="col-12 mt-2">
                    Received with thanks from:
                </div>
            
                <div className="col-6 mt-2">
                    Name: {this.props.printData !== undefined ?

this.props.printData.AffiliateCnicNavigation.AffiliateName

                                : null}
                </div>
                <div className="col-6 mt-2 text-right">
                    CNIC:
                    {this.props.printData !== undefined ?

                        this.props.printData.Contract.AffiliateCnic

                                : null}
                </div>
                
                <div className="col-6 mt-2">
                    Cell: {this.props.printData !== undefined ?

                        this.props.printData.Contract.AffiliateCnicNavigation.PhoneNumber

                                : null}
                </div>
                 */}

        <div className="col-12 mt-3">
          <table className="table">
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Sr.
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  ID
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Acc. Name
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Narration
                </th>
                {/* {this.props.printData?.PaymentType === "BANK" && (
                  <th
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    Cheque Number
                  </th>
                )} */}
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Debit
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Credit
                </th>
              </tr>
            </thead>

            {this.props.printData !== undefined ? (
              <tbody>
                {this.props?.printData?.Transaction?.length ? (
                  this.props?.printData?.Transaction?.map((row, idx) => {
                    if (row?.TransactionType === "DEBIT") {
                      debit += row?.Amount;
                    } else {
                      credit += row?.Amount;
                    }

                    return (
                      <tr key={idx}>
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        >
                          {idx + 1}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        >
                          {row?.Account?.AccountId}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        >
                          {row?.Account?.AccountTitle}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        >
                          {row?.Narration}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        >
                          {row?.TransactionType === "DEBIT"
                            ? parseFloat(row?.Amount).toLocaleString()
                            : ""}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        >
                          {row?.TransactionType === "CREDIT"
                            ? parseFloat(row?.Amount).toLocaleString()
                            : ""}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    {this.props?.printData?.GeneralVoucherSaved && (
                      <tr>
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        >
                          1
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        >
                          {
                            this.props?.printData?.GeneralVoucherSaved
                              ?.CreditAccount?.AccountId
                          }
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        >
                          {
                            this.props?.printData?.GeneralVoucherSaved
                              ?.CreditAccount?.AccountTitle
                          }
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        >
                          {
                            this.props?.printData?.GeneralVoucherSaved
                              ?.Narration
                          }
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        ></td>
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        >
                          {parseFloat(
                            this.props?.printData?.GeneralVoucherSaved
                              ?.NetAmount
                          ).toLocaleString()}
                        </td>
                      </tr>
                    )}

                    {this.props?.printData?.GeneralVoucherSavedDetail?.map(
                      (row, idx) => {
                        debit += row?.Amount;
                        return (
                          <tr key={idx}>
                            <td
                              style={{
                                borderLeft: "1px solid grey",
                                borderRight: "1px solid grey",
                              }}
                            >
                              {idx + 2}
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid grey",
                                borderRight: "1px solid grey",
                              }}
                            >
                              {row?.Account?.AccountId}
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid grey",
                                borderRight: "1px solid grey",
                              }}
                            >
                              {row?.Account?.AccountTitle}
                            </td>

                            <td
                              style={{
                                borderLeft: "1px solid grey",
                                borderRight: "1px solid grey",
                              }}
                            >
                              {row?.Description}
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid grey",
                                borderRight: "1px solid grey",
                              }}
                            >
                              {parseFloat(row?.Amount).toLocaleString()}
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid grey",
                                borderRight: "1px solid grey",
                              }}
                            ></td>
                          </tr>
                        );
                      }
                    )}
                  </>
                )}

                {/* <tr>
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    1
                  </td>
                  {this.props.printData?.Transaction[0] !== undefined ? (
                    this.props.printData?.Transaction[0].Account !== null &&
                    this.props.printData?.Transaction[0].Account !==
                      undefined ? (
                      <td>
                        {
                          this.props.printData?.Transaction[0].Account
                            .AccountTitle
                        }
                      </td>
                    ) : (
                      <td></td>
                    )
                  ) : (
                    <td>
                      {
                        this.props.printData?.GeneralVoucherSaved?.DebitAccount
                          ?.AccountTitle
                      }
                    </td>
                  )}
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    {this.props.printData.Narration}
                  </td>
                  {this.props.printData?.PaymentType === "BANK" && (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      {this.props.printData?.ChequeNumber}
                    </td>
                  )}
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    {parseFloat(this.props.printData.Amount).toLocaleString()}
                  </td>
                </tr> */}
              </tbody>
            ) : null}

            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th style={{ borderLeft: "1px solid grey" }}>Total</th>
                <th></th>

                <th></th>
                <th></th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  {this.props?.printData !== undefined
                    ? parseFloat(debit)?.toLocaleString()
                    : null}
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  {this.props?.printData !== undefined
                    ? parseFloat(credit)?.toLocaleString()
                    : null}
                </th>
              </tr>
            </thead>
          </table>
        </div>

        <div className="col-12 mt-2">
          Total Amount:{" "}
          {this.props.printData !== undefined
            ? parseFloat(this.props.printData.Amount).toLocaleString()
            : null}
        </div>

        <div className="col-12 mt-2">
          Amount in words:{" "}
          {this.props.printData !== undefined
            ? converter
                .toWords(parseFloat(this.props.printData.Amount))
                ?.toUpperCase()
            : null}
        </div>

        {/* <div className="col-12 mt-2">
                    On account payment of shop:
                </div>                          
             */}

        <div className="col-12 mt-5 d-flex justify-content-center flex-wrap">
          <div className="mr-5 d-flex flex-column gap-1">
            <b className="text-center">___________________</b>
            <b className="text-center mt-2">Prepared By:</b>
          </div>
          <div className="mr-5 d-flex flex-column gap-1">
            <b className="text-center">___________________</b>
            <b className="text-center mt-2">Authorized By:</b>
          </div>
          <div className="mr-5 d-flex flex-column gap-1">
            <b className="text-center">___________________</b>
            <b className="text-center mt-2">Audited By:</b>
          </div>
          <div className="mr-5 d-flex flex-column gap-1">
            <b className="text-center">___________________</b>
            <b className="text-center mt-2">Cashier:</b>
          </div>
          <div className="d-flex flex-column gap-1">
            <b className="text-center">___________________</b>
            <b className="text-center mt-2">Received By:</b>
          </div>
        </div>
        <div className="col-12 mt-4">
          <hr />
        </div>
      </div>
    );
  }
}

export default GeneralPayPrint;

export class LandPaymentPrint extends Component {
  render() {
    return (
      <div className="row m-0 p-0">
           <div className="col-12 d-flex justify-content-between align-items-center">
          <div>Print Date: {moment()?.format("DD-MMM-YY, hh:mm a")}</div>

          <div>
            <img
              src={tgic}
              style={{
                height: "120px",
                width: "120px",
                mixBlendMode: "multiply",
              }}
              className="pt-2"
              alt="Company logo"
            />
          </div>
        </div>
        <h4 className="col-12 mt-5 text-center">
          <u>Land Payment</u>
        </h4>
        <h4 className="col-12 text-center">
          {localStorage.getItem("ProjectName")}
        </h4>
        <div className="col-6 mt-2">
          Voucher No:{" "}
          {this.props.printData !== undefined
            ? this.props.printData.LandPaymentVoucherId
            : null}
        </div>
        <div className="col-6 mt-2 text-right">
          Voucher Date:{" "}
          {this.props.printData !== undefined
            ? moment(this.props.printData.EntryDate?.split("T")[0]).format(
                "DD-MMM-YYYY"
              )
            : null}
        </div>

        <div className="col-6 mt-2">
          Payment Type:{" "}
          {this.props.printData !== undefined
            ? this.props.printData.PaymentType
            : null}
        </div>
        {/* <div className="col-6 mt-2 text-right">
                    Print Date:{" "}
                    {moment().format("DD-MMM-YYYY")}
                </div> */}

        {/* <div className="col-12 mt-2">
                    Received with thanks from:
                </div>
            
                <div className="col-6 mt-2">
                    Name: {this.props.printData !== undefined ?

this.props.printData.AffiliateCnicNavigation.AffiliateName

                                : null}
                </div>
                <div className="col-6 mt-2 text-right">
                    CNIC:
                    {this.props.printData !== undefined ?

                        this.props.printData.Contract.AffiliateCnic

                                : null}
                </div>
                
                <div className="col-6 mt-2">
                    Cell: {this.props.printData !== undefined ?

                        this.props.printData.Contract.AffiliateCnicNavigation.PhoneNumber

                                : null}
                </div>
                 */}
        <div className="col-6 mt-2 text-right"></div>

        <div className="col-12 mt-3">
          <table className="table">
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Sr.
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Project
                </th>

                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Creditor
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Land
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Narration
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Amount
                </th>
              </tr>
            </thead>

            {this.props.printData !== undefined ? (
              <tbody>
                <tr>
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    1
                  </td>
                  {this.props.printData.Project !== undefined &&
                  this.props.printData.Project !== null ? (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      {this.props.printData.Project.ProjectName}
                    </td>
                  ) : (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    ></td>
                  )}
                  {this.props.printData.LandCreditor !== undefined &&
                  this.props.printData.LandCreditor !== null ? (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      {this.props.printData.LandCreditor.LandCreditorName}
                    </td>
                  ) : (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    ></td>
                  )}
                  {this.props.printData.LandName !== null ? (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      {this.props.printData.LandName}
                    </td>
                  ) : (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    ></td>
                  )}
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    {this.props.printData.Narration}
                  </td>
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    {parseFloat(this.props.printData.NetPaid).toLocaleString()}
                  </td>
                </tr>
              </tbody>
            ) : null}
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th style={{ borderLeft: "1px solid grey" }}>Total</th>
                <th></th>

                <th></th>
                <th></th>
                <th></th>

                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  {this.props.printData !== undefined
                    ? parseFloat(this.props.printData.NetPaid).toLocaleString()
                    : null}
                </th>
              </tr>
            </thead>
          </table>
        </div>

        <div className="col-12 mt-2">
          Total Amount:{" "}
          {this.props.printData !== undefined
            ? parseFloat(this.props.printData.NetPaid).toLocaleString()
            : null}
        </div>

        <div className="col-12 mt-2">
          Amount in words:{" "}
          {this.props.printData !== undefined
            ? converter.toWords(parseFloat(this.props.printData.NetPaid))
            : null}
        </div>

        {/* <div className="col-12 mt-2">
                    On account payment of shop:
                </div>                          
             */}
        {/* <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Received By:</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Authorized By:</b>
        </div> */}
       <div className="col-12 mt-5 d-flex justify-content-center flex-wrap">
          <div className="mr-5 d-flex flex-column gap-1">
            <b className="text-center">___________________</b>
            <b className="text-center mt-2">Prepared By:</b>
          </div>
          <div className="mr-5 d-flex flex-column gap-1">
            <b className="text-center">___________________</b>
            <b className="text-center mt-2">Authorized By:</b>
          </div>
          <div className="mr-5 d-flex flex-column gap-1">
            <b className="text-center">___________________</b>
            <b className="text-center mt-2">Audited By:</b>
          </div>
          <div className="mr-5 d-flex flex-column gap-1">
            <b className="text-center">___________________</b>
            <b className="text-center mt-2">Cashier:</b>
          </div>
        </div>
        <div className="col-12 mt-4">
          <hr />
        </div>
      </div>
    );
  }
}
export class LoanPaymentPrint extends Component {
  render() {
    return (
      <div className="row m-0 p-0">
        <IndividualPrintHeader />

        <h4 className="col-12 text-center">
          <u>Investment Payment</u>
        </h4>
        <h4 className="col-12 text-center">
          {localStorage.getItem("ProjectName")}
        </h4>
        <div className="col-6 mt-2">
          Voucher No:{" "}
          {this.props.printData !== undefined
            ? this.props.printData.LoanPaymentVoucherId
            : null}
        </div>
        <div className="col-6 mt-2 text-right">
          Voucher Date:{" "}
          {this.props.printData !== undefined
            ? moment(this.props.printData.EntryDate.split("T")[0]).format(
                "DD-MMM-YYYY"
              )
            : null}
        </div>

        <div className="col-6 mt-2">
          Payment Type:{" "}
          {this.props.printData !== undefined
            ? this.props.printData.PaymentType
            : null}
        </div>
        {/* <div className="col-6 mt-2 text-right">
                    Print Date:{" "}
                    {moment().format("DD-MMM-YYYY")}
                </div> */}

        {/* <div className="col-12 mt-2">
                    Received with thanks from:
                </div>
            
                <div className="col-6 mt-2">
                    Name: {this.props.printData !== undefined ?

this.props.printData.AffiliateCnicNavigation.AffiliateName

                                : null}
                </div>
                <div className="col-6 mt-2 text-right">
                    CNIC:
                    {this.props.printData !== undefined ?

                        this.props.printData.Contract.AffiliateCnic

                                : null}
                </div>
                
                <div className="col-6 mt-2">
                    Cell: {this.props.printData !== undefined ?

                        this.props.printData.Contract.AffiliateCnicNavigation.PhoneNumber

                                : null}
                </div>
                 */}
        <div className="col-6 mt-2 text-right"></div>

        <div className="col-12 mt-3">
          <table className="table">
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Sr.
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Loan Name
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Lender Name
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Narration
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Amount
                </th>
              </tr>
            </thead>

            {this.props.printData !== undefined ? (
              <tbody>
                <tr>
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    1
                  </td>
                  {this.props.printData.Loan !== undefined &&
                  this.props.printData.Loan !== null ? (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      {this.props.printData.Loan.LoanName}
                    </td>
                  ) : (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    ></td>
                  )}
                  {this.props.printData.Lender !== undefined &&
                  this.props.printData.Lender !== null ? (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      {this.props.printData.Lender.LenderName}
                    </td>
                  ) : (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    ></td>
                  )}
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    {this.props.printData.Narration}
                  </td>
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    {parseFloat(
                      this.props.printData.PaidAmount
                    ).toLocaleString()}
                  </td>
                </tr>
              </tbody>
            ) : null}
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th style={{ borderLeft: "1px solid grey" }}>Total</th>
                <th></th>

                <th></th>
                <th></th>

                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  {this.props.printData !== undefined
                    ? parseFloat(
                        this.props.printData.PaidAmount
                      ).toLocaleString()
                    : null}
                </th>
              </tr>
            </thead>
          </table>
        </div>

        <div className="col-12 mt-2">
          Total Amount:{" "}
          {this.props.printData !== undefined
            ? parseFloat(this.props.printData.PaidAmount).toLocaleString()
            : null}
        </div>

        <div className="col-12 mt-2">
          Amount in words:{" "}
          {this.props.printData !== undefined
            ? converter.toWords(parseFloat(this.props.printData.PaidAmount))
            : null}
        </div>

        {/* <div className="col-12 mt-2">
                    On account payment of shop:
                </div>                          
             */}
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Received By:</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Authorized By:</b>
        </div>

        <div className="col-12 mt-4">
          <hr />
        </div>
      </div>
    );
  }
}

export class CommissionPaymentPrint extends Component {
  render() {
    return (
      <div className="row m-0 p-0">
        <IndividualPrintHeader />

        <h4 className="col-12 text-center">
          <u>Commission Payment</u>
        </h4>
        <h4 className="col-12 text-center">
          {localStorage.getItem("ProjectName")}
        </h4>
        <div className="col-6 mt-2">
          Voucher No:{" "}
          {this.props.printData !== undefined
            ? this.props.printData.CommissionPaymentVoucherId
            : null}
        </div>
        <div className="col-6 mt-2 text-right">
          Voucher Date:{" "}
          {this.props.printData !== undefined
            ? moment(this.props.printData.EntryDate.split("T")[0]).format(
                "DD-MMM-YYYY"
              )
            : null}
        </div>

        <div className="col-6 mt-2">
          Payment Type:{" "}
          {this.props.printData !== undefined
            ? this.props.printData.VoucherType
            : null}
        </div>
        {/* <div className="col-6 mt-2 text-right">
                    Print Date:{" "}
                    {moment().format("DD-MMM-YYYY")}
                </div> */}

        {/* <div className="col-12 mt-2">
                    Received with thanks from:
                </div>
            
                <div className="col-6 mt-2">
                    Name: {this.props.printData !== undefined ?

this.props.printData.AffiliateCnicNavigation.AffiliateName

                                : null}
                </div>
                <div className="col-6 mt-2 text-right">
                    CNIC:
                    {this.props.printData !== undefined ?

                        this.props.printData.Contract.AffiliateCnic

                                : null}
                </div>
                
                <div className="col-6 mt-2">
                    Cell: {this.props.printData !== undefined ?

                        this.props.printData.Contract.AffiliateCnicNavigation.PhoneNumber

                                : null}
                </div>
                 */}
        <div className="col-6 mt-2 text-right"></div>

        <div className="col-12 mt-3">
          <table className="table">
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Sr.
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Affiliate
                </th>
                {/* <th style={{borderLeft:"1px solid grey", borderRight:"1px solid grey"}}>Project</th> */}
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Unit
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Amount
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Narration
                </th>
              </tr>
            </thead>

            {this.props.printData !== undefined ? (
              <tbody>
                <tr>
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    1
                  </td>
                  {this.props.printData.AffiliateCnicNavigation !==
                  undefined ? (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      {this.props.printData.AffiliateCnicNavigation
                        .AffiliateName !== null
                        ? this.props.printData.AffiliateCnicNavigation
                            .AffiliateName
                        : this.props.printData.Employee.EmpName}
                    </td>
                  ) : (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    ></td>
                  )}
                  {/* {this.props.printData.Project !== undefined && this.props.printData.Project !== null ?
                                    <td style={{borderLeft:"1px solid grey", borderRight:"1px solid grey"}}>{ this.props.printData.Project.ProjectName === "THE GRAND CITY" ? "THE GRAND ICONIC CITY" : this.props.printData.Project.ProjectName}</td> : <td style={{borderLeft:"1px solid grey", borderRight:"1px solid grey"}}></td>} */}
                  {this.props.printData.Unit !== undefined ? (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      {this.props.printData.Unit.UnitName}
                    </td>
                  ) : (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    ></td>
                  )}
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    {parseFloat(this.props.printData.Amount).toLocaleString()}
                  </td>
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    {this.props.printData.Narration !== null
                      ? this.props.printData.Narration
                      : null}
                  </td>
                </tr>
              </tbody>
            ) : null}
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th style={{ borderLeft: "1px solid grey" }}>Total</th>
                <th></th>

                <th></th>
                <th></th>

                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  {this.props.printData !== undefined
                    ? parseFloat(this.props.printData.Amount).toLocaleString()
                    : null}
                </th>
              </tr>
            </thead>
          </table>
        </div>

        <div className="col-12 mt-2">
          Total Amount:{" "}
          {this.props.printData !== undefined
            ? parseFloat(this.props.printData.Amount).toLocaleString()
            : null}
        </div>

        <div className="col-12 mt-2">
          Amount in words:{" "}
          {this.props.printData !== undefined
            ? converter.toWords(parseFloat(this.props.printData.Amount))
            : null}
        </div>

        {/* <div className="col-12 mt-2">
                    On account payment of shop:
                </div>                          
             */}
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Received By:</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Authorized By:</b>
        </div>

        <div className="col-12 mt-4">
          <hr />
        </div>
      </div>
    );
  }
}

export class DealBoughtPrint extends Component {
  render() {
    return (
      <div className="row m-0 p-0">
        <IndividualPrintHeader />

        <h4 className="col-12 text-center">
          <u>Deal Bought Payment</u>
        </h4>
        <h4 className="col-12 text-center">
          {localStorage.getItem("ProjectName")}
        </h4>
        <div className="col-6 mt-2">
          Voucher No:{" "}
          {this.props.printData !== undefined
            ? this.props.printData.DealBoughtPaymentVoucherId
            : null}
        </div>
        <div className="col-6 mt-2 text-right">
          Voucher Date:{" "}
          {this.props.printData !== undefined
            ? moment(this.props.printData.EntryDate.split("T")[0]).format(
                "DD-MMM-YYYY"
              )
            : null}
        </div>

        <div className="col-6 mt-2">
          Payment Type:{" "}
          {this.props.printData !== undefined
            ? this.props.printData.PaymentType
            : null}
        </div>
        {/* <div className="col-6 mt-2 text-right">
                    Print Date:{" "}
                    {moment().format("DD-MMM-YYYY")}
                </div> */}

        {/* <div className="col-12 mt-2">
                    Received with thanks from:
                </div>
            
                <div className="col-6 mt-2">
                    Name: {this.props.printData !== undefined ?

this.props.printData.AffiliateCnicNavigation.AffiliateName

                                : null}
                </div>
                <div className="col-6 mt-2 text-right">
                    CNIC:
                    {this.props.printData !== undefined ?

                        this.props.printData.Contract.AffiliateCnic

                                : null}
                </div>
                
                <div className="col-6 mt-2">
                    Cell: {this.props.printData !== undefined ?

                        this.props.printData.Contract.AffiliateCnicNavigation.PhoneNumber

                                : null}
                </div>
                 */}
        <div className="col-6 mt-2 text-right"></div>

        <div className="col-12 mt-3">
          <table className="table">
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Sr.
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Seller
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Deal
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Amount
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Narration
                </th>
              </tr>
            </thead>

            {this.props.printData !== undefined ? (
              <tbody>
                <tr>
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    1
                  </td>
                  {this.props.printData.Seller !== undefined &&
                  this.props.printData.Project !== null ? (
                    <td>{this.props.printData.Seller.SellerName}</td>
                  ) : (
                    <td></td>
                  )}
                  {this.props.printData.DealName !== undefined ? (
                    <td>{this.props.printData.DealName}</td>
                  ) : (
                    <td></td>
                  )}
                  <td>
                    {parseFloat(this.props.printData.Amount).toLocaleString()}
                  </td>
                  <td>
                    {this.props.printData.Narration !== null
                      ? this.props.printData.Narration
                      : null}
                  </td>
                </tr>
              </tbody>
            ) : null}
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th style={{ borderLeft: "1px solid grey" }}>Total</th>
                <th></th>

                <th></th>
                <th></th>

                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  {this.props.printData !== undefined
                    ? parseFloat(this.props.printData.Amount).toLocaleString()
                    : null}
                </th>
              </tr>
            </thead>
          </table>
        </div>

        <div className="col-12 mt-2">
          Total Amount:{" "}
          {this.props.printData !== undefined
            ? parseFloat(this.props.printData.Amount).toLocaleString()
            : null}
        </div>

        <div className="col-12 mt-2">
          Amount in words:{" "}
          {this.props.printData !== undefined
            ? converter.toWords(parseFloat(this.props.printData.Amount))
            : null}
        </div>

        {/* <div className="col-12 mt-2">
                    On account payment of shop:
                </div>                          
             */}
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Received By:</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Authorized By:</b>
        </div>

        <div className="col-12 mt-4">
          <hr />
        </div>
      </div>
    );
  }
}
export class SalaryPaymentPrint extends Component {
  render() {
    return (
      <div className="row m-0 p-0">
        <IndividualPrintHeader />

        <h4 className="col-12 text-center">
          <u>Salary Payment</u>
        </h4>
        <h4 className="col-12 text-center">
          {localStorage.getItem("ProjectName")}
        </h4>
        <div className="col-6 mt-2">
          Voucher No:{" "}
          {this.props.printData !== undefined
            ? this.props.printData.SalaryVoucherId
            : null}
        </div>
        <div className="col-6 mt-2 text-right">
          Voucher Date:{" "}
          {this.props.printData !== undefined
            ? moment(this.props.printData.EntryDate.split("T")[0]).format(
                "DD-MMM-YYYY"
              )
            : null}
        </div>

        <div className="col-6 mt-2">
          Payment Type:{" "}
          {this.props.printData !== undefined
            ? this.props.printData.PaymentType
            : null}
        </div>
        {/* <div className="col-6 mt-2 text-right">
                    Print Date:{" "}
                    {moment().format("DD-MMM-YYYY")}
                </div> */}

        {/* <div className="col-12 mt-2">
                    Received with thanks from:
                </div>
            
                <div className="col-6 mt-2">
                    Name: {this.props.printData !== undefined ?

this.props.printData.AffiliateCnicNavigation.AffiliateName

                                : null}
                </div>
                <div className="col-6 mt-2 text-right">
                    CNIC:
                    {this.props.printData !== undefined ?

                        this.props.printData.Contract.AffiliateCnic

                                : null}
                </div>
                
                <div className="col-6 mt-2">
                    Cell: {this.props.printData !== undefined ?

                        this.props.printData.Contract.AffiliateCnicNavigation.PhoneNumber

                                : null}
                </div>
                 */}
        <div className="col-6 mt-2 text-right"></div>

        <div className="col-12 mt-3">
          <table className="table">
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Sr.
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Employee
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  PaySlip Date
                </th>
                {/* <th style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey" }}></th> */}
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Amount
                </th>
              </tr>
            </thead>

            {this.props.printData !== undefined ? (
              <tbody>
                <tr>
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    1
                  </td>
                  {this.props.printData.Employee !== undefined &&
                  this.props.printData.Employee !== null ? (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      {this.props.printData.Employee.EmpName}
                    </td>
                  ) : (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    ></td>
                  )}
                  {this.props.printData.Payslip !== undefined &&
                  this.props.printData.Payslip !== null ? (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      {moment(
                        this.props.printData.Payslip.PayslipMonth.split("T")[0]
                      ).format("DD-MMM-YYYY")}
                    </td>
                  ) : (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    ></td>
                  )}
                  {/* <td style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey" }}>{this.props.printData.SalaryVoucherId}</td> */}
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    {parseFloat(this.props.printData.Amount).toLocaleString()}
                  </td>
                </tr>
              </tbody>
            ) : null}
          </table>
        </div>

        <div className="col-12 mt-2">
          Total Amount:{" "}
          {this.props.printData !== undefined
            ? parseFloat(this.props.printData.Amount).toLocaleString()
            : null}
        </div>

        <div className="col-12 mt-2">
          Amount in words:{" "}
          {this.props.printData !== undefined
            ? converter.toWords(parseFloat(this.props.printData.Amount))
            : null}
        </div>

        {/* <div className="col-12 mt-2">
                    On account payment of shop:
                </div>                          
             */}
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Received By:</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Authorized By:</b>
        </div>

        <div className="col-12 mt-4">
          <hr />
        </div>
      </div>
    );
  }
}

export class RefundVouchPrint extends Component {
  render() {
    return (
      <div className="row m-0 p-0">
        <IndividualPrintHeader />

        <h4 className="col-12 text-center">
          <u>Refund Voucher</u>
        </h4>
        <h4 className="col-12 text-center">
          {localStorage.getItem("ProjectName")}
        </h4>
        <div className="col-6 mt-2">
          Voucher No:{" "}
          {this.props.printData !== undefined
            ? this.props.printData.GeneralVoucherId
            : null}
        </div>
        <div className="col-6 mt-2 text-right">
          Voucher Date:{" "}
          {this.props.printData !== undefined
            ? moment(this.props.printData.EntryDate.split("T")[0]).format(
                "DD-MMM-YYYY"
              )
            : null}
        </div>

        <div className="col-6 mt-2">
          Payment Type:{" "}
          {this.props.printData !== undefined
            ? this.props.printData.PaymentType
            : null}
        </div>
        {/* <div className="col-6 mt-2 text-right">
                    Print Date:{" "}
                    {moment().format("DD-MMM-YYYY")}
                </div> */}

        {/* <div className="col-12 mt-2">
                    Received with thanks from:
                </div>
            
                <div className="col-6 mt-2">
                    Name: {this.props.printData !== undefined ?

this.props.printData.AffiliateCnicNavigation.AffiliateName

                                : null}
                </div>
                <div className="col-6 mt-2 text-right">
                    CNIC:
                    {this.props.printData !== undefined ?

                        this.props.printData.Contract.AffiliateCnic

                                : null}
                </div>
                
                <div className="col-6 mt-2">
                    Cell: {this.props.printData !== undefined ?

                        this.props.printData.Contract.AffiliateCnicNavigation.PhoneNumber

                                : null}
                </div>
                 */}
        <div className="col-6 mt-2 text-right"></div>

        <div className="col-12 mt-3">
          <table className="table">
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Sr.
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Project Owner
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Narration
                </th>
                {this.props.printData?.PaymentType === "BANK" && (
                  <th
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    Cheque Number
                  </th>
                )}
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Amount
                </th>
              </tr>
            </thead>

            {this.props.printData !== undefined ? (
              <tbody>
                <tr>
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    1
                  </td>
                  {this.props.printData?.Transaction[0] !== undefined ? (
                    this.props.printData?.Transaction[0].Account !== null &&
                    this.props.printData?.Transaction[0].Account !==
                      undefined ? (
                      <td>
                        {
                          this.props.printData?.Transaction[0].Account
                            .AccountTitle
                        }
                      </td>
                    ) : (
                      <td></td>
                    )
                  ) : (
                    <td>
                      {
                        this.props.printData?.GeneralVoucherSaved?.DebitAccount
                          ?.AccountTitle
                      }
                    </td>
                  )}
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    {this.props.printData.Project.ProjectOwner}
                  </td>
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    {this.props.printData.Narration}
                  </td>
                  {this.props.printData?.PaymentType === "BANK" && (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      {this.props.printData?.ChequeNumber}
                    </td>
                  )}
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    {parseFloat(this.props.printData.Amount).toLocaleString()}
                  </td>
                </tr>
              </tbody>
            ) : null}
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th style={{ borderLeft: "1px solid grey" }}>Total</th>
                <th></th>

                <th></th>

                <th></th>
                {this.props.printData?.PaymentType === "BANK" && <th></th>}

                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  {this.props.printData !== undefined
                    ? parseFloat(this.props.printData.Amount).toLocaleString()
                    : null}
                </th>
              </tr>
            </thead>
          </table>
        </div>

        <div className="col-12 mt-2">
          Total Amount:{" "}
          {this.props.printData !== undefined
            ? parseFloat(this.props.printData.Amount).toLocaleString()
            : null}
        </div>

        <div className="col-12 mt-2">
          Amount in words:{" "}
          {this.props.printData !== undefined
            ? converter.toWords(parseFloat(this.props.printData.Amount))
            : null}
        </div>

        {/* <div className="col-12 mt-2">
                    On account payment of shop:
                </div>                          
             */}

        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Received By:</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Authorized By:</b>
        </div>
        <div className="col-12 mt-4">
          <hr />
        </div>
      </div>
    );
  }
}
