import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getrecoveryreport } from "../../../actions/AccountReports/recoveryreport";
import { getallTaxTable } from "../../../actions/accountsactions/addtax";
import { getallbanks, getallcash } from "../../../actions/getbanks";
import { getallacounts } from "../../../actions/getheading";
import { BsCloudUpload, BsFillInfoSquareFill } from "react-icons/bs";
import { RiArrowGoBackFill } from "react-icons/ri";
import ReactDOM from "react-dom";
import TaxModal from "../../TaxModal/TaxModal";
import RectifiyModal from "../../Vouchers/RectifyModal/rectifiyModal";
import ScreenInfoModal from "../../../ScreenInfoModal/ScreenInfoModal";
import { GeneralReceiptTab } from "./GeneralReceiptTab";

export const GeneralReceiptForm = (props) => {
  const resultsallbanks = useSelector((state) => state.getAllBanksReducer);
  const resultsallcash = useSelector((state) => state.getAllCash);
  const [RevertModal, setRevertModal] = useState(false);
  const [showTaxmodal, setshowTaxmodal] = useState(false);
  const resultsallaccount = useSelector((state) => state.getAllAccountsReducer);
  const [allvalues, setallvalues] = useState([]);
  const [stateamount, setamount] = useState("0");
  const [taxtotal, settaxtotal] = useState("0");
  const [disableBtn, setdisableBtn] = useState(true);
  const [showDetailModal, setshowDetailModal] = useState(false);
  const [formData, setformData] = useState({
    VoucherId: "",
    CreditAccountId: "",
    DebitAccountId: "",
    GrossAmount: "",
    ChequeNumber: "",
    VoucherSaveDate: "",
    PaymentType: "",
    Narration: "",
    ProjectId: "",
    TotaTaxAmount: "",
    NetAmount: "",
  });
  const [rowstate, setrowstate] = useState([
    {
      generalVoucherSavedDetailId: null,
      accountId: 0,
      amount: 0,
      description: "",
    },
  ]);
  const [sumAmt, setSumAmt] = useState(0);
  const dispatch = useDispatch();
  const [accountid, setaccountid] = useState(null);
  let vouchernumber = `GR-${Math.floor(Date.now())}`;
  // const [vouchernumber, setvouchernumber] = useState(`GR-${Math.floor(Date.now())}`);

  useEffect(() => {
    dispatch(getallacounts());
    dispatch(getallbanks());
    dispatch(getallcash());
    // dispatch(getallProject());
  }, [dispatch]);

  useEffect(() => {
    if (props.vouchData?.VoucherId) {
      let sum = 0;
      let PreviousSelectedTax = props.vouchData.VoucherTaxSubmitedDetail.map(
        (data) => {
          sum = sum + data.TaxAmount;
          let obj = { ...data, Checked: true };

          return obj;
        }
      );
      settaxtotal(sum);
      PreviousSelectedTax = PreviousSelectedTax.map((value) => {
        let obj = value.Tax.TaxId;
        return { ...value, TaxId: obj };
      });
      setallvalues(PreviousSelectedTax);
      setdisableBtn(false);
      if (props.vouchData?.PaymentType === "CASH") {
        // document.getElementById("vouchertype").options[2].selected = true;
        document.getElementById("vouchertype").options[1].selected = true;
        document.getElementById("bankdiv").style.display = "none";
        document.getElementById("cashdiv").style.display = "block";
        setaccountid(props.vouchData?.CreditAccountId);
        // document.getElementById("Account").value =
        //   props.vouchData?.CreditAccount?.AccountTitle;
        document.getElementById("cashs").value =
          props.vouchData?.DebitAccount.AccountId;
      } else {
        document.getElementById("vouchertype").options[2].selected = true;
        document.getElementById("bankdiv").style.display = "block";
        document.getElementById("cashdiv").style.display = "none";
        document.getElementById("chequeno").value =
          props.vouchData?.ChequeNumber;
        document.getElementById("banks").value =
          props.vouchData?.DebitAccount.AccountTitle;
        setaccountid(props.vouchData?.CreditAccountId);
        // document.getElementById("Account").value =
        //   props.vouchData?.CreditAccount.AccountTitle;
      }

      document.getElementById("voucherno").value = props.vouchData?.VoucherId;
      document.getElementById("date").value =
        props.vouchData?.AddedOnDate?.slice(0, 10);

      document.getElementById("Amount").value = props.vouchData?.GrossAmount;
      document.getElementById("taxnet").value = props.vouchData?.NetAmount;
      document.getElementById("narration").value = props.vouchData?.Narration;
      settaxtotal(props.vouchData.TotaTaxAmount?.toString());
      setamount(props.vouchData?.GrossAmount?.toString());
    }
  }, [props.vouchData, resultsallaccount, resultsallbanks, resultsallcash]);
  const handleDisableBtn = () => {
    if (props.vouchData?.VoucherId) {
      setdisableBtn(true);
    }
  };
  console.log(
    Number(document.getElementById("Amount")?.value.replace(/,/g, ""))
  );
  const savedata = () => {
    if (document.getElementById("date").value === "") {
      toast.info("Date is Required");
    } else if (document.getElementById("Amount").value === "") {
      toast.info("Amount is Required");
    } else if (document.getElementById("narration").value === "") {
      toast.info("Narration is Required");
    } else if (rowstate.length === 0) {
      toast.info("Please add Account detail in table ");
    } else if (rowstate.some((x) => x.accountId === "" || x.amount === "")) {
      toast.info("Please fill all fields in the table");
    } else if (
      sumAmt !==
      Number(document.getElementById("Amount")?.value.replace(/,/g, ""))
    ) {
      toast.info("Amount should be Equal to Sum of All Amounts in Table");
    }
    // else if (document.getElementById("Account").value === "") {
    //   toast.info("Account is Required");
    // }
    else if (document.getElementById("vouchertype").selectedIndex === 0) {
      toast.info("Select Type");
    } else if (
      document.getElementById("vouchertype").options[
        document.getElementById("vouchertype").selectedIndex
      ].value === "Bank Voucher" &&
      (document.getElementById("banks").options[
        document.getElementById("banks").selectedIndex
      ].value === "Select" ||
        document.getElementById("chequeno").value === "")
    ) {
      toast.info("Cheque no and Bank name are Required");
    } else if (
      document.getElementById("vouchertype").options[
        document.getElementById("vouchertype").selectedIndex
      ].value === "Cash Voucher" &&
      document.getElementById("cashs").options[
        document.getElementById("cashs").selectedIndex
      ].value === "Select"
    ) {
      toast.info("Selech Cash Account");
    } else {
      let paymentType =
        document.getElementById("vouchertype").options[
          document.getElementById("vouchertype").selectedIndex
        ].value;
      if (paymentType === "Bank Voucher") {
        paymentType = "Bank";
      } else if (paymentType === "Cash Voucher") {
        paymentType = "Cash";
      }

      let bankId =
        document.getElementById("banks").options[
          document.getElementById("banks").selectedIndex
        ].value;
      if (bankId === "Select") {
        bankId = 0;
      } else {
        bankId =
          document.getElementById("banks").options[
            document.getElementById("banks").selectedIndex
          ].dataset.id;
      }
      let cashId =
        document.getElementById("cashs").options[
          document.getElementById("cashs").selectedIndex
        ].value;
      if (cashId === "Select") {
        cashId = 0;
      } else {
        cashId =
          document.getElementById("cashs").options[
            document.getElementById("cashs").selectedIndex
          ].dataset.id;
      }
      let checkNumber;

      if (paymentType === "Cash") {
        checkNumber = null;
      } else {
        checkNumber = document.getElementById("chequeno").value;
      }
      let taxList = [];
      allvalues?.map((val, i) => {
        if (val.Checked == true) {
          taxList.push({
            TaxId: val.TaxId,
            TaxPer: Number(val.TaxPer),
            TaxAmount: Number(val.TaxAmount),
            Checked: true,
            TaxSubmitedDetailId: val.TaxSubmitedDetailId
              ? val.TaxSubmitedDetailId
              : 0,
            ProjectId: localStorage.getItem("ProjectId"),
            ReferenceId: "",
            ReferenceType: "",
          });
        }
      });

      let data = {
        VoucherId: document.getElementById("voucherno").value,
        // CreditAccountId: parseInt(accountid),
        CreditAccountId: rowstate[0]?.accountId,

        DebitAccountId:
          paymentType === "Cash" ? parseInt(cashId) : parseInt(bankId),
        // Amount: parseFloat(document.getElementById("Amount").value),
        GrossAmount: parseFloat(
          document.getElementById("Amount").value.replace(/,/g, "")
        ),

        ChequeNumber: checkNumber,
        VoucherSaveDate: document.getElementById("date").value,
        PaymentType: paymentType,
        Narration: document.getElementById("narration").value,
        ProjectId: parseInt(`${localStorage.getItem("ProjectId")}`),
        VoucherType: "general receipt",
        // VoucherTaxSubmitedDetail: taxList,
        TotaTaxAmount: taxtotal,
        NetAmount:
          parseFloat(
            document.getElementById("Amount").value.replace(/,/g, "")
          ) - Number(taxtotal),
      };

      document.getElementById("headingsave").disabled = true;

      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Vouchers/Receipt/General/Add`,
        data: JSON.stringify({
          voucher: data,
          taxDetail: taxList,
          GeneralVoucherSavedDetail: rowstate,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("headingsave").disabled = false;
          if (result.data.IsSuccess === true) {
            document.getElementById("chequeno").value = "";
            document.getElementById("voucherno").value = "";
            document.getElementById("narration").value = "";
            document.getElementById("Amount").value = "";
            document.getElementById("vouchertype").selectedIndex = 0;
            document.getElementById("banks").selectedIndex = 0;
            // document.getElementById("project").selectedIndex = 0;
            // document.getElementById("Account").value = "";
            document.getElementById("taxnet").value = "";
            setamount("0");
            settaxtotal("0");
            setaccountid(null);
            setrowstate([]);
            props.setvoucherSaveDetails(null);

            toast.info(result.data.Message);
            dispatch(getrecoveryreport());
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Someting went wrong");
          document.getElementById("headingsave").disabled = false;
        }
      );
    }
  };

  const submitdata = () => {
    setdisableBtn(true);
    axios({
      method: "post",
      url: `${
        process.env.REACT_APP_API_URL
      }Vouchers/Receipt/General/Submit?voucherId=${
        document.getElementById("voucherno").value
      }`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          document.getElementById("chequeno").value = "";
          document.getElementById("voucherno").value = "";
          document.getElementById("narration").value = "";

          document.getElementById("Amount").value = "";
          document.getElementById("vouchertype").selectedIndex = 0;
          document.getElementById("banks").selectedIndex = 0;
          // document.getElementById("project").selectedIndex = 0;
          // document.getElementById("Account").value = "";
          setamount("0");
          settaxtotal("0");
          setaccountid(null);
          setrowstate([]);
          props.setvoucherSaveDetails(null);

          toast.info(result.data.Message);
          dispatch(getrecoveryreport());
        } else {
          toast.info(result.data.Message);
          setdisableBtn(false);
        }
      },
      (error) => {
        setdisableBtn(false);
        toast.info("Someting went wrong");
      }
    );
  };
  let suggestionlist = [];
  const onTextChange = (e, id) => {
    var accountnames = [];
    resultsallaccount.data.forEach((name) => {
      accountnames.push(name.AccountTitle.concat("-", name.AccountId));
    });
    const value = e.target.value;

    if (value === "Add New") {
      ReactDOM.render(null, document.getElementById("dropdown"));
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = accountnames.sort().filter((v) => regex.test(v));
      }

      suggestionlist = suggestions;

      // this.suggestionlist.push("Add New");
      if (suggestionlist.length === 0) {
        ReactDOM.render(null, document.getElementById("dropdown"));
      } else {
        ReactDOM.render(
          <ul className="suggestbox">
            {suggestionlist.map((item) => (
              <li
                className="listcss"
                onClick={() => suggestionselected(item, id)}
              >
                {item}
              </li>
            ))}
          </ul>,
          document.getElementById("dropdown")
        );
      }
    }
  };
  const suggestionselected = (val, id) => {
    var res = val.split("-");

    document.getElementById("Account").value = res[0];
    setaccountid(res[1]);
    suggestionlist = [];
    ReactDOM.render(null, document.getElementById("dropdown"));
  };
  function closemodal() {
    if (
      parseFloat(stateamount.replace(/,/g, "")) -
        parseFloat(taxtotal.replace(/,/g, "")) <
      0
    ) {
      toast.info("Total of percentages of taxes should not greater then 100");
    } else {
      document.getElementById("mymodal").style.display = "none";
    }
  }
  function showmodal() {
    document.getElementById("mymodal").style.display = "block";
  }

  function retrieveDatalistValue() {
    handleDisableBtn();
    var inputValue = document.getElementById("Account").value;
    var options = document.getElementById("mylist").childNodes;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value === inputValue) {
        setaccountid(options[i].attributes.accountid.value);
        break;
      }
    }
  }
  return (
    <>
      {/* <div className="row ">
          <div className="col-md-2 mt-4 text-left p-0">
            <label className="input_label m-0 " >Project:</label>
          </div>
          <div className="col-md-3 mt-4  p-0 ">
            <select className="input_styling" id="project" >
              <option>Select</option>
              {resultsproject.data.map((val, index) => (
                <option key={index} data-id={val.ProjectId}>{val.ProjectName}</option>
              ))}
            </select>
  
          </div>
  
  
        </div> */}
      <div className="col-md-12 text-right">
        <span>
          {resultsallaccount.loading ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </span>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0 ">Date:</label>
        </div>
        <div className="col-md-3 mt-4 p-0">
          <input
            type="date"
            className="input_date_styling"
            onChange={handleDisableBtn}
            defaultValue={moment().format("YYYY-MM-DD")}
            id="date"
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-4 p-0"></div>
      </div>
      <div className="row">
        {/* <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Account:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="textarea"
            className="input_styling "
            id="Account"
            list="mylist"
            onChange={retrieveDatalistValue}
          ></input>
          <datalist id="mylist">
            {resultsallaccount?.data?.map((x, idx) => (
              <option accountid={x.AccountId} value={x.AccountTitle}>
                {x.AccountIdCode}
              </option>
            ))}
          </datalist>
          <span className="estaric">*</span>
          <span id="dropdown"></span>
        </div> */}
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Voucher No:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text"
            disabled
            value={
              props.vouchData?.VoucherId
                ? props.vouchData?.VoucherId
                : vouchernumber
            }
            className="input_styling  "
            id="voucherno"
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-4 p-0"></div>
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Amount:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text"
            className="input_styling  "
            id="Amount"
            defaultValue={formData.GrossAmount}
            onChange={(e) => {
              handleDisableBtn();
              setamount(e.target.value);
              let taxAmt = 0;
              // resultalltaxes.data?.forEach((item2) => {
              //   if (item2.Checked === true) {
              //     item2.TaxAmount = parseFloat(
              //       (item2.TaxPer / 100) * parseFloat(e.target.value.replace(/,/g, ''))

              //     )
              //     taxAmt = taxAmt + parseFloat(
              //       (item2.TaxPer / 100) * parseFloat(e.target.value.replace(/,/g, ''))

              //     )
              //   }

              // });
              settaxtotal(taxAmt?.toString());
              if (e.target.value.length > 0) {
                document.getElementById("Amount").value = parseInt(
                  e.target.value.replace(/,/g, "")
                ).toLocaleString();
              }
            }}
          ></input>
          <span className="estaric">*</span>
        </div>
        {/* <div className="col-md-2 mt-4 text-left p-0">
            <label className="input_label m-0 ">Type:</label>
          </div>
          <div className="col-md-3 mt-4 p-0">
            <select
              id="vouchertype"
              onChange={(e) => {
                if (e.target.value === "Bank Voucher") {
                  document.getElementById("bankdiv").style.display = "block";
                } else {
                  document.getElementById("bankdiv").style.display = "none";
                }
              }}
              type="text"
              className="input_styling  "
            >
              <option>Cash Voucher</option>
              <option>Bank Voucher</option>
            </select>
          </div> */}
      </div>
      <div className="row ">
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0 ">Type:</label>
        </div>
        <div className="col-md-3 mt-4 p-0">
          <select
            id="vouchertype"
            onChange={(e) => {
              handleDisableBtn();
              if (e.target.value === "Bank Voucher") {
                document.getElementById("bankdiv").style.display = "block";
                document.getElementById("cashdiv").style.display = "none";
              } else if (e.target.value === "Cash Voucher") {
                document.getElementById("bankdiv").style.display = "none";
                document.getElementById("cashdiv").style.display = "block";
              } else {
                document.getElementById("bankdiv").style.display = "none";
                document.getElementById("cashdiv").style.display = "none";
              }
            }}
            type="text"
            className="input_styling  "
          >
            <option>Select</option>
            <option selected>Cash Voucher</option>
            <option>Bank Voucher</option>
          </select>
          <span className="estaric">*</span>
        </div>

        <div className="col-md-2 mt-4 p-0"></div>

        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Net Amount:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text"
            className="input_styling "
            disabled
            id="taxnet"
            value={parseFloat(
              parseFloat(stateamount.replace(/,/g, "")) - parseFloat(taxtotal)
            ).toLocaleString()}
          ></input>
        </div>
      </div>
      <span id="cashdiv">
        <div className="row  ">
          <div className="col-md-2 mt-4 text-left p-0">
            <label className="input_label m-0">Cash:</label>
          </div>
          <div className="col-md-3 mt-4  p-0">
            <select id="cashs" className="input_styling  ">
              <option>Select</option>
              {resultsallcash.data.map((x) => (
                <option value={x.AccountId} data-id={x.AccountId}>
                  {x.AccountTitle}
                </option>
              ))}
            </select>
            <span className="estaric">*</span>
          </div>
        </div>
      </span>

      <span id="bankdiv" style={{ display: "none" }}>
        <div className="row  ">
          <div className="col-md-2 mt-4 text-left p-0">
            <label className="input_label m-0">Bank:</label>
          </div>
          <div className="col-md-3 mt-4  p-0">
            <select
              onChange={handleDisableBtn}
              id="banks"
              className="input_styling  "
            >
              <option>Select</option>
              {resultsallbanks.data.map((x) => (
                <option data-id={x.AccountId}>{x.AccountTitle}</option>
              ))}
            </select>
            <span className="estaric">*</span>
          </div>

          <div className="col-md-2 mt-4 p-0"></div>

          <div className="col-md-2 mt-4 text-left p-0">
            <label onChange={handleDisableBtn} className="input_label m-0">
              Cheque No:
            </label>
          </div>
          <div className="col-md-3 mt-4  p-0">
            <input
              id="chequeno"
              type="text"
              placeholder="Cheque No"
              onChange={handleDisableBtn}
              className="input_styling "
            ></input>
            <span className="estaric">*</span>
          </div>
        </div>
      </span>
      <div className="row ">
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Narration:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <textarea
            onChange={handleDisableBtn}
            className="input_address "
            id="narration"
          ></textarea>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="mt-4">
        <GeneralReceiptTab
          resultsallaccount={resultsallaccount}
          rowstate={rowstate}
          setrowstate={setrowstate}
          setdisableBtn={setdisableBtn}
          voucherSaveDetails={props.voucherSaveDetails}
          setSumAmt={setSumAmt}
          sumAmt={sumAmt}
        />
      </div>
      <div className=" float-right mt-md-4 mr-md-3">
        {/* ///// Form Scnerio detail button */}
        <span>
          <button
            className="save_btn ml-2  "
            onClick={() => {
              setshowDetailModal(true);
            }}
          >
            <BsFillInfoSquareFill size="1em" className="mr-1" />
            Detail
          </button>
        </span>

        {localStorage.getItem("Designation") === "Admin" && props.vouchData && (
          <span>
            <button
              className="save_btn ml-2  "
              onClick={() => setRevertModal(true)}
            >
              <RiArrowGoBackFill size="1em" />
              Revert
            </button>
          </span>
        )}
        <span>
          <button
            className="save_btn ml-2  "
            onClick={() => {
              if (document.getElementById("Amount").value !== "") {
                setamount(document.getElementById("Amount").value);
                setshowTaxmodal(true);
              }
            }}
          >
            Add Tax
          </button>
        </span>
        <span>
          <button
            className="cancel_btn ml-2  "
            onClick={() => {
              props.cancelgeneraltable();
            }}
          >
            Cancel
          </button>
        </span>

        <span>
          <button
            disabled={!disableBtn}
            className="save_btn ml-2  "
            id="headingsave"
            onClick={savedata}
          >
            <BsCloudUpload size="1.3em" />
            Save
          </button>
        </span>
        {localStorage.getItem("Designation") === "Admin" && (
          <span>
            <button
              disabled={disableBtn}
              className="save_btn ml-2  "
              id="headingsumbit"
              onClick={submitdata}
            >
              {/* <RiSave3Fill size="1.3em" /> */}
              Submit
            </button>
          </span>
        )}
      </div>
      {showDetailModal ? (
        <ScreenInfoModal
          setshowModal={setshowDetailModal}
          screenName="General Receipt"
        />
      ) : null}

      {showTaxmodal === true ? (
        <TaxModal
          enableSaveBtn={handleDisableBtn}
          setshowTaxmodal={setshowTaxmodal}
          stateamount={stateamount}
          taxtotal={taxtotal}
          settaxtotal={settaxtotal}
          vouchData={props.vouchData}
          allvalues={allvalues}
          setallvalues={setallvalues}
        />
      ) : null}
      {RevertModal && (
        <RectifiyModal
          entryId={document.getElementById("voucherno").value}
          entryType={"General Receipt"}
          addedById={props.vouchData.AddedById}
          setshowModal={setRevertModal}
        />
      )}
    </>
  );
};
