const CancelPlotPayment = { data: [], loading: true };
export const GetCancelPlotPaymentRed = (state = CancelPlotPayment, action) => {
  switch (action.type) {
    case "get_cancelplotpayment":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};
