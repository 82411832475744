import { useState } from "react";
import ContractWithUnit from "./ContractWithUnit";
import ContractWithoutUnit from "./ContractWithoutUnit";

const MainContract = () => {
  const [tab, settab] = useState("Contract With Unit");

  const [Tabs, setTabs] = useState([
    {
      name: "Contract With Unit",
      info: "Contract With Unit",
    },
    {
      name: "Contract Without Unit",
      info: "Contract Without Unit",
    },
  ]);
  return (
    <>
      <div className="row  ">
        <div className="dash_background col-md-12 p-0">
          <div className="row ">
            <div className="col-md-12 text-center  p-0">
              {Tabs?.map((SingleTab) => {
                return (
                  <button
                    className={
                      tab === SingleTab?.name
                        ? "adjust_btns_selected ml-1 "
                        : "adjust_btns ml-1 "
                    }
                    onClick={() => {
                      settab(SingleTab?.name);
                    }}
                  >
                    <span
                      className="comp_btns_text"
                      // style={
                      //   SingleTab?.name === "General Receipt"
                      //     ? {}
                      //     : localStorage.getItem("ProjectName") !==
                      //       "HEAD OFFICE"
                      //     ? {
                      //         textDecorationLine: "none",
                      //       }
                      //     : {
                      //         textDecorationLine: "line-through",
                      //         textDecorationStyle: "solid",
                      //       }
                      // }
                    >
                      <div>{SingleTab?.name}</div>
                    </span>
                  </button>
                );
              })}
            </div>
            <div className="col-12">
              {tab === "Contract With Unit" ? (
                <ContractWithUnit />
              ) : tab === "Contract Without Unit" ? (
                <ContractWithoutUnit />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainContract;
