const populate = { data: [], loading: true };
export const GetAllRefundPopulate = (state = populate, action) => {
  switch (action.type) {
    case "get_allrefund_populate":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};
