import React from "react";
// import { useSelector } from "react-redux";
function IndividualPrintHeader() {
  // const companyInfo = useSelector((state) => state.getCompany);
  return (
    <>
      <div
        className="col-md-12"
        style={{ height: "2.5in", minHeight: "2.5in", maxHeight: "2.5in" }}
      ></div>
    </>
  );
}

export default IndividualPrintHeader;
