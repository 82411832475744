import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { GrPrint } from "react-icons/gr";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getallLandTable } from "../../../actions/vouchersactions/getlandpayment";
import { LandPaymentPrint } from "../../accpayablepages/vouchersprints/paymentprint";
import LandPaymentTransactionDetailModal from "./LandPaymentTransactionDetailModal";
import { CSVLink } from "react-csv";
import LandPaymentModal from "./LandPaymentModal";
import moment from "moment";
import { MdDeleteForever } from "react-icons/md";
import { toast } from "react-toastify";
import axios from "axios";
import { RiFileEditLine } from "react-icons/ri";

export const LandPaymentTable = (props) => {
  const resultslandtable = useSelector((state) => state.getLandTable);
  const dispatch = useDispatch();
  const [TransactionData, setTransactionData] = useState();
  const [filterdata, setfilterdata] = useState([]);
  const [statetoggle, settoggle] = useState(true);
  const [printData, SetPrintData] = useState();
  const rolesdata = useSelector((state) => state.GetAllRole);
  const [contractRowdata, setcontractRowdata] = useState(null);

  const [debitState, setdebitState] = useState(0);
  const [creditState, setcreditState] = useState(0);
  const [loading2, setloading2] = useState(false);
  const [disableBtn, setdisableBtn] = useState(true);

  // print function
  const componentRef = useRef();

  const printPaymentLand = useReactToPrint({
    content: () => componentRef.current,
  });
  const [printDataModal, SetPrintDataModal] = useState();

  // print function

  useEffect(() => {
    if (resultslandtable) {
      setfilterdata(resultslandtable.data);
    }
  }, [resultslandtable]);

  useEffect(() => {
    if (printData !== undefined) {
      printPaymentLand();
    }
  }, [printData, statetoggle]);
  //Table Data fiter
  // var suggestionlist = [];
  // const onTextChange = (e) => {
  //     const value = e.target.value;
  //     if (value === "") {

  //         dispatch(getallLandTable({}));
  //     } else {
  //         let suggestions = [];
  //         if (value.length > 0) {
  //             const regex = new RegExp(`${value}`, "i");

  //             suggestions = resultslandtable.data
  //                 .sort()
  //                 .filter((val) =>
  //                     (regex.test(val.LandPaymentVoucherId) || regex.test(val.LandCreditor !== undefined && val.LandCreditor !== null ? val.LandCreditor.LandCreditorName : null)));

  //         }
  //         suggestionlist = suggestions;
  //         // dispatch(GetAllLandTableData(suggestionlist));
  //         setfilterdata(suggestionlist);

  //     }
  // };
  const onTextChange = (e) => {
    if (
      document.getElementById("creditorfilter").value === "" &&
      document.getElementById("datefilter").value === "" &&
      document.getElementById("voucherfilter").value === "" &&
      document.getElementById("landfilter").value === ""
    ) {
      setfilterdata(resultslandtable.data);
    } else {
      let suggestions = [];
      {
        const regex = new RegExp(
          document.getElementById("creditorfilter").value,
          "i"
        );
        const regex1 = new RegExp(
          document.getElementById("voucherfilter").value,
          "i"
        );
        const regex2 = new RegExp(
          document.getElementById("datefilter").value,
          "i"
        );
        const regex3 = new RegExp(
          document.getElementById("landfilter").value,
          "i"
        );

        suggestions = resultslandtable.data
          .sort()
          .filter(
            (val) =>
              (document.getElementById("creditorfilter").value === "" ||
                regex.test(val.LandCreditor.LandCreditorName)) &&
              (document.getElementById("voucherfilter").value === "" ||
                regex1.test(val.LandPaymentVoucherId)) &&
              (document.getElementById("datefilter").value === "" ||
                regex2.test(val.EntryDate)) &&
              (document.getElementById("landfilter").value === "" ||
                regex3.test(val.LandName))
          );
      }
      setfilterdata(suggestions);
    }
  };

  function closemodal() {
    document.getElementById("mymodal").style.display = "none";
  }
  useEffect(() => {
    let data = {};
    dispatch(getallLandTable(data));
  }, [dispatch]);
  function showmodal(data) {
    document.getElementById("mymodal").style.display = "block";
    setTransactionData(data);
    var credit = 0;
    var debit = 0;
    data?.map((val) =>
      val.TransactionType === "CREDIT"
        ? (credit += parseFloat(val.Amount))
        : val.TransactionType === "DEBIT"
        ? (debit += parseFloat(val.Amount))
        : null
    );
    setdebitState(debit);
    setcreditState(credit);
  }

  function ongo() {
    let data = {
      ProjectId: `${localStorage.getItem("ProjectId")}`,
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
    };

    dispatch(getallLandTable(data));
  }
  const [CsvData, setCsvData] = useState([]);
  useEffect(() => {
    let arr = [];
    var i = 1;

    filterdata?.forEach((val) => {
      let year = val.EntryDate.slice(0, 4);
      let day = val.EntryDate.slice(8, 10);
      let month = val.EntryDate.slice(5, 7);
      arr.push({
        Sr: i++,
        Date: day + "-" + month + "-" + year,
        "Voucher No": val.LoanReceiptVoucherId,
        Project:
          val.Project !== undefined && val.Project !== null
            ? val.Project.ProjectName
            : "",
        Creditor:
          val.LandCreditor !== undefined && val.LandCreditor !== null
            ? val.LandCreditor.LandCreditorName
            : "",
        Land: val.LandName !== null ? val.LandName : "",
        Amount: parseFloat(val.NetPaid).toLocaleString(),
      });
    });
    setCsvData(arr);
  }, [filterdata]);
  function showmodal2(data) {
    setcontractRowdata(data);
    document.getElementById("mymodal2").style.display = "block";
  }
  function deleteContractVoucher() {
    if (document.getElementById("landPaymentEmail").value === "") {
      return toast.info("Email is Required");
    } else if (document.getElementById("landPaymentPassword").value === "") {
      return toast.info("Password is Required");
    } else if (document.getElementById("landPaymentReason").value === "") {
      return toast.info("Reason is Required");
    }
    setloading2(true);
    setdisableBtn(false);
    const Email = document.getElementById("landPaymentEmail").value;
    const Password = document.getElementById("landPaymentPassword").value;
    const Reason = document.getElementById("landPaymentReason").value;
    // const data={
    //     Email:Email,
    //   Password:Password,

    // }
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}Scripts/DeleteLandPaymentVoucher?Email=${Email}&Password=${Password}&Reason=${Reason}&voucherId=${contractRowdata?.LandPaymentVoucherId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        // document.getElementById("headingsave").disabled = false;
        if (result.data.IsSuccess === true) {
          document.getElementById("landPaymentEmail").value = "";
          document.getElementById("landPaymentPassword").value = "";
          document.getElementById("landPaymentReason").value = "";
          let data = {
            ProjectId: `${localStorage.getItem("ProjectId")}`,
            From: document.getElementById("fromdate").value,
            To: document.getElementById("todate").value,
          };
          dispatch(getallLandTable(data));
          toast.info(result.data.Message);
          setdisableBtn(true);
          setloading2(false);
          setcontractRowdata(null);
          closemodal2();
        } else {
          toast.info(result.data.Message);
          document.getElementById("landPaymentEmail").value = "";
          document.getElementById("landPaymentPassword").value = "";
          document.getElementById("landPaymentReason").value = "";
          setdisableBtn(true);
          setloading2(false);
          // setvoucherRowdata(null)
        }
      },
      (error) => {
        toast.info("Someting went wrong");
        // document.getElementById("headingsave").disabled = false;
      }
    );
  }
  function closemodal2() {
    document.getElementById("mymodal2").style.display = "none";
    document.getElementById("landPaymentEmail").value = "";
    document.getElementById("landPaymentPassword").value = "";
    document.getElementById("landPaymentReason").value = "";
  }
  return (
    <>
      <div hidden>
        <LandPaymentPrint ref={componentRef} printData={printData} />
      </div>

      <div className="row pt-3">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="datefilter"
            onChange={onTextChange}
            placeholder="Date"
          ></input>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="voucherfilter"
            onChange={onTextChange}
            placeholder="Voucher"
          ></input>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="creditorfilter"
            onChange={onTextChange}
            placeholder="Creditor"
          ></input>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="landfilter"
            onChange={onTextChange}
            placeholder="Land"
          ></input>
        </div>
        <div className="col-md-1 pr-0 pl-0">
          <LandPaymentModal />
        </div>
        <div className="col-md-1 pr-0 pl-0">
          {resultslandtable.loading ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </div>
      </div>

      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0"></div>
        <div className="col-md-4  pr-0 pl-0">
          <label className="input_label m-0">From:</label>
          <input type="date" className="input_date ml-3" id="fromdate"></input>
        </div>
        <div className="col-md-4 pr-0 pl-0">
          <label className="input_label m-0">To:</label>
          <input type="date" className="input_date ml-3" id="todate"></input>
        </div>
        <div className="col-md-1 pr-0 pl-0">
          <button className="btn_Go" onClick={ongo}>
            Go
          </button>
        </div>
      </div>

      <div className="table-responsive vouchers_table_height mt-4">
        <table className="table table-borderless m-0">
          <thead>
            <tr>
              <th>Sr</th>
              <th>Date</th>
              <th>Added On</th>
              <th>Voucher No.</th>
              <th>Project</th>
              <th>Creditor</th>
              <th>Land</th>
              <th>Narration</th>
              <th>Amount</th>
              <th className="text-center">
                {rolesdata?.data?.Access?.includes("C") ? (
                  <button
                    className="save_btn"
                    onClick={() => {
                      props.showlandtable();
                    }}
                  >
                    New+
                  </button>
                ) : null}
              </th>
            </tr>
          </thead>
          <tbody>
            {filterdata?.length
              ? filterdata.map((val, index) => {
                  let year = val.EntryDate.slice(0, 4);
                  let day = val.EntryDate.slice(8, 10);
                  let month = val.EntryDate.slice(5, 7);
                  switch (month) {
                    case "01":
                      month = "Jan";

                      break;
                    case "02":
                      month = "Feb";

                      break;
                    case "03":
                      month = "Mar";

                      break;
                    case "04":
                      month = "Apr";

                      break;
                    case "05":
                      month = "May";

                      break;
                    case "06":
                      month = "Jun";

                      break;
                    case "07":
                      month = "Jul";

                      break;
                    case "08":
                      month = "Aug";

                      break;
                    case "09":
                      month = "Sep";

                      break;
                    case "10":
                      month = "Oct";
                      break;
                    case "11":
                      month = "Nov";
                      break;
                    case "12":
                      month = "Dec";
                      break;
                    default:
                      break;
                  }
                  return (
                    <tr key={index}>
                      <td
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {day + "-" + month + "-" + year}
                      </td>
                      <td
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {moment(
                          val.Transaction[0]?.AddedOn?.slice(0, 10),
                          "YYYY:MM-DD"
                        )?.format("DD-MMM-YYYY")}
                      </td>
                      <td
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {val.LandPaymentVoucherId}
                      </td>
                      {val.Project !== undefined && val.Project !== null ? (
                        <td
                          onClick={() => {
                            showmodal(val.Transaction);
                            SetPrintDataModal(val.Transaction);
                          }}
                        >
                          {val.Project.ProjectName}
                        </td>
                      ) : (
                        <td
                          onClick={() => {
                            showmodal(val.Transaction);
                            SetPrintDataModal(val.Transaction);
                          }}
                        ></td>
                      )}
                      {val.LandCreditor !== undefined &&
                      val.LandCreditor !== null ? (
                        <td
                          onClick={() => {
                            showmodal(val.Transaction);
                            SetPrintDataModal(val.Transaction);
                          }}
                        >
                          {val.LandCreditor.LandCreditorName}
                        </td>
                      ) : (
                        <td
                          onClick={() => {
                            showmodal(val.Transaction);
                            SetPrintDataModal(val.Transaction);
                          }}
                        ></td>
                      )}
                      {val.LandName !== null ? (
                        <td
                          onClick={() => {
                            showmodal(val.Transaction);
                            SetPrintDataModal(val.Transaction);
                          }}
                        >
                          {val.LandName}
                        </td>
                      ) : (
                        <td
                          onClick={() => {
                            showmodal(val.Transaction);
                            SetPrintDataModal(val.Transaction);
                          }}
                        ></td>
                      )}
                      <td
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {val.Narration}
                      </td>
                      <td
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {parseFloat(val.NetPaid).toLocaleString()}
                      </td>

                      <td className="text-center cur_sor">
                        {rolesdata?.data?.Access?.includes("U") ? (
                          <RiFileEditLine
                            title="Edit"
                            size="1em"
                            color={
                              val.Submitted === false ? "black" : "#707070"
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              if (val.Submitted === false) {
                                props.setvoucherData(val);
                                // props.showlandtable();
                              } else {
                                toast.info("Can't edit voucher");
                              }
                              // props.showgeneraltable();
                            }}
                            className="cur_sor"
                          />
                        ) : null}
                        &nbsp;
                        <GrPrint
                          onClick={() => {
                            SetPrintData(val);
                            settoggle(!statetoggle);
                          }}
                          size="1em"
                        />
                        &nbsp;
                        {localStorage.getItem("Designation") === "Admin" ? (
                          <MdDeleteForever
                            onClick={() => {
                              showmodal2(val);
                            }}
                            title="Delete"
                            className="cur_sor"
                            size="1em"
                          />
                        ) : null}
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
      <button
        style={{ color: "black !important" }}
        className="print_btn ml-2 float-left"
      >
        <CSVLink
          style={{ color: "black" }}
          filename="Land_Payment_Csv"
          data={CsvData}
        >
          Download CSV
        </CSVLink>
      </button>
      <LandPaymentTransactionDetailModal
        creditState={creditState}
        debitState={debitState}
        printDataModal={printDataModal}
        closemodal={closemodal}
        TransactionData={TransactionData}
      />
      <div id="mymodal2" class="modal">
        <div class="modal-content-cat_voucher2">
          <div className="row ">
            <div className="col-12  page_heading">
              <h4 className="  text-center pt-2">Delete Voucher</h4>
              <span class="close pr-2  " onClick={closemodal2}>
                &times;
              </span>
            </div>
          </div>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-12 " style={{ paddingTop: "10px" }}>
              <label className="input_label m-0">Email:</label>
            </div>
            <div className="col-12 ">
              <input
                style={{ width: "100%" }}
                className="input_total_styling "
                placeholder="Email"
                id="landPaymentEmail"
                type="email"
              ></input>
            </div>
            <div className="col-12 " style={{ paddingTop: "10px" }}>
              <label className="input_label m-0">Password:</label>
            </div>
            <div className="col-12 ">
              <input
                style={{ width: "100%" }}
                className="input_total_styling "
                placeholder="Password"
                id="landPaymentPassword"
              ></input>
            </div>
            <div className="col-12  " style={{ paddingTop: "10px" }}>
              <label className="input_label m-0">Reason:</label>
            </div>
            <div className="col-12  " style={{ width: "100%" }}>
              <textarea
                style={{ width: "100%", height: "100px" }}
                className="input_total_styling "
                placeholder="Reason"
                id="landPaymentReason"
                rows="4"
              ></textarea>
            </div>
            <div
              style={{
                display: "flex",
                float: "right",
                width: "100%",
                justifyContent: "end",
                marginRight: "3%",
                marginTop: "10px",
                alignItems: "center",
              }}
            >
              {loading2 && (
                <Loader type="ThreeDots" color="green" height={40} width={40} />
              )}
              <div>
                <span>
                  <button
                    className="cancel_btn ml-2  "
                    onClick={() => {
                      closemodal2();
                      setcontractRowdata(null);
                    }}
                  >
                    Cancel
                  </button>
                </span>
              </div>
              <div>
                <span>
                  <button
                    className="save_btn ml-2  "
                    disabled={!disableBtn}
                    onClick={deleteContractVoucher}
                  >
                    Save
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
