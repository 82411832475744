import React from "react";
import { useSelector } from "react-redux";
function PrintHeader() {
  const companyInfo = useSelector((state) => state.getCompany);
  return (
    <>
     <div
        style={{ height: "3in", minHeight: "3in", maxHeight: "3in" }}
      ></div>
    </>
  );
}

export default PrintHeader;
